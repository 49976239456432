
import grey from "@material-ui/core/colors/grey";

export const styles = theme => ({
    button: {
      marginLeft: theme.spacing(2),
      marginTop:-100, 
      marginRight:-24,
      "@media only screen and (max-width: 600px)": {
        marginRight:-18,
      }      
    },  
    leftIcon: {
      marginRight: theme.spacing(1),
      "@media only screen and (max-width: 600px)": {
        marginRight: 50,
      }      
    },  
    buttonText: {
      "@media only screen and (max-width: 600px)": {
        display: "none"
      }
    },  
    gridOverride: {
      //gets rid of blue outlines around cells
      //and headers when sorting
      "&:focus": {
        outline: "none"
      }
    },
    header: {
      "&:focus": {
        outline: "none" ,
      },
      "&:hover": {
        backgroundColor: theme.palette.grey[200]
      },
    },
    tableRow: {
      cursor: "pointer",
      borderBottom: "1px solid #e0e0e0", 
      display: "flex", 
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200]
      }
    },
  
    table: {
      fontFamily: theme.typography.fontFamily,
      width: "100%",
    },
    tableCell: {
      //flex: 1,
      flex: "0 0 0px !important",
      "@media only screen and (max-width: 650px)": {
        display: "none !important",
        flexBasis: "0 !important",
        minWidth: "0 !important",
        width: "0 !important",
        flex: "0 0 0px !important",
      }
    },
    tableCellFirst: {
      paddingLeft: 20,
      "@media only screen and (max-width: 400px)": { }
    },
    flexContainerFirst: {
      display: "flex",
      alignItems: "center",
      "@media only screen and (max-width: 400px)": {}
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      "@media only screen and (max-width: 650px)": {
        display: "none !important",
        flex: "0 0 0px !important",
        minWidth: "0 !important",
        width: "0 !important",
      }
    },
    hiddenHeader: {
      "@media only screen and (max-width: 650px)": {
        display: "none !important",
        flex: "0 !important",
        minWidth: "0 !important",
        width: "0 !important"
      }
    },
    hiddenRows: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box"
    },
    highlightedRows:{
      backgroundColor: theme.palette.grey[100]
    },
    actions: {
      color: theme.palette.text.primary
    },
    spacer: {
      flex: "1 1 100%"
    },
    title: {
      flex: "0 0 auto"
    },
    waitCircle: {
      width: "100%",
      textAlign: "center",
      float: "left",
      marginTop: "30vh"
    },
    column: {
      flex: 1
    },
    chip: {
      margin: 1
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
  });