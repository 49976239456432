import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import api from "../../Functions/APIHelpers";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: "relative",
    left: -16,
    //top: 20,
    top: 83,
    zIndex: 500,
    "@media only screen and (max-width: 400px)": {
      left: -25,
    },
  },
}));

const RecordNoteForm = ({
  Submission,
  Refresh,
  isAdding,
  setIsAdding,
  theme,
}) => {
  const classes = useStyles();
  const [noteText, setNoteText] = useState("");
  const [isPublic, setIsPublic] = useState(false);

  const handleAddSubmissionNote = () => {
    addSubmissionNote(Submission.id).then((r) => {
      setNoteText("");
      setIsAdding(false);
      setIsPublic(false);
      Refresh(Submission);
    });
  };

  const addSubmissionNote = (submissionID) => {
    let body = {
      message: noteText,
      author_name: "Joe",
      is_public: isPublic,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.addMedicareSubmissionNoteMEWA(submissionID, JSON.stringify(body))
      );
    });
  };

  return (
    <React.Fragment>
      {isAdding && (
        <div style={{ marginTop: 25, marginBottom: 15 }}>
          <TextField
            id="outlined-multiline-flexible"
            label="Note Text"
            multiline
            fullWidth
            //rowsMax={4}
            value={noteText}
            onChange={(e) => {
              if(noteText.length < 500)
                setNoteText(e.target.value);
            }}
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublic}
                onChange={(e) => {
                  setIsPublic(e.target.checked);
                }}
                value="Public Note"
                color="primary"
              />
            }
            //style={{ marginTop: 40 }}
            label="Public Note"
          />
        </div>
      )}
      {!isAdding && (
        <div id="fabContainer">
          <Fab
            color="primary"
            aria-label="Add"
            size="small"
            style={{ marginLeft: 17 }}
            //className={classes.fab}
            onClick={() => {
              setIsAdding(true);
            }}
          >
            <Add />
          </Fab>
        </div>
      )}
      {isAdding && (
        <React.Fragment>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setNoteText("");
              setIsAdding(false);
              setIsPublic(false);
            }}
            //disabled={this.state.arcRequestSaving}
            style={{
              marginRight: 10,
              color: theme.palette.error.main,
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleAddSubmissionNote}
            //disabled={this.state.arcRequestSaving}
            color="primary"
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            Save
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withStyles(null, { withTheme: true })(RecordNoteForm);
