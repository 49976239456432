import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


const styles = theme => ({
  formWrapper: {
    marginLeft: '17px',
    marginRight: '17px'
  },
  nameWrapper: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
});

const ContactForm = ({cancel, save, classes}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <div class={classes.formWrapper}>
      <div class={classes.nameWrapper}>
      <TextField
        id="first-name-text-field"
        label="First Name"
        margin="normal"
        onChange={(e) => setFirstName(e.target.value)}
        style={{marginRight: 10, flexGrow: '1'}}
        value={firstName}
      />
      <TextField
        id="last-name-text-field"
        label="Last Name"
        margin="normal"
        onChange={(e) => setLastName(e.target.value)}
        style={{flexGrow: '1'}}
        value={lastName}
      />
      </div>
      <TextField
        id="email--text-field"
        label="Email"
        margin="dense"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        fullWidth
      />
      <Button onClick={cancel} color="primary">
        Cancel
      </Button>
      <Button onClick={()=>{save(firstName, lastName, email)}} color="primary">
        Save
      </Button>
    </div>
  );
};

export default withStyles(styles)(ContactForm);
