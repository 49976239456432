import React from "react";

import Typography from "@material-ui/core/Typography";
import api from "../../Shared/Functions/APIHelpers";

class ConfirmConfirmationContainer extends React.Component {
  state = {
    confirmationStatus: "",
  };

  componentDidMount() {
    let confirmationID = this.getConfirmationID();
    if (confirmationID) {
      this.handleConfirm(confirmationID);
    }
  }

  getConfirmationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let confirmationID = params.get("confirmationID");

    if (!confirmationID)
      this.props.history.push(
        "/employer/auth?error=7&portalID=" + this.props.portalID
      );
    else return confirmationID;
  };

  handleConfirm = (confirmationID) => {
    this.confirmContinuation(confirmationID).then((r) => {
      this.setState({ confirmationStatus: r.confirmation_status });
    });
  };

  confirmContinuation = (confirmationID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.confirmRequestSecured(confirmationID));
      else resolve(api.confirmRequest(confirmationID));
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.confirmationStatus === "complete" && (
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Typography variant="h6">
              Thank you. Your continuation status has been confirmed.
            </Typography>
          </div>
        )}
        {this.state.confirmationStatus === "verified" && (
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Typography variant="h6">
              Thank you. Your continuation status has already been confirmed.
            </Typography>
          </div>
        )}
        {this.state.confirmationStatus === "invalid" && (
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Typography variant="h6">
              Confirmation Code Does Not Exist
            </Typography>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ConfirmConfirmationContainer;
