import React from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import TextField from "@material-ui/core/TextField";
import BrokerInviteEmployerAsyncSelect from "./BrokerInviteEmployerAsyncSelect";
import api from "../../Shared/Functions/APIHelpers";

import Divider from "@material-ui/core/Divider";

class ViewTeamMemberDialog extends React.Component {
  state = {
    employers: []
  };
  componentDidMount() {}
  
  getPermissionLevel = (permissionLevel) => {
    if (permissionLevel === 0) return "Notify";
    if (permissionLevel === 1) return "View";
    if (permissionLevel === 2) return "Update";
    return "";
  };

  close = () => {
    this.setState({ email: "" }, () => {
      this.props.Close();
    });
  };


  handleClose = () => {
    this.close();
  };

  handleAdjustPermissions = () =>{
    this.props.history.push("/broker/edit-team-member?brokerID="+this.props.TeamMember.id);
  }

  render() {
    const { fullScreen, TeamMember } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">{TeamMember.first_name} {TeamMember.last_name}'s Employers<br/><Typography variant="body1">{TeamMember.email}</Typography></DialogTitle>

          <DialogContent style={{ minHeight: 400, minWidth: 325, maxWidth: 450 }}>
          <Typography variant="body1">
              This individual has access to the following of your employers.
            </Typography>             
            <div style={{ marginTop: 30 }}>
              {TeamMember.employers.map((employer, index) => {
                return (
                  <div key={index} style={{ display: "block" }}>
                    <div>
                      <Typography variant="h6">
                      {employer.name}
                      </Typography>
                    </div>
                    <div>
                    <Typography variant="body2">
                        Permission Level{": "}
                        {this.getPermissionLevel(employer.permission_level)}
                    </Typography>
                    <Typography variant="body2">
                        Set by employer: {employer.relationship_level === 1 ? "Yes" : "No"} {" "}
                    </Typography>                    
                    </div>
                    <Divider style={{marginTop:20, marginBottom:20}}/>
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <div style={{width:"100%"}}>
              <div style={{float:"left"}}>
                <Button onClick={this.handleAdjustPermissions} color="primary">
                  Adjust Permissions
                </Button>
              </div>
              <div style={{float:"right"}}>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(ViewTeamMemberDialog));
