import React from "react";

import Typography from "@material-ui/core/Typography";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Auth } from "aws-amplify";
import api from "../../Functions/APIHelpers";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
});

class BrokerAgreement extends React.Component {
  state = {
    open: true,
    checkAgree: false,
  };

  handleAgree = () => {
    api.setBrokerTOS(this.props.portalID).then((res) => {
      this.props.Close();
      //this.setState({open:false})
    });
  };

  handleOpenTerms = () => {
    var url = "https://mewa.io/terms-of-use";
    var win = window.open(url, "_blank");
    win.focus();
  };

  handleClose = () => {
    window.location.href = "https://mewa.io/";
  };

  render() {
    const { fullScreen, classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.Open && this.state.open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          maxWidth={"lg"}
          scroll="paper"
        >
          <DialogContent
            style={{ maxHeight: 250, maxWidth: 500, textAlign: "center" }}
          >
            <div className={classes.logoContainer}>
              <img
                src={require("../../Images/mewaio_logo_horiz.png")}
                style={{ height:40}}
                alt="Reform Health Logo"
              />
            </div>
            <br />
            <Typography variant="body">
              Before continuing, you must read and agree to the <a  target="_blank" href="https://mewa.io/terms-of-use">Terms Of Use</a>
            </Typography>

            <br />
            <br /> <br />
            <Checkbox
              checked={this.state.checkAgree}
              onChange={(event) => {
                this.setState({ checkAgree: event.target.checked });
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />{" "}
            <Typography variant="body">
              I have read and agree to the Terms of Use
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
            >
              Close
            </Button>
            <Button
              onClick={this.handleAgree}
              disabled={!this.state.checkAgree}
              color="primary"
            >
              Agree and Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(BrokerAgreement));
