import { InputAdornment, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { RemoveRedEye } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { Component } from "react";

const styles = theme => ({
  //eye: {
    //cursor: "pointer"
  //}
});

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true
    };
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));
  };

  render() {
    const { passwordIsMasked } = this.state;

    return (
      <TextField
        type={passwordIsMasked ? "password" : "text"}
        {...this.props}
        value={this.props.value ? this.props.value : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <RemoveRedEye
                onClick={this.togglePasswordMask}
                style={{ color: "grey", cursor:"pointer" }}
              />
            </InputAdornment>
          )
        }}
      />
    );
  }
}

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  //value: PropTypes.func.isRequired
};

export default (PasswordInput = withStyles(styles)(PasswordInput));
