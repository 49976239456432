import React from "react";
import MaskedInput, {conformToMask} from 'react-text-mask';

const dispayPhoneNumberMask = [
    "(",
    /\d/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
]

const outputPhoneNumberMask = [
    "+",
    1,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const PhoneNumberTextBox = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={dispayPhoneNumberMask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

export default PhoneNumberTextBox;



export function mapPhoneToMask(phoneNumber) {
      
	return conformToMask(
        phoneNumber, 
        dispayPhoneNumberMask,
        {guide: false}
    )
  }

  export function outputPhoneFromMask(phoneNumberWithMask) {
      return conformToMask(
          phoneNumberWithMask,
          outputPhoneNumberMask,
          {guide:false}
      )
  }
