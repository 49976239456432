import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import PageHeader from "../../Nav/PageHeader";
import SignatureModal from "./SignatureModal";

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class SignatureContainer extends React.Component {
  state = {
    signatureModalOpen: false,
  };

  handleClose = () => {
    this.setState({ signatureModalOpen: false });
  };

  sign = () => {
    this.setState({ signatureModalOpen: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <SignatureModal
          isOpen={this.state.signatureModalOpen}
          Close={this.handleClose}
          Public={true}
          ID={this.props.ID}
          PortalID={this.props.PortalID}
          UpdateSignature={this.props.UpdateSignature}
        />
        <PageHeader
          hasBack={true}
          hasNext={true}
          pageTitle="Signature"
          backText="View Form"
          nextText="Sign"
          back={this.props.Back}
          next={this.sign}
        />
        {this.props.RequesterIsSig && (
          <Card style={{ marginBottom: 50, marginTop: 25 }}>
            <CardHeader
              subheader="Signer cannot be the same as Medicare Beneficiary/Employee"
              style={{ backgroundColor: "#FFCC00" }}
            />
            <CardContent>
              <Typography variant="body2" style={{ marginTop: 10 }}>
                CMS requires that the employer representative who signs the
                exception request cannot be the same as the Medicare
                Beneficiary/Employee. The individual entered on the previous
                page should sign this form.
              </Typography>
            </CardContent>
          </Card>
        )}
        <Card style={{ marginTop: 20 }}>
          <CardHeader
            subheader="Signature Instructions"
            style={{ backgroundColor: "#f2f2f2" }}
          />
          <CardContent>
            <Typography variant="body1" style={{ textAlign: "left" }}>
              Please affirm that the information on the previous pages as well
              as the forms you have reviewed are complete and correct to the
              best of your knowledge by drawing or typing your signature.
            </Typography>
            <div style={{ clear: "both", height: 12 }} />
            <div
              style={{ marginTop: 30, marginBottom: 50, textAlign: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                id="final-sign-form-button"
                onClick={this.sign}
              >
                <EditIcon className={classes.leftIcon} />
                <div>Sign My Form</div>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(SignatureContainer);
