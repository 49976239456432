import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import EmailIcon from "@material-ui/icons/Email";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitIcon from "@material-ui/icons/ExitToApp";
import HelpIcon from "@material-ui/icons/Help";

import AccountCircle from "@material-ui/icons/AccountCircle";

import { Auth } from "aws-amplify";

const styles = {
  root: {
    flexGrow: 1,
  },
  title: {
    "@media only screen and (max-width: 400px)": {
      //display:'none',
      fontSize: 16,
    },
    textDecoration: "none",
    color: "white",
    alignItems: "center",
  },
  headerlogo: {
    height: 36,
    //"@media only screen and (max-width: 400px)": {
    //  width: 120,
    //},
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 10,
  },
  avatar: {
    height: 30,
    width: 30,
    margin: 0,
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  grad: {
    background: "linear-gradient(45deg, #2996cc 30%, #53abd6 90%)",
  },
  portalTitle: {
    color: "white",
    marginTop: 0,
    marginBottom: 0,
    "@media only screen and (max-width: 400px)": {
      display: "none",
    },
  },
};

class MenuAppBar extends React.Component {
  state = {
    anchorElSettings: null,
    anchorEl: null,
  };

  handleSettingsMenu = (event) => {
    this.setState({ anchorElSettings: event.currentTarget });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseSettings = () => {
    this.setState({ anchorElSettings: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  goMyAccount = () => {
    let url = "";
    if (this.props.app === "admin") url = "/mewa-admin/account";
    if (this.props.app === "broker") url = "/broker/account";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    if (this.props.homeURLContainsParams) {
      url = "/employer/account" + window.location.search;
    }
    this.props.history.push(url);
    this.setState({ anchorEl: null });
  };

  goViewInvitations = () => {
    let url = "/broker/view-invitations";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    this.props.history.push(url);
    this.setState({ anchorEl: null });
  };

  goViewTeam = () => {
    let url = "/broker/view-team";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    this.props.history.push(url);
    this.setState({ anchorEl: null });
  };

  goManageEmployers = () => {
    let url = "/broker/manage-employers";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    this.props.history.push(url);
    this.setState({ anchorEl: null });
  };

  goManageBroker = () => {
    let url = "/employer/manage-brokers" + window.location.search;
    this.props.history.push(url);
    this.setState({ anchorEl: null });
  };

  goViewInvitationEmployer = () => {
    let url = "/employer/my-invitations" + window.location.search;
    this.props.history.push(url);
    this.setState({ anchorEl: null });
  };

  goHelp = () => {
    let url = "https://support.mewa.io";
    var win = window.open(url, "_blank");
    win.focus();
    this.setState({ anchorEl: null });
  };

  getMEWAID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let mewaID = params.get("mewaID") ? params.get("mewaID") : null;
    return mewaID;
  };

  handleSignOut = () => {
    this.signOut();
  };

  signOut() {
    Auth.signOut()
      .then((data) => {
        localStorage.clear();
        this.props.history.push(this.props.logoutURL);
      })
      .catch((err) => {
        console.log(err);
        this.props.history.push(this.props.logoutURL);
      });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            background:
              "linear-gradient(45deg, " +
              this.props.theme.header_color_1 +
              " 30%, " +
              this.props.theme.header_color_2 +
              " 90%)",
          }}
        >
          <Toolbar>
            {!this.props.hasAccount && (
              <img
                src={this.props.theme.header_logo}
                className={classes.headerlogo}
                alt="Mewa.io"
              />
            )}
            {this.props.hasAccount && (
              <React.Fragment>
                <IconButton
                  className={classes.menuButton}
                  onClick={this.props.openNavDrawer}
                  aria-label="Menu"
                  id="nav-drawer-icon-button"
                >
                  <MenuIcon
                    style={{ color: this.props.theme.header_icon_color }}
                  />
                </IconButton>
                <Link to={this.props.home} className={classes.grow}>
                  <img
                    src={this.props.theme.header_logo}
                    className={classes.headerlogo}
                    alt="Mewa.io"
                  />
                </Link>

                <React.Fragment>
                  <div className={classes.portalTitle}>
                    {this.props.app === "admin" && <div>Admin portal</div>}
                    {this.props.app === "employer" && (
                      <div>Employer portal</div>
                    )}
                    {this.props.app === "broker" && <div>Broker portal</div>}
                  </div>
                </React.Fragment>

                <IconButton
                  aria-owns={open ? "menu-appbar" : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  style={{ margin: 5 }}
                  id="avatarClickButton"
                >
                  {(false && (
                    <Avatar
                      src={this.props.user.attributes.picture}
                      className={classes.avatar}
                      style={{ color: this.props.theme.header_icon_color }}
                    />
                  )) || (
                    <AccountCircle
                      style={{ color: this.props.theme.header_icon_color }}
                    />
                  )}
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.goMyAccount}>
                    <SettingsIcon style={{ marginRight: 10 }} />
                    My Account
                  </MenuItem>
                  {this.props.app === "broker" && (
                    <MenuItem onClick={this.goViewInvitations}>
                      <EmailIcon style={{ marginRight: 10 }} />
                      View Invitations
                    </MenuItem>
                  )}
                  {this.props.app === "broker" && (
                    <MenuItem onClick={this.goViewTeam}>
                      <GroupIcon style={{ marginRight: 10 }} />
                      Manage My Team
                    </MenuItem>
                  )}
                  {this.props.app === "broker" && (
                    <MenuItem onClick={this.goManageEmployers}>
                      <BusinessIcon style={{ marginRight: 10 }} />
                      Manage My Employers
                    </MenuItem>
                  )}
                  {this.props.app === "employer" && (
                    <MenuItem onClick={this.goManageBroker}>
                      <GroupIcon style={{ marginRight: 10 }} />
                      Manage Brokers
                    </MenuItem>
                  )}
                  {this.props.app === "employer" && (
                    <MenuItem onClick={this.goViewInvitationEmployer}>
                      <EmailIcon style={{ marginRight: 10 }} />
                      My Invitations
                    </MenuItem>
                  )}

                  <MenuItem onClick={this.goHelp}>
                    <HelpIcon style={{ marginRight: 10 }} />
                    Help
                  </MenuItem>
                  {!this.props.hideSignout && (
                    <MenuItem id="signOutButton" onClick={this.handleSignOut}>
                      <ExitIcon style={{ marginRight: 10 }} />
                      Sign Out
                    </MenuItem>
                  )}
                </Menu>
              </React.Fragment>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuAppBar);
