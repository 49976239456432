import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";

import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

import TextFilter from "./TextFilter";
import { styles } from "./TableStyles";

const TableFilters = ({
  classes,
  theme,
  columns,
  loadedData,
  staticFilterValues,
  setActiveFilters,
  activeFilters,
  enqueueSnackbar,
  tableName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [textFilters, setTextFilters] = useState({});

  const handleOpenFilterMenu = (e) => {
    setAnchorEl(e.currentTarget);
    setFiltersOpen(true);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
    setFiltersOpen(false);
  };

  const addFilter = (filterValue, filter) => {
    //check if new data key is in activeFilters
    let columnFilter = activeFilters.findIndex(
      (x) => x[filter.dataKey] !== undefined
    );

    //define new filter object that needs added
    let newFilter = {
      [filter.dataKey]: filterValue,
      label: filter.label,
      dataKey: filter.dataKey,
      nestedCol: filter.nestedCol,
    };

    //if the column already had a filter, replace with new one at index if its not default
    if (columnFilter >= 0) {
      if (filterValue === "default") {
        setActiveFilters(activeFilters.filter((f, i) => i !== columnFilter));
      } else {
        activeFilters[columnFilter] = newFilter;
        setActiveFilters(activeFilters);
      }
    } //if not just add filter unless default
    else {
      if (filterValue !== "default")
        setActiveFilters([...activeFilters, newFilter]);
    }
  };

  const removeFilter = (filter) => {
    setActiveFilters(activeFilters.filter((f) => f.dataKey !== filter.dataKey));
    setTextFilters({});
  };

  const resetFilters = () => {
    setActiveFilters([]);
    setTextFilters({});
  };

  return (
    <React.Fragment>
      <Toolbar style={{ minHeight: 0, height: 36}}>
        <div
          className={classes.title}
          style={{ color: theme.header_text_color, display:"none" }}
        >
          <Typography variant="h4" id="tableTitle">
            {tableName}
          </Typography>
        </div>
        <div style={{width:"100%", height: 36}}>
              <div style={{float:"left", marginLeft:-16}}>
                    {activeFilters.map((filter, index) => {
                return (
                  <Chip
                    key={index}
                    label={filter.label + " : " + filter[filter.dataKey]}
                    onDelete={() => {
                      removeFilter(filter);
                    }}
                    className={classes.chip}
                  />
                );
              })}

              </div>
              <div style={{float:"right"}}>
              {/*<Button
                  style={{marginTop:-100, marginRight:10}}
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenFilterMenu}
                  //className={classes.button}
                  startIcon={<AddIcon>Add</AddIcon>}
                >
                  Add
              </Button>*/}
                <Button
                  //style={{marginTop:-100, marginRight:-24}}
                  variant="outlined"
                  //color="disabled"
                  onClick={handleOpenFilterMenu}
                  className={classes.button}
                  //className={classes.leftIcon}
                  //startIcon={<FilterListIcon>Filter</FilterListIcon>}
                >
                  <FilterListIcon className={classes.rightIcon} />
                  <div className={classes.buttonText} style={{color:"grey"}}>Filter</div>
                </Button>
              </div>
        </div>
      </Toolbar>
      <Popover
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={filtersOpen}
        onClose={handleCloseFilterMenu}
      >
        <div style={{ width: 275, padding: 20 }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Filters</Typography>
            <Button
              style={{ marginLeft: 20 }}
              color="primary"
              onClick={resetFilters}
            >
              Reset
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            {columns
              .filter((c) => c.hasFilter && c.filterType === "All")
              .map((filter, index) => {
                let selectValue = activeFilters.find(
                  (f) => f.dataKey === filter.dataKey
                );

                return (
                  <FormControl margin="dense" key={index}>
                    <InputLabel htmlFor="filter">{filter.label}</InputLabel>
                    <Select
                      value={selectValue ? selectValue[filter.dataKey] : ""}
                      onChange={(e) => {
                        addFilter(e.target.value, filter);
                      }}
                      index={index}
                      inputProps={{
                        name: "filter",
                        id: "filter",
                      }}
                      fullWidth={true}
                    >
                      <MenuItem value="default">All</MenuItem>
                      {loadedData
                        .reduce(
                          (f, r) =>
                            f.find(
                              (i) => i[filter.dataKey] === r[filter.dataKey]
                            )
                              ? f
                              : [...f, r],
                          []
                        )
                        .map((row, index) => {
                          return (
                            <MenuItem key={index} value={row[filter.dataKey]}>
                              {row[filter.dataKey]}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                );
              })}
            {columns
              .filter((c) => c.hasFilter && c.filterType === "Static")
              .map((filter, index) => {
                //Get static filter values for this column
                let searchTerm = (filter.nestedCol) ? filter.nestedCol : filter.dataKey;
                let static_value_object = staticFilterValues.find(sf => Object.keys(sf).toString() === searchTerm)
                let static_values = static_value_object ? Object.values(static_value_object) : null;
                let selectValue

                //check if filter has an active selected value  different if nested col
                if(filter.nestedCol){
                  selectValue = activeFilters.find(
                  (f) => f.nestedCol === searchTerm
                );
                }
                else{
                  selectValue = activeFilters.find(
                  (f) => f.dataKey === searchTerm
                );
                }
                
                
                if (static_values === null)
                  return (null)

                return (
                  <FormControl margin="dense" key={index}>
                    <InputLabel htmlFor="filter">{filter.label}</InputLabel>
                    <Select
                      value={selectValue ? selectValue[filter.dataKey] : ""}
                      onChange={(e) => {
                        addFilter(e.target.value, filter);
                      }}
                      index={index}
                      inputProps={{
                        name: "filter",
                        id: "filter",
                      }}
                      fullWidth={true}
                    >
                      <MenuItem value="default">All</MenuItem>
                      {static_values[0]
                        .map((val, index) => {
                          return (
                            <MenuItem key={index} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                );
              })}
            {columns
              .filter((c) => c.hasFilter && c.filterType === "Search" && !c.nestedCol)
              .map((filter, index) => {
                let hasActiveFilter =
                  activeFilters.findIndex(
                    (f) => f.dataKey === filter.dataKey
                  ) >= 0;

                return (
                  <TextFilter
                    key={index}
                    hasActiveFilter={hasActiveFilter}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    textFilters={textFilters}
                    setTextFilters={setTextFilters}
                    filter={filter}
                  />
                );
              })}
              {columns
              .filter((c) => c.hasFilter && c.filterType === "Search" && c.nestedCol)
              .map((filter, index) => {
                
                let hasActiveFilter =
                  activeFilters.findIndex(
                    (f) => ( (f.dataKey === filter.dataKey) && (filter.nestedCol === f.nestedCol)  )
                  ) >= 0;
                  
                return (
                  <TextFilter
                    key={index}
                    hasActiveFilter={hasActiveFilter}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    textFilters={textFilters}
                    setTextFilters={setTextFilters}
                    filter={filter}
                  />
                );
              })}
          </div>
        </div>
      </Popover>

      <div id="filterChips" style={{ paddingLeft: 28 }}>

      </div>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(TableFilters);
