import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { styles } from "./Styles/AuthStyles";

import { Auth } from "aws-amplify";

class ChangePassword extends React.Component {
  state = {
    newPassword: ""
  };

  handleSave = () => {

     Auth.completeNewPassword(
            this.props.user,              
            this.state.newPassword,       
        ).then(user => {
            this.props.history.push(this.props.homeURL);
        }).catch(e => {
          if(e.code === "InvalidPasswordException"){
            this.props.enqueueSnackbar(("Password must be at least 8 characters, contain 1 number, and 1 special character"), {
              variant: "error"
            });
          }
          console.log(e);
        });

  };


  render() {
    return (
      <React.Fragment>
        <Typography variant="body2" color="inherit">
          Change Your Password
        </Typography>

        <Typography variant="h6" gutterBottom>
          Password requirements
        </Typography>
        <div>
          <ul style={{textAlign:'left', width:'60%', margin:'auto', marginBottom: 10}}>
            <li>At least 8 characters</li>
            <li>At least 1 number</li>
            <li>At least 1 special character</li>
          </ul>
        </div>
        <form>
          <TextField
            id="standard-dense-new-password"
            label="New Password"
            margin="dense"
            type="Password"
            onChange={e =>
              this.setState({
                newPassword: e.target.value,
                hasChanged: true
              })
            }
            fullWidth
          />
        </form>
        <Button onClick={this.handleSave} color="primary" autoFocus>
          Save
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ChangePassword);