import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ModalStyles";
import api from "../../Functions/APIHelpers";
import UploadNotification from "./UploadNotification";

const initialState = {
  startDate: "",
  startDateError: false,
  endDate: "",
  endDateError: false,
  displaySignature: false,
  signatureError: false,
  signatureThumbnail: "",
  uploadedFile: {
    name: "",
  },
  uploading: false,
};

class TerminationUploadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  uploadMedicareFile = () => {
    var body = JSON.stringify({
      uploaded: "",
    });

    const formData = new FormData();
    formData.append("file", this.state.uploadedFile);
    formData.append("meta_data", body);

    return new Promise((resolve, reject) => {
      api
        .uploadMedicareFile(formData)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.setState({ errorMessage: "Error uploading file" });
          console.log(err);
        });
    });
  };

  uploadTerminationFile = () => {
    var body = JSON.stringify({
      uploaded: "",
    });

    const formData = new FormData();
    formData.append("file", this.state.uploadedFile);
    formData.append("meta_data", body);

    return new Promise((resolve, reject) => {
      api
        .uploadTerminationFile(formData)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.setState({ errorMessage: "Error uploading file" });
          reject(err);
          console.log(err);
        });
    });
  };

  handleSave = () => {
    this.setState({ uploading: true }, () => {
      if (this.props.type === "medicare") {
        this.uploadMedicareFile().then((r) => {
          this.props.enqueueSnackbar("Upload Complete", {
            variant: "success",
            content: (key, message) => (
              <UploadNotification id={key} message={message} results={r} />
            ),
            persist: true,
          });
          this.setState({ uploading: false }, () => {
            this.props.RefreshTable();
            this.close();
          });
        });
      }
      if (this.props.type === "termination") {
        this.uploadTerminationFile().then((r) => {
          this.props.enqueueSnackbar("Upload Complete", {
            variant: "success",
            content: (key, message) => (
              <UploadNotification id={key} message={message} results={r} />
            ),
            persist: true,
          });
          this.setState({ uploading: false }, () => {
            this.props.RefreshTable();
            this.close();
          });
        });
      }
    });
  };

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  getValidForm = () => {
    if (this.props.type === "medicare") return this.isValidFormMedicare;
    if (this.props.type === "termination") return this.isValidFormTermination;
  };

  isValidFormMedicare = () => {
    this.setState({
      startDateError: this.state.startDate === "",
      endDateError: this.state.endDate === "",
      signatureError:
        this.state.displaySignature && this.state.signatureThumbnail === "",
    });
    if (
      !this.startDateError &&
      !this.state.endDateError &&
      !this.state.signatureError
    ) {
      return true;
    }
  };

  isValidFormTermination = () => {
    this.setState({
      startDateError: this.state.startDate === "",
      endDateError: this.state.endDate === "",
    });
    if (!this.startDateError && !this.state.endDateError) {
      return true;
    }
  };

  render() {
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
          maxWidth={"lg"}
        >
          <DialogTitle id="form-dialog-title">Upload Bulk Records</DialogTitle>

          <DialogContent style={{ minHeight: 100 }}>
            {!this.state.uploading && (
              <React.Fragment>
                <input
                  accept="pdf/*"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    this.setState({ uploadedFile: e.target.files[0] });
                  }}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    className={classes.button}
                    style={{ marginLeft: 0, marginTop: 20, marginBottom: 10 }}
                  >
                    Select File
                  </Button>
                </label>

                {this.state.uploadedFile.name !== "" && (
                  <React.Fragment>
                    <div>
                      File uploaded: {String(this.state.uploadedFile.name)}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {this.state.uploading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress style={{ marginTop: 100, marginBottom: 100 }} size={60} />
              </div>
            )}
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Close
            </Button>            
            <Button onClick={this.handleSave} color="primary">
              Upload
            </Button>

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(TerminationUploadDialog));
