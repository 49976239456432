import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { styles } from "./Styles/AuthStyles";

import PasswordInput from "./Shared/Components/PasswordInput";



class SignUp extends React.Component {
  state = {
    email: "",
    password: ""
  };

  render() {
    const { classes, showConfirmation } = this.props;
    return (
      <div>
        {!showConfirmation && (
          <div>
            <div>
              <Typography variant="h4" color="inherit" noWrap>
                Sign Up
              </Typography>
            </div>

            <form>
              <div>
                <TextField
                  id="standard-email-dense"
                  label="Email"
                  error={this.props.emailError}
                  helperText={this.props.emailError ? "Required" : ""}
                  className={classes.textField}
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="dense"
                  onChange={e => this.setState({ email: e.target.value })}
                  defaultValue={this.state.email}
                  fullWidth
                />
              </div>
              <div>
                <PasswordInput
                  id="standard-password-input"
                  label="Password"
                  error={this.props.passwordError}
                  helperText={this.props.passwordError ? "Required" : ""}
                  //name="password"
                  className={classes.textField}
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  fullWidth
                />
              </div>
            </form>
            <br />
            <div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button)}
                  onClick={() => {
                    this.props.SignUp(this.state.email, this.state.password);
                  }}
                  style={{ marginLeft: 0, marginTop: 10 }}
                  fullWidth
                >
                  Create Account
                </Button>
              </div>

              <div className={classes.needAccountContainer}>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={() => this.props.action("signIn")}
                >
                  Already Have an Account?
                </Button>
              </div>
            </div>
          </div>
        )}
        {showConfirmation && (
          <div>
            <div>
              <Typography variant="h4" color="inherit" noWrap>
                Confirm
              </Typography>
            </div>
            <br />
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                Enter the code sent to {this.state.email} to confirm your
                account.
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-code-dense"
                label="Confirmation Code"
                className={classes.textField}
                name="code"
                margin="dense"
                onChange={e => this.setState({ code: e.target.value })}
                style={{ width: 300 }}
              />
            </div>
            <br />
            <div>
              <div className={classes.leftDiv}>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  onClick={this.props.back}
                >
                  Back
                </Button>
              </div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={() => {
                    this.props.ConfirmSignUp(
                      this.state.email,
                      this.state.password,
                      this.state.code
                    );
                  }}
                  style={{ width: 170 }}
                >
                  Confirm Signup
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SignUp);