import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class ConfirmationRequestDetail extends React.Component {

  close = () => {
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  getActiveStep = () => {
    let activeStep = -1
    if(this.props.Confirmation.date_sent)
        activeStep = 0
    if(this.props.Confirmation.date_opened)
        activeStep = 1
    if(this.props.Confirmation.date_verified || this.props.Confirmation.date_closed)
        activeStep = 2
    return activeStep
  }
  render() {
    const { Open } = this.props;
    let activeStep = this.getActiveStep();

    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Confirmation Tracker
            <br/>
            <Typography variant="caption">
            Sent To: {this.props.Confirmation.employer_contact_email}
            </Typography>

          </DialogTitle>

          <DialogContent style={{ height: 300, width: 250 }}>
          
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step expanded={true} key={0}>
                <StepLabel>
                  <Typography variant="subtitle1">Record Sent</Typography>
                  {activeStep >= 0 && (
                    <Typography variant="caption" display="block" gutterBottom>
                      {formatDateTime(this.props.Confirmation.date_sent)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>
              <Step expanded={true} key={1}>
                <StepLabel>
                  <Typography variant="subtitle1">Record Opened</Typography>
                  {activeStep > 0 && (
                    <Typography variant="caption" gutterBottom>
                    {formatDateTime(this.props.Confirmation.date_opened)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>
              <Step expanded={true} key={2}>
                <StepLabel>
                  <Typography variant="subtitle1">Record Verified</Typography>
                  {activeStep > 1 && (
                    <Typography variant="caption" display="block" gutterBottom>
                    {formatDateTime(this.props.Confirmation.date_verified)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>
            </Stepper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(ConfirmationRequestDetail));
