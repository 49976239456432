import React from "react";

import Button from "@material-ui/core/Button";
import TerminationForm from "../../Shared/Components/Terminations/TerminationForm";

//import OpenTerminationDialog from "./OpenTerminationDialog";
import TerminationHelpDialog from "../../Shared/Components/Terminations/TerminationHelpDialog";

import { outputEINFromMask } from "../../Shared/Components/Input/EINTextBox";

import api from "../../Shared/Functions/APIHelpers";
import * as moment from "moment";

class Termination extends React.Component {
  state = {
    loaded: false,
    openListModalOpen: false,
    hasAuthorization: true,
    openList: [],
    historyList: [],

    //state variables for termination copied over from the TerminationContainer
    employer_name: this.props.terminationDetail.employer_name || "",
    EIN: this.props.terminationDetail.EIN || "",
    employer_contact_email:
      this.props.terminationDetail.employer_contact_email || "",
    employee_first: this.props.terminationDetail.employee_first || "",
    employee_last: this.props.terminationDetail.employee_last || "",
    employee_street: this.props.terminationDetail.employee_street || "",
    employee_city: this.props.terminationDetail.employee_city || "",
    employee_state: this.props.terminationDetail.employee_state
      ? this.props.terminationDetail.employee_state.id
      : "",
    employee_zip: this.props.terminationDetail.employee_zip || "",
    termination_date: this.props.terminationDetail.termination_date
      ? moment(this.props.terminationDetail.termination_date).format("YYYY-MM-DD")
      : null,
    termination_reason: this.props.terminationDetail.termination_reason || 0,
    members: this.props.terminationDetail.members || [],
    canEditTerminationDate: this.props.terminationDetail.can_edit_termination_date,
    errors: [],
    helpModal: false,
  };

  componentDidMount(){
    this.props.setCheckError(this.inError)
    this.props.setGetBody(this.getBody)
  }

  onChange = (value, field) => {
    this.setState({ [field]: value });
  };

  inError = () => {
    let errors = [];
    const body = this.getBody();
    Object.entries(body).map((field) => {
      if (field[0] === "termination_reason" && field[1] === 0)
        errors.push(field[0]);
      if (field[1] === "") errors.push(field[0]);
    });
    this.setState({ errors: errors });
    return errors.length > 0;
  };

  getBody = () => {
    return {
      employer_name: this.state.employer_name,
      EIN: outputEINFromMask(this.state.EIN).conformedValue,
      employer_contact_email: this.state.employer_contact_email,

      employee_first: this.state.employee_first,
      employee_last: this.state.employee_last,

      employee_street: this.state.employee_street,
      employee_city: this.state.employee_city,
      employee_state_id: this.state.employee_state,
      employee_zip: this.state.employee_zip,

      termination_reason: this.state.termination_reason,
      termination_date: this.state.termination_date
        ? this.state.termination_date
        : "",
    };
  };

  render() {

    return (
      <React.Fragment>
        
        <TerminationHelpDialog
          Open={this.state.helpModal}
          Close={() => {
            this.setState({ helpModal: false });
          }}
        />

        <div style={{ paddingBottom: 50, display: "none" }}>
          <Button
            color="primary"
            onClick={() => {
              console.log(this.state.historyList);
              this.setState({ openListModalOpen: true });
            }}
          >
            View History
          </Button>
        </div>
        {this.props.loaded ? (
          <TerminationForm
            OpenHelpModal={() => {
              this.setState({ helpModal: true });
            }}
            errors={this.state.errors}
            employer_name={this.state.employer_name}
            EIN={this.state.EIN}
            TIN={this.state.TIN}
            employer_contact_email={this.state.employer_contact_email}
            employee_first={this.state.employee_first}
            employee_last={this.state.employee_last}
            employee_street={this.state.employee_street}
            employee_city={this.state.employee_city}
            employee_state={this.state.employee_state}
            employee_zip={this.state.employee_zip}
            termination_date={this.state.termination_date}
            termination_reason={this.state.termination_reason}
            members={this.state.members}
            onChange={this.onChange}
            isAdd={false}
            canEditTerminationDate={this.state.canEditTerminationDate}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Termination;
