import React from "react";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Auth } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
});

class ArcFormContinuationStatusCard extends React.Component {
  state = {
    signerFirst: "",
    signerLast: "",
  };

  handleClose = () => {
    window.location.href = "https://mewa.io/";
  };

  render() {
    return (
      <React.Fragment>
        <Card style={{ marginBottom: 50, marginTop: 25 }}>
          <CardHeader
            subheader="Your continuation status has changed"
            style={{ backgroundColor: "#FFCC00" }}
          />
          <CardContent>
            <Typography
              variant="body2"
              style={{ marginTop: 10, marginBottom: 2 }}
            >
              Please note that your continuation status change will take effect
              January 1st and the Plan's continuation provider will be
              automatically notified.
            </Typography>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(ArcFormContinuationStatusCard)
);
