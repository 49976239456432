import React from "react";
import {
  withRouter,
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import getBrandedThemeOrDefault from "../Auth/Styles/BrandedTheme";
import PasswordResetRequest from "../Auth/Shared/Components/PasswordResetRequest/PasswordResetRequest";
import BaseContainer from "../Shared/Layout/BaseContainer";
import DenyEmployerInvitePublic from "./Invite/DenyEmployerInvitePublic";
import ContactContainer from "./Contacts/ContactContainer";
import AccountContainer from "./Account/AccountContainer";
import AcceptEmployerInvite from "./Invite/AcceptEmployerInvite";
import TerminationContainer from "./Termination/TerminationContainer";
import MedicareContainer from "./Medicare/MedicareContainer";
import AuthContainer from "../Auth/AuthContainer";
import CreateAccount from "./Account/CreateAccount";
import ArcContainer from "./Arc/ArcContainer";
import ArcTableContainer from "./Arc/ArcTableContainer";
import ViewInvitationsContainer from "./Invite/ViewInvitationsContainer";
import DocumentsContainer from "./Documents/DocumentsContainer";
import ReviewBrokerInviteContainer from "./Invite/ReviewBrokerInviteContainer";
import ViewTeamContainer from "./Team/ViewTeamContainer";
import ViewEmployerTeamContainer from "./Employers/ViewEmployerTeamContainer";
import ManageEmployersContainer from "./Employers/ManageEmployersContainer";
import AddTeamMemberContainer from "./Team/AddTeamMemberContainer";
import BrokerHOC from "../Auth/HOC/BrokerHOC";
import BrokerHOCSecured from "../Auth/HOC/BrokerHOCSecured";
import Home from "./Home/Home";
import BrokerRequestAccount from "../Auth/Shared/Components/RequestAccountBroker/BrokerRequestAccount";
import EmployerContainer from "./Employers/EmployerContainer";
import NavList from "./Nav/NavList";

class BaseContainerWrapped extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {},
  };

  getHome = () => {
    return "/broker/auth";
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <BaseContainer
              {...props}
              component={Component}
              navList={NavList}
              home={this.getHome()}
              theme={this.props.theme}
              hasAccount={false} //This is for icon in top right
              fixed={this.props.fixed}
              isLoaded={true}
              enqueueSnackbar={this.props.enqueueSnackbar}
            />
          );
        }}
      />
    );
  }
}

BaseContainerWrapped = withRouter(BrokerHOC(BaseContainerWrapped));

class BaseContainerWrappedSecured extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {},
  };

  componentDidMount() {
    document.title = "mewa.io - Broker Portal";
  }

  getHome = () => {
    let url = "/broker/home";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    return url;
  };

  getMEWAID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let mewaID = params.get("mewaID") ? params.get("mewaID") : null;
    return mewaID;
  };

  getBrokerID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("brokerID");
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <BaseContainer
              {...props}
              component={Component}
              navList={NavList}
              home={this.getHome()}
              logoutURL={"/broker/auth"}
              theme={this.props.theme}
              hasAccount={true} //This is for icon in top right
              app={"broker"}
              fixed={this.props.fixed}
              isLoaded={true}
              enqueueSnackbar={this.props.enqueueSnackbar}
              user={this.props.user}
              needTOS={this.props.needTOS}
              hasAgency={this.props.hasAgency}
              getBrokerTOS={this.props.getBrokerTOS}
            />
          );
        }}
      />
    );
  }
}

BaseContainerWrappedSecured = withRouter(
  BrokerHOCSecured(BaseContainerWrappedSecured)
);

const Routes = (props) => {
  const getSignInURL = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let next = params.get("next");
    let invitationID = params.get("invitationID");
    let result = params.get("result");
    let mewaID = params.get("mewaID");
    if (next) {
      if (next === "notification") {
        return "/broker/account";
      }
      if (next === "manage-employers") {
        return "/broker/manage-employers";
      }
      if (next === "invitation") {
        if (!mewaID) {
          return (
            "/broker/accept-employer-invite?invitationID=" +
            invitationID +
            "&result=" +
            result
          );
        } else {
          return (
            "/broker/accept-employer-invite?invitationID=" +
            invitationID +
            "&result=" +
            result +
            "&mewaID=" +
            mewaID
          );
        }
      }
      if (next === "brokerInvitation") {
        if (!mewaID) {
          return "/broker/review-broker-invite?invitationID=" + invitationID;
        } else {
          return (
            "/broker/review-broker-invite?invitationID=" +
            invitationID +
            "&mewaID=" +
            mewaID
          );
        }
      }
    } else {
      if (!mewaID) return "/broker/home";
      else {
        return "/broker/home?mewaID=" + mewaID;
      }
    }
  };

  return (
    <Router>
      <Switch>
        <BaseContainerWrapped
          exact
          path="/broker/contacts"
          fixed={true}
          component={ContactContainer}
        />
        <BaseContainerWrapped
          exact
          path="/broker/arc"
          fixed={true}
          component={ArcContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/home"
          fixed={true}
          component={Home}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/arc-records"
          fixed={true}
          component={ArcTableContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/account"
          fixed={true}
          component={AccountContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/employers"
          fixed={true}
          component={EmployerContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/documents"
          fixed={true}
          component={DocumentsContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/medicare"
          fixed={true}
          component={MedicareContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/termination"
          fixed={true}
          component={TerminationContainer}
        />
        <BaseContainerWrapped
          exact
          path="/broker/deny-employer-invite"
          fixed={true}
          component={DenyEmployerInvitePublic}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/accept-employer-invite"
          fixed={true}
          component={AcceptEmployerInvite}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/review-broker-invite"
          fixed={true}
          component={ReviewBrokerInviteContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/view-invitations"
          fixed={true}
          component={ViewInvitationsContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/view-team"
          fixed={true}
          component={ViewTeamContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/manage-employers"
          fixed={true}
          component={ManageEmployersContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/edit-employer-team"
          fixed={true}
          component={ViewEmployerTeamContainer}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/add-team-member"
          fixed={true}
          component={(props) => {
            return <AddTeamMemberContainer isEdit={false} {...props} />;
          }}
        />
        <BaseContainerWrappedSecured
          exact
          path="/broker/edit-team-member"
          fixed={true}
          component={(props) => {
            return <AddTeamMemberContainer isEdit={true} {...props} />;
          }}
        />
        <Route
          exact
          path="/broker/password-reset-request"
          component={() => (
            <PasswordResetRequest
              logoutURL={"/broker/auth"}
              loginURL={getSignInURL()}
              app={"broker"}
            />
          )}
        />
        <Route
          exact
          path="/broker/request-account-link"
          component={() => <BrokerRequestAccount />}
        />
        <Route
          exact
          path="/broker/create-account"
          component={() => (
            <CreateAccount
              logoutURL={"/broker/auth"}
              loginURL={getSignInURL()}
            />
          )}
        />
        <Route
          exact
          path="/broker/auth"
          //user={props.user}
          component={() => (
            <AuthContainer
              logoutURL={"/broker/auth"}
              loginURL={getSignInURL()}
              theme={getBrandedThemeOrDefault({})}
              app={"broker"}
              welcomeMessage={"Welcome Broker!"}
              title={"mewa.io"}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
