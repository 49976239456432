import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import HomeIcon from '@material-ui/icons/Home';
import SendIcon from "@material-ui/icons/Send";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FolderIcon from "@material-ui/icons/Folder";

import ContactMailIcon from "@material-ui/icons/ContactMail";
import NewTable from "../../Shared/Components/Table/NewTable";
import GroupIcon from "@material-ui/icons/Group";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import BrokerInviteEmployerDialog from "./BrokerInviteEmployerDialog";
import EmployerTeamDialog from "./EmployerTeamDialog";
import BrokerChangeContactDialog from "./BrokerChangeContactDialog";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import BrokerAgreement from "../../Shared/Components/Agreement/BrokerAgreement";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import config from "../../config";
import {
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  nestedCellDataGetter,
  nestedSort,
  employerSecureIconDataGetter,
  employerIgnoreIconDataGetter,
  employerIgnoreSort,
  employerMEWACellDataGetter,
  employerTerminatedIconDataGetter,
} from "../../Shared/Components/Table/TableUtilities";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  brokerEmployerInviteModalOpen: false,
  brokerChangeContactModalOpen: false,
  employerTeamDialogOpen: false,

  employer: {},

  //FOR TABLE
  tableData: [],
  recordCount: 0,
  dataURL: "broker-portal-employers",
  staticFilterURL: "broker-portal-employers-static-filters",
  staticFilters: ["mewa__name"],
  staticFilterValues: [],
  sortBy: "",
  nestedSortBy: "",
  sortDirection: "ASC",
  countURL: "broker-portal-employers-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /* state for password reset dialog */
  employerPasswordDialogOpen: false,
  employerPassword: "",

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class EmployerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState({ initialLoadDone: false, fetching: true }, () => {
      this.getStaticFilterValues().then((values) => {
        this.setState({ staticFilterValues: values }, () => {
          this.getTableCount().then((count) => {
            this.getTableData(0, 10)
              .then((r) => {
                this.setState({
                  tableData: r,
                  recordCount: count["record_count"],
                  fetching: false,
                  initialLoadDone: true,
                });
                if (isInitial)
                  this.setState({
                    initialCount: count["record_count"],
                    currentCount: count["record_count"],
                  });
                else this.setState({ currentCount: count["record_count"] });
              })
              .catch((err) => {
                if (err === 403) {
                  this.props.history.push("/mewa-admin/auth?error=5");
                }
                if (err === 400) {
                  this.props.enqueueSnackbar(
                    "Something went wrong rendering employers",
                    {
                      variant: "error",
                    }
                  );
                }
              });
          });
        });
      });
    });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering emplopyers",
                {
                  variant: "error",
                }
              );
            }
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            this.state.nestedSortBy
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    let nestedColSortKey = "";
    if (col.nestCol) {
      nestedColSortKey = col.nestedColSortKey;
    }

    this.setState(
      {
        sortBy: sortBy,
        sortDirection: sortDirection,
        nestedSortBy: nestedColSortKey,
      },
      () => {
        this.handleInitialData();
      }
    );
  };

  handleChangeContact = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      brokerChangeContactModalOpen: true,
    });
    closeMenu();
  };

  handleViewEmployerTeam = (selectedItem, closeMenu) => {
    this.setState({ employer: selectedItem, employerTeamDialogOpen: true });
    closeMenu();
  };
  handleResendWelcomeEmail = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Resend Employer Welcome Email",
      confirmAlertText: "Are you sure you wish to resend the welcome email to " + selectedItem.name + "? The email will be sent to " + selectedItem.contact_first + " " + selectedItem.contact_last + " (" + selectedItem.contact_email + ").",
      doNotFunc: this.doNotResendEmployer,
      doFunc: this.doResendEmployer,
    });
    closeMenu();
  };
  doResendEmployer = () => {
    this.resendWelcomeEmail(this.state.employer.portal_id).then((r) => {
      this.setState({
        confirmAlertOpen: false,
        employer: {},
        doFunc: null,
        doNotFunc: null,
        confirmAlertTitle: "",
        confirmAlertText: "",
      });
      this.handleInitialData()
      this.props.enqueueSnackbar("Welcome Email Resent", {
        variant: "success",
      });
    });
  };
  doNotResendEmployer = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };
  resendWelcomeEmail = (portalID) => {
    return new Promise((resolve, reject) => {
      api
        .resendEmployerWelcomeEmailBroker(portalID)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.props.enqueueSnackbar(
            "Something went wrong resending welcome email",
            {
              variant: "error",
            }
          );
        });
    });
  };

  handleSendPortalLink = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Send Portal Link",
      confirmAlertText: "Are you sure you wish to send " + selectedItem.name + ", a link to their portal? The link will be sent to " + selectedItem.contact_first + " " + selectedItem.contact_last + " (" + selectedItem.contact_email + ").",
      doNotFunc: this.doNotSendPortalLink,
      doFunc: this.doSendPortalLink,
    });
    closeMenu();
  };
  doSendPortalLink = () => {
    this.sendPortalLink(this.state.employer.portal_id)
      .then((r) => {
        this.props.enqueueSnackbar("Portal Link Resent", {
          variant: "success",
        });
        this.setState({
          confirmAlertOpen: false,
          employer: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.props.enqueueSnackbar("Something went wrong sending portal link", {
          variant: "error",
        });
        this.setState({
          confirmAlertOpen: false,
          employer: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      });
  };
  doNotSendPortalLink = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  sendPortalLink = (portalID) => {
    return new Promise((resolve, reject) => {
      api.sendPortalLinkBroker(portalID).then((r) => {
        resolve(r);
      }).catch(err => {
        resolve(err)
      });
    });
  };

  handleDocumentCenter = (selectedItem, closeMenu) => {
    this.props.history.push({
      pathname: "/broker/documents",
      state: { employer: selectedItem },
    });
    closeMenu();
  };

  handleResetPassword = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Reset Password",
      confirmAlertText: "Are you sure you wish to reset " + selectedItem.contact_first + " " + selectedItem.contact_last + "'s password at " + selectedItem.name + "?",
      doNotFunc: this.doNotResetPassword,
      doFunc: this.doResetPassword,
    });
  };

  doNotResetPassword = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  doResetPassword = () => {
    this.setState({
      confirmAlertOpen: false,
      employerPasswordDialogOpen: true,
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  finalResetPassword = () => {
    this.resetPassword(this.state.employer.portal_id).then((r) => {
      this.handleInitialData();
      this.props.enqueueSnackbar("Contact Password Has Been Reset", {
        variant: "success",
      });
    });
    this.setState({ employerPasswordDialogOpen: false, employerPassword: "" });
    this.state.closeMenu();
  };

  resetPassword = (portalID) => {
    var body = JSON.stringify({
      password: this.state.employerPassword,
    });
    return new Promise((resolve, reject) => {
      api
        .resetEmployerPassword(portalID, body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleOpen = () => {
    this.setState({ brokerEmployerInviteModalOpen: true });
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;

    return (
      <div>
        
        <MenuItem
          onClick={() => {
            this.handleSendPortalLink(selectedItem, closeMenu);
          }}
        >
          <HomeIcon className={classes.leftIcon} />
          Send Employer Portal Link
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleResendWelcomeEmail(selectedItem, closeMenu);
          }}
        >
          <SendIcon className={classes.leftIcon} />
          Resend Employer Welcome
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.handleDocumentCenter(selectedItem, closeMenu);
          }}
          disabled={selectedItem.permission_level ===  0}
        >
          <FolderIcon className={classes.leftIcon} />
          Open Document Center
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleChangeContact(selectedItem, closeMenu);
          }}
        >
          <ContactMailIcon className={classes.leftIcon} />
          Change Contact
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleViewEmployerTeam(selectedItem, closeMenu);
          }}
          id={'view-team-'+selectedItem.id}
        >
          <GroupIcon className={classes.leftIcon} />
          View Team
        </MenuItem>

      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
      <BrokerAgreement Open={this.props.needTOS} Close={()=>{this.props.getBrokerTOS()}} />
        {this.state.employerTeamDialogOpen && (
          <EmployerTeamDialog
            Open={this.state.employerTeamDialogOpen}
            Close={() => {
              this.setState({ employerTeamDialogOpen: false });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            Employer={this.state.employer}
            history={this.props.history}
          />
        )}
        {this.state.brokerChangeContactModalOpen && (
          <BrokerChangeContactDialog
            Open={this.state.brokerChangeContactModalOpen}
            Close={() => {
              this.setState({ brokerChangeContactModalOpen: false });
            }}
            RefreshTable={this.handleInitialData}
            enqueueSnackbar={this.props.enqueueSnackbar}
            Employer={this.state.employer}
            EIN={this.state.employer.EIN}
          />
        )}
        {this.state.brokerEmployerInviteModalOpen && (
          <BrokerInviteEmployerDialog
            Open={this.state.brokerEmployerInviteModalOpen}
            Close={() => {
              this.setState({ brokerEmployerInviteModalOpen: false });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
        )}
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        <div className={classes.containerRoot}>
          {/*<div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <Add />
            </Fab>
          </div>*/}
          <div id='broker-employer-container' className={classes.containerTitle}>Employers</div>
          <div className={classes.containerContent}>
            <div style={{ height: 0, textAlign: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                id='broker-employer-table-add-button'
                onClick={this.handleOpen}
                className={classes.button}
              >
                <AddIcon className={classes.rightIcon} />
                <div className={classes.buttonText}>Add</div>
              </Button>
            </div>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper}>
              <NewTable
                tableName={"Employers"}
                enqueueSnackbar={this.props.enqueueSnackbar}
                loadedData={this.state.tableData}
                loaded={this.state.initialLoadDone}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                staticFilterValues={this.state.staticFilterValues}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                setActiveFilters={this.setActiveFilters}
                activeFilters={this.state.activeFilters}
                columns={[
                  {
                    label: "Carrier ID",
                    dataKey: "carrier_case_id",
                    width: 100,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },                    
                  {
                    dataKey: "name",
                    label: "Employer Name",
                    width: 250,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "mewa",
                    nestedCol: "mewa__name",
                    label: "MEWA",
                    cellRenderer: employerMEWACellDataGetter,
                    customSort: nestedSort,
                    hasFilter: true,
                    filterType: "Static",
                    width: 250,
                    flexGrow: 3,
                    flexShrink: 1,
                  },
                  {
                    dataKey: "contact_email",
                    label: "Email",
                    width: 200,
                    flexGrow: 3,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "continuation_status",
                    label: "Continuation Status",
                    width: 170,
                    flexGrow: 3,
                    flexShrink: 1,
                    hasFilter: false,
                  },                  
                  {
                    label: "Terminated",
                    dataKey: "termination_date",
                    cellRenderer: employerTerminatedIconDataGetter,
                    width: 100,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Secure",
                    dataKey: "is_secured",
                    cellRenderer: employerSecureIconDataGetter,
                    width: 75,
                    flexGrow: 1,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(EmployerContainer);
