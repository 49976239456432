import React from "react";

import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import getBrandedThemeOrDefault from "../Styles/BrandedTheme";

const BrokerHOC = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      loaded: false,
      theme: {},
    };

    componentDidMount() {
      this.getBrokerContactTheme();
    }

    getBrokerContactTheme = () => {
      if (!this.getContactID()) {
        let theme = getBrandedThemeOrDefault({});
        document.querySelector("link[rel='shortcut icon']").href =
          theme.fav_icon;
        document.title = theme.page_title;
        this.setState({ theme: theme, loaded: true });
      } else {
        api
          .getMewaContactTheme(this.getContactID(), this.getMEWAID())
          .then((res) => {
            let theme = getBrandedThemeOrDefault(res.theme);
            document.querySelector("link[rel='shortcut icon']").href =
              theme.fav_icon;
            document.title = theme.page_title;
            this.setState({ theme: theme, loaded: true });
          });
      }
    };

    getMEWAID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let mewaID = params.get("mewaID") ? params.get("mewaID") : null;
      return mewaID;
    };

    getContactID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let contactID = params.get("contactID") ? params.get("contactID") : null;
      if (!contactID) {
        this.setState({ validContactID: false });
      }
      return contactID;
    };

    render() {
      const { ...rest } = this.props;
      const { loaded } = this.state;
      if (!loaded) return null;

      return (
        <Route
          render={(props) => {
            return (
              <MuiThemeProvider theme={this.state.theme}>
                <WrappedComponent
                  {...props}
                  {...rest}
                  theme={this.state.theme}
                />
              </MuiThemeProvider>
            );
          }}
        />
      );
    }
  };
};
export default BrokerHOC;
