import React from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import ContactList from "./ContactList";
import ContactForm from "./ContactForm";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";

import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  contacts: [],
  adding: false,
  loaded: false,

  confirmDeleteContact: false,
  deleteContactID: "",

  validContactID: true,
};

class ContactsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.getContacts(this.getContactID())
      .then((r) => {
        this.setState({ contacts: r });
      })
      .catch((err) => {
        this.showUnauthorizedWarning("Must Be a Primary Contact To View");
        this.setState({ validContactID: false });
      });
  }

  getContactID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let contactID = params.get("contactID") ? params.get("contactID") : null;
    if (!contactID) {
      this.showUnauthorizedWarning("Must Provide a Valid Contact ID");
      this.setState({ validContactID: false });
    }
    return contactID;
  };

  showUnauthorizedWarning = (message) => {
    this.props.enqueueSnackbar(message, {
      variant: "warning",
    });
  };

  confirmDeleteContact = (deleteContactID) => {
    this.setState({
      confirmDeleteContact: true,
      deleteContactID: deleteContactID,
    });
  };

  cancelDeleteContact = () => {
    this.setState({ confirmDeleteContact: false, deleteContactID: "" });
  };

  handleDeleteContact = () => {
    this.deleteContact(this.state.deleteContactID).then((r) => {
      this.getContacts(this.getContactID()).then((r) => {
        this.setState({
          confirmDeleteContact: false,
          deleteContactID: "",
          contacts: r,
        });
      });
    }).catch(err => console.log(err));
  };

  handleAddContact = (firstName, lastName, email) => {
    var body = JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email: email,
    });

    this.addContact(this.getContactID(), body).then((r) => {
      this.getContacts(this.getContactID()).then((r) => {
        this.setState({ adding: false, contacts: r });
      });
    });
  };

  addContact = (contactID, body) => {
    return new Promise((resolve, reject) => {
      resolve(api.addBrokerContact(contactID, body)); 
    });
  };

  deleteContact = (contactID) => {
    return new Promise((resolve, reject) => {
      resolve(api.deleteBrokerContact(contactID)); 
    });
  };

  getContacts = (contactID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerContacts(contactID)); 
    });
  };

  showAdding = () => {
    this.setState({ adding: true });
  };

  render() {
    const { classes } = this.props;
    const { validContactID } = this.state;

    if (!validContactID) return null;
    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmDeleteContact}
          isNo={this.cancelDeleteContact}
          isYes={this.handleDeleteContact}
          AlertTitle={"Confirm Delete Contact"}
          AlertText={"Are you sure you want to delete this contact?"}
        />

        <Container maxWidth="sm">
          <Typography variant="subtitle1">Setup Contacts</Typography>

          <Typography variant="caption">
            <p>
              Add any other team members within your agency you wish to receive these notifications. 
              Once added, they will receive each pending employer request notification you do for all of the employers you work with.
              <br/><br/>
              Please note whomever you add will receive every pending employer request notification sent to you, 
              regardless of whether they actually work on that case. This may be relevant if your team member works with multiple agents.
              <br/><br/>
              Please do not add employers as they will be contacted directly when necessary and should not receive a copy of every notification sent to you. 
              These notifications are simply meant as an FYI to ensure you and your team are informed when we reach out to your employers.
              <br/><br/>
              You may return to this page at any time using the links in your notifications to add or subtract team members.
            </p>
          </Typography>
          <ContactList
            contacts={this.state.contacts}
            deleteContact={this.confirmDeleteContact}
          />
          {(this.state.adding && (
            <ContactForm
              cancel={() => {
                this.setState({ adding: false });
              }}
              save={this.handleAddContact}
            />
          )) || (
            <div id="fabContainer" className={classes.fabContainer}>
              <Fab
                color="primary"
                aria-label="Add"
                className={classes.fab}
                onClick={this.showAdding}
              >
                <Add />
              </Fab>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ContactsContainer);
