import React from "react";
import {
  withRouter,
  Switch,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import getBrandedThemeOrDefault from "../Auth/Styles/BrandedTheme";
import BaseContainer from "../Shared/Layout/BaseContainer";
import OutputContainer from "./Output/OutputContainer";
import NavList from "./Nav/NavList";
import ReportHOC from "../Auth/HOC/ReportHOC";
import AuthContainer from "../Auth/AuthContainer";

class BaseContainerWrapped extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {}
  };

  getHome = () => {
    return "https://www.mewa.io";
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          return (
              <BaseContainer
                {...props}
                theme={getBrandedThemeOrDefault({})}
                component={Component}
                navList={NavList}
                home={this.getHome()}
                hasAccount={false} //This is for icon in top right
                fixed={this.props.fixed}
                isLoaded={true}
              />
          );
        }}
      />
    );
  }
}

BaseContainerWrapped = withRouter(ReportHOC(BaseContainerWrapped));

const Routes = props => {
  const getSignInURL = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return "/report/output?id=" + params.get("id");
  };
  const getSignOutURL = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return "/report/auth?id=" +  params.get("id");
  };
  return (
    <Router>
      <Switch>
        <BaseContainerWrapped
          exact
          path="/report/output"
          fixed={true}
          component={OutputContainer}
        />
        <Route
          exact
          path="/report/auth"
          //user={props.user}
          component={() => (
            <AuthContainer
              logoutURL={getSignOutURL()}
              loginURL={getSignInURL()}
              theme={getBrandedThemeOrDefault({})}
              welcomeMessage={"Welcome!"}
              title={"mewa.io"}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
