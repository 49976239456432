import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";

const initialState = {
  invitation: {},
  permissionLevel: 0,
  loaded: false,
  validParams: true,
  acceptInvite: false,
  declineInvitation: false,
  invitationClosed: false,
};

class ReviewBrokerPermissionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    let inviteResponse = this.getResponse();
    let acceptInvite = inviteResponse === "review" ? 1 : 0;

    this.getInvitationDetails(this.getInvitationID()).then((r) => {
      this.setState(
        {
          invitation: r,
          loaded: true,
          acceptInvite: acceptInvite,
          invitationClosed: r.date_closed ? true : false,
        },
        () => {
          if (!acceptInvite && !r.date_closed) {
            this.handleUpdateInvitationDetails(r.id, true);
          }
        }
      );
    });
  }
  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };

  getResponse = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let inviteResponse = params.get("response");
    if (!inviteResponse) this.setState({ validParams: false });
    else return inviteResponse;
  };

  getInvitationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invitationID = params.get("invitationID")
      ? params.get("invitationID")
      : null;
    if (!invitationID) this.setState({ validParams: false });
    else return invitationID;
  };

  getInvitationDetails = (invitationID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(api.getBrokerEmployerInviteSecured(invitationID));
      } else {
        resolve(api.getBrokerEmployerInvite(invitationID));
      }
    });
  };

  handleUpdateInvitationDetails = (invitationID, decline) => {
    this.updateInvitationDetails(invitationID, decline).then((r) => {
      if (!decline) {
        window.location.href =
          config.appLocation.URL +
          "/employer/manage-brokers?portalID=" +
          this.getPortalID();
      }
    });
  };

  updateInvitationDetails = (invitationID, decline) => {
    let body = this.state.invitation;
    body.permission_level = this.state.permissionLevel;
    body.result = decline ? 0 : 1;
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(
          api.updateBrokerEmployerInviteSecured(
            invitationID,
            JSON.stringify(body)
          )
        );
      } else {
        resolve(
          api.updateBrokerEmployerInvite(invitationID, JSON.stringify(body))
        );
      }
    });
  };

  render() {
    const { validParams, loaded, invitation, acceptInvite, invitationClosed } =
      this.state;

    if (!validParams) return null;
    if (!loaded) return null;
    if (invitationClosed) {
      return (
        <React.Fragment>
          <Container maxWidth="sm" style={{ height:"50%", display: "flex", flexDirection: "column", justifyContent:"center", textAlign:"center"}}>
            <Typography variant="h4" >Invitation Closed</Typography>
            <Typography variant="body2" style={{marginTop:30}}>
              This invitation has already been closed. If you would like to view or change the broker permissions on your account, please click the My Account icon in the upper right hand corner and click Manage Brokers.
            </Typography>
          </Container>
        </React.Fragment>
      );
    }
    if (!acceptInvite) {
      return (
        <React.Fragment>
        <Container maxWidth="sm" style={{ height:"50%", display: "flex", flexDirection: "column", justifyContent:"center", textAlign:"center"}}>
          <Typography variant="h4" >Invitation Declined</Typography>
          <Typography variant="body2" style={{marginTop:30}}>
            You have declined this invitation. If you would like to invite the broker to manage your account at a later time, please click the My Account icon in the upper right hand corner and click Manage Brokers.
          </Typography>
        </Container>
      </React.Fragment>        
      );
    }
    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmDeleteContact}
          isNo={this.cancelDeleteContact}
          isYes={this.handleDeleteContact}
          AlertTitle={"Confirm Delete Contact"}
          AlertText={"Are you sure you want to delete this contact?"}
        />

          <div style={{maxWidth:800}}>
            <Typography variant="h4" style={{marginBottom:30}}>Invitation Review</Typography>
            <Typography variant="h6" display="inline">Requester:</Typography> <Typography variant="body1" display="inline">{invitation.from_first_name} {invitation.from_last_name} ({invitation.from_email})</Typography>
            <Typography variant="body2" style={{marginTop:30, marginBottom:30}}>
              Please select what permission level the broker should have. This can be edited later.
            </Typography>

            <div style={{maxWidth:800}}>
            <FormControl
              component="fieldset"
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              <RadioGroup
                aria-label="exempt-entity"
                name="exempt-entity"
                value={this.state.permissionLevel}
                onChange={(e) => {
                  this.setState({ permissionLevel: parseInt(e.target.value) });
                }}
              >
                <FormControlLabel
                  id="notify-radio"
                  value={-1}
                  control={<Radio />}
                  label={<Typography variant="body1">None</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will not receive notifications regarding your account, nor will they have any view or update.</Typography>                
                <FormControlLabel
                  id="notify-radio"
                  value={0}
                  control={<Radio />}
                  label={<Typography variant="body1">Notify</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will receive notifications when we need to contact you regarding things like termination verifications, employees who are eligible for Medicare and completing ARC Forms, however they will not be able to see any information about the requests.</Typography>
                <FormControlLabel
                  id="view-radio"
                  value={1}
                  control={<Radio />}
                  label={<Typography variant="body1">View</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will be able to view the status of any records in your Mewa.io account, but will not be able to edit them.</Typography>
                <FormControlLabel
                  id="update-radio"
                  value={2}
                  control={<Radio />}
                  label={<Typography variant="body1">Update</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will be able to view the status of any records in your Mewa.io account and update certain records on your behalf, such as a termination verification. They will not be able to sign any documents on your behalf.</Typography>
              </RadioGroup>
            </FormControl>
            </div>
          <div id="button-div" style={{ textAlign: "left", marginTop: 30 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.handleUpdateInvitationDetails(invitation.id, false);
              }}
              style={{ width: 100 }}
            >
              Save
            </Button>
          </div>
          </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReviewBrokerPermissionsContainer);
