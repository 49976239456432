import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import NewTable from "../../Shared/Components/Table/NewTable";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import ArcRequestDialog from "./ArcRequestDialog";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";
import GroupIcon from "@material-ui/icons/Group";
import ArcRequestDetail from "../../Shared/Components/Arc/ArcRequestDetailDialog";
import BrokerAgreement from "../../Shared/Components/Agreement/BrokerAgreement";

import config from "../../config";
import {
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  nestedCellDataGetter,
  nestedSort,
} from "../../Shared/Components/Table/TableUtilities";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  arcRequest: {},
  arcDialogOpen: false,
  detailDialogOpen: false,

  //FOR TABLE
  tableData: [],
  recordCount: 0,
  dataURL: "broker-portal-arc",
  staticFilterURL: "broker-portal-arc-static-filters",
  staticFilters: ["plan_year", "status", "employer__mewa__name"],
  staticFilterValues: [],
  sortBy: "",
  nestedSortBy: "",
  sortDirection: "ASC",
  countURL: "broker-portal-arc-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /* state for password reset dialog */
  employerPasswordDialogOpen: false,
  employerPassword: "",

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class ArcTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState({ initialLoadDone: false, fetching: true }, () => {
      this.getStaticFilterValues().then((values) => {
        this.setState({ staticFilterValues: values }, () => {
          this.getTableCount().then((count) => {
            this.getTableData(0, 10)
              .then((r) => {
                this.setState({
                  tableData: r,
                  recordCount: count["record_count"],
                  fetching: false,
                  initialLoadDone: true,
                });
                if (isInitial)
                  this.setState({
                    initialCount: count["record_count"],
                    currentCount: count["record_count"],
                  });
                else this.setState({ currentCount: count["record_count"] });
              })
              .catch((err) => {
                /*
                if (err === 403) {
                  this.props.history.push("/mewa-admin/auth?error=5");
                }
                if (err === 400) {
                  this.props.enqueueSnackbar(
                    "Something went wrong rendering employers",
                    {
                      variant: "error",
                    }
                  );
                }
                */
              });
          });
        });
      });
    });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            /*
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering emplopyers",
                {
                  variant: "error",
                }
              );
            }
            */
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            this.state.nestedSortBy
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    let nestedColSortKey = "";
    if (col.nestCol) {
      nestedColSortKey = col.nestedColSortKey;
    }

    this.setState(
      {
        sortBy: sortBy,
        sortDirection: sortDirection,
        nestedSortBy: nestedColSortKey,
      },
      () => {
        this.handleInitialData();
      }
    );
  };

  handleCompleteArc = (selectedItem, closeMenu) => {
    this.setState({
      arcRequest: selectedItem,
      arcDialogOpen: true,
    });
    closeMenu();
  };

  handleResendArcEmail = (selectedItem, closeMenu) => {
    let confirmText = (
      <React.Fragment>
        Are you sure you wish to resend this ARC Form to{" "}
        {selectedItem.employer_name}? This email will be sent to{" "}
        {selectedItem.employer_contact_first}{" "}
        {selectedItem.employer_contact_last}(
        {selectedItem.employer_contact_email}).
        <br />
        <br />
        Employers receive reminders automatically. Please only resend this
        notification if the employer has requested it.
      </React.Fragment>
    );
    this.setState({
      arcRequestDetail: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Confirm Resend",
      confirmAlertText: confirmText,
      doNotFunc: this.doNotResendEmail,
      doFunc: this.doResendArcEmail,
    });
    closeMenu();
  };

  doResendArcEmail = () => {
    this.resendArcEmail(this.state.arcRequestDetail.id).then((r) => {
      this.props.enqueueSnackbar("ARC Email Resent", {
        variant: "success",
      });
      this.setState({
        confirmAlertOpen: false,
        arcRequestDetail: {},
        doFunc: null,
        doNotFunc: null,
        confirmAlertTitle: "",
        confirmAlertText: "",
      });
    });
  };

  doNotResendEmail = () => {
    this.setState({
      confirmAlertOpen: false,
      arcRequestDetail: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  resendArcEmail = (arcID) => {
    return new Promise((resolve, reject) => {
      api
        .resendARCRequestBrokerPortal(arcID)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          if (err === 403) {
            this.props.history.push("/broker/auth?error=11");
          }
          if (err === 400) {
            this.props.enqueueSnackbar(
              "Something went wrong resending welcome email",
              {
                variant: "error",
              }
            );
          }
        });
    });
  };

  handleTrackArcRequest = (selectedItem, closeMenu) => {
    this.setState({ detailDialogOpen: true, arcRequest: selectedItem });
    closeMenu();
  };

  handleOpen = () => {
    this.setState({ brokerEmployerInviteModalOpen: true });
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;

    return (
      <div>
        <MenuItem
          onClick={() => {
            this.handleTrackArcRequest(selectedItem, closeMenu);
          }}
        >
          <GroupIcon className={classes.leftIcon} />
          Track Request
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.handleResendArcEmail(selectedItem, closeMenu);
          }}
        >
          <SendIcon className={classes.leftIcon} />
          Resend Request
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.handleCompleteArc(selectedItem, closeMenu);
          }}
          disabled={
            selectedItem.permission_level < 2 ||
            selectedItem.date_closed !== null
          }
        >
          <AssignmentTurnedInIcon className={classes.leftIcon} />
          Complete
        </MenuItem>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <BrokerAgreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getBrokerTOS();
          }}
        />
        {this.state.arcDialogOpen && (
          <ArcRequestDialog
            ArcRequest={this.state.arcRequest}
            Open={this.state.arcDialogOpen}
            Close={() => {
              this.setState({ arcDialogOpen: false, arcRequest: {} });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            history={this.props.history}
            RefreshTable={this.handleInitialData}
            theme={this.props.theme}
          />
        )}
        <ArcRequestDetail
          Open={this.state.detailDialogOpen}
          Close={() => {
            this.setState({ detailDialogOpen: false, arcRequest: {} });
          }}
          GetDetailFunction={api.getARCRequestEventsBrokerPortal}
          ArcRequest={this.state.arcRequest}
        />
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        <div className={classes.containerRoot}>
          {/*<div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <Add />
            </Fab>
          </div>*/}
          <div className={classes.containerTitle}>ARC Requests</div>
          <div className={classes.containerContent}>
            <div style={{ height: 0, textAlign: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleOpen}
                className={classes.button}
              >
                <AddIcon className={classes.rightIcon} />
                <div className={classes.buttonText}>Add</div>
              </Button>
            </div>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper}>
              <NewTable
                enqueueSnackbar={this.props.enqueueSnackbar}
                loadedData={this.state.tableData}
                loaded={this.state.initialLoadDone}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                staticFilterValues={this.state.staticFilterValues}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                setActiveFilters={this.setActiveFilters}
                activeFilters={this.state.activeFilters}
                tableName={"ARC Requests"}
                columns={[
                  {
                    label: "Employer Name",
                    dataKey: "employer_name",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    label: "Year",
                    dataKey: "plan_year",
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                    filterType: "Static",
                  },
                  {
                    label: "Event Start",
                    dataKey: "arc_event_start_date",
                    width: 150,
                    flexGrow: 1,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    dataKey: "mewa",
                    nestedCol: "employer__mewa__name",
                    label: "MEWA",
                    customSort: nestedSort,
                    hasFilter: true,
                    hide: true, //dont neeed tis vis
                    filterType: "Static",
                  },
                  {
                    label: "Carrier Case ID",
                    dataKey: "employer__carrier_case_id",
                    width: 300,
                    flexGrow: 1,
                    hide: true,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    label: "Contact",
                    dataKey: "employer_contact_email",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: false,
                  },
                  {
                    label: "Status",
                    dataKey: "status",
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                    filterType: "Static",
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ArcTableContainer);
