import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const initialState = {
  isFormValid: true,
  helperText: '',
  email: ''
};

class RequestHelpDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  SetRequestReason;

  handleCancel = () => {
    //clear state variable before closing
    this.setState({email: ''}, () => {
      this.props.Close();
    });
  };

  setEmail(value) {
    this.props.SetHelpField("requestHelpEmail", value)
    this.setState({ email: value })
  }

  handleSave = () => {
    if(!this.state.email){
      this.setState({isFormValid: false, helperText: 'Required'});
    } else {
      this.setState ({ email: '', isFormValid: true})
      this.props.Save();
    }
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.Open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Request Help from your Broker
        </DialogTitle>
        <DialogContent>
          <Typography variant="caption" color="inherit">
            Please enter the name and email address of your broker or service
            rep who can help you complete this form.
          </Typography>

          <TextField
            id="standard-dense"
            label={"Broker First Name"}
            onChange={(e) =>
              this.props.SetHelpField("requestHelpFirst", e.target.value)
            }
            value={this.props.RequestHelpFirst}
            autoComplete="off"
            fullWidth
          />
          <TextField
            id="standard-dense"
            label={"Broker Last Name"}
            onChange={(e) =>
              this.props.SetHelpField("requestHelpLast", e.target.value)
            }
            value={this.props.RequestHelpLast}
            autoComplete="off"
            fullWidth
          />
          <TextField
            id="standard-dense"
            error={!this.state.isFormValid}
            helperText={this.state.helperText}
            label={"Broker Email"}
            margin="dense"
            fullWidth
            onChange={(e) =>
              //this.props.SetHelpField("requestHelpEmail", e.target.value)
              this.setEmail(e.target.value)
            }
            value={this.state.email}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary" autoFocus>
            Ask for Help
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(RequestHelpDialog);
