import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from "@material-ui/icons/Edit";

const styles = theme => ({
  pageHeader: {
    marginBottom: theme.spacing(3)
  },
  title: {
    fontSize: 28,
    color: "grey",
    float: "left"
  },
  nav: {
    float: "right"
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  buttonText: {
    "@media only screen and (max-width: 600px)": {
      display: "none"
    }
  },
  waive: {
    marginBottom: 20,
    textAlign: "center",
    color: "white",
    backgroundColor: "#ffa930",
    display: "block"
  }
});

class PageHeader extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.pageHeader}>
          <div className={classes.title}>
            <Typography color="inherit" variant="h5" gutterBottom>
              {this.props.pageTitle}
            </Typography>
          </div>

          {!this.props.summary && (
            <div className={classes.nav}>
              {this.props.hasBack && (
              <Button
                variant="contained"
                color="default"
                id="page-header-button-back"
                className={classes.button}
                onClick={this.props.back}
              >
                <BackIcon className={classes.leftIcon} />
                <div className={classes.buttonText}>
                  {this.props.backText || "Back"}
                </div>
              </Button>
              )}
              {this.props.hasNext && (
              <Tooltip title={this.props.disabledText || ""}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    id="page-header-button-next"
                    disabled={this.props.nextDisabled}
                    className={classes.button}
                    onClick={this.props.next}
                  >
                    {this.props.nextText !== "Sign" && (
                      <React.Fragment>
                        <div className={classes.buttonText}>
                          {this.props.nextText || "Next"}
                        </div>
                        { !this.props.hideArrow  && (
                          <NextIcon className={classes.rightIcon} />
                        ) }
                        { this.props.hideArrow  && (
                          <SaveIcon className={classes.rightIcon} />
                        )}
                      </React.Fragment>
                    )}
                    {this.props.nextText === "Sign" && (
                      <React.Fragment>
                        <EditIcon className={classes.leftIcon} />
                        <div className={classes.buttonText}>Sign</div>
                      </React.Fragment>
                    )}
                  </Button>
                </span>
              </Tooltip>
              )}
            </div>
          )}
          <div style={{ clear: "both" }} />
        </div>

        {this.props.waiving && (
          <div className={classes.waive}>
            <Typography color="inherit" variant="subtitle1" gutterBottom>
              Because you are waiving coverage, you do not need to complete this
              section.
            </Typography>
          </div>
        )}
      </React.Fragment>
    );
  }
}

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default (withStyles(styles)(PageHeader));
