import React from "react";

import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import getBrandedThemeOrDefault from "../Styles/BrandedTheme";

const EmployerHOC = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      loaded: false,
      isAuthenticated: false,
      user: {},
      employerIsSecured: false,
      employerContact: "",
      employerName: "",
      mewaName: "",
      navOptions: {},
      needTOS: false,
      theme: {},
    };

    componentDidMount() {
      this.getEmployerInfo();
    }

    getEmployerInfo = () => {
      //for employer routes we first need to check if authentication is turned on
      this.getIsEmployerProtected()
        .then((response) => {
          if (response.is_secured) {
            this.setTheme(response.theme).then(() => {
              this.setState({
                employerContact: response.employer_email,
                employerName: response.employer_name,
                mewaName: response.mewa_name,
                navOptions: {
                  "hasContinuation": response.has_continuation,
                  "hasMedicare": response.has_medicare,
                  "hasTermination": response.has_termination,
                  "hasARC": response.has_arc,
                },
                needTOS: response.need_tos,
              });
            });

            this.makePrivate();
          } else {
            this.setTheme(response.theme).then(() => {
              //if not, give all routes access to the email that will be setup with an account
              //when this feature is turned on
              this.setState({
                employerContact: response.employer_email,
                employerName: response.employer_name,
                mewaName: response.mewa_name,
                navOptions: {
                  "hasContinuation": response.has_continuation,
                  "hasMedicare": response.has_medicare,
                  "hasTermination": response.has_termination,
                  "hasARC": response.has_arc,
                },
                employerIsSecured: false,
                needTOS: response.need_tos,
                loaded: true,
              });
            });
          }
        })
        .catch((err) => {
          if (err === 400)
            //Here a user is trying to login without a portalID and server throws 400
            this.props.history.push("/employer/auth?error=1");
        });
    };

    setTheme = (brandedThemeInfo) => {
      return new Promise((resolve, reject) => {
        let theme = getBrandedThemeOrDefault(brandedThemeInfo);
        document.querySelector("link[rel='shortcut icon']").href =
          theme.fav_icon;
        this.setState({ theme: theme }, () => {
          resolve("done");
        });
      });
    };

    makePrivate = () => {
      //check if JWT already exists... AKA user is already logged in
      this.authenticate();

      //setting employer has auth so that the user object is propogated to children componenets
      this.setState({ employerIsSecured: true });

      //define a function that will check if the JWT token exists EVERY TIME the URL changes
      //this is because this.props.history.listen RETURNS an unlisten function
      //so when the component unmounts we will call that to stop making the check
      this.unlisten = this.props.history.listen(() => {
        Auth.currentAuthenticatedUser()
          .then()
          .catch(() => {
            if (this.state.isAuthenticated) {
              this.setState({ isAuthenticated: false });
            }
          });
      });
    };

    getIsEmployerProtected = () => {
      return new Promise((resolve, reject) => {
        resolve(api.getEmployerIsProtected(this.getPortalID()));
      });
    };

    getPortalID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("portalID");
    };

    getTerminationID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("id");
    };

    getSARID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("sarID") ? params.get("sarID") : null;
    };

    componentWillUnmount() {
      //call the function defined above to deactivate the route listener when component is unmounted
      //if loaded flag was never true, it means that there was an error calling authenticate
      if (
        this.state.loaded &&
        !this.props.history.location.search.includes("error")
      )
        this.unlisten();
    }

    authenticate() {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.setState({ user: user, loaded: true, isAuthenticated: true });
        })
        .catch((err) => {
          localStorage.clear();
          //redirect to appropriate app, or user if null
          this.showUnauthorizedWarning(this.props.message, this.props.app);
          //check current route to see where user wanted to go
          let redirectURL =
            "/employer/auth?error=4&portalID=" + this.getPortalID();
          console.log(this.props.history.location);
          if (
            this.props.history.location.pathname.includes("termination-record")
          ) {
            redirectURL =
              redirectURL + "&next=term&id=" + this.getTerminationID();
          }
          if (this.props.history.location.pathname.includes("arc")) {
            redirectURL = redirectURL + "&next=arc";
          }
          if (this.props.history.location.search.includes("sarID")) {
            redirectURL = redirectURL + "&next=sar&id=" + this.getSARID();
          }

          this.props.history.push(redirectURL);
        });
    }

    showUnauthorizedWarning = (message, app) => {
      if (message) {
        this.props.enqueueSnackbar(message, {
          variant: "warning",
        });
      }
    };

    render() {
      const { ...rest } = this.props;
      const { loaded, user, employerIsSecured } = this.state;
      if (!loaded) return null; //doesn't load the private component until user is loaded

      //if the employer doesn't have auth setup we can render without passing the user param
      if (!employerIsSecured) {
        return (
          <Route
            render={(props) => {
              return (
                <MuiThemeProvider theme={this.state.theme}>
                  <WrappedComponent
                    {...props}
                    {...rest}
                    getEmployerInfo={this.getEmployerInfo}
                    employerContact={this.state.employerContact}
                    mewaName={this.state.mewaName}
                    navOptions={this.state.navOptions}
                    employerName={this.state.employerName}
                    needTOS={this.state.needTOS}
                    employerIsSecured={false}
                    hideSignout={true}
                    theme={this.state.theme}
                  />
                </MuiThemeProvider>
              );
            }}
          />
        );
      }

      //In the route we explicitly pass the state element User so that Container components
      //can allow their routes to have access to the cognito user object
      return (
        <Route
          render={(props) => {
            return (
              <div>
                <div id="login">
                  <MuiThemeProvider theme={this.state.theme}>
                    <WrappedComponent
                      {...props}
                      {...rest}
                      user={user}
                      mewaName={this.state.mewaName}
                      navOptions={this.state.navOptions}
                      getEmployerInfo={this.getEmployerInfo}
                      employerName={this.state.employerName}
                      needTOS={this.state.needTOS}
                      employerIsSecured={true}
                      theme={this.state.theme}
                    />
                  </MuiThemeProvider>
                </div>
              </div>
            );
          }}
        />
      );
    }
  };
};
export default EmployerHOC;
