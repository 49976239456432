import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import config from "../config";
import { styles } from "./Styles/AuthStyles";
import api from "../Shared/Functions/APIHelpers";
import PasswordInput from "../Shared/Components/Accounts/PasswordInput";
import { Auth } from "aws-amplify";

class ForgotPassword extends React.Component {
  state = {
    open: false,
    email: "",
    showConfirmation: false,
    deliveryDetails: {},
    data: {},
    deliveryMedium: "",
    destination: "",
    newPassword: "",
  };

  handleRequestPasswordReset = () => {
    if (this.props.app === "employer")
      this.handleRequestPasswordResetEmployer();
    if (this.props.app === "broker") this.handleRequestPasswordResetBroker();
  };

  handleRequestPasswordResetBroker = () => {
    this.requestPasswordResetBroker().then((r) => {
      if (r.message === "match") {
        //if email is found in brokers and cognito, tell them to check their email
        window.location.href =
          config.appLocation.URL + "/broker/auth?message=4";
      }
      if (r.message === "nomatch") {
        //if email is found in brokers and cognito, tell them to check their email
        window.location.href =
          config.appLocation.URL + "/broker/auth?message=6";
      }
    });
  };

  handleRequestPasswordResetEmployer = () => {
    this.requestPasswordResetEmployer(this.getPortalID()).then((r) => {
      if(r.message === "not_secure"){
        window.location.href =
          config.appLocation.URL +
          "/employer/portal-lookup-request"
      }
      if (r.message === "match") {
        //if username matches, tell them to check email
        window.location.href =
          config.appLocation.URL +
          "/employer/auth?portalID=" +
          this.getPortalID() +
          "&message=4";
      }
      if (r.message === "nomatch") {
        //if username doesn't match, tell them this
        window.location.href =
          config.appLocation.URL +
          "/employer/auth?portalID=" +
          this.getPortalID() +
          "&message=5";
      }
    });
  };

  requestPasswordResetBroker = () => {
    let body = {
      email: this.state.email,
    };

    return new Promise((resolve, reject) => {
      resolve(api.requestPasswordResetBroker(JSON.stringify(body)));
    });
  };

  requestPasswordResetEmployer = (portalID) => {
    let body = {
      email: this.state.email,
    };

    return new Promise((resolve, reject) => {
      resolve(api.requestPasswordResetEmployer(portalID, JSON.stringify(body)));
    });
  };

  Back = () => {
    this.setState({ showConfirmation: false });
  };

  Next = () => {
    this.changePassword();
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };

  redirectUser = (code) => {
    if (this.props.loginURL.indexOf("portalID") > 0) {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "&message=" + code;
    } else {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "?message=" + code;
    }
  };

  render() {
    const { showConfirmation } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h4" color="inherit" noWrap>
          Password Reset
        </Typography>
        <div style={{ height: 20 }}></div>
        <Typography variant="caption">
          Enter your email address to receive a link to reset your password.
        </Typography>
        <div style={{ height: 10 }}></div>
        <TextField
          id="standard-email-dense"
          label="Email"
          className={classes.textField}
          type="email"
          name="email"
          autoComplete="email"
          margin="dense"
          onChange={(e) => this.setState({ email: e.target.value })}
          defaultValue={this.state.email}
          style={{ width: 300 }}
        />
        <div style={{ height: 20 }}></div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleRequestPasswordReset}
          style={{ width: 250 }}
        >
          Request Password Reset
        </Button>
        <div style={{ height: 20 }}></div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
