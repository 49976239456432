import React, { useState } from "react";
import api from "../../Functions/APIHelpers";
import AsyncSelect from "react-select/async";

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const EmployerAsyncSelect = props => {
  const [employerSearchValue, setSearchValue] = useState();

  const loadEmployerOptions = () => {
    return new Promise((resolve, reject) => {
      api
        .getEmployerOptions(employerSearchValue)
        .then(response => {
          var employerList = [];
          response.forEach(r => {
            r.value = r.id;
            r.label = r.name;
            employerList.push(r);
          });
          resolve(response);
        })
        .catch(err => {
          if(err === 403){ 
            this.props.history.push("/mewa-admin/auth?error=5")
          }
          if(err === 400){
            this.props.enqueueSnackbar("Error Getting Employer Options", {
              variant: "error"
            })
          }
        });
    });
  };

  const handleEmplyerInputChange = (newValue, change) => {
    setSearchValue(newValue)
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadEmployerOptions}
      onInputChange={handleEmplyerInputChange}
      onChange={props.handleEmployerChange}//handleEmployerChange
      styles={customStyles}
      isSearchable={true}
      openMenuOnClick={false}
      fullWidth={true}
      placeholder={"Type employer name..."}
      isClearable
    />
  );
};

export default EmployerAsyncSelect;