import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArcForm from "../../Shared/Components/Arc/ArcForm";
import { outputEINFromMask } from "../../Shared/Components/Input/EINTextBox";
import { calculateAutoFillFields } from "../../Shared/Components/Arc/ArcHelpers";
import api from "../../Shared/Functions/APIHelpers";
import * as moment from "moment";

class BrokerContactDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: this.props.Broker.first_name,
      lastName: this.props.Broker.last_name,
      email: this.props.Broker.email,
      brokerContactSaving: false,
    };
  }

  updateBrokerContact = (isComplete) => {
    let body = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.editBrokerContact(this.props.Broker.id, JSON.stringify(body))
      );
    });
  };

  handleSave = () => {
    this.setState({ brokerContactSaving: true });
    this.updateBrokerContact()
      .then((r) => {
        this.setState({ brokerContactSaving: false });
        this.props.enqueueSnackbar(
          "Broker contact updated: " +
            this.state.firstName +
            " " +
            this.state.lastName +
            "(" +
            this.state.email +
            ")",
          {
            variant: "success",
          }
        );
        this.props.Close();
        this.props.RefreshTable();
      })
      .catch((err) => {
        this.props.enqueueSnackbar("Error Saving Broker Contact", {
          variant: "error",
        });
        this.props.Close();
      });
  };

  close = () => {
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Broker Contact</DialogTitle>

          <DialogContent style={{ height: 300, width: 550 }}>
            <TextField
              id="first_name-text-field"
              label="First Name"
              margin="dense"
              onChange={(e) => {
                this.setState({ firstName: e.target.value });
              }}
              value={this.state.firstName}
              fullWidth
              autoComplete="off"
            />
            <TextField
              id="last_name-text-field"
              label="Last Name"
              margin="dense"
              onChange={(e) => {
                this.setState({ lastName: e.target.value });
              }}
              value={this.state.lastName}
              fullWidth
              autoComplete="off"
            />
            <TextField
              id="email-text-field"
              label="Email"
              margin="dense"
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              value={this.state.email}
              fullWidth
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <div className={this.props.classes.wrapper}>
              <Button
                onClick={this.handleSave}
                disabled={
                  this.state.brokerContactSaving ||
                  !this.state.email.match(/^(\S+@\S+\.\S+)/)
                }
                color="primary"
              >
                Save
              </Button>
              {this.state.brokerContactSaving && (
                <CircularProgress
                  size={24}
                  className={this.props.classes.buttonProgress}
                />
              )}
            </div>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(BrokerContactDialog));
