import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import TrackingEventList from "../../Shared/Components/Tracking/TrackingEventList";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class EmployerTeamDialog extends React.Component {
  state = {
    loaded: false,
    teamMembers: [],
  };

  close = () => {
    this.setState({ loaded: false }, () => {
      this.props.Close();
    });
  };

  handleClose = () => {
    this.close();
  };
  componentDidMount() {
    const { Employer } = this.props;
    this.handleGetEmployerTeam(Employer.portal_id);
  }

  handleGetEmployerTeam = (employerPortalID) => {
    this.getEmployerTeam(employerPortalID).then((r) => {
      
      this.setState({ loaded: true, teamMembers: r.data.brokers });
    });
  };

  handleAdjustPermissions = () => {
    this.props.history.push(
      "/broker/edit-employer-team?portalID=" + this.props.Employer.portal_id
    );
  };

  getEmployerTeam = (employerPortalID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerTeamEmployer(employerPortalID));
    });
  };

  mapPermissionLevel = (permissionLevel) => {
    if (permissionLevel === 0) return "Notify";
    if (permissionLevel === 1) return "View";
    if (permissionLevel === 2) return "Update";
    return "";
  };
  render() {
    const { Open } = this.props;

    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            {this.props.Employer.name} Team
          </DialogTitle>

          <DialogContent
            style={{ minHeight: 400, minWidth: 325, maxWidth: 450 }}
          >
            <Typography variant="body1">
              The following Team Members have access to this employer's Mewa.io
              data. To view or adjust permissions, go to Manage my Team under My
              Account.
            </Typography>
            <div style={{ height: 30 }}></div>
            {this.state.teamMembers
              .filter((t) =>
                t.has_relationship
              )
              .map((teamMember, index) => {
                //get permission level for the employer we are viewing
                //let permissionLevel = teamMember.employers.find(
                //  (e) => e.portal_id === this.props.Employer.portal_id
                //).permission_level;
                return (
                  <div key={index} style={{ display: "block" }}>
                    <div>
                      <Typography variant="h6">
                        {teamMember.broker_info.first_name} {teamMember.broker_info.last_name}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body1">
                        {teamMember.broker_info.email}
                      </Typography>
                      <Typography variant="caption">
                        Permission Level:{" "}
                        {this.mapPermissionLevel(teamMember.permission_info.permission_level)}
                      </Typography>
                    </div>
                    <div style={{ height: 15 }}></div>
                  </div>
                );
              })}
          </DialogContent>
          <DialogActions>
            <div style={{ width: "100%" }}>
              <div style={{ float: "left" }}>
                <Button onClick={this.handleAdjustPermissions} color="primary" id="adjust-employer-team-button">
                  Adjust Permissions
                </Button>
              </div>
              <div style={{ float: "right" }}>
                <Button onClick={this.handleClose} color="primary">
                  Close
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EmployerTeamDialog));
