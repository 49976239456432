import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./ContinuationStyles";

const SupportingDocumentationList = ({
  uploadedFiles,
  removeFile,
  classes,
}) => {
  return (
    <React.Fragment>
      <div style={{ maxWidth: 350 }}>
        <List dense={true}>
          {uploadedFiles.map((file, index) => {
            return (
              <ListItem key={index}>
                <ListItemText primary={file.name} secondary={"type: " + file.type} />
                <ListItemSecondaryAction>
                  <IconButton onClick={()=>{removeFile(file)}} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(
  SupportingDocumentationList
);
