import React from "react";
import MaskedInput, {conformToMask} from 'react-text-mask';

const displayEINMask = [
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const outputEINMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const EINTextBox = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={displayEINMask}
      placeholderChar={"\u2000"}
      showMask={false}
    />
  );
};

export default EINTextBox;



export function mapEINToMask(EIN) {
      
	return conformToMask(
        EIN, 
        displayEINMask,
        {guide: false}
    )
  }

  export function outputEINFromMask(EINWithMask) {
      return conformToMask(
        EINWithMask,
        outputEINMask,
          {guide:false}
      )
  }
