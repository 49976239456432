import React from "react";

import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import getBrandedThemeOrDefault from "../Styles/BrandedTheme";
import config from "../../config";

const BrokerHOCSecured = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      loaded: false,
      theme: {},
      user: {},
      needTOS: false,
      hasAgency: true,
    };

    componentDidMount() {
      //check if JWT already exists... AKA user is already logged in
      this.authenticate();

      //get them for MEWA if provided
      this.getBrokerTheme().then((r) => {
        //call broker endpoint to check if TOS complete
        this.getBrokerTOS();
      });

      //define a function that will check if the JWT token exists EVERY TIME the URL changes
      //this is because this.props.history.listen RETURNS an unlisten function
      //so when the component unmounts we will call that to stop making the check
      this.unlisten = this.props.history.listen(() => {
        Auth.currentAuthenticatedUser()
          .then()
          .catch(() => {
            if (this.state.isAuthenticated) {
              this.setState({ isAuthenticated: false });
            }
          });
      });
    }

    getBrokerTheme = () => {
      return new Promise((resolve, reject) => {
        if (!this.getMEWAID()) {
          let theme = getBrandedThemeOrDefault({});
          document.querySelector("link[rel='shortcut icon']").href =
            theme.fav_icon;
          document.title = theme.page_title;
          this.setState({ theme: theme }, () => {
            resolve("done");
          });
        } else {
          api.getMewaBrokerTheme(this.getMEWAID()).then((res) => {
            let theme = getBrandedThemeOrDefault(res.theme);
            document.querySelector("link[rel='shortcut icon']").href =
              theme.fav_icon;
            document.title = theme.page_title;
            this.setState({ theme: theme }, () => {
              resolve("done");
            });
          });
        }
      });
    };

    getMEWAID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let mewaID = params.get("mewaID") ? params.get("mewaID") : null;
      return mewaID;
    };

    getBrokerTOS = () => {
      api.getBrokerTOS().then((r) => {
        this.setState({
          needTOS: r.need_tos,
          hasAgency: r.has_agency,
          loaded: true,
        });
      });
    };

    authenticate() {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.setState({
            user: user,
            isAuthenticated: true,
          });
        })
        .catch((err) => {
          localStorage.clear();
          //redirect to appropriate app, or user if null
          //this.showUnauthorizedWarning(this.props.message, this.props.app);
          //let redirectURL = "/employer/auth?error=4&portalID=" + this.getPortalID()
          let redirectURL =
            config.appLocation.URL +
            "/broker/auth" +
            this.props.location.search +
            "?error=5";
          if (this.props.history.location.pathname.includes("account")) {
            redirectURL = "/broker/auth?next=notification&error=5";
          }
          if (
            this.props.history.location.pathname.includes("manage-employers")
          ) {
            redirectURL = "/broker/auth?next=manage-employers&error=5";
          }
          if (this.props.history.location.search.includes("invitationID")) {
            redirectURL =
              "/broker/auth?next=invitation&invitationID=" +
              this.getInvitationID() +
              "&result=" +
              this.getResult() +
              "&error=5";
          }
          if (
            this.props.history.location.search.includes("brokerInvitationID")
          ) {
            redirectURL =
              "/broker/auth?next=brokerInvitation&invitationID=" +
              this.getBrokerInvitationID() +
              "&error=10";
          }
          if (this.getMEWAID()) {
            redirectURL += "&mewaID=" + this.getMEWAID();
          }
          window.location.href = redirectURL;
        });
    }
    getBrokerInvitationID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("brokerInvitationID");
    };
    getInvitationID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("invitationID");
    };
    getResult = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("result");
    };
    showUnauthorizedWarning = (message, app) => {
      if (message) {
        this.props.enqueueSnackbar(message, {
          variant: "warning",
        });
      }
    };

    render() {
      const { ...rest } = this.props;
      const { loaded, user } = this.state;
      if (!loaded) return null;

      return (
        <Route
          render={(props) => {
            return (
              <MuiThemeProvider theme={this.state.theme}>
                <WrappedComponent
                  {...props}
                  {...rest}
                  theme={this.state.theme}
                  user={user}
                  needTOS={this.state.needTOS}
                  hasAgency={this.state.hasAgency}
                  getBrokerTOS={this.getBrokerTOS}
                />
              </MuiThemeProvider>
            );
          }}
        />
      );
    }
  };
};
export default BrokerHOCSecured;
