import React from "react";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import config from "../config";
import SignIn from "./SignIn";
import SignUpContainer from "./SignUpContainer";
import ForgotPassword from "./ForgotPassword";
import AdminForgotPassword from "./AdminForgotPassword";
import ChangePassword from "./ChangePassword";

import {
  withRouter,
} from "react-router-dom";
import { mapErrorCodeToMessage } from "../Shared/Components/Error/ErrorCodes";
import { mapMessageCodeToMessage } from "../Shared/Functions/MessageCodes";
import { styles } from "./Styles/AuthStyles";

class AuthContainer extends React.Component {
  state = {
    authPage: "signIn",
    user: {} 
  };

  componentDidMount() {
    if(this.props.app === 'employer'){
      if(!this.getPortalID()){
        window.location.href =config.appLocation.URL + "/"
      }
    }
    document.title=this.props.title
    document.querySelector("link[rel='shortcut icon']").href = this.props.theme.fav_icon
    this.showError();
    this.showMessage();
  }

  showError = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let error = params.get("error")
    if(error){
      this.props.enqueueSnackbar(mapErrorCodeToMessage(error), {
        variant: "error"
      });
    } 
  };

  showMessage = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let message = params.get("message")

    if(message){
      let [text, variant] = mapMessageCodeToMessage(message)
      this.props.enqueueSnackbar(text, {
        variant: variant
      });
    }
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };


  handler() {
    this.setState({
      showSignIn: false
    });
  }

  switchState = authPage => {
    this.setState({
      authPage
    });
  };

  changePassword = user => {
    this.setState({user:user, authPage:"changePassword"})
  };

  renderSwitch = param => {
    switch (param) {
      case "signIn":
        return (
          <SignIn
            loginURL={this.props.loginURL}
            logoutURL={this.props.logoutURL}
            app={this.props.app}
            welcomeMessage={this.props.welcomeMessage}
            history={this.props.history}
            changePassword={this.changePassword}
            action={this.switchState}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
        );
      case "signUp":
        return (
          <SignUpContainer
            history={this.props.history}
            action={this.switchState}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
        );
      case "changePassword" : 
        return (
          <ChangePassword
            history={this.props.history}
            homeURL={this.props.loginURL}
            loginURL={this.props.loginURL}
            action={this.switchState}
            enqueueSnackbar={this.props.enqueueSnackbar}
            user={this.state.user}
          />
        );
      case "forgotPassword":
        if(this.props.app === "employer" || this.props.app === "broker" ){
          return (
            <ForgotPassword
              app={this.props.app}
              history={this.props.history}
              loginURL={this.props.loginURL}
              logoutURL={this.props.logoutURL}
              action={this.switchState}
              enqueueSnackbar={this.props.enqueueSnackbar}
            />
          );
        }
        else{
          return (
            <AdminForgotPassword
              history={this.props.history}
              loginURL={this.props.loginURL}
              logoutURL={this.props.logoutURL}
              action={this.switchState}
              enqueueSnackbar={this.props.enqueueSnackbar}
            />
          );
        }
        
      default:
        return (
          <SignIn history={this.props.history} welcomeMessage={this.props.welcomeMessage} action={this.switchState} loginURL={this.props.loginURL}  />
        );
    }
  };

  render() {
    const { classes } = this.props;
    const { authPage } = this.state;

    return (
      <div className={classes.basecontent}>
        
                  
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className={classes.root} elevation={5} style={{ zIndex: 10 }}>
            <div className={classes.header}>
              <div className={classes.logoContainer}>
                <img
                  src={require("../Shared/Images/mewaio_vert_350.png")}
                  className={classes.largeLogo}
                  alt="Reform Health Logo"
                />
              </div>
              {this.renderSwitch(authPage)}
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withSnackbar(withStyles(styles)(AuthContainer)));


