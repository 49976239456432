import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import NewTable from "../../Shared/Components/Table/NewTable";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import FolderIcon from "@material-ui/icons/Folder";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import MenuItem from "@material-ui/core/MenuItem";

import DenyRequestReasonDialog from "./DenyRequestReasonDialog";
import SupportingDocumentationDialog from "./SupportingDocumentationDialog";
import {
  dateCellDataGetter,
  changeRequestStatusDataGetter,
  newContinuationStatusDataGetter,
  nestedCellDataGetter,
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  nestedSort,
} from "../../Shared/Components/Table/TableUtilities";

import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  documentationDialogOpen: false,
  denyRequestReasonDialogOpen: false,
  denyRequestReason: "",
  changeRequestDetail: {},
  supportingDocumentation: [],

  //FOR TABLE
  tableData: [],
  recordCount: 0,
  dataURL: "change-request-mewa",
  staticFilterURL: "change-request-mewa-static-filter",
  staticFilters: ["employer__mewa__name","request_status_text"],
  staticFilterValues: [],
  sortBy: "",
  sortDirection: "ASC",
  countURL: "change-request-mewa-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class ContactContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState({ initialLoadDone: false, fetching: true }, () => {
      this.getStaticFilterValues().then((values) => {
        this.setState({ staticFilterValues: values }, () => {
          this.getTableCount().then((count) => {
            this.getTableData(0, 10)
              .then((r) => {
                this.setState({
                  tableData: r,
                  recordCount: count["record_count"],
                  fetching: false,
                  initialLoadDone: true,
                });
                //if this is the first time the page is mounted, set record count as initial table amount
                //else set the current filtered count
                if (isInitial)
                  this.setState({
                    initialCount: count["record_count"],
                    currentCount: count["record_count"],
                  });
                else this.setState({ currentCount: count["record_count"] });
              })
              .catch((err) => {
                if (err === 403) {
                  this.props.history.push("/mewa-admin/auth?error=5");
                }
                if (err === 400) {
                  this.props.enqueueSnackbar(
                    "Something went wrong rendering medicare",
                    {
                      variant: "error",
                    }
                  );
                }
              });
          });
        });
      });
    });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering Contacts",
                {
                  variant: "error",
                }
              );
            }
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            ""
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    this.setState({ sortBy: sortBy, sortDirection: sortDirection }, () => {
      this.handleInitialData();
    });
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;
    return (
      <div>
        <MenuItem
          onClick={() => {
            this.handleViewSupportingDocumentation(selectedItem, closeMenu);
          }}
        >
          <FolderIcon className={classes.leftIcon} />
          View Supporting Documentation
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.handleApproveChangeRequest(selectedItem, closeMenu);
          }}
        >
          <ThumbUpIcon className={classes.leftIcon} />
          Approve
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.collectChangeRequestDeniedReason(selectedItem, closeMenu);
          }}
        >
          <ThumbDownIcon className={classes.leftIcon} />
          Deny
        </MenuItem>
      </div>
    );
  };

  handleViewSupportingDocumentation = (selectedItem, closeMenu) => {
    this.getSupportingDocumentationList(selectedItem.id).then((r) => {
      this.setState({ supportingDocumentation: r });
    });
    this.setState({
      changeRequestDetail: selectedItem,
      documentationDialogOpen: true,
    });
    closeMenu();
  };

  getSupportingDocumentationList = (changeRequestID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getSupportingDocumentationList(changeRequestID));
    });
  };

  handleApproveChangeRequest = (selectedItem, closeMenu) => {
    this.setState({
      changeRequestDetail: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Approve Change Request",
      confirmAlertText: "Are you sure you wish to approve this change request for " + selectedItem.employer.name + "?",
      doNotFunc: this.doNotApproveChangeRequest,
      doFunc: this.doApproveChangeRequest,
    });
    closeMenu();
  };

  doNotApproveChangeRequest = () => {
    this.setState({
      confirmAlertOpen: false,
      changeRequestDetail: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  doApproveChangeRequest = () => {
    this.setState({ confirmAlertOpen: false });
    var body = JSON.stringify({
      response:"",
      request_status: 1,
    });

    this.updateChangeRequestMEWA(this.state.changeRequestDetail.id, body).then(
      (r) => {
        this.handleInitialData();
        this.props.enqueueSnackbar("Approved Change Request, Employer Will Be Notified", {
          variant: "success",
        });
      }
    );
  };


  collectChangeRequestDeniedReason = (selectedItem, closeMenu) => {
    this.setState({ changeRequestDetail: selectedItem, denyRequestReasonDialogOpen: true });
    closeMenu();
  };

  doDenyChangeRequest = () => {
    //First collect a "reason" via dialog popup

    var body = JSON.stringify({
      request_status: 2,
      response: this.state.denyRequestReason
    });

    this.updateChangeRequestMEWA(this.state.changeRequestDetail.id, body).then(
      (r) => {
        this.handleInitialData();
        this.setState({denyRequestReason: ""});
        this.props.enqueueSnackbar("Denied Change Request, Employer Will Be Notified", {
          variant: "success",
        });
      }
    );
  };

  updateChangeRequestMEWA = (changeRequestID, body) => {
    return new Promise((resolve, reject) => {
      resolve(api.updateChangeRequestMEWA(changeRequestID, body));
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        <DenyRequestReasonDialog
          Open={this.state.denyRequestReasonDialogOpen}
          Save={()=>{
            this.setState({denyRequestReasonDialogOpen: false})
            this.doDenyChangeRequest();
          }}
          Close={()=>{
            this.setState({denyRequestReasonDialogOpen: false, denyRequestReason: ""})
          }}
          SetRequestReason={(reason)=>{
            this.setState({denyRequestReason: reason})
          }}
          DenyRequestReason={this.state.denyRequestReason}
        />
        <SupportingDocumentationDialog
          Open={this.state.documentationDialogOpen}
          Close={() => {
            this.setState({ documentationDialogOpen: false });
          }}
          Documentation={this.state.supportingDocumentation}
        />

        <div className={classes.containerRoot}>
        <div className={classes.containerTitle}>Change Requests</div>
          <div className={classes.containerContent}>
            <Paper className={classes.containerPaper}>
              <NewTable
                enqueueSnackbar={this.props.enqueueSnackbar}
                loaded={this.state.initialLoadDone}
                loadedData={this.state.tableData}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                staticFilterValues={this.state.staticFilterValues}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                setActiveFilters={this.setActiveFilters}
                activeFilters={this.state.activeFilters}
                tableName={"Change Requests"}
                columns={[
                  {
                    dataKey: "employer",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    nestedCol: "name",
                    label: "Employer Name",
                    cellRenderer: nestedCellDataGetter,
                    customSort: nestedSort,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "mewa",
                    nestedCol: "employer__mewa__name",
                    label: "MEWA",
                    customSort: nestedSort,
                    hasFilter: true,
                    hide: true, //dont neeed tis vis
                    filterType: "Static",
                  },
                  {
                    dataKey: "employer",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    nestedCol: "contact_email",
                    label: "Employer Contact",
                    cellRenderer: nestedCellDataGetter,
                    customSort: nestedSort,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    label: "Date Added",
                    dataKey: "date_added",
                    cellRenderer: dateCellDataGetter,
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Date Closed",
                    dataKey: "date_closed",
                    cellRenderer: dateCellDataGetter,
                    width: 125,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Changing To",
                    dataKey: "new_continuation_status",
                    cellRenderer: newContinuationStatusDataGetter,
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Request Status",
                    dataKey: "request_status_text",
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                    filterType: "Static",
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ContactContainer);
