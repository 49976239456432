import React from "react";

import Typography from "@material-ui/core/Typography";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Auth } from "aws-amplify";
import api from "../../Functions/APIHelpers";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
});

class ArcFormContinuationMismatchDialog extends React.Component {
  render() {
    const { fullScreen, classes } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          maxWidth={"lg"}
          scroll="paper"
        >
          <DialogTitle id="responsive-dialog-title">
            <React.Fragment>
              Please confirm your continuation status
              <br /> and total number of employees
            </React.Fragment>
          </DialogTitle>
          <DialogContent style={{ height: 300, width: 400 }}>
            {this.props.ContinuationStatus === "State Continuation" && (
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Typography variant="caption" color="inherit">
                  State Continuation generally applies to employers with 19 or
                  fewer employees in the prior year. Please confirm the total
                  number of employees and continuation status is correct.
                </Typography>
              </div>
            )}
            {this.props.ContinuationStatus === "COBRA" && (
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Typography variant="caption" color="inherit">
                  COBRA generally applies to employers with 20 or more employees
                  in the prior year. Please confirm the total number of
                  employees and continuation status is correct.
                </Typography>
              </div>
            )}
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Typography variant="caption" color="inherit">
                If an employer has 20 or more employees during a calendar year,
                COBRA applies from the first day of January for the following
                calendar year. If an employer currently subject to COBRA falls
                below 20 employees, they must offer COBRA through the end of
                that calendar year.
              </Typography>
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Typography variant="caption" color="inherit">
                <a href="https://www.dol.gov/general/topic/health-plans/cobra">
                  Please refer to the U.S. Department of Labor for additional
                  info{" "}
                </a>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button onClick={this.props.Close} color="primary">
                Close and Adjust
              </Button>
              <Button
                onClick={this.props.Save}
                id="continuation-dialog-continue-anyways"
                variant="contained"
                color="primary"
              >
                Continue Anyways
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(ArcFormContinuationMismatchDialog)
);
