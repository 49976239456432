export const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(2),
    marginTop: -60,
    marginRight: 110,
    "@media only screen and (max-width: 600px)": {
      marginTop: -64,
      marginRight: 80,
      marginLeft: 0,
    },
  },
  buttonFarRight: {
    marginLeft: theme.spacing(2),
    marginTop: -60,
    marginRight: 0,
    "@media only screen and (max-width: 600px)": {
      marginTop: -64,
      marginRight: 80,
      marginLeft: 0,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    "@media only screen and (max-width: 600px)": {
      marginRight: 0,
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  buttonText: {
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  //'@page': { margin: 0 },
  containerRoot: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexFlow: "column",
  },
  containerHeader: {
    width: "100%",
    textAlign: "left",
    marginLeft: 20,
    "@media only screen and (max-width: 400px)": {
      margin: theme.spacing(1),
    },
    "@media print": {
      backgroundColor: "red",
    },
  },
  containerTitle: {
    height: 40,
    fontSize: "2.125rem",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: 1.235,
    letterSpacing: "0.00735em",
    "@media only screen and (max-width: 400px)": {
      fontSize: "1.5rem",
      height: 75,
    },
    //marginLeft: 10,
  },
  containerContent: {
    flex: 2,
    //overflow: "auto",
    height: "100%",
    width: "100%",
    //flex: 2,
  },
  containerPaper: {
    flex: 3,
    height: "92%",
    //overflow: "auto",
    marginTop: 10, //5
    marginLeft: 0, //20
    marginRight: 0, //20
    "@media only screen and (max-width: 400px)": {
      margin: theme.spacing(1),
    },
  },
  fab: {
    margin: theme.spacing(1),
    position: "relative",
    left: -24, //-16
    //top: 20,
    top: 100, //83
    zIndex: 500,
    "@media only screen and (max-width: 400px)": {
      left: -25,
    },
  },
  addButton: {
    margin: theme.spacing(1),
    position: "relative",
    left: -24, //-16
    //top: 20,
    top: 100, //83
    zIndex: 500,
    "@media only screen and (max-width: 400px)": {
      left: -25,
    },
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  waitCircle: {
    width: "100%",
    textAlign: "center",
    float: "left",
    marginTop: "30vh",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  basecontent: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: theme.palette.background.default,
    width: "100vw",
    minHeight: "100vh",
    alignItems: "center",
    "@media only screen and (max-width: 400px)": {
      backgroundColor: "white",
    },
    zIndex: 300,
  },
  basecontentFixed: {
    height: "100vh",
  },
  basecontentExpand: {},
  baseroot: {
    flex: 2,
    //overflow: 'auto', //*
    minWidth: "92vw",
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    minHeight: "50vh",
    maxWidth: 1500,
    "@media only screen and (min-width: 1600px)": {
      minWidth: 0,
      width: 1500,
    },
    "@media only screen and (max-width: 400px)": {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
      //padding: 50,
      //margin: 50,
      //marginLeft: 100,
      //marginRight: 100,
      //margin:0,
      //minWidth: '100vw',
      boxShadow: "none",
      backgroundColor: theme.palette.background.paper,
    },
  },
  baserootFixed: {
    overflow: "auto",
  },
  baserootFixedNarrow: {
    minWidth: 0,
    overflow: "auto",
    maxWidth: 800,
    "@media only screen and (max-width: 400px)": {
      maxWidth: "100%",
      margin: theme.spacing(1),
    },
  },
  baserootExpand: {},
  fabContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "-20px",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});
