export function mapMessageCodeToMessage(messageCode){
    switch(messageCode) {
        case "1":
            return ["Password Changed Successfully", "success"]
        case "2":
            return ["Secured Employer, Check Email for Login Instructions", "success"]
        case "3":
            return ["Check Email for Login Instructions", "success"]
        case "4":
            return ["Password Reset Email Sent", "success"]
        case "5":
            return ["Email Entered Does Not Match Employer Email on File", "warning"]
        case "6":
            return ["Email Entered Does Not Match an Existing Broker In Our System", "warning"]
        default:
            return "N/A"
      }

}