import React from "react";

import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import getBrandedThemeOrDefault from "../Styles/BrandedTheme";
import api from "../../Shared/Functions/APIHelpers";

const MEWAHOC = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      loaded: false,
      isAuthenticated: false,
      user: {},
      theme:{},
    };

    componentDidMount() {
      //console.log('componentDidMount Router')
      //check if JWT already exists... AKA user is already logged in
      this.authenticate();

      //define a function that will check if the JWT token exists EVERY TIME the URL changes
      //this is because this.props.history.listen RETURNS an unlisten function
      //so when the component unmounts we will call that to stop making the check
      this.unlisten = this.props.history.listen(() => {
        Auth.currentAuthenticatedUser()
          .then()
          .catch(() => {
            if (this.state.isAuthenticated) {
              this.setState({ isAuthenticated: false });
            }
          });
      });
    }

    componentWillUnmount() {
      //call the function defined above to deactivate the route listener when component is unmounted
      this.unlisten();
    }

    authenticate() {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          //get mewa settings based on logged in user
          this.getMewaTheme().then(() => {
            this.setState({ user: user, loaded: true, isAuthenticated: true });
          })
        })
        .catch((err) => {
          localStorage.clear();
          console.log(err);
          console.log("Something happened with authentication in privateRoute");
          //redirect to appropriate app, or user if null
          this.showUnauthorizedWarning(this.props.message, this.props.app);
          let redirectURL ="/mewa-admin/auth"
          if (this.props.history.location.search.includes("invitationID")) {
            redirectURL = "/mewa-admin/auth?next=invitation&invitationID="+this.getInvitationID()+"&result=" + this.getResult()
          }
          this.props.history.push(redirectURL);
        });
    }
    getInvitationID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("invitationID");
    };
    getResult = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("result");
    };
    getMewaTheme = () => {
      return new Promise((resolve, reject) => {
        api.getMewaTheme().then(t => {
          let theme = getBrandedThemeOrDefault(t.theme);
          document.querySelector("link[rel='shortcut icon']").href = theme.fav_icon;
          this.setState({ theme: theme }, () => {resolve('done')});
        })
        
      });
    };

    showUnauthorizedWarning = (message, app) => {
      if (message) {
        this.props.enqueueSnackbar(message, {
          variant: "warning",
        });
      }
    };

    render() {
      //const { component: Component, ...rest } = this.props;
      const { ...rest } = this.props;
      const { loaded, user } = this.state;
      if (!loaded) return null; //doesn't load the private component until user is loaded

      //In the route we explicitly pass the state element User so that Container components
      //can allow their routes to have access to the cognito user object
      return (
        <Route
          render={(props) => {
            return (
              <div>
                <div id="login">
                  <MuiThemeProvider theme={this.state.theme}>
                    <WrappedComponent {...props} {...rest} user={user} theme={this.state.theme} />
                  </MuiThemeProvider>
                </div>
              </div>
            );
          }}
        />
      );
    }
  };
};

export default MEWAHOC;
