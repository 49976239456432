import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Checkbox from "@material-ui/core/Checkbox";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Storage } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import Signature from "../../Shared/Components/Signature/Signature";
import MedicareSubmissionDocumentDialog from "./MedicareSubmissionDocumentDialog";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  confirmSubmit: false,
  submissionMemo: "",
  displaySignature: true,
  signatureError: false,
  signatureThumbnail: "",
  previewDocumentDialogOpen: false,
  downloading: false,
  submitting: false,
  activeStep: 0,
};

class MedicareSubmissionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  downloadMedicareForms = (isFax) => {
    var body = JSON.stringify({
      previously_downloaded: true,
      fax_records: isFax,
      records: this.props.IncludedForms.map((f) => f.id),
      submission_memo: this.state.submissionMemo
    });

    return new Promise((resolve, reject) => {
      api
        .getMedicareFormGroup(body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.setState({ errorMessage: "Error Getting Forms" });
          reject(err);
        });
    });
  };

  afterStorage = () => {
    return new Promise((resolve, reject) => {
      resolve("done");
    });
  };

  showThumbnail = () => {
    Storage.configure({ level: "private" });
    Storage.get("sig_versioned.png").then((result) => {
      this.setState({ signatureThumbnail: result });
    });
  };

  downloadFile = (fileName) => {
    Storage.configure({ level: "private" });
    Storage.get(fileName)
      .then((result) => {
        this.setState({ downloading: false }, () => {
          const link = document.createElement("a");
          link.href = result;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  handleDownload = () => {
    this.setState({ downloading: true }, () => {
      this.downloadMedicareForms(false)
        .then((r) => {
          this.downloadFile(r.outputFiles[0].fileName);
        })
        .catch((err) => {
          this.props.enqueueSnackbar("No Records Found For Timeframe", {
            variant: "warning",
          });
        });
    });
  };

  handleSubmit = () => {
    this.setState({ submitting: true }, () => {
      this.downloadMedicareForms(true)
        .then((r) => {
          this.setState({ submitting: false }, () => {
            this.props.enqueueSnackbar("Fax Sent!", {
              variant: "success",
            });
            this.handleClose();
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar("No Records Found For Timeframe", {
            variant: "warning",
          });
        });
    });
  };

  render() {
    const { fullScreen, IsFax } = this.props;
    const { submitting, downloading, confirmSubmit, signatureThumbnail } =
      this.state;

    return (
      <div>
        {this.state.previewDocumentDialogOpen && (
          <MedicareSubmissionDocumentDialog
            Open={this.state.previewDocumentDialogOpen}
            Close={() => {
              this.setState({ previewDocumentDialogOpen: false });
            }}
            HandleMap={this.downloadMedicareForms}
          />
        )}
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
          maxWidth={"lg"}
        >
          <DialogTitle id="form-dialog-title">Submit Forms to CMS</DialogTitle>

          <DialogContent
            style={{
              minHeight: 100,
              minWidth: 800,
            }}
          >
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              <Step key={0}>
                <StepLabel>Confirm Selected Forms</StepLabel>
                <StepContent>
                  <div style={{ width: "100%" }}>
                    <Typography variant="subtitle1">
                      The following forms have been selected for this packet:{" "}
                    </Typography>
                    <List dense={false}>
                      {this.props.IncludedForms.map((form, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primary={
                                form.employee_first +
                                " " +
                                form.employee_first +
                                " (" +
                                form.employer_name +
                                ")"
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>

                  <StepButtons
                    activeStep={this.state.activeStep}
                    disableBack={true}
                    handleBack={() => {
                      this.setState({ activeStep: 0 });
                    }}
                    disableNext={false}
                    handleNext={() => {
                      this.setState({ activeStep: 1 });
                    }}
                  />
                </StepContent>
              </Step>

              <Step key={1}>
                <StepLabel>Sign Selected Forms</StepLabel>
                <StepContent>
                  <div style={{ width: "100%" }}>
                    {this.state.displaySignature &&
                      this.state.signatureThumbnail === "" &&
                      this.props.Open && (
                        <Signature
                          Public={false}
                          IsDone={this.showThumbnail}
                          AfterStorage={this.afterStorage}
                        />
                      )}
                    {this.state.displaySignature &&
                      this.state.signatureThumbnail !== "" && (
                        <img
                          ref="sig_image"
                          alt="sig_image"
                          //className={sig_styles.sigImage}
                          src={this.state.signatureThumbnail}
                          style={{ height: 200, marginBottom: 20 }}
                        />
                      )}
                  </div>
                  <StepButtons
                    activeStep={this.state.activeStep}
                    disableBack={false}
                    handleBack={() => {
                      this.setState({ activeStep: 0 });
                    }}
                    disableNext={this.state.signatureThumbnail === ""}
                    handleNext={() => {
                      this.setState({ activeStep: 2 });
                    }}
                  />
                </StepContent>
              </Step>

              <Step key={2}>
                <StepLabel>Preview and download selected forms</StepLabel>
                <StepContent>
                  <div className={this.props.classes.wrapper}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        this.setState({ previewDocumentDialogOpen: true });
                      }}
                      disabled={this.state.download}
                      style={{ marginTop: 20, marginBottom: 20 }}
                      startIcon={<VisibilityIcon />}
                    >
                      Preview
                    </Button>
                  </div>
                  <div className={this.props.classes.wrapper}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.handleDownload}
                      disabled={this.state.download}
                      style={{ marginBottom: 30 }}
                      startIcon={<GetAppIcon />}
                    >
                      Download
                      {this.state.downloading && (
                        <CircularProgress
                          size={24}
                          className={this.props.classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </div>

                  <StepButtons
                    activeStep={this.state.activeStep}
                    disableBack={this.state.downloading}
                    handleBack={() => {
                      this.setState({ activeStep: 1 });
                    }}
                    handleNext={() => {
                      this.setState({ activeStep: 3 });
                    }}
                    disableNext={this.state.downloading}
                  />
                </StepContent>
              </Step>

              <Step key={3}>
                <StepLabel>Send forms to CMS</StepLabel>
                <StepContent>
                  These Forms will be faxed to:{" "}
                  {process.env.REACT_APP_FAX_NUMBER}
                  <div style={{ width: "100%" }}>
                    <TextField
                      id="standard-email-dense"
                      label="Memo"
                      margin="dense"
                      onChange={(e) => this.setState({ submissionMemo: e.target.value })}
                      defaultValue={this.state.submissionMemo}
                      multiline
                      rows={4}
                      style={{width:350,marginTop: 20,marginBottom: 10}}
                      variant="outlined"
                    />
                    <br/>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.confirmSubmit}
                          onChange={(e) => {
                            this.setState({ confirmSubmit: e.target.checked });
                          }}
                          value="Confirm"
                          color="primary"
                        />
                      }
                      style={{  marginBottom: 40 }}
                      label="Confirm this packet is ready to be submitted to CMS"
                    />
                  </div>
                  <StepButtons
                    activeStep={this.state.activeStep}
                    disableBack={false}
                    handleBack={() => {
                      this.setState({ activeStep: 2 });
                    }}
                    handleNext={this.handleSubmit}
                    disableNext={
                      !this.state.confirmSubmit || this.state.submitting
                    }
                    submitting={this.state.submitting}
                    classes={this.props.classes}
                  />
                </StepContent>
              </Step>
            </Stepper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(MedicareSubmissionDialog));

const StepButtons = ({
  activeStep,
  handleBack,
  disableBack,
  handleNext,
  disableNext,
  submitting,
  classes,
}) => {
  return (
    <div>
      <Button disabled={disableBack} onClick={handleBack}>
        BACK
      </Button>
      {activeStep !== 3 && (
        <Button
          disabled={disableNext}
          variant="contained"
          color="primary"
          onClick={handleNext}
        >
          NEXT
        </Button>
      )}
      {activeStep === 3 && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={disableNext}
        >
          SUBMIT TO CMS
          {submitting && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      )}
    </div>
  );
};
