import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Storage } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ModalStyles";
import Signature from "../Signature/Signature";

import api from "../../Functions/APIHelpers";

const initialState = {
  startDate: null,
  startDateError: false,
  endDate: null,
  endDateError: false,
  searchBy: "closed",
  excludeOver20: true,
  previouslyDownloaded: false,
  faxRecords: false,
  automaticallyClosed: true,
  displaySignature: false,
  signatureError: false,
  signatureThumbnail: "",
  downloading: false,
};

class MedicareDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  downloadMedicareForms = () => {
    var body = JSON.stringify({
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      is_signed_download: this.state.displaySignature,
      date_to_range: this.state.searchBy,
      fax_records: this.state.faxRecords,
      previously_downloaded: this.state.previouslyDownloaded,
      exclude_over_20: this.state.excludeOver20,
    });

    return new Promise((resolve, reject) => {
      api
        .getMedicareFormGroup(body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.setState({ errorMessage: "Error Getting Forms" });
          reject(err);
        });
    });
  };

  downloadAuthForms = () => {
    var body = JSON.stringify({
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    });

    return new Promise((resolve, reject) => {
      api
        .getTerminationAuthForms(body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.setState({
            errorMessage: "Error Getting Termination Auth Forms",
          });
          reject(err);
        });
    });
  };

  downloadTerminationRecords = () => {
    var body = JSON.stringify({
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      date_to_range: this.state.searchBy,
      previously_downloaded: this.state.previouslyDownloaded,
      automatically_closed: this.state.automaticallyClosed,
    });

    return new Promise((resolve, reject) => {
      api
        .getTerminationRecords(body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          this.setState({ errorMessage: "Error Getting Termination Records" });
          reject(err);
        });
    });
  };

  afterStorage = () => {
    return new Promise((resolve, reject) => {
      resolve("done");
    });
  };

  showThumbnail = () => {
    Storage.configure({ level: "private" });
    Storage.get("sig_versioned.png").then((result) => {
      this.setState({ signatureThumbnail: result });
    });
  };

  setSignedRecords = (isSigned) => {
    if (isSigned)
      this.setState({ displaySignature: true, signatureThumbnail: "" });
    else this.setState({ displaySignature: false });
  };

  setFaxRecords = (isFaxed) => {
    if (isFaxed) this.setState({ faxRecords: true });
    else this.setState({ faxRecords: false });
  };

  downloadFile = (fileName) => {
    Storage.configure({ level: "private" });
    Storage.get(fileName)
      .then((result) => {
        this.setState({ downloading: false }, () => {
          const link = document.createElement("a");
          link.href = result;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  downloadCSV = (data) => {
    this.setState({ downloading: false }, () => {
      const link = document.createElement("a");
      var fileName =
        "mewaio_termination_records_" +
        this.state.startDate +
        "_to_" +
        this.state.endDate +
        ".csv";
      link.download = fileName;
      link.href = "data:text/csv;charset=UTF-8," + encodeURIComponent(data);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  handleSave = () => {
    var validForm = this.getValidForm();
    if (validForm()) {
      this.setState({ downloading: true }, () => {
        if (this.props.type === "medicare") {
          this.downloadMedicareForms()
            .then((r) => {
              this.downloadFile(r.outputFiles[0].fileName);
            })
            .catch((err) => {
              this.props.enqueueSnackbar("No Records Found For Timeframe", {
                variant: "warning",
              });
            });
        }
        if (this.props.type === "termination") {
          this.downloadTerminationRecords()
            .then((r) => {
              this.downloadCSV(r);
            })
            .catch((err) => {
              this.props.enqueueSnackbar("No Records Found For Timeframe", {
                variant: "warning",
              });
            });
        }
        if (this.props.type === "authForm") {
          this.downloadAuthForms()
            .then((r) => {
              console.log(r);
              this.downloadFile(r.outputFiles[0].fileName);
            })
            .catch((err) => {
              console.log(err);
              this.props.enqueueSnackbar("No Records Found For Timeframe", {
                variant: "warning",
              });
            });
        }
      });
    }
  };

  getValidForm = () => {
    if (this.props.type === "medicare") return this.isValidFormMedicare;
    if (this.props.type === "termination") return this.isValidFormTermination;
    if (this.props.type === "authForm")
      return function () {
        return true;
      };
  };

  isValidFormMedicare = () => {
    this.setState({
      startDateError: this.state.startDate === "",
      endDateError: this.state.endDate === "",
      signatureError:
        this.state.displaySignature && this.state.signatureThumbnail === "",
    });
    if (
      !this.startDateError &&
      !this.state.endDateError &&
      !this.state.signatureError
    ) {
      return true;
    }
  };

  isValidFormTermination = () => {
    this.setState({
      startDateError: this.state.startDate === "",
      endDateError: this.state.endDate === "",
    });
    if (!this.startDateError && !this.state.endDateError) {
      return true;
    }
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
          maxWidth={"lg"}
        >
          <DialogTitle id="form-dialog-title">Download Records</DialogTitle>

          <DialogContent style={{ minHeight: 100 }}>
            <KeyboardDatePicker
              id="startDatePicker"
              autoOk={true}
              value={this.state.startDate}
              openTo="date"
              format="MM/DD/YYYY"
              label="Start Date"
              views={["year", "month", "date"]}
              style={{ width: 170, marginRight: 10 }}
              onChange={(date) => this.setState({ startDate: date })}
            />
            <KeyboardDatePicker
              id="endDatePicker"
              autoOk={true}
              value={this.state.endDate}
              openTo="date"
              format="MM/DD/YYYY"
              label="End Date"
              views={["year", "month", "date"]}
              style={{ width: 170, marginRight: 10 }}
              onChange={(date) => this.setState({ endDate: date })}
            />
            <br />
            <FormControl component="fieldset" style={{ marginTop: 20 }}>
              <FormLabel component="legend">Search By</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={this.state.searchBy}
                onChange={(e) => {
                  console.log(e.target.value);
                  this.setState({ searchBy: e.target.value });
                }}
              >
                <FormControlLabel
                  value={"closed"}
                  control={<Radio />}
                  label="Date Closed"
                />
                <FormControlLabel
                  value={"added"}
                  control={<Radio />}
                  label="Date Added"
                />
              </RadioGroup>
            </FormControl>
            {this.props.type === "medicare" && (
              <React.Fragment>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.excludeOver20}
                      onChange={(e) => {
                        this.setState({
                          excludeOver20: e.target.checked,
                        });
                      }}
                      value="Exclude 20+ Groups"
                      color="primary"
                    />
                  }
                  style={{ marginTop: 40 }}
                  label="Exclude Groups with 20 or More Employees"
                />
              </React.Fragment>
            )}
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.previouslyDownloaded}
                  onChange={(e) => {
                    this.setState({ previouslyDownloaded: e.target.checked });
                  }}
                  value="Previously Downloaded"
                  color="primary"
                />
              }
              label="Include Previously Downloaded"
            />
            {this.props.type === "termination" && (
              <React.Fragment>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.automaticallyClosed}
                      onChange={(e) => {
                        this.setState({
                          automaticallyClosed: e.target.checked,
                        });
                      }}
                      value="Automatically Closed"
                      color="primary"
                    />
                  }
                  label="Include Automatically Closed Records"
                />
              </React.Fragment>
            )}
            {this.props.type === "medicare" && (
              <React.Fragment>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.faxRecords}
                      onChange={(e) => {
                        this.setFaxRecords(e.target.checked);
                      }}
                      value="Fax Records"
                      color="primary"
                    />
                  }
                  label="Fax Records"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.displaySignature}
                      onChange={(e) => {
                        this.setSignedRecords(e.target.checked);
                      }}
                      value="Sign Forms"
                      color="primary"
                    />
                  }
                  label="Sign Records"
                />
                {this.state.displaySignature && (
                  <Signature
                    Public={false}
                    IsDone={this.showThumbnail}
                    AfterStorage={this.afterStorage}
                  />
                )}
                {this.state.displaySignature &&
                  this.state.signatureThumbnail !== "" && (
                    <img
                      ref="sig_image"
                      alt="sig_image"
                      //className={sig_styles.sigImage}
                      src={this.state.signatureThumbnail}
                      style={{ height: 100 }}
                    />
                  )}
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <div className={this.props.classes.wrapper}>
              <Button
                onClick={this.handleSave}
                color="primary"
                disabled={this.state.downloading}
              >
                Download
              </Button>
              {this.state.downloading && (
                <CircularProgress
                  size={24}
                  className={this.props.classes.buttonProgress}
                />
              )}
            </div>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(MedicareDialog));
