import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import MedicareForm from "../../Shared/Components/Medicare/MedicareForm";
import { outputEINFromMask } from "../../Shared/Components/Input/EINTextBox";
import api from "../../Shared/Functions/APIHelpers";
import * as moment from "moment";

class MedicareDialog extends React.Component {
  state = {
    errors: [],
    employer_name: this.props.medicareDetail.employer_name || "",
    EIN: this.props.medicareDetail.EIN || "",
    number_employees: this.props.medicareDetail.number_employees || "",
    employer_street: this.props.medicareDetail.employer_street || "",
    employer_city: this.props.medicareDetail.employer_city || "",
    employer_state: this.props.medicareDetail.employer_state
      ? this.props.medicareDetail.employer_state.id
      : "",
    employer_zip: this.props.medicareDetail.employer_zip || "",
    employer_contact_first:
      this.props.medicareDetail.employer_contact_first || "",
    employer_contact_last:
      this.props.medicareDetail.employer_contact_last || "",
    employer_contact_email:
      this.props.medicareDetail.employer_contact_email || "",
    employee_first: this.props.medicareDetail.employee_first || "",
    employee_last: this.props.medicareDetail.employee_last || "",
    employee_email: this.props.medicareDetail.employee_email || "",
    beneficiary_first: this.props.medicareDetail.beneficiary_first || "",
    beneficiary_last: this.props.medicareDetail.beneficiary_last || "",
    beneficiary_ssn: this.props.medicareDetail.beneficiary_ssn || "",
    beneficiary_dob: this.props.medicareDetail.beneficiary_dob
      ? moment(this.props.medicareDetail.beneficiary_dob)
      : null,
    effective_date: this.props.medicareDetail.effective_date
      ? moment(this.props.medicareDetail.effective_date)
      : null,

    usingEmployerPreload: false,
  };

  addMedicareMEWA = (body) => {
    return new Promise((resolve, reject) => {
      resolve(api.addMedicareMEWA(JSON.stringify(body)));
    });
  };

  updateMedicareMEWA = (body) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.updateMedicareMEWA(
          this.props.medicareDetail.id,
          JSON.stringify(body)
        )
      );
    });
  };

  onChange = (value, field) => {
    this.setState({ [field]: value });
  };

  employerChange = (employer) => {
    this.setState({
      employer_name: employer ? employer.name : "",
      EIN: employer ? employer.EIN : "",
      employer_contact_first: employer ? employer.contact_first : "",
      employer_contact_last: employer ? employer.contact_last : "",
      employer_contact_email: employer ? employer.contact_email : "",
      employer_city: employer ? employer.city : "",
      employer_state: employer ? employer.state.id : "",
      employer_street: employer ? employer.street : "",
      employer_zip: employer ? employer.zip : "",
      usingEmployerPreload: employer ? true : false,
    });
  };

  inError = () => {
    let errors = [];
    const body = this.getBody();
    Object.entries(body).map((field) => {
      if (field[1] === "") errors.push(field[0]);
    });
    this.setState({ errors: errors });
    console.log(errors)
    return errors.length > 0;
  };

  getBody = () => {
    return {
      added_by: 1,
      employer_name: this.state.employer_name,
      EIN: outputEINFromMask(this.state.EIN).conformedValue,
      number_employees: this.state.number_employees,
      employer_street: this.state.employer_street,
      employer_city: this.state.employer_city,
      employer_state_id: this.state.employer_state,
      employer_zip: this.state.employer_zip,
      employer_contact_first: this.state.employer_contact_first,
      employer_contact_last: this.state.employer_contact_last,
      employer_contact_email: this.state.employer_contact_email,
      employee_first: this.state.employee_first,
      employee_last: this.state.employee_last,
      employee_email: this.state.employee_email,
      beneficiary_first: this.state.beneficiary_first,
      beneficiary_last: this.state.beneficiary_last,
      set_beneficiary_ssn: this.state.beneficiary_ssn.replaceAll("-", ""),
      beneficiary_dob: this.state.beneficiary_dob
        ? this.state.beneficiary_dob.format("YYYY-MM-DD")
        : "",
      effective_date: this.state.effective_date
        ? this.state.effective_date.format("YYYY-MM-DD")
        : "",
    };
  };

  close = () => {
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  handleSave = () => {
    if (this.inError()) return;
    let saveFunction = this.props.isAdd
      ? this.addMedicareMEWA
      : this.updateMedicareMEWA;

    saveFunction(this.getBody())
      .then((r) => {
        this.handleClose();
        this.props.RefreshTable();
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/mewa-admin/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar("Something went wrong saving medicare", {
            variant: "error",
          });
        }
      });
  };

  render() {
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Add Medicare</DialogTitle>

          <DialogContent style={{ height: 600, width: 550 }}>
            <MedicareForm
              usingEmployerPreload={this.state.usingEmployerPreload}
              employerChange={this.employerChange}
              onChange={this.onChange}
              isAdd={this.props.isAdd}
              errors={this.state.errors}
              employer_name={this.state.employer_name}
              EIN={this.state.EIN}
              number_employees={this.state.number_employees}
              employer_street={this.state.employer_street}
              employer_city={this.state.employer_city}
              employer_state={this.state.employer_state}
              employer_zip={this.state.employer_zip}
              employer_contact_first={this.state.employer_contact_first}
              employer_contact_last={this.state.employer_contact_last}
              employer_contact_email={this.state.employer_contact_email}
              employee_first={this.state.employee_first}
              employee_last={this.state.employee_last}
              employee_email={this.state.employee_email}
              beneficiary_first={this.state.beneficiary_first}
              beneficiary_last={this.state.beneficiary_last}
              beneficiary_ssn={this.state.beneficiary_ssn}
              beneficiary_dob={this.state.beneficiary_dob}
              effective_date={this.state.effective_date}
            />
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Close
            </Button>            
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(MedicareDialog));
