import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import DoneIcon from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class MedicareDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submissionNotes: [],
      activeStep: -1,
      dateCompleted: "",
      dateSubmitted: "",
      loaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { Open, SelectedRecord } = this.props;
    const { loaded } = this.state;

    if (Open && !loaded) this.handleGetDetails(SelectedRecord.id);
  }

  handleGetDetails = (medicareID) => {
    this.getMedicareEventDetail(this.props.SelectedRecord.id)
      .then((r) => {
        let submissionNotes = r.return_notes
        let dateCompleted,
          dateSubmitted = "";
        let activeStep = -1;
        if (this.props.SelectedRecord.signature_date) {
          dateCompleted = this.props.SelectedRecord.signature_date;
          activeStep = 0;
        }
        if (r.submission_date) {
          dateSubmitted = r.submission_date;
          activeStep = 1;
        }

        this.setState({
          activeStep: activeStep,
          dateCompleted: dateCompleted,
          dateSubmitted: dateSubmitted,
          submissionNotes: submissionNotes,
          loaded: true,
        });
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/mewa-admin/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "Something went wrong rendering termination details",
            {
              variant: "error",
            }
          );
        }
      });
  };

  getMedicareEventDetail = (medicareID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(api.getMedicareSubmissionPortal(medicareID));
      } else {
        resolve(api.getMedicareSubmissionPortalSecured(medicareID));
      }
    });
  };

  close = () => {
    this.setState({ sendgridEvents: [], systemEvents: {}, loaded: false });
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { Open } = this.props;

    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Medicare Tracker
            <br />
          </DialogTitle>

          <DialogContent style={{ height: 250, minWidth: 325 }}>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              <Step expanded={true} key={0}>
                <StepLabel>
                  <Typography variant="subtitle1">Record Completed</Typography>
                  {this.state.activeStep >= 0 && (
                    <Typography variant="caption" display="block" gutterBottom>
                      {formatDateTime(this.state.dateCompleted)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>

              <Step expanded={true} key={1}>
                <StepLabel>
                  <Typography variant="subtitle1">
                    Record Submitted to CMS
                  </Typography>
                  {this.state.activeStep > 0 && (
                    <Typography variant="caption" gutterBottom>
                      {formatDateTime(this.state.dateSubmitted)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>
            </Stepper>
          
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(MedicareDetail));
export { MedicareDetail as PureMedicareDetail };
