import React from "react";
import {
  withRouter,
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { withSnackbar } from "notistack";
import BaseContainer from "../Shared/Layout/BaseContainer";
import AuthContainer from "../Auth/AuthContainer";
import NavList from "./Nav/NavList";
import Home from "./Home/Home";
import MedicareContainer from "./Medicare/MedicareContainer";
import MedicareSubmissionContainer from "./MedicareSubmissions/MedicareSubmissionContainer";
import TerminationContainer from "./Termination/TerminationContainer";
import ContactContainer from "./Contacts/ContactContainer"; 
import EmployerContainer from "./Employers/EmployerContainer";
import DocumentsContainer from "./Documents/DocumentsContainer";
import AccountContainer from "./Account/AccountContainer";
import BrokerContainer from "./Brokers/BrokerContainer";
import ApproveNewBrokerContainer from "./Invite/ApproveNewBrokerContainer";
import ConfirmationContainer from "./Continuation/ConfirmationContainer";
import ChangeRequestContainer from "./Continuation/ChangeRequestContainer";
import ArcContainer from "./Arc/ArcContainer";
import MEWAHOC from "../Auth/HOC/MEWAHOC";
import getBrandedThemeOrDefault from "../Auth/Styles/BrandedTheme";

class BaseContainerWrapped extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {},
  };

  componentDidMount() {
    /*document.title = this.props.theme.page_title*/
    document.title = "mewa.io - Admin Portal"
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <BaseContainer
                {...props}
                user={this.props.user}
                component={Component}
                home={"/mewa-admin/dashboard"}
                theme={this.props.theme}
                logoutURL={"/mewa-admin/auth"}
                fixed={this.props.fixed}
                isLoaded={true}
                hasAccount={true}
                app={"admin"}
                navList={NavList}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      />
    );
  }
}

//PrivateRoute = withRouter(PrivateRoute)
BaseContainerWrapped = withSnackbar(withRouter(MEWAHOC(BaseContainerWrapped)));

const Routes = (props) => {
  const getLoginURL = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let next = params.get("next")
    let invitationID = params.get("invitationID")
    let result = params.get("result")
    if(next){
      if(next === "invitation")
      {
        return "/mewa-admin/approve-new-broker?invitationID=" + invitationID + "&result=" + result
      }
    }
    return "/mewa-admin/dashboard"
  };
  return (
    <Router>
      <Switch>
        <BaseContainerWrapped
          exact
          path="/mewa-admin/dashboard"
          fixed={true}
          component={Home}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/account"
          fixed={true}
          user={props.user} //explicitly giving account container access to the User object from cognito
          component={AccountContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/contacts"
          fixed={true}
          component={ContactContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/brokers"
          fixed={true}
          component={BrokerContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/employers"
          fixed={true}
          component={EmployerContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/medicare"
          fixed={true}
          component={MedicareContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/medicare-submissions"
          fixed={true}
          component={MedicareSubmissionContainer}
        />   
        <BaseContainerWrapped
          exact
          path="/mewa-admin/termination"
          fixed={true}
          component={TerminationContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/arc"
          fixed={true}
          component={ArcContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/documents"
          fixed={true}
          component={DocumentsContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/confirmations"
          fixed={true}
          component={ConfirmationContainer}
        />
        
        <BaseContainerWrapped
          exact
          path="/mewa-admin/change-requests"
          fixed={true}
          component={ChangeRequestContainer}
        />
        <BaseContainerWrapped
          exact
          path="/mewa-admin/approve-new-broker"
          fixed={true}
          component={ApproveNewBrokerContainer}
        />
        <Route
          exact
          path="/mewa-admin/auth"
          user={props.user}
          component={() => (
            <AuthContainer

              logoutURL={"/mewa-admin/auth"}
              theme={getBrandedThemeOrDefault({})}
              loginURL={getLoginURL()}
              app={"admin"}
              welcomeMessage={"Welcome MEWA Admin!"}
              title={"mewa.io - Admin"}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
