//import { withStyles } from '@material-ui/core/styles';

export const styles = theme => ({
	navDrawer: {},
	navList: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	listItem: {
		minWidth: 35
	},
	nested: {
		paddingLeft: theme.spacing(4)
	}
});