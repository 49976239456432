import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import TerminationForm from "../../Shared/Components/Terminations/TerminationForm";
import TerminationHelpDialog from "../../Shared/Components/Terminations/TerminationHelpDialog";
import { outputEINFromMask } from "../../Shared/Components/Input/EINTextBox";
import api from "../../Shared/Functions/APIHelpers";
import * as moment from "moment";

class TerminationDialog extends React.Component {
  state = {
    helpModal: false,

    employer_name: this.props.terminationDetail.employer_name || "",
    EIN: this.props.terminationDetail.EIN || "",
    employer_contact_email:
      this.props.terminationDetail.employer_contact_email || "",
    employee_first: this.props.terminationDetail.employee_first || "",
    employee_last: this.props.terminationDetail.employee_last || "",
    employee_street: this.props.terminationDetail.employee_street || "",
    employee_city: this.props.terminationDetail.employee_city || "",
    employee_state: this.props.terminationDetail.employee_state
      ? this.props.terminationDetail.employee_state.id
      : "",
    employee_zip: this.props.terminationDetail.employee_zip || "",
    termination_date: this.props.terminationDetail.termination_date
      ? moment(this.props.terminationDetail.termination_date)
      : null,
    termination_reason: this.props.terminationDetail.termination_reason || 0,
    members: this.props.terminationDetail.members ? this.props.terminationDetail.members : [],
    usingEmployerPreload: false,
    errors: [],
  };

  addTerminationMEWA = (body) => {
    return new Promise((resolve, reject) => {
      resolve(api.addTerminationMEWA(JSON.stringify(body)));
    });
  };

  updateTerminationMEWA = (body) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.updateTerminationMEWA(
          this.props.terminationDetail.id,
          JSON.stringify(body)
        )
      );
    });
  };

  onChange = (value, field) => {
    this.setState({ [field]: value });
  };

  employerChange = (employer) => {
    this.setState({
      employer_name: employer ? employer.name : "",
      EIN: employer ? employer.EIN : "",
      employer_contact_email: employer ? employer.contact_email : "",
      usingEmployerPreload: employer ? true : false,
    });
  };

  handleSave = () => {
    if (this.inError()) return;
    let saveFunction = this.props.isAdd
      ? this.addTerminationMEWA
      : this.updateTerminationMEWA;

    saveFunction(this.getBody())
      .then((r) => {
        this.props.Close();
        this.props.RefreshTable();
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/mewa-admin/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "Something went wrong saving termination",
            {
              variant: "error",
            }
          );
        }
      });
  };

  inError = () => {
    let errors = [];
    const body = this.getBody();
    Object.entries(body).map((field) => {
      if (field[0] === "termination_reason" && field[1] === 0)
        errors.push(field[0]);
      if (field[1] === "") errors.push(field[0]);
    });
    this.setState({ errors: errors });
    return errors.length > 0;
  };

  getBody = () => {
    //check if termination date is a Moment object before formatting. If it is,
    //we need to use the .format() method. This means the termination date has not been changed by user.
    //If its not, that means the user has changed it and no .format() is necessary as that takes place in EditTerminationDateDialog.js
    let termination_date = this.state.termination_date;
    if (termination_date) {
      if (moment.isMoment(this.state.termination_date))
        termination_date = this.state.termination_date.format("YYYY-MM-DD");
    } else {
      termination_date = "";
    }
    return {
      employer_name: this.state.employer_name,
      EIN: outputEINFromMask(this.state.EIN).conformedValue,
      employer_contact_email: this.state.employer_contact_email,

      employee_first: this.state.employee_first,
      employee_last: this.state.employee_last,

      employee_street: this.state.employee_street,
      employee_city: this.state.employee_city,
      employee_state_id: this.state.employee_state,
      employee_zip: this.state.employee_zip,

      termination_reason: this.state.termination_reason,
      termination_date: termination_date,
    };
  };

  close = () => {
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          {this.props.isAdd && (
            <DialogTitle id="form-dialog-title">Add Termination</DialogTitle>
          )}
          {!this.props.isAdd && (
            <DialogTitle id="form-dialog-title">Edit Termination</DialogTitle>
          )}
          <DialogContent style={{ height: 600, width: 550 }}>
            <TerminationHelpDialog
              Open={this.state.helpModal}
              Close={() => {
                this.setState({ helpModal: false });
              }}
            />
            <TerminationForm
              OpenHelpModal={() => {
                this.setState({ helpModal: true });
              }}
              usingEmployerPreload={this.state.usingEmployerPreload}
              errors={this.state.errors}
              employerChange={this.employerChange}
              employer_name={this.state.employer_name}
              EIN={this.state.EIN}
              employer_contact_email={this.state.employer_contact_email}
              employee_first={this.state.employee_first}
              employee_last={this.state.employee_last}
              employee_street={this.state.employee_street}
              employee_city={this.state.employee_city}
              employee_state={this.state.employee_state}
              employee_zip={this.state.employee_zip}
              members={this.state.members}
              termination_date={this.state.termination_date}
              termination_reason={this.state.termination_reason}
              canEditTerminationDate={true}
              onChange={this.onChange}
              isAdd={this.props.isAdd}
            />
          </DialogContent>
          <DialogActions>
            <Button id={'save-termination-button'} onClick={this.handleSave} color="primary">
              Save
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(TerminationDialog));
