import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import api from "../../Shared/Functions/APIHelpers";

import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  teamMemberBox: {
    display: "flex",
    marginTop: 25,
    "@media only screen and (max-width: 600px)": {
      display: "block",
    },
    paddingBottom: 25,
    borderBottom: "1px solid #f2f2f2",
  },
  teamMemberBoxLeft: {
    width: "70%",
  },
  teamMemberBoxRight: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

class ViewEmployerTeamContainer extends React.Component {
  state = {
    errors: [],

    employerName: "",
    brokerEmail: "",
    brokerFirstName: "",
    brokerLastName: "",

    brokerAssignments: [],
    showPermissions: false,
    loaded: true,
    showConfirmRelationshipRemovalPrompt: false,
  };

  componentDidMount() {
    this.handleGetEmployerTeam(this.getPortalID());
  }

  handleGetEmployerTeam = (employerPortalID) => {
    //here the serializer will give back broker permissions for all their employer
    //so lets filter and edit that object to just give us pertient info
    this.getEmployerTeam(employerPortalID).then((r) => {
      let brokerAssignments = r.data.brokers;
      brokerAssignments = brokerAssignments
        .map((b) => {
          return {
            email: b.broker_info.email,
            first_name: b.broker_info.first_name,
            last_name: b.broker_info.last_name,
            broker_id: b.broker_info.id,
            employer_id: b.permission_info.id,
            permission_level: b.permission_info.permission_level,
            max_permission_level: b.permission_info.max_permission_level,
            has_relationship: b.has_relationship,
            self_edit: b.broker_info.self_edit,
          };
        })
        .sort((a, b) => a.broker_id.localeCompare(b.broker_id));

      this.setState({ employerName: r.data.employer_name });
      this.setState({ loaded: true, brokerAssignments: brokerAssignments });
    });
  };

  getEmployerTeam = (employerPortalID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerTeamEmployer(employerPortalID));
    });
  };

  confirmSubmitBrokerAssignPermissions = () => {
    //If a self edit relationship is being removed we need to show confirmation
    //if not just go ahead and hit endpoint
    let removingSelfAssignment = false;
    this.state.brokerAssignments.forEach((assignment) => {
      if (assignment.self_edit && assignment.permission_level === -1)
        removingSelfAssignment = true;
    });

    if (removingSelfAssignment)
      this.setState({ showConfirmRelationshipRemovalPrompt: true });
    else this.handleSubmitBrokerAssignPermissions();
  };

  handleSubmitBrokerAssignPermissions = () => {
    let assignmentJobs = [];
    this.state.brokerAssignments.forEach((assignment) => {
      let employers = [];
      employers.push({
        id: assignment.employer_id,
        existing_relationship: assignment.has_relationship,
        permission_level: assignment.permission_level,
      });
      assignmentJobs.push(
        this.assignBrokerPermissions(assignment.broker_id, employers)
      );
    });
    Promise.all(assignmentJobs).then((values) => {
      this.showMessage("Permissions Assigned", "success");
      this.props.history.push("/broker/employers");
    });
  };

  assignBrokerPermissions = (brokerID, employers) => {
    let body = {
      broker_id: brokerID,
      employers: employers,
    };
    return new Promise((resolve, reject) => {
      resolve(api.assignBrokerPermissions(JSON.stringify(body)));
    });
  };

  handleCancel = () => {
    this.props.history.push("/broker/employers");
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let portalID = params.get("portalID") ? params.get("portalID") : null;
    return portalID;
  };

  changePermissionLevel = (assignment, permissionLevel) => {
    let tempBrokerAssignments = this.state.brokerAssignments;
    //remove current selection for assignment in state
    tempBrokerAssignments = tempBrokerAssignments.filter(
      (a) => a.broker_id !== assignment.broker_id
    );

    //add with selected permission level
    assignment.permission_level = parseInt(permissionLevel);
    tempBrokerAssignments.push(assignment);

    //resort list so looks like nothing change
    tempBrokerAssignments.sort((a, b) =>
      a.broker_id.localeCompare(b.broker_id)
    );

    //resign employer to state
    this.setState({ brokerAssignments: tempBrokerAssignments });
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };

  render() {
    const { brokerAssignments, loaded } = this.state;
    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.showConfirmRelationshipRemovalPrompt}
          isNo={() => {
            this.setState({ showConfirmRelationshipRemovalPrompt: false });
          }}
          isYes={this.handleSubmitBrokerAssignPermissions}
          AlertTitle={"Remove Employer?"}
          AlertText={
            "By selecting “None” as a permission type, you will no longer have access to manage that employer through Mewa.io. This cannot be undone."
          }
        />
        <Typography variant="h4">
          Edit {this.state.employerName} Permissions
        </Typography>
        <div style={{ height: 20 }} />
        <Typography variant="body2" color="inherit">
          You may adjust broker permissions for this employer. Note, you cannot
          grant a higher permission level than you've been granted by the
          employer.
        </Typography>
        <div style={{ height: 20 }} />
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Team Permissions
        </Typography>
        <Typography variant="caption" style={{ marginTop: 20 }}>
          Permissions for each team member
        </Typography>

        {loaded &&
          brokerAssignments.map((assignment, index) => {
            return (
              <Grid container spacing={2} style={{ marginTop: 30 }}>
                <Grid container item xs={12} sm={3} alignItems="center">
                  <Typography variant="body1">
                    {assignment.first_name} {assignment.last_name}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={assignment.permission_level}
                  >
                    <React.Fragment>
                      <FormControlLabel
                        value={-1}
                        id={"radio-group--1" + assignment.broker_id}
                        control={<Radio color="primary" />}
                        onClick={(e) => {
                          this.changePermissionLevel(
                            assignment,
                            e.target.value
                          );
                        }}
                        label="None"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value={0}
                        id={"radio-group-0" + assignment.broker_id}
                        control={<Radio color="primary" />}
                        onClick={(e) => {
                          this.changePermissionLevel(
                            assignment,
                            e.target.value
                          );
                        }}
                        label="Notify"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value={1}
                        id={"radio-group-1" + assignment.broker_id}
                        control={<Radio color="primary" />}
                        onClick={(e) => {
                          this.changePermissionLevel(
                            assignment,
                            e.target.value
                          );
                        }}
                        disabled={assignment.max_permission_level < 1}
                        label="View"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value={2}
                        id={"radio-group-2" + assignment.broker_id}
                        control={<Radio color="primary" />}
                        onClick={(e) => {
                          this.changePermissionLevel(
                            assignment,
                            e.target.value
                          );
                        }}
                        disabled={assignment.max_permission_level < 2}
                        label="Update"
                        labelPlacement="top"
                      />
                    </React.Fragment>
                  </RadioGroup>
                </Grid>
              </Grid>
            );
          })}

        {loaded && (
          <React.Fragment>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleCancel}
              style={{ width: "150px", marginRight: "20px", marginTop: "50px" }}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              variant="contained"
              id="submit-broker-manage-employer-permissions"
              //onClick={this.handleSubmitBrokerAssignPermissions}
              onClick={this.confirmSubmitBrokerAssignPermissions}
              style={{ width: "150px", marginTop: "50px" }}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ViewEmployerTeamContainer);
