import React from "react";

import Typography from "@material-ui/core/Typography";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Auth } from "aws-amplify";
import api from "../../Functions/APIHelpers";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
});

class ArcFormParticipationRateWarningDialog extends React.Component {
  render() {
    const { fullScreen, classes } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          maxWidth={"lg"}
          scroll="paper"
        >
          <DialogTitle id="responsive-dialog-title">
            <React.Fragment>
              Participation rate cannot exceed 100%
            </React.Fragment>
          </DialogTitle>
          <DialogContent style={{ height: 100, width: 400 }}>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Typography variant="caption" color="inherit">
                Please ensure total eligible employees, enrolled employees,
                employees with qualified waivers and continuants are correct.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button onClick={this.props.Close} color="primary">
                Close and Adjust
              </Button>
              <Button
                onClick={this.props.Continue}
                variant="contained"
                color="primary"
              >
                Continue Anyways
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(ArcFormParticipationRateWarningDialog)
);
