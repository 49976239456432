import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Agreement from "../../Shared/Components/Agreement/Agreement";
import Typography from "@material-ui/core/Typography";

import CompleteIcon from "@material-ui/icons/AssignmentTurnedIn";

import NewTable from "../../Shared/Components/Table/NewTable";
import {
  dateCellDataGetterDate,
  employerStatusDataGetter,
  getURLFromFilters,
  employerIgnoreIconDataGetter,
} from "../../Shared/Components/Table/TableUtilities";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";

const initialState = {
  //FOR TABLE
  tableData: [],
  dataURL: "termination-portal",
  sortBy: "",
  sortDirection: "ASC",
  activeFilters: [],
  loaded: false,
  closeMenu: null,
};

class TerminationTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleGetData();
  }

  handleGetData = () => {
    this.setState({ loaded: false }, () => {
      this.getTableData().then((r) => {
        this.setState({ tableData: r, loaded: true });
      }).catch(err => {
        if (err === 403) {
          //This would be a LOGGED IN USER with a portal ID that they AREN'T authorized to use
          this.props.history.push("/employer/auth?error=2");
        }
      });
    });
  };

  getTableData = () => {
    return new Promise((resolve, reject) => {
      if(this.props.employerIsSecured){
        resolve(
          api.getTableDataPortalSecured(
            getURLFromFilters(
              this.state.activeFilters,
              this.state.dataURL + "?portalID="+this.props.portalID,
              this.state.sortBy,
              this.state.sortDirection,
            )
          )
        )
      }
      else{
        resolve(
          api.getTableDataPortal(
            getURLFromFilters(
              this.state.activeFilters,
              this.state.dataURL + "?portalID="+this.props.portalID,
              this.state.sortBy,
              this.state.sortDirection,
            )
          )
        )
      }
    });
  };


  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleGetData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    if(col.nestedCol)
      sortBy=col.nestedCol
    this.setState({sortBy: sortBy, sortDirection: sortDirection }, () => {
      this.handleGetData();
    })
  };

  handleCompleteRecord = (selectedItem, closeMenu) => {
    window.location.href =
      config.appLocation.URL +
      "/employer/termination-record?id=" +
      selectedItem.id +
      "&portalID=" +
      this.props.portalID;
    closeMenu();
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;

    return (
      <div>
        <MenuItem
          name="completeRecordMenuItem"
          onClick={() => {
            this.handleCompleteRecord(selectedItem, closeMenu);
          }}
        >
          <CompleteIcon className={classes.leftIcon} />
          Complete Termination
        </MenuItem>        
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Agreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getEmployerInfo();
          }}
          portalID={this.props.portalID}
        />
        <div className={classes.containerRoot}>
          <div className={classes.containerContent}>
          <Typography variant="h4">Termination Records</Typography>
            <div style={{ height: 0 }} />
            <Paper
              className={classes.containerPaper}
              style={{ overflow: "hidden" }}
            >
              
              <NewTable
                tableName={"Termination Records"}
                enqueueSnackbar={this.props.enqueueSnackbar}
                loadedData={this.state.tableData}
                loadMoreRows={()=>{}}
                loaded={this.state.loaded}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                recordCount={0}
                setActiveFilters={this.setActiveFilters}
                activeFilters={this.state.activeFilters}
                columns={[
                  {
                    dataKey: "employee_first",
                    label: "Employee First",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                  },
                  {
                    dataKey: "employee_last",
                    label: "Employee Last",
                    hasFilter: false,
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                  },
                  {
                    dataKey: "date_added",
                    label: "Date Added",
                    cellRenderer: dateCellDataGetterDate,
                    width: 125,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                  },
                  {
                    dataKey: "date_verified",
                    label: "Date Verified",
                    cellRenderer: dateCellDataGetterDate,
                    width: 130,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                  },
                  {
                    label: "Status",
                    dataKey: "status",
                    cellRenderer: employerStatusDataGetter,
                    width: 125,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(TerminationTableContainer);
export { TerminationTableContainer as PureTerminationTableContainer };
