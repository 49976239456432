import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import ArcForm from "../../Shared/Components/Arc/ArcForm";
import Agreement from "../../Shared/Components/Agreement/Agreement";
import RequestHelpDialog from "./RequestHelpDialog";
import SubmitArcRequestDialog from "./SubmitArcRequestDialog";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { DropzoneDialog } from "material-ui-dropzone";
import { calculateAutoFillFields } from "../../Shared/Components/Arc/ArcHelpers";
import VerifiedArcDialog from "./VerifiedArcDialog";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  arcRequest: {},
  errors: [],
  loaded: false,
  arcRequestSaving: false,
  requestFieldFocus: "",

  needSupportingDocumentation: false,
  allowSupportingDocumentation: false,
  uploadedFiles: [],

  requestHelpDialogOpen: false,
  requestHelpEmail: "",
  requestHelpFirst: "",
  requestHelpLast: "",
  requestAllowedStatus: "",

  submitRequestDialogOpen: false,
  verifiedDialog: false,

  validArcRequestID: true,
  validContactID: true,
};

class ArcContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    //get portal ID
    let portalID = this.getPortalID();
    this.setState({ portalID: portalID }, () => {
      this.getArcRequestAllowed()
        .then((r) => {
          this.setState({ requestAllowedStatus: r.status }, () => {
            if (r.status === "arc_verified") {
              this.setState({ verifiedDialog: true });
            }
            this.handleGetArcRequest();
          });
        })
        .catch((err) => {
          this.props.history.push("/employer/auth?error=5");
        });
    });
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    //Check to make sure that hex value is provided to DocumentList
    let portalID = params.get("portalID") ? params.get("portalID") : null;
    if (!portalID) return portalID;
    return portalID.replace(new RegExp("-", "g"), "");
  };

  inError = () => {
    let errors = [];
    const body = this.state.arcRequest;
    if (this.state.arcRequest.has_employees) {
      //if exempt entity don't need to check for anything but forms and the total employees section which consists of:
      //total_eligible_employees
      //total_current_employees
      //part_time_employees
      //full_time_employees
      //net_enrolled_employees
      //first filter out unrequired fields or auto calculated fields
      Object.entries(body)
        .filter((f) => f[0] !== "id")
        .filter((f) => f[0] !== "broker_assistance_requested")
        .filter((f) => f[0] !== "signature_date")
        .filter((f) => f[0] !== "sig_form_name")
        .filter((f) => f[0] !== "EIN")
        .filter((f) => f[0] !== "suite")
        .filter((f) => f[0] !== "title")
        .filter((f) => f[0] !== "entity_type")
        .filter((f) => f[0] !== "total_employees_wage_and_tax")
        .filter((f) => f[0] !== "part_time_employees")
        .filter((f) => f[0] !== "full_time_employees")
        .filter((f) => f[0] !== "medicare_status")
        .filter((f) => f[0] !== "chamber_name")
        .filter((f) => f[0] !== "chamber_start_date")
        .filter((f) => f[0] !== "chamber_end_date")
        .filter((f) => f[0] !== "original_continuation_status")
        /*
      .filter((f) => f[0] !== "full_time_employees")
      .filter((f) => f[0] !== "net_eligible_employees")
      .filter((f) => f[0] !== "percentage_participating_plan")
      .filter((f) => f[0] !== "continuation_status")
      .filter((f) => f[0] !== "medicare_status")
      .filter((f) => f[0] !== "employer_contribution_percentage")
      */
        .map((field) => {
          if (field[1] === "") errors.push(field[0]);
          if (field[1] === null) errors.push(field[0]);
          if (field[1] < 0) errors.push(field[0]);
        });
      if (Number.isNaN(body.employer_contribution_percentage)) {
        errors.push("employer_contribution_percentage");
      }
    }

    //even if you have employees we still want to ensure employer address is completed
    if (
      this.state.arcRequest.street === null ||
      this.state.arcRequest.street === ""
    ) {
      errors.push("street");
    }
    if (
      this.state.arcRequest.city === null ||
      this.state.arcRequest.city === ""
    ) {
      errors.push("city");
    }
    if (
      this.state.arcRequest.state === null ||
      this.state.arcRequest.state === ""
    ) {
      errors.push("state");
    }
    if (
      this.state.arcRequest.zip === null ||
      this.state.arcRequest.zip === ""
    ) {
      errors.push("zip");
    }

    //if this is MCF we need to ensure that chamber_name is filled out
    if (this.state.arcRequest.arc_mewa === "9ac40fe874e84df5b81288daf45de042") {
      if (
        this.state.arcRequest.chamber_name === "" ||
        this.state.arcRequest.chamber_name === null
      ) {
        errors.push("chamber_name");
      }
    }

    //if this is MCF we need to ensure that chamber_start_date
    if (this.state.arcRequest.arc_mewa === "9ac40fe874e84df5b81288daf45de042") {
      if (
        this.state.arcRequest.chamber_start_date === "" ||
        this.state.arcRequest.chamber_start_date === null
      ) {
        errors.push("chamber_start_date");
      }
    }
    //if this is MCF we need to ensure that chamber_end_date
    if (this.state.arcRequest.arc_mewa === "9ac40fe874e84df5b81288daf45de042") {
      if (
        this.state.arcRequest.chamber_end_date === "" ||
        this.state.arcRequest.chamber_end_date === null
      ) {
        errors.push("chamber_end_date");
      }
    }

    //ensure that entity type is not NULL which means it was never set by whoever was filling out the form
    if (this.state.arcRequest.entity_type === null) {
      errors.push("entity_type");
    }
    //only check signature_date (for ARC Document) if required
    if (
      this.state.arcRequest.signature_date == null &&
      this.state.arcRequest.require_arc_sig_form
    ) {
      errors.push("signature_date");
    }
    //only check docs if NEED supporting docs
    if (
      this.state.uploadedFiles.length === 0 &&
      this.state.needSupportingDocumentation
    ) {
      errors.push("uploadedFiles");
    } else {
      errors = errors.filter((e) => e !== "uploadedFiles");
    }
    this.setState({ errors: errors });
    return errors.length > 0;
  };

  addUploadedFiles = (files) => {
    let currentFiles = this.state.uploadedFiles;

    //For each file name uploaded, create a friendly name
    //field on the FILE object in memory that will drop any bad characters
    let regex = /[/:*?"<>|]/g;
    files.forEach((f) => {
      f.friendly_name = f.name.replace(regex, "");
      currentFiles.push(f);
    });
    this.setState({ uploadedFiles: currentFiles, supportingDialogOpen: false });
    if (this.state.errors.length > 0) this.inError();
  };

  removeFile = (file) => {
    let currentFiles = this.state.uploadedFiles;
    currentFiles = currentFiles.filter((f) => f.name !== file.name);
    this.setState({ uploadedFiles: currentFiles });
  };

  handleGetPresignedURLs = () => {
    var body = JSON.stringify({
      requested_presigned_urls: this.state.uploadedFiles.map(
        (f) => f.friendly_name
      ),
      arc_request: this.state.arcRequest.id ? this.state.arcRequest.id : "",
    });

    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.getSupportingDocumentationARCPresignedSecured(
            this.props.portalID,
            body
          )
        );
      else
        resolve(
          api.getSupportingDocumentationARCPresigned(this.props.portalID, body)
        );
    });
  };

  uploadFiles = (s3Files) => {
    return new Promise((resolve, reject) => {
      let uploadJobs = [];
      //For each presigned url, we need to find the file in state and upload it
      s3Files.forEach((f) => {
        uploadJobs.push(
          new Promise((resolve, reject) => {
            var s3Fields = f.fields;
            const formData = new FormData();

            Object.keys(s3Fields).forEach((key) => {
              formData.append(key, s3Fields[key]);
            });
            formData.append(
              "file",
              this.state.uploadedFiles.find(
                (file) => file.friendly_name === f["frontend_name"]
              )
            );
            fetch(f.url, {
              method: "POST",
              body: formData,
            })
              .then((r) => {
                resolve(r);
              })
              .catch((e) => {
                reject(e);
              });
          })
        );
      });

      Promise.all(uploadJobs)
        .then((values) => {
          resolve("done");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  handleRequestHelp = () => {
    this.requestHelp().then((r) => {
      this.props.enqueueSnackbar(
        this.state.requestHelpEmail +
          " will be notified that you have requested help completing this form.",
        {
          variant: "success",
        }
      );
      this.setState({
        requestHelpDialogOpen: false,
        requestHelpEmail: "",
        requestHelpFirst: "",
        requestHelpLast: "",
      });
    });
  };

  handleUpdateArcRequest = (requestHelp = false) => {
    //if (this.inError()) return;
    this.setState({ arcRequestSaving: true });
    this.handleGetPresignedURLs().then((r) => {
      this.uploadFiles(r.urls)
        .then((r) => {
          this.updateArcRequest(false).then((r) => {
            this.setState({
              submitRequestDialogOpen: !requestHelp,
              arcRequestSaving: false,
              errors: [],
              arcRequest: calculateAutoFillFields(this.state.arcRequest),
            });
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar(
            "There was an issue uploading supporting documentation. Please try again.",
            {
              variant: "error",
            }
          );
          this.setState({
            arcRequestSaving: false,
            errors: [],
            arcRequest: calculateAutoFillFields(this.state.arcRequest),
          });
        });
    });
  };

  handleSubmitArcRequest = () => {
    return new Promise((resolve, reject) => {
      if (this.inError()) reject("inError");
      else {
        this.updateArcRequest(true)
          .then((r) => {
            resolve("done");
          })
          .catch((err) => {
            this.props.enqueueSnackbar(
              "There was an issue submitting ARC Form",
              {
                variant: "error",
              }
            );
          });
      }
    });
  };

  handleGetArcRequest = () => {
    this.getArcRequest()
      .then((r) => {
        calculateAutoFillFields(r);
        this.setState({
          arcRequest: r,
          uploadedFiles: r.saved_supporting_documentation.map((f) => {
            return {
              name: f.name,
              friendly_name: f.name,
            };
          }),
          allowSupportingDocumentation: r.arc_supporting_documentation,
          needSupportingDocumentation: r.require_arc_supporting_documentation,
          loaded: true,
        });
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/employer/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "There was an issue generating document list",
            {
              variant: "error",
            }
          );
        }
        if (err === 404) {
          this.setState({ loaded: true });
        }
      });
  };

  getArcRequestAllowed = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getArcRequestAllowedSecured(this.state.portalID));
      else resolve(api.getArcRequestAllowed(this.state.portalID));
    });
  };

  getArcRequest = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getArcRequestSecured(this.state.portalID));
      else resolve(api.getArcRequest(this.state.portalID));
    });
  };

  updateArcRequest = (isComplete) => {
    let body = this.state.arcRequest;
    body.is_complete = false;

    //ensure perecentage participating is 2 decimals only
    if (body.percentage_participating_plan) {
      body.percentage_participating_plan =
        body.percentage_participating_plan.toFixed(2);
    }
    //if medicare status is null, use the default value which is Medicare Secondary
    if (!body.medicare_status) {
      body.medicare_status = "Medicare Secondary";
    }

    //filter blank fields from objects (strings and nulls) becaue this isnt a submission
    Object.entries(body).map((field) => {
      if (field[1] === "") {
        body[field[0]] = null;
      }
      if (field[1] < 0) {
        body[field[0]] = null;
      }
    });
    //only add supportinDocumentation key to body if there were documents uploaded
    if (this.state.uploadedFiles.length && !isComplete) {
      body.supporting_documentation = this.state.uploadedFiles.map(
        (f) => f.friendly_name
      );
    } else {
      delete body.supporting_documentation;
    }

    if (isComplete) {
      body.is_complete = true;
    }

    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.updateArcRequestSecured(this.state.portalID, JSON.stringify(body))
        );
      else
        resolve(
          api.updateArcRequest(this.state.portalID, JSON.stringify(body))
        );
    });
  };

  requestHelp = () => {
    let body = {
      first_name: this.state.requestHelpFirst,
      last_name: this.state.requestHelpLast,
      email: this.state.requestHelpEmail,
    };

    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.requestArcFormHelpSecured(
            this.state.portalID,
            JSON.stringify(body)
          )
        );
      else
        resolve(
          api.requestArcFormHelp(this.state.portalID, JSON.stringify(body))
        );
    });
  };

  updateRequestField = (field, value) => {
    let request = this.state.arcRequest;
    request[field] = value;
    //request = this.calculateAutoFillFields(request);
    this.setState({ arcRequest: request });
  };

  updateDocumentField = (field, value, callback) => {
    let request = this.state.arcRequest;
    request[field] = value;

    this.setState({ arcRequest: request }, () => {
      if (callback) {
        callback();
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div style={{ width: "100%" }}>
          <VerifiedArcDialog
            Open={this.state.verifiedDialog}
            PortalID={this.props.portalID}
            Close={() => {
              this.setState({ verifiedDialog: false });
            }}
            ArcRequest={this.state.arcRequest}
            employerIsSecured={this.props.employerIsSecured}
          />
          <Agreement
            Open={this.props.needTOS}
            Close={() => {
              this.props.getEmployerInfo();
            }}
            portalID={this.props.portalID}
          />
          {this.state.loaded &&
            (this.state.requestAllowedStatus === "allowed" ||
              this.state.requestAllowedStatus === "broker_complete" ||
              this.state.requestAllowedStatus === "broker_assist" ||
              this.state.requestAllowedStatus === "arc_verified") && (
              <React.Fragment>
                <SubmitArcRequestDialog
                  Open={this.state.submitRequestDialogOpen}
                  errors={this.state.errors}
                  Close={() => {
                    this.setState({
                      submitRequestDialogOpen: false,
                    });
                  }}
                  Submit={this.handleSubmitArcRequest}
                  PortalID={this.props.portalID}
                />
                <RequestHelpDialog
                  Open={this.state.requestHelpDialogOpen}
                  Close={() => {
                    this.setState({
                      requestHelpDialogOpen: false,
                      requestHelpEmail: "",
                      requestHelpFirst: "",
                      requestHelpLast: "",
                    });
                  }}
                  Save={this.handleRequestHelp}
                  SetHelpField={(field, value) => {
                    this.setState({ [field]: value });
                  }}
                  RequestHelpEmail={this.state.requestHelpEmail}
                  RequestHelpFirst={this.state.requestHelpFirst}
                  RequestHelpLast={this.state.requestHelpLast}
                />
                <div
                  className={classes.title}
                  style={{ color: this.props.theme.header_text_color }}
                >
                  <Typography variant="h4" id="arcRequestTitle">
                    ARC Form
                  </Typography>
                  <Typography variant="body1" id="arcRequestTitle">
                    Annual Rate and Census Reconcilliation (ARC) Form
                  </Typography>
                </div>

                <div style={{ marginTop: 10, marginBottom: 0 }}>
                  <Typography variant="caption">
                    The ARC Form assists the plan administrator in completing
                    the MEWA's annual Form 5500 which is required by ERISA.
                  </Typography>
                </div>

                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Typography variant="h6">Employer</Typography>
                  <Typography variant="body1" id="arcRequestTitle">
                    {this.props.employerName}
                  </Typography>
                </div>

                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ requestHelpDialogOpen: true }, () => {
                      this.handleUpdateArcRequest(true);
                    });
                  }}
                  className={"verify-term"}
                  style={{ marginTop: 30, marginBottom: 30 }}
                  startIcon={<ContactSupportIcon />}
                  variant="contained"
                  size="small"
                >
                  Request Help
                </Button>
                <div style={{ height: 30 }} />

                <ArcForm
                  arcRequest={this.state.arcRequest}
                  calculateAutoFillFields={() => {
                    this.setState(
                      {
                        requestFieldFocus: "",
                        request: calculateAutoFillFields(this.state.arcRequest),
                      },
                      () => {
                        if (this.state.errors.length > 0) this.inError();
                      }
                    );
                  }}
                  updateRequestFieldFocus={(field) => {
                    this.setState({ requestFieldFocus: field });
                  }}
                  hideDocuments={!this.state.allowSupportingDocumentation}
                  requestFieldFocus={this.state.requestFieldFocus}
                  updateRequestField={this.updateRequestField}
                  updateDocumentField={this.updateDocumentField}
                  addUploadedFiles={this.addUploadedFiles}
                  removeFile={this.removeFile}
                  uploadedFiles={this.state.uploadedFiles}
                  save={() => {
                    this.handleUpdateArcRequest(false);
                  }}
                  arcRequestSaving={this.state.arcRequestSaving}
                  errors={this.state.errors}
                  theme={this.props.theme}
                />
              </React.Fragment>
            )}
          {(this.state.requestAllowedStatus === "no_arc" ||
            this.state.requestAllowedStatus === "arc_closed") && (
            <React.Fragment>
              <div style={{ textAlign: "center", marginTop: 50 }}>
                <Typography variant="h6">
                  This ARC Form has been closed.
                </Typography>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ArcContainer);
