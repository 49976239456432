export function mapErrorCodeToMessage(errorCode){
    switch(errorCode) {
        case "1":
            return "Invalid or No Portal ID Supplied"
        case "2":
            return "Not Authorized to View This Portal"
        case "3":
            return "There was a problem generating Terminations"
        case "4":
            return "Please Login to View Secured Portal"
        case "5": 
            return "Not Authorized to View, Please Login"
        case "6":
            return "This report contains secure data. Please login to access."
        case "7":
            return "No Confirmation ID Supplied"
        case "8":
            return "Invalid Create Broker Link"
        case "9":
            return "Broker account already created, please reset password"
        case "10":
            return "Please login with broker account to view invites"
        case "11":
            return "Please login with broker account to resend request"
        default:
            return "Error Occurred Please Login Again"
      }

}
