import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../Styles/NavStyles";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

class NavDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
    this.props.closeNavDrawer();
  };

  render() {
    const { navList: Component } = this.props;
    const { classes } = this.props;

    return (
      <div style={{ width: "100vw" }}>
        <SwipeableDrawer
          className={classes.navDrawer}
          open={this.props.navDrawerOpen}
          onClose={this.toggleDrawer("left", false)}
          onOpen={this.toggleDrawer("left", true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("left", false)}
            onKeyDown={this.toggleDrawer("left", false)}
          >
            <Component theme={this.props.theme} history={this.props.history} navOptions={this.props.navOptions}/>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

NavDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavDrawer);
