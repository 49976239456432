import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from '@material-ui/core/Input';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import PhoneNumberTextBox, {mapPhoneToMask, outputPhoneFromMask} from "../Input/PhoneNumberTextBox";

class ResponsiveDialog extends React.Component {
  state = {
    open: false,
    hasChanged: false,
    newValue: "",
    textMask: "(   )    -    "
  };

  handleClickOpen = () => {
    //if this is a phone number dialog, we need to map the +1440 value from aws 
    //into a value that conforms to the text mask we want to display
    if(this.props.PhoneNumber){
      if(this.props.defaultValue){
        var defaultPhone = this.props.defaultValue.substring(2)
        this.setState({ open: true, textMask: (mapPhoneToMask(defaultPhone).conformedValue) });
      }
      else{
        this.setState({ open: true});
      }
    }
    else
      this.setState({open:true})
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleSave = () => {
    //If this is a phone  number dialog, return the phone number formatted to cognito standards (+440)
    if (this.state.hasChanged && this.props.PhoneNumber) {
      var outputVal = outputPhoneFromMask(this.state.textMask)
      this.props.returnValue(outputVal.conformedValue);
    }
    else{
      this.props.returnValue(this.state.newValue)
    }
    this.setState({ open: false });

  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div style={{ float: "left" }}>
        <Button onClick={this.handleClickOpen} color="primary" size="small">
          {this.props.ButtonTitle}
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          width="400"
        >
          <DialogTitle id="responsive-dialog-title">
            {this.props.Title}
          </DialogTitle>
          <DialogContent>
            {(this.props.PhoneNumber && (
              <FormControl>
                <InputLabel htmlFor="formatted-text-mask-input">
                  {this.props.label}
                </InputLabel>
                <Input
                  value={this.state.textMask}
                  //defaultValue={defaultPhone}
                  onChange={(e) => this.setState({ textMask: e.target.value, hasChanged: true })}
                  name="textmask"
                  id="formatted-text-mask-input"
                  inputComponent={PhoneNumberTextBox}
                  style={{width:300}}
                  autoComplete='off'
                />
              </FormControl>
            )) || (
              <TextField
                id="standard-dense"
                label={this.props.label}
                margin="dense"
                onChange={(e) =>
                  this.setState({ newValue: e.target.value, hasChanged: true })
                }
                defaultValue={this.props.defaultValue}
                style={{width:300}}
                autoComplete='off'
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(ResponsiveDialog);
