import React from "react";

import classNames from "classnames";
import { withSnackbar } from "notistack";
import { styles } from "../../Shared/Styles/ContainerStyles";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import AppBar from "../Nav/AppBar";
import NavDrawer from "../Nav/NavDrawer";


class BaseContainer extends React.Component {
  state = {
    NavOpen: false
  };

  componentDidUpdate(prevProps) { }

  toggleNavDrawer = () => {
    this.setState({ NavOpen: !this.state.NavOpen });
  };


  scrollToBottom = () => {
    var element = this.refs.bottomRef;
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };


  render() {
    const { component: Component, classes, ...rest } = this.props;
    return (
      <div
        className={classNames(classes.basecontent, {
          [classes.basecontentFixed]: this.props.fixed,
          [classes.basecontentFixedNarrow]: this.props.fixedNarrow
        })}
        style={{backgroundColor:this.props.theme.page_background_color}}
      >
        <header>  
          <AppBar
            hideSignout={this.props.hideSignout}
            homeURLContainsParams={this.props.homeURLContainsParams}
            history={this.props.history}
            logoutURL={this.props.logoutURL}
            openNavDrawer={this.toggleNavDrawer}
            enqueueSnackbar={this.props.enqueueSnackbar}
            home={this.props.home}
            hasAccount={this.props.hasAccount}
            app={this.props.app}
            theme={this.props.theme}
          />
          <NavDrawer
            history={this.props.history}
            navDrawerOpen={this.state.NavOpen}
            closeNavDrawer={this.toggleNavDrawer}
            navList={this.props.navList}
            theme={this.props.theme}
            navOptions={this.props.navOptions}
          />
        </header>
        <Paper
          className={classNames(classes.baseroot, {
            [classes.baserootFixed]: this.props.fixed,
            [classes.baserootFixedNarrow]: this.props.fixedNarrow,
          })}
          elevation={1}
          square={true}
        >
          {!this.props.isLoaded && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 300
              }}
            >
              <div>
                <CircularProgress className={classes.progress} />
              </div>
            </div>
          )}
          {this.props.isLoaded && <Component {...rest} scrollToBottom={this.scrollToBottom} />}
          <div
          id="bottomRef"
          ref="bottomRef"
          style={{
            height: 0,
            marginTop: 0,
            backgroundColor: "red",
            display: "block",
          }}
        />
        </Paper>
        
      </div>
    );
  }
}


export default withSnackbar(withStyles(styles)(BaseContainer));
