import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";
import TextField from "@material-ui/core/TextField";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import PasswordInput from "../../Shared/Components/Accounts/PasswordInput";
import { styles } from "../../Shared/Styles/ModalStyles";

class EmployerPasswordDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      characterLength: true,
      specialChar: false,
      number: false,
    };
  }

  render() {
    const { Open } = this.props;
    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            New Employer Password
          </DialogTitle>

          <DialogContent style={{ height: 300, width: 400 }}>
            <PasswordInput
              Password={this.props.Password}
              SetPassword={this.props.SetPassword}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.Close} color="primary">
              Close
            </Button>
            <Button
              name="savePasswordButton"
              onClick={this.props.Save}
              color="primary"
              disabled={
                !this.props.Password.match(
                  /^(?=.*[!@#$%^&*])(?=.{8,})(?=.*[0-9])/
                )
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EmployerPasswordDialog));
