import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import TextField from "@material-ui/core/TextField";
import api from "../../Shared/Functions/APIHelpers";

class BrokerChangeContactDialog extends React.Component {
  state = {
    email: "",  
    newContactFirstName: "",
    newContactLastName: "",
    newContactEmail: "",

  };
  componentDidMount() {}

  close = () => {
    this.setState({ email: "" }, () => {
      this.props.RefreshTable();
      this.props.Close();
    });
  };

  handleChangeEmployerContact = () => {
    if (
      !this.state.newContactFirstName ||
      !this.state.newContactLastName ||
      !this.state.newContactEmail
    ) {
      this.showMessage("Please Complete Required Fields", "warning");
      return;
    } else {
      this.changeEmployerContqct().then((r) => {
        this.changeContactResponse(r.message);
      });
    }
  };

  changeEmployerContqct = () => {
    let body = {
      new_ein: this.props.EIN,
      new_contact_first: this.state.newContactFirstName,
      new_contact_last: this.state.newContactLastName,
      new_contact_email: this.state.newContactEmail,
    };
    return new Promise((resolve, reject) => {
      resolve(api.addBrokerEmployerInviteNewContact(JSON.stringify(body)));
    });
  };

  handleClose = () => {
    this.close();
  };

  changeContactResponse = (message) => {
    if (message === "no_employers_found") {
      this.showMessage("No employers with this email address were found.", "warning");
    }
    if (message === "multiple_employers_found") {
      this.showMessage("Multiple employers with this email address were found. A change request cannot be sent.", "warning");
    }

    if (message === "change_request_sent") {
      this.showMessage(
        "A contact change request has been sent to the employer.",
        "success"
      );
      this.handleClose();
    }
    if (message === "contact_change_no_invitation") {
      this.showMessage(
        "A contact change request has been sent to the new employer contact.",
        "success"
      );
      this.handleClose();
    }
    if (message === "contact_change_invitation") {
      this.showMessage(
        "A contact change request and invitation has been sent to the new employer contact.",
        "success"
      );
      this.handleClose(); 
      }
         
    if (message === "contact_change_exists") {
      this.showMessage("Change request already exists", "warning");
      this.handleClose();
    }
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };

  render() {
    const { fullScreen } = this.props;
    
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Change Employer Contact for {this.props.Employer.name}
          </DialogTitle>

          <DialogContent style={{ height: 600 }}>
            <div>
              <Typography variant="body1" color="inherit">
                {/*Enter the new employer contact information below. They will be
                made the new contact for this employer immediately and be sent
                instructions for changing the contact with the carrier. If the
                contact is not changed with the carrier within 30 days, the
                original contact may be set back and the new contact will lose
                access. Please note the existing employer contact will be
                notified of the change and have the ability to override this
                request.*/}
                Enter the new employer contact's information below. They will be
                made the new contact for this employer immediately.
              </Typography>
              <div style={{marginTop: 20, marginBottom: 30, padding: 10, backgroundColor: "#f3f3f3", border: "1px solid #e3e3e3"}}>
                <Typography variant="body2" >
                Please note the current employer contact will be
                notified of the change and have the ability to override this
                request. After the contact is changed, the old contact will no longer
                receive notifications or have access to Mewa.io. <br/><br/>This will not
                change the contact at the carrier level.
                </Typography>                
              </div>              
              <Typography variant="h6" style={{marginTop: 20}} color="inherit">
                Current Contact
              </Typography>              
              <Typography variant="body1" color="inherit">
                {this.props.Employer.contact_first} {this.props.Employer.contact_last} ({this.props.Employer.contact_email})
              </Typography>
            </div>
            <div style={{marginTop:10}}>
              <TextField
                id="broker-employer-invite-new-contact-first-text-field"
                label="New Contact First Name"
                margin="dense"
                onChange={(e) => {
                  this.setState({ newContactFirstName: e.target.value });
                }}
                value={this.state.newContactFirstName}
                fullWidth
                autoComplete="off"
              />
              <TextField
                id="broker-employer-invite-new-contact-last-text-field"
                label="New Contact Last Name"
                margin="dense"
                onChange={(e) => {
                  this.setState({ newContactLastName: e.target.value });
                }}
                value={this.state.newContactLastName}
                fullWidth
                autoComplete="off"
              />
              <TextField
                id="broker-employer-invite-new-contact-email-text-field"
                label="New Contact Email"
                margin="dense"
                onChange={(e) => {
                  this.setState({ newContactEmail: e.target.value });
                }}
                value={this.state.newContactEmail}
                fullWidth
                autoComplete="off"
              />
            </div>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>            
          <Button onClick={this.handleChangeEmployerContact} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(BrokerChangeContactDialog)
);
