import React from "react";

import Typography from "@material-ui/core/Typography";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
});

class VerifiedArcDialog extends React.Component {

  handleGoDashboard = () => {
    window.location.href =
      config.appLocation.URL + "/employer/home?portalID=" + this.props.PortalID;
  };

  handleUnverifyARCRecord = () => {
    this.unverifyARCRecord().then(r=> {
      this.props.Close()
    })
    
  };

  unverifyARCRecord = () => {
      let body = {
        arc_request: this.props.ArcRequest.id
      };
  
      return new Promise((resolve, reject) => {
        if (this.props.employerIsSecured)
          resolve(
            api.unverifyArcRequestSecured(this.props.PortalID, JSON.stringify(body))
          );
        else
          resolve(
            api.unverifyArcRequest(this.props.PortalID, JSON.stringify(body))
          );
      });

  }
  
  
  render() {
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          maxWidth={"lg"}
          scroll="paper"
        >
          <DialogContent
            style={{ maxHeight: 250, maxWidth: 500, textAlign: "center" }}
          >
            <div className={classes.logoContainer}>
            {!this.props.theme.isCustomBranding && (
              <img
                src={require("../../Shared/Images/mewaio_logo_horiz.png")}
                style={{ height:40}}
                alt="Reform Health Logo"
              />
            )}
            </div>
            <br />
            <Typography variant="body1">
              This Arc Request has already been verified. Are you sure you would like to make changes? You will need to re-submit the Arc Request.
            </Typography>

            <br />
            <br /> <br />

          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleGoDashboard}
              color="primary"
            >
              No
            </Button>          
            <Button
              onClick={this.handleUnverifyARCRecord}
              color="primary"
              id="yes-verify-button"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles, {withTheme: true})(VerifiedArcDialog));
