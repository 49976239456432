import React from "react";
import ErrorScreen from "./ErrorScreen";

class ErrorHOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, errorInfo);
      console.log("ERROR: ")
      console.log(error)
      console.log("ERROR INFO:")
      console.log(errorInfo)
    }

  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (<ErrorScreen />)
      }
  
      return this.props.children; 
    }
  }

  export default ErrorHOC;