import  {  useEffect } from 'react'; 
import config from "../../config"

const RedirectUtility = ({redirectURL}) => {

    useEffect(() => {
        window.location.href = config.appLocation.URL + redirectURL;
      });

    return null
}

export default RedirectUtility;