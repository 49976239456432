import { createTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";

/*
export default createMuiTheme({
  
});
*/

export default function getBrandedThemeOrDefault(themeInfo) {
  if (Object.keys(themeInfo).length === 0) {
    return createTheme({
      palette: {
        primary: {
          light: blue[600],
          //main: blue[800],
          main: "#2996cc",
          dark: blue[1000],
          contrastText: "#fff",
        },
        disabled: grey,
        secondary: {
          light: "#FF9C29",
          main: "#f57921",
          dark: "#FF9C29",
          contrastText: "#fff",
        },
        error: red,
      },
      overrides: {
        MuiTableCell: {
          root: {
            padding: "4px 24px 4px 16px",
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
      header_text_color: "black",
      header_color_1: "#2996cc",
      header_color_2: "#53abd6",
      header_icon_color: "#FFFFFF",
      navlist_logo:
        "https://s3.us-east-2.amazonaws.com/static.mewa.io/logos/mewaio_logo_horiz-200x48.png",
      header_logo:
        "https://s3.us-east-2.amazonaws.com/static.mewa.io/logos/mewaio_horiz_cutout_header.png",
      fav_icon:
        "https://s3.us-east-2.amazonaws.com/static.mewa.io/logos/default-fav-icon.png",
      page_title: "mewa.io - Portal",
      page_background_color: "#fafafa",
      isCustomBranding: false,
    });
  } else {
    console.log(themeInfo);
    return createTheme({
      palette: {
        primary: {
          main: themeInfo.primary_color,
          contrastText: "#fff",
        },
        disabled: grey,
        secondary: {
          light: "#FF9C29",
          main: themeInfo.secondary_color,
          dark: "#FF9C29",
          contrastText: "#fff",
        },
        error: red,
      },
      header_color_1: themeInfo.header_color_1,
      header_color_2: themeInfo.header_color_2,
      header_icon_color: themeInfo.header_icon_color
        ? themeInfo.header_icon_color
        : "#FFFFFF",
        navlist_logo: themeInfo.header_logo
        ? themeInfo.header_logo
        : "https://s3.us-east-2.amazonaws.com/static.mewa.io/logos/mewaio_horiz_cutout_header.png",
      header_logo: themeInfo.header_logo
        ? themeInfo.header_logo
        : "https://s3.us-east-2.amazonaws.com/static.mewa.io/logos/mewaio_horiz_cutout_header.png",
      header_text_color: themeInfo.header_text_color
        ? themeInfo.header_text_color
        : "black",
      page_background_color: themeInfo.page_background_color
        ? themeInfo.page_background_color
        : "#fafafa",
      page_title: themeInfo.page_title
        ? themeInfo.page_title
        : "mewa.io - Admin",
      fav_icon: themeInfo.fav_icon
        ? themeInfo.fav_icon
        : "https://s3.us-east-2.amazonaws.com/static.mewa.io/logos/default-fav-icon.png",
      overrides: {
        MuiTableCell: {
          root: {
            padding: "4px 24px 4px 16px",
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
      isCustomBranding: true,
    });
  }
}

/*
  Needed Branding Options

  Header Bar Color
  Menu and My Account Button Color
  MewaIO Logo Replaced
  Page Background
  Header Text
  Primary and Secondary
  Web Page Title
  and Favicon


*/
