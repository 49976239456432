import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddTeamMemberDialog from "./AddTeamMemberDialog";
import ViewTeamMemberDialog from "./ViewTeamMemberDialog";
import api from "../../Shared/Functions/APIHelpers";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import BrokerAgreement from "../../Shared/Components/Agreement/BrokerAgreement";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  teamMemberBox: {
    display: "flex",
    marginTop: 25,
    "@media only screen and (max-width: 600px)": {
      display: "block",
    },
    paddingBottom: 25,
    borderBottom: "1px solid #f2f2f2",
  },
  teamMemberBoxLeft: {
    width: "70%",
  },
  teamMemberBoxRight: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
  },
});
class ViewTeamContainer extends React.Component {
  state = {
    loaded: true,
    team: [],
    selectedTeamMember: {},
    addTeamMemberDialogOpen: false,
    viewTeamMemberDialogOpen: false,
  };

  componentDidMount() {
    this.getTeam().then((r) => {
      this.setState({ loaded: true, team: r });
    });
  }

  getTeam = () => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerTeam());
    });
  };

  render() {
    const { loaded } = this.state;
    const { classes } = this.props;
    if (!loaded) return null;
    console.log(this.props);
    return (
      <React.Fragment>
        <BrokerAgreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getBrokerTOS();
          }}
        />
        {this.state.viewTeamMemberDialogOpen && (
          <ViewTeamMemberDialog
            Open={this.state.viewTeamMemberDialogOpen}
            Close={() => {
              this.setState({
                viewTeamMemberDialogOpen: false,
                selectedTeamMember: {},
              });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            history={this.props.history}
            TeamMember={this.state.selectedTeamMember}
          />
        )}
        {this.state.addTeamMemberDialogOpen && (
          <AddTeamMemberDialog
            Open={this.state.addTeamMemberDialogOpen}
            Close={() => {
              this.setState({ addTeamMemberDialogOpen: false });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
        )}
        <Typography variant="h4">My Team</Typography>
        <Typography
          variant="body1"
          style={{ marginTop: "15px", marginBottom: "25px" }}
        >
          The following brokers have access to some of your employers in
          Mewa.io. Please note that some of these individuals may have been
          invited by another broker. For instance, you may have invited a team
          member and they gave permission to their team members to help manage
          the account.
        </Typography>

        {!this.props.hasAgency && (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={9}>
              <Card style={{ backgroundColor: "#fafafa" }}>
                <CardContent>
                  <Typography variant="h6">No Assigned Agency</Typography>
                  <br />
                  <Typography variant="body1">
                    You are not currently under an agency so no team members can
                    be displayed.
                  </Typography>
                  <br />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {this.state.team.map((teamMember, index) => {
          return (
            <div key={index}>
              <Grid container spacing={2} style={{ marginTop: 30 }}>
                <Grid item xs={12} sm={9}>
                  <Typography variant="h6">
                    {teamMember.first_name} {teamMember.last_name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {teamMember.email}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  justifycontent="flex-start"
                  alignItems="center"
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      console.log(teamMember);
                      this.setState({
                        viewTeamMemberDialogOpen: true,
                        selectedTeamMember: teamMember,
                      });
                    }}
                    style={{ width: "200px" }}
                  >
                    View Employers
                  </Button>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 30, marginBottom: 30 }} />
            </div>
          );
        })}

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            this.props.history.push("/broker/add-team-member");
          }}
          style={{ marginTop: "20px" }}
        >
          Add Team Member
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ViewTeamContainer);
