import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import TextField from "@material-ui/core/TextField";
import api from "../../Shared/Functions/APIHelpers";

class BrokerInviteEmployerDialog extends React.Component {
  state = {
    showBackButton: false,
    showSubmitButton: false,

    email: "",

    employerEIN: "",
    newContactFirstName: "",
    newContactLastName: "",
    newContactEmail: "",

    hasMainContact: false,
    showInputs: false,
  };
  componentDidMount() {}

  close = () => {
    this.setState({ email: "" }, () => {
      this.props.Close();
    });
  };

  handleSave = () => {
    if (this.state.hasMainContact)
      this.handleAddBrokerEmployerInviteKnownContact();
    if (!this.state.hasMainContact)
      this.handleAddBrokerEmployerInviteUnknownContact();
  };

  handleAddBrokerEmployerInviteKnownContact = () => {
    if (!this.state.email) {
      this.showMessage("Please Enter an Email", "warning");
      return;
    } else {
      this.addBrokerEmployerInviteKnownContact().then((r) => {
        this.addInviteResponse(r.message);
      });
    }
  };

  addBrokerEmployerInviteKnownContact = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.addBrokerEmployerInvite(JSON.stringify({}), this.state.email)
      );
    });
  };

  handleAddBrokerEmployerInviteUnknownContact = () => {
    if (
      !this.state.employerEIN ||
      !this.state.newContactFirstName ||
      !this.state.newContactLastName ||
      !this.state.newContactEmail
    ) {
      this.showMessage("Please Complete Required Fields", "warning");
      return;
    } else {
      this.addBrokerEmployerInviteUnknownContact().then((r) => {
        this.addInviteResponse(r.message);
      });
    }
  };

  addBrokerEmployerInviteUnknownContact = () => {
    let body = {
      new_ein: this.state.employerEIN,
      new_contact_first: this.state.newContactFirstName,
      new_contact_last: this.state.newContactLastName,
      new_contact_email: this.state.newContactEmail,
    };
    return new Promise((resolve, reject) => {
      resolve(api.addBrokerEmployerInviteNewContact(JSON.stringify(body)));
    });
  };

  handleClose = () => {
    this.close();
  };

  addInviteResponse = (message) => {
    if (message === "invitation_created") {
      this.showMessage("An invitation was sent to the employer.", "success");
      this.handleClose();
    }
    if (message === "no_employers_found" && this.state.hasMainContact) {
      this.showMessage("No employers with this contact email were found. Please click back and try another option.", "warning");
    }
    if (message === "no_employers_found" && !this.state.hasMainContact) {
      this.showMessage("No employers with this EIN were found. Please click back and try another option.", "warning");
    }
    if (message === "multiple_employers_found") {
      this.showMessage("Multiple employers with this EIN were found. An invitation cannot be sent.", "warning");
    }
    if (message === "relationship_exists") {
      this.showMessage("You already have a relationship with this employer.", "warning");
    }
    if (message === "invitation_exists") {
      this.showMessage(
        "An invitation has already been sent to this employer.",
        "warning"
      );
    }
    if (message === "contact_change_no_invitation") {
      this.showMessage("Contact Change Request Created.", "success");
      this.handleClose();
    }
    if (message === "contact_change_invitation") {
      this.showMessage(
        "Contact Change Request Created. New Contact Sent Invitation",
        "success"
      );
      this.handleClose();
    }
    if (message === "contact_change_exists") {
      this.showMessage(
        "A contact change request already exists for this employer.",
        "warning"
      );
      this.handleClose();
    }
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };
  handleBack = () => {
    this.setState({
      showBackButton: false,
      showSubmitButton: false,
      showInputs: false,
      hasMainContact: false,
      email: "",
      employerEIN: "",
      newContactFirstName: "",
      newContactLastName: "",
      newContactEmail: "",
    });
  };
  render() {
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Invite Employer</DialogTitle>

          <DialogContent style={{ height: 600 }}>
            <div>
              <Typography variant="body2" color="inherit">
                You may request access to an employer’s Mewa.io data by entering
                the email address of the main contact. If found, the employer
                will receive an email with your request.
              </Typography>

              <br />
              <Typography variant="body2" color="inherit">
                If you do not have the main contact’s email address, or it does
                not match the contact on record with the carrier, enter the
                employer’s EIN and the new employer contact information. The
                current contact will be asked to approve the change, after
                which, the new contact will receive an email with your request
                to manage the group’s information and instructions for updating
                their contact info with the carrier.
              </Typography>

              <br />
              <Typography variant="body2" color="inherit">
                To view your existing invitations, click the “My Account” Icon,
                and select “View Invitations”.
              </Typography>
            </div>

            {!this.state.showInputs && (
              <div style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ hasMainContact: true, showInputs: true, showBackButton: true, showSubmitButton: true });
                  }}
                  style={{ marginTop: 20 }}
                  id='broker-employer-invite-has-email-button'
                >
                  I have the main contact's email address
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ hasMainContact: false, showInputs: true, showBackButton: true, showSubmitButton: true });
                  }}
                  style={{ marginTop: 40 }}
                  id='broker-employer-invite-no-email-button'
                >
                  I do not have the main contact's email address
                </Button>
              </div>
            )}
            {this.state.hasMainContact && this.state.showInputs && (
              <TextField
                id="broker-employer-invite-text-field"
                label="Employer Email"
                margin="dense"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
                value={this.state.email}
                fullWidth
                autoComplete="off"
              />
            )}
            {!this.state.hasMainContact && this.state.showInputs && (
              <div>
                <TextField
                  id="broker-employer-invite-ein-text-field"
                  label="Employer EIN"
                  margin="dense"
                  onChange={(e) => {
                    if (e.target.value.match(/^[0-9]{0,9}$|^$/))
                      this.setState({ employerEIN: e.target.value });
                  }}
                  value={this.state.employerEIN}
                  fullWidth
                  autoComplete="off"
                />
                <TextField
                  id="broker-employer-invite-new-contact-first-text-field"
                  label="New Contact First Name"
                  margin="dense"
                  onChange={(e) => {
                    this.setState({ newContactFirstName: e.target.value });
                  }}
                  value={this.state.newContactFirstName}
                  fullWidth
                  autoComplete="off"
                />
                <TextField
                  id="broker-employer-invite-new-contact-last-text-field"
                  label="New Contact Last Name"
                  margin="dense"
                  onChange={(e) => {
                    this.setState({ newContactLastName: e.target.value });
                  }}
                  value={this.state.newContactLastName}
                  fullWidth
                  autoComplete="off"
                />
                <TextField
                  id="broker-employer-invite-new-contact-email-text-field"
                  label="New Contact Email"
                  margin="dense"
                  onChange={(e) => {
                    this.setState({ newContactEmail: e.target.value });
                  }}
                  value={this.state.newContactEmail}
                  fullWidth
                  autoComplete="off"
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div style={{width:"100%"}}>
              <div style={{float:"left"}}>
                {this.state.showBackButton && (
                  <Button onClick={this.handleBack} color="primary">
                    Back
                  </Button>
                )}
              </div>
              <div style={{float:"right"}}>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
              {this.state.showBackButton && (
              <Button onClick={this.handleSave} color="primary" id='submit-broker-invite-employer-button'>
                Submit
              </Button>
              )}

              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(BrokerInviteEmployerDialog)
);
