import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import CloudDownload from "@material-ui/icons/CloudDownload";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import { formatDateTime } from "../../Shared/Functions/Format";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import api from "../../Shared/Functions/APIHelpers";

class SupportingDocumentationDialog extends React.Component {
  state = {
    errors: [],
  };

  getMewas() {
    return new Promise((resolve, reject) => {
      api
        .getMewas()
        .then((r) => {
          resolve(r);
          this.setState({ MEWAS: r });
        })
        .catch((err) => {
          this.setState({ errorMessage: "Error Getting Mewas" });
          console.log(err);
        });
    });
  }

  handleDownloadSupportingDocument = (doc) => {
    this.downloadSupportingDocument(doc.id).then((r) => {
      const link = document.createElement("a");
      link.href = r.download_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  downloadSupportingDocument = (supportingDocumentID) => {
    return new Promise((resolve, reject) => {
      resolve(api.downloadSupportingDocument(supportingDocumentID));
    });
  };

  close = () => {
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  onChange = (value, field) => {
    this.setState({ [field]: value });
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Supporting Documentation
          </DialogTitle>
          <DialogContent style={{ width: 550 }}>
            {this.props.Documentation.length > 0 && (
              <div>
                <List dense={true}>
                  {this.props.Documentation.map((doc, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemText
                          primary={doc.file_name}
                          secondary={formatDateTime(doc.date_added)}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => {
                              this.handleDownloadSupportingDocument(doc);
                            }}
                            edge="end"
                            aria-label="delete"
                          >
                            <CloudDownload />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(SupportingDocumentationDialog)
);
