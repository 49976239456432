import React from "react";
import SignUp from "./SignUp";

import { Auth } from "aws-amplify";

class SignUpContainer extends React.Component {
  state = {
    open: false,
    showConfirmation: false,
    errorMessage: "",
    emailError: false,
    passwordError: false,
    emailLabel: "Work Email",
    loginType: "Employer Login",
    subheader: "",
  };

  componentDidMount() {}

  SignUp = (email, password) => {
    if (this.isValidForm(email, password)) {
      const username = email;
      Auth.signUp({
        username,
        password,
        attributes: {
          email,
          "custom:app": "mewaio"
        }
      })
        .then(() => this.setState({ showConfirmation: true }))
        .catch(err => {
          this.setState({ emailError: false, passwordError: false });
          if (err.message) { 
            if (err.code === "UsernameExistsException") {
              //try to resend confirmation, else show error that account confirmed
              Auth.resendSignUp(email)
                .then(() => {
                  this.props.enqueueSnackbar("Confirmation Code Resent", {
                    variant: "success"
                  });
                  this.setState({ showConfirmation: true });
                })
                .catch(error => {
                  this.props.enqueueSnackbar(err.message, {
                    variant: "error"
                  });
                });
            }
          } else { //some error objects dont have a message property
            this.props.enqueueSnackbar(err, {
              variant: "error"
            });
          }
        });
    }
  };

  Back = () => {
    this.setState({ showConfirmation: false });
  };

  SignIn = (email, password) => {
    Auth.signIn(email, password)
      .then(user => {
        this.props.history.push("/mewa-admin")
      })
      .catch(err => this.setState({ errorMessage: err.message }));
  };

  ConfirmSignUp = (email, password, code) => {
    Auth.confirmSignUp(email, code)
      .then(data => {
        this.SignIn(email, password);
      })
      .catch(err => this.setState({ errorMessage: err.message }));
  };

  isValidForm = (email, password) => {
    if (email && password) {
      return true;
    } else {
      this.setState({
        emailError: email === "",
        passwordError: password === ""
      });
    }
  };

  render() {

    return (
      <React.Fragment>
        <SignUp
          showConfirmation={this.state.showConfirmation}
          emailError={this.state.emailError}
          passwordError={this.state.passwordError}
          action={this.props.action}
          SignUp={this.SignUp}
          back={this.Back}
          ConfirmSignUp={this.ConfirmSignUp}
        />
      </React.Fragment>
    );
  }
}

export default SignUpContainer;