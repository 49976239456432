import React from "react";

import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";

const EmployerHOC = (WrappedComponent) => {
  return class extends React.Component {
    state = {
      loaded: false,
      isAuthenticated: false,
      user: {},
      reportIsSecured: false,
      reportName: "",
    };

    componentDidMount() {
      this.getReportOutputInfo();
    }

    getReportOutputInfo = () => {
      this.getReportOutput(this.getOutputID())
        .then((r) => {
          this.setState({
            reportName: r.report_name,
            isSecured: r.is_secured,
            links: r.links,
            loaded: !r.is_secured
          });
          if (r.is_secured) this.makePrivate();
        })
        .catch((err) => {
          console.log(err);
          this.showUnauthorizedWarning("Must Be a Primary Contact To View");
        });
    };

    getOutputID = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get("id") ? params.get("id") : null;
    };

    getReportOutput = (outputID) => {
      return new Promise((resolve, reject) => {
        resolve(api.getReportOutput(outputID));
      });
    };

    makePrivate = () => {
      //check if JWT already exists... AKA user is already logged in
      this.authenticate();

      //setting employer has auth so that the user object is propogated to children componenets
      this.setState({ reportIsSecured: true });

      //define a function that will check if the JWT token exists EVERY TIME the URL changes
      //this is because this.props.history.listen RETURNS an unlisten function
      //so when the component unmounts we will call that to stop making the check
      this.unlisten = this.props.history.listen(() => {
        Auth.currentAuthenticatedUser()
          .then()
          .catch(() => {
            if (this.state.isAuthenticated) {
              this.setState({ isAuthenticated: false });
            }
          });
      });
    };

    componentWillUnmount() {
      //call the function defined above to deactivate the route listener when component is unmounted
      //if loaded flag was never true, it means that there was an error calling authenticate
      if (
        this.state.loaded &&
        !this.props.history.location.search.includes("error")
      )
        this.unlisten();
    }

    authenticate() {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.setState({ user: user, loaded: true, isAuthenticated: true });
        })
        .catch((err) => {
          localStorage.clear();
          //redirect to appropriate app, or user if null
          this.showUnauthorizedWarning(this.props.message, this.props.app);
          this.props.history.push(
            "/report/auth?error=6&id=" + this.getOutputID()
          );
        });
    }

    showUnauthorizedWarning = (message, app) => {
      if (message) {
        this.props.enqueueSnackbar(message, {
          variant: "warning",
        });
      }
    };

    render() {
      const { ...rest } = this.props;
      const { loaded, user, reportIsSecured } = this.state;
      if (!loaded) return null; //doesn't load the private component until user is loaded

      //if the employer doesn't have auth setup we can render without passing the user param
      if (!reportIsSecured) {
        return (
          <Route
            render={(props) => {
              return (
                <WrappedComponent
                  {...props}
                  {...rest}
                  reportIsSecured={false}
                />
              );
            }}
          />
        );
      }

      //In the route we explicitly pass the state element User so that Container components
      //can allow their routes to have access to the cognito user object
      return (
        <Route
          render={(props) => {
            return (
              <div>
                <div id="login">
                  <WrappedComponent
                    {...props}
                    {...rest}
                    user={user}
                    reportIsSecured={true}
                  />
                </div>
              </div>
            );
          }}
        />
      );
    }
  };
};
export default EmployerHOC;
