import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";


import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme) => ({
  inviteBox: {
    //display: "flex",
    marginTop: 40,
    "@media only screen and (max-width: 600px)": {
      display: "block",
    },
  },
  inviteBoxLeft: {
    width: "70%",
    float: "left",
  },
  inviteBoxRight: {
    //width: "30%",
    //textAlign: "right",
    float: "right",
  },
});
class ReviewBrokerInviteContainer extends React.Component {
  state = {
    loaded: true,
    invitations: [],
    radio: 1,
  };

  componentDidMount() {
    this.getInvites().then((r) => {
      //default all invitations to accept
      let invitations = r.map((i) => {
        i.result = 1;
        return i;
      });
      this.setState({
        loaded: true,
        invitations: invitations.sort((i, b) =>
          i.employer_name.localeCompare(b.employer_name)
        ),
      });
    });
  }

  getInvites = () => {
    return new Promise((resolve, reject) => {
      resolve(api.getInvitationsListReviewBroker());
    });
  };

  updateBrokerInvite = (invitation) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.updateBrokerInvite(invitation.id, JSON.stringify(invitation))
      );
    });
  };

  acceptDenyEmployer = (invitation, result) => {
    let tempInvitations = this.state.invitations;
    //remove whats currently in array forthis employer
    tempInvitations = tempInvitations.filter((i) => i.id !== invitation.id);
    //add with selected permission level
    invitation.result = parseInt(result);
    tempInvitations.push(invitation);

    this.setState({
      invitations: tempInvitations.sort((i, b) =>
        i.employer_name.localeCompare(b.employer_name)
      ),
    });
  };

  handleSubmitInvitationChanges = () => {
    let invitationUpdateJobs = [];
    this.state.invitations.forEach((i) => {
      invitationUpdateJobs.push(this.updateBrokerInvite(i));
    });
    Promise.all(invitationUpdateJobs).then((values) => {
      window.location.href = config.appLocation.URL + "/broker/employers";
    });
  };

  render() {
    const { loaded } = this.state;
    const { classes } = this.props;
    if (!loaded) return null;

    if (this.state.invitations.length === 0) {
      return (
        <React.Fragment>
          <Typography variant="h4">No Open Invites</Typography>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Typography variant="h4">Review Employer Invites</Typography>
        <div style={{ height: 20 }} />
        <Typography variant="body2" color="inherit">
          You have been invited to manage the following groups. You may choose to accept or deny the invitation for each group.
        </Typography>        
        <div
          id="invite-container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {this.state.invitations.map((invitation, index) => {
            return (

                  <div
                    style={{
                      display: "flex",
                      //justifyContent: "space-between",
                      width: "100%",
                      marginTop: 20,
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                        flexBasis: 500,
                      }}
                    >
                      {invitation.employer_name}
                    </div>
                    <div style={{ display: "flex", flexGrow: 0, flexBasis: 500, marginLeft: "auto"}}>
                      <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          value={invitation.result}
                          //value={this.state.radio}
                          inputProps={{
                            id: "employer-permission-" + index,
                          }}                      
                        >
                        <React.Fragment>
                          <FormControlLabel
                            value={0}
                            control={<Radio color="primary" />}
                            onClick={(e) => {
                              this.acceptDenyEmployer(
                                invitation,
                                e.target.value
                              );
                            }}                      
                            label="Deny"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            onClick={(e) => {
                              this.acceptDenyEmployer(
                                invitation,
                                e.target.value
                              );
                            }}
                            label="Accept"
                            labelPlacement="top"
                          />
                          </React.Fragment>
                        </RadioGroup>
                    </div>
                  </div>

            );
          })}

        </div>
        <div
          id="submit-button-container"
          className={classes.inviteBoxRight}
          style={{ float: "left", textAlign: "left", marginTop: 30, width:"50%" }}
          
        >
          <Button
            style={{ width: 200 }}
            onClick={this.handleSubmitInvitationChanges}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReviewBrokerInviteContainer);
