import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";

const initialState = {
  invitation: {},
  permissionLevel: 0,
  loaded: false,
  validParams: true,
  acceptChange: false,
  changeClosed: false,
  changeRequest: {},
};

class ReviewContactChangeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    let contactChangeResponse = this.getResponse();
    let acceptChange = contactChangeResponse === "approve" ? 1 : 0;
    let changeID = this.getChangeID();

    this.getContactChangeDetails(changeID).then((r) => {
      console.log(r);
      this.setState(
        {
          changeRequest: r,
          loaded: true,
          acceptChange: acceptChange,
          changeClosed: r.date_closed ? true : false,
        },
        () => {
          if (!r.date_closed) {
            this.handleUpdateContactChange(r.id, !acceptChange);
          }
        }
      );
    });
  }
  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };

  getResponse = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let contactChangeResponse = params.get("response");
    if (!contactChangeResponse) this.setState({ validParams: false });
    else return contactChangeResponse;
  };

  getChangeID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let changeID = params.get("changeID") ? params.get("changeID") : null;
    if (!changeID) this.setState({ validParams: false });
    else return changeID;
  };

  getContactChangeDetails = (changeID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(api.getBrokerEmployerContactChangeSecured(changeID));
      } else {
        resolve(api.getBrokerEmployerContactChange(changeID));
      }
    });
  };

  handleUpdateContactChange = (changeID, decline) => {
    this.updateContactChange(changeID, decline).then((r) => {
      console.log(r)
    });
  };

  updateContactChange = (changeID, decline) => {
    let body = {
      result: decline ? 0 : 1,
    };

    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(
          api.updateBrokerEmployerContactChangeSecured(
            changeID,
            JSON.stringify(body)
          )
        );
      } else {
        resolve(
          api.updateBrokerEmployerContactChange(changeID, JSON.stringify(body))
        );
      }
    });
  };

  render() {
    const { validParams, loaded, acceptChange, changeClosed } = this.state;

    if (!validParams) return null;
    if (!loaded) return null;
    if (changeClosed) {
      return (
        <React.Fragment>
        <Container maxWidth="sm" style={{ height:"50%", display: "flex", flexDirection: "column", justifyContent:"center", textAlign:"center"}}>
          <Typography variant="h4" >Employer Contact Change Request Already Closed</Typography>
          <Typography variant="body2" style={{marginTop:30}}>
            This contact change request has already been closed. Thank you!
          </Typography>    
        </Container>
      </React.Fragment>         
      );
    }
    if (!acceptChange) {
      return (
        <React.Fragment>
        <Container maxWidth="sm" style={{ height:"50%", display: "flex", flexDirection: "column", justifyContent:"center", textAlign:"center"}}>
          <Typography variant="h4" >Employer Contact Change Declined</Typography>
          <Typography variant="body2" style={{marginTop:30}}>
            You will remain the primary employer contact. Thank you!
          </Typography>  
        </Container>
      </React.Fragment>         
      );
    }
    if (acceptChange) {
      return (
        <React.Fragment>
        <Container maxWidth="sm" style={{ height:"50%", display: "flex", flexDirection: "column", justifyContent:"center", textAlign:"center"}}>
          <Typography variant="h4" >Employer Contact Change Approved</Typography>
          <Typography variant="body2" style={{marginTop:30}}>
            The new employer contact will be notified. Thank you!
          </Typography>
        </Container>
      </React.Fragment>        
      );
    }
    return <React.Fragment></React.Fragment>;
  }
}

export default withStyles(styles)(ReviewContactChangeContainer);
