import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import TextField from "@material-ui/core/TextField";
import api from "../../Shared/Functions/APIHelpers";
import { getStates } from "../../Shared/Functions/Format";

class EmployerInviteBrokerDialog extends React.Component {
  state = {

    showBackButton: false,

    emailError: "",

    brokerEmail: "",
    addType: "new",
    collectBrokerEmail: true,
    collectBrokerInfo: false,
    collectAgencyInfo: false,

    brokerFirstName: "",
    brokerLastName: "",

    agencyName: "",
    agencyPhone: "",
    agencyStreet: "",
    agencyCity: "",
    agencyStateID: "",
    agencyZip: "",

    permissionLevel: 1,
    errors:[]
  };
  componentDidMount() {}

  close = () => {
      this.props.Close()
  };


  handleAddBroker = () => {
    if (this.isValidForm()){
      this.addBroker()
        .then((r) => {
          if (r.message === "multiple_brokers_found") {
            this.showMessage("Multiple brokers with that address were found. Cannot sent invitation.", "warning");
          }
          if (r.message === "matches_employer_domain") {
            this.showMessage(
              "This individual cannot be invited to manage your account.",
              "warning"
            );
            this.resetForm();
          }
          if (r.message === "invitation_exists") {
            this.showMessage(
              "An invitation has already been sent to this invidual.",
              "warning"
            );
            this.resetForm();
          }
          if (r.message === "relationship_exists") {
            this.showMessage("This individual already has permission to manage your account.", "warning");
            this.resetForm();
          }
          if (r.message === "need_broker_info") {
            this.showMessage(
              "No broker could be found using this email. Please enter more info.",
              "warning"
            );
            this.collectBrokerInformation();
          }
          if (r.message === "need_agency_info") {
            this.showMessage(
              "Please provide additional details about this broker's agency.",
              "warning"
            );
            this.collectAgencyInfo();
          }
          if (r.message === "invitation_already_created") {
            this.showMessage(
              "An invitation already exists for this broker",
              "warning"
            );
            this.resetForm();
          }
          if (r.message === "invitation_created_no_approval") {
            this.showMessage(
              "Invitation sent successfully!",
              "success"
            );
            this.handleClose()
          }
          if (r.message === "invitation_created_no_verification") {
            this.showMessage(
              "Invitation sent successfully!",
              "success"
            );
            this.handleClose()
          }
          if (r.message === "invitation_created_verification_required") {
            this.showMessage(
              "Invitation created and will be send pending admin approval.",
              "success"
            );
            this.handleClose()
          }

          console.log(r.message);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  addBroker = () => {
    let body = this.getPostBody();
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.addEmployerBrokerInviteSecured(
            this.props.portalID,
            JSON.stringify(body)
          )
        );
      else
        resolve(
          api.addEmployerBrokerInvite(this.props.portalID, JSON.stringify(body))
        );
    });
  };

  handleClose = () => {
    this.close();
  };

  getPostBody = () => {
    return {
      add_type: this.state.addType,

      broker_email: this.state.brokerEmail,
      broker_first_name: this.state.brokerFirstName,
      broker_last_name: this.state.brokerLastName,

      agency_name: this.state.agencyName,
      agency_phone: this.state.agencyPhone,
      agency_street: this.state.agencyStreet,
      agency_city: this.state.agencyCity,
      agency_state: this.state.agencyStateID,
      agency_zip: this.state.agencyZip,

      permission_level: this.state.permissionLevel,
    };
  };

  collectBrokerInformation = () => {
    this.setState({
      collectBrokerEmail: false,
      collectBrokerInfo: true,
      errors:[],
      addType: "new_with_broker",
    });
  };

  collectAgencyInfo = () => {
    this.setState({
      collectBrokerEmail: false,
      collectBrokerInfo: true,
      collectAgencyInfo: true,
      errors:[],
      addType: "new_with_agency",
    });
  };

  resetForm = () => {
    this.setState({
      addType: "new",
      brokerEmail: "",
      brokerFirstName: "",
      brokerLastName: "",
      agencyName: "",
      agencyPhone: "",
      agencyStreet: "",
      agencyCity: "",
      agencyState: "",
      agencyZip: "",
      collectBrokerEmail: true,
      collectBrokerInfo: false,
      collectAgencyInfo: false,
      errors:[],
    });
  };

  stateAutoSelect = (event) => {
    let keyPressed = event.key.toLowerCase();
    if (keyPressed.match(/[a-z]/) && keyPressed.length === 1) {
      //this means character
      let state = getStates().find((s) =>
        s.abbreviation.charAt(0).toLowerCase().startsWith(keyPressed)
      );
      if (state) this.setState({ agencyStateID: state.id });
    }
  };
  
  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };

  isValidForm = () => {
    let errors = []
    if (!this.state.brokerEmail) {
      errors.push('brokerEmail');
    }
    if(this.state.collectBrokerInfo){
      if(!this.state.brokerFirstName)
      {
        errors.push('brokerFirstName');
      }
      if(!this.state.brokerLastName)
      {
        errors.push('brokerLastName');
      }
    }
    if(this.state.collectAgencyInfo){
      if(!this.state.agencyName)
      {
        errors.push('agencyName');
      }
      if(!this.state.agencyPhone)
      {
        errors.push('agencyPhone');
      }

      if(!this.state.agencyStreet)
      {
        errors.push('agencyStreet');
      }
      if(!this.state.agencyCity)
      {
        errors.push('agencyCity');
      }
      if(!this.state.agencyZip)
      {
        errors.push('agencyZip');
      }
      if(this.state.agencyStateID === ""){
        errors.push('agencyStateID')
      }
    }
    this.setState({ errors: errors });
    return errors.length === 0;
  }; 

  render() {
    const { fullScreen } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Invite Broker</DialogTitle>

          <DialogContent >
            <div style={{marginBottom:20}}>
              <Typography variant="body2" color="inherit">
                You may grant your broker access to your information in Mewa.io
                by entering their email address below. They will be notified of
                your request and invited to create an account if they do not
                already have one.
              </Typography>

              <br />
              <Typography variant="body2" color="inherit">
                To view your invitations or broker access, click the "My
                Account" icon and select the desired option
              </Typography>
            </div>

            <TextField
              id="employer-invite-broker-email-text-field"
              label="Broker Email"
              error={errors.includes("brokerEmail")}
              helperText={errors.includes("brokerEmail") ? "Required" : ""}              
              margin="normal"
              fullWidth
              onChange={(e) => this.setState({ brokerEmail: e.target.value })}
              disabled={!this.state.collectBrokerEmail}
              //style={{flexGrow: '1'}}
              value={this.state.brokerEmail}
            />
            {this.state.collectBrokerInfo && (
              <React.Fragment>
                <TextField
                  id="employer-invite-broker-first-name-text-field"
                  label="Broker First Name"
                  margin="normal"
                  fullWidth
                  error={errors.includes("brokerFirstName")}
                  helperText={errors.includes("brokerFirstName") ? "Required" : ""}
                  onChange={(e) =>
                    this.setState({ brokerFirstName: e.target.value })
                  }
                  value={this.state.brokerFirstName}
                />
                <TextField
                  id="employer-invite-broker-last-name-text-field"
                  label="Broker Last Name"
                  margin="normal"
                  error={errors.includes("brokerLastName")}
                  helperText={errors.includes("brokerLastName") ? "Required" : ""}
                  fullWidth
                  onChange={(e) =>
                    this.setState({ brokerLastName: e.target.value })
                  }
                  //style={{flexGrow: '1'}}
                  value={this.state.brokerLastName}
                />
              </React.Fragment>
            )}
            {this.state.collectAgencyInfo && (
              <React.Fragment>
                <Typography variant="h6" style={{ marginTop: 40 }}>
                  Agency Info
                </Typography>
                <TextField
                  id="agency-name-text-field"
                  label="Agency Name"
                  margin="normal"
                  error={errors.includes("agencyName")}
                  helperText={errors.includes("agencyName") ? "Required" : ""}
                  fullWidth
                  onChange={(e) =>
                    this.setState({ agencyName: e.target.value })
                  }
                  value={this.state.agencyName}
                />
                <TextField
                  id="agency-phone-text-field"
                  label="Agency Phone"
                  margin="normal"
                  error={errors.includes("agencyPhone")}
                  helperText={errors.includes("agencyPhone") ? "Required" : ""}
                  fullWidth
                  onChange={(e) =>
                    this.setState({ agencyPhone: e.target.value })
                  }
                  value={this.state.agencyPhone}
                />
                <TextField
                  id="agency-street-text-field"
                  label="Agency Street"
                  margin="normal"
                  error={errors.includes("agencyStreet")}
                  helperText={errors.includes("agencyStreet") ? "Required" : ""}
                  fullWidth
                  onChange={(e) =>
                    this.setState({ agencyStreet: e.target.value })
                  }
                  value={this.state.agencyStreet}
                />
                <TextField
                  id="agency-city-text-field"
                  label="Agency City"
                  margin="normal"
                  error={errors.includes("agencyCity")}
                  helperText={errors.includes("agencyCity") ? "Required" : ""}
                  onChange={(e) =>
                    this.setState({ agencyCity: e.target.value })
                  }
                  value={this.state.agencyCity}
                />

                <FormControl
                  margin="dense"
                  onKeyUp={this.stateAutoSelect}
                  style={{ marginTop: 19, marginLeft: 5 }}
                  error={errors.includes("agencyStateID")}
                >
                  <InputLabel htmlFor="states">State</InputLabel>
                  <Select
                    value={this.state.agencyStateID || ""}
                    onChange={(e) =>
                      this.setState({ agencyStateID: e.target.value })
                    }
                    inputProps={{
                      name: "agencyState",
                      id: "agemcy-state",
                    }}
                    style={{ width: 70, marginRight: 10 }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {getStates().map((state) => {
                      return (
                        <MenuItem value={state.id} key={"state_" + state.id}>
                          {state.abbreviation}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="agency-zip-text-field"
                  label="Agency Zip"
                  error={errors.includes("agencyZip")}
                  helperText={errors.includes("agencyZip") ? "Required" : ""}
                  margin="normal"
                  onChange={(e) => this.setState({ agencyZip: e.target.value })}
                  value={this.state.agencyZip}
                />                
              </React.Fragment>
            )}
            <FormControl
              component="fieldset"
              style={{ marginTop: 40, marginBottom: 20 }}
            >
              <RadioGroup
                aria-label="permission-level-radio"
                name="permission-level-radio"
                value={this.state.permissionLevel}
                onChange={(e) => {
                  this.setState({ permissionLevel: parseInt(e.target.value) });
                }}
              >             
                <FormControlLabel
                  id="notify-radio"
                  value={0}
                  control={<Radio />}
                  label={<Typography variant="body1">Notify</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will receive notifications when we need to contact you regarding things like termination verifications, employees who are eligible for Medicare and completing ARC Forms, however they will not be able to see any information about the requests.</Typography>
                <FormControlLabel
                  id="view-radio"
                  value={1}
                  control={<Radio />}
                  label={<Typography variant="body1">View</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will be able to view the status of any records in your Mewa.io account, but will not be able to edit them.</Typography>
                <FormControlLabel
                  id="update-radio"
                  value={2}
                  control={<Radio />}
                  label={<Typography variant="body1">Update</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will be able to view the status of any records in your Mewa.io account and update certain records on your behalf, such as a termination verification. They will not be able to sign any documents on your behalf.</Typography>
              </RadioGroup>


            </FormControl>            
          </DialogContent>
          <DialogActions>
            <div style={{width:"100%"}}>
              <div style={{float:"left"}}>
              {this.state.showBackButton && (
                <Button onClick={this.handleBack} color="primary">
                  Back
                </Button>
              )}
              </div>
              <div style={{float:"right"}}>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>                
              <Button 
                onClick={this.handleAddBroker}
                color="primary"
                id='employer-invite-broker-submit-button'
                disabled={
                !this.state.brokerEmail.match(
                  /^(\S+@\S+\.\S+)/
                )
              }
              >
                Submit
              </Button> 
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(EmployerInviteBrokerDialog)
);
