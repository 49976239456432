import React from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import TextField from "@material-ui/core/TextField";
import BrokerInviteEmployerAsyncSelect from "./BrokerInviteEmployerAsyncSelect";
import api from "../../Shared/Functions/APIHelpers";

class AddTeamMemberDialog extends React.Component {
  state = {
    brokerEmail: "",
    brokerFirstName: "",
    brokerLastName: "",
    selectedEmployers: [],
    errors: [],
  };
  componentDidMount() {}

  close = () => {
    this.setState({ email: "" }, () => {
      this.props.Close();
    });
  };

  handleAddBrokerBrokerInvite = () => {
    if (this.inError()) {
      this.showMessage("Please Correct Errors and Select At least 1 Employer", "warning");
      return;
    } else {
      this.addBrokerBrokerInvite().then((r) => {
        this.showMessage("Invitation Sent", "success");
        this.close()
      });
    }
  };

  inError = () => {
    let errors = [];
    if (!this.state.brokerEmail) errors.push("brokerEmail");
    if (!this.state.brokerFirstName) errors.push("brokerFirstName");
    if (!this.state.brokerLastName) errors.push("brokerLastName");

    if (this.state.selectedEmployers.length === 0)
      errors.push("selectedEmployers");
    this.setState({errors:errors})
    return errors.length > 0;
  };

  addBrokerBrokerInvite = () => {
    let body = {
      new_broker_email: this.state.brokerEmail,
      new_broker_first_name: this.state.brokerFirstName,
      new_broker_last_name: this.state.brokerLastName,
      employers:this.state.selectedEmployers 
    };
    return new Promise((resolve, reject) => {
      resolve(api.addBrokerBrokerInvite(JSON.stringify(body)));
    });
  };

  handleClose = () => {
    this.close();
  };

  addInviteResponse = (message) => {};

  addEmployerToList = (employer) => {
    let tempSelectedEmployers = this.state.selectedEmployers;
    //only add if employer not already selected
    if (!tempSelectedEmployers.find((f) => f.employer.id === employer.id)) {
      tempSelectedEmployers.push({
        employer: employer,
        permission: 0,
      });
      tempSelectedEmployers.sort((a, b) =>
        a.employer.name.localeCompare(b.employer.name)
      );
      this.setState({ selectedEmployers: tempSelectedEmployers });
    }
  };

  changeEmployerPermission = (employer, permission) => {
    let tempSelectedEmployers = this.state.selectedEmployers;
    //remove whats currently in array forthis employer
    tempSelectedEmployers = tempSelectedEmployers.filter(
      (e) => e.employer.id !== employer.id
    );
    //add with selected permission level
    tempSelectedEmployers.push({
      employer: employer,
      permission: permission,
    });
    //reset state object with sorted list
    tempSelectedEmployers.sort((a, b) =>
      a.employer.name.localeCompare(b.employer.name)
    );
    this.setState({ selectedEmployers: tempSelectedEmployers });
  };

  deleteSelectedEmployer = (employer) => {
    let tempSelectedEmployers = this.state.selectedEmployers;
    tempSelectedEmployers = tempSelectedEmployers.filter(
      (e) => e.employer.id !== employer.id
    );
    tempSelectedEmployers.sort((a, b) =>
      a.employer.name.localeCompare(b.employer.name)
    );
    this.setState({
      selectedEmployers: tempSelectedEmployers,
    });
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };

  render() {
    const { fullScreen } = this.props;
    const { errors } = this.state;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Invite Broker</DialogTitle>

          <DialogContent style={{ height: 600 }}>
            <div>
              <Typography variant="body2" color="inherit">
                You may add a team member to give them access to employer’s in
                Mewa.io data byentering their information
              </Typography>

              <br />
              <br />
              <Typography variant="body2" color="inherit">
                To view your existing invitations, click the “My Account” Icon,
                and select “View Invitations”.
              </Typography>
            </div>

            <Typography variant="h6" style={{ marginTop: 20 }}>
              Broker Info
            </Typography>
            <TextField
              id="broker-broker-invite-text-field"
              label="Broker Email"
              margin="dense"
              error={errors.includes("brokerEmail")}
              onChange={(e) => {
                this.setState({ brokerEmail: e.target.value });
              }}
              value={this.state.brokerEmail}
              fullWidth
              autoComplete="off"
            />
            <TextField
              id="broker-broker-first-invite-text-field"
              label="Broker First Name"
              margin="dense"
              error={errors.includes("brokerFirstName")}
              onChange={(e) => {
                this.setState({ brokerFirstName: e.target.value });
              }}
              value={this.state.brokerFirstName}
              fullWidth
              autoComplete="off"
            />
            <TextField
              id="broker-broker-last-invite-text-field"
              label="Broker Last Name"
              error={errors.includes("brokerLastName")}
              margin="dense"
              onChange={(e) => {
                this.setState({ brokerLastName: e.target.value });
              }}
              value={this.state.brokerLastName}
              fullWidth
              autoComplete="off"
            />

            <Typography variant="h6" style={{ marginTop: 20 }}>
              Employer Permissions
            </Typography>

            <BrokerInviteEmployerAsyncSelect
              handleEmployerChange={this.addEmployerToList}
            />
            <div style={{ marginTop: 40 }}>
              {this.state.selectedEmployers.map((employer, index) => {
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {employer.employer.name}
                    </div>
                    <div
                      style={{
                        width: "60%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        value={employer.permission}
                        onChange={(e) =>
                          this.changeEmployerPermission(
                            employer.employer,
                            e.target.value
                          )
                        }
                        inputProps={{
                          id: "employer-permission-" + index,
                        }}
                        style={{ width: 200, marginRight: 10 }}
                      >
                        <MenuItem value={0}>Notify</MenuItem>
                        <MenuItem value={1}>View</MenuItem>
                        <MenuItem value={2}>Update</MenuItem>
                      </Select>
                      <IconButton
                        onClick={() => {
                          this.deleteSelectedEmployer(employer.employer);
                        }}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            <Button onClick={this.handleAddBrokerBrokerInvite} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(AddTeamMemberDialog));
