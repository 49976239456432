import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import QRCode from "qrcode.react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from '@material-ui/core/Divider';


import { withStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";

/*  
  ***** NOTE ABOUT TOTP *****
  When you send to server to set it up, you send TOTP as the value
  When you've already established the software app to use then the server will
  send back SOFTWARE_TOKEN_MFA

  Likewise when you setup phone authentication, you send the server SMS
  but if it's already setup you get back SMS_MFA
*/

export const styles = (theme) => ({
  test: {},
});

class MFADialog extends React.Component {
  state = {
    open: false,
    currentMFA: "", //this value will mirror what user has selected in cognito
    newMFA: "", //this value will mirror what the user has selected in state
    user: {},
    userAttributes: {},
    qrCode: "",
    totpCode: "",
  };

  handleClickOpen = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      this.setState({
        open: true,
        currentMFA: user.preferredMFA,
        newMFA: user.preferredMFA,
        user: user,
        userAttributes: user.attributes,
      });
    });
  };

  setPreferredMFA = (mfaType) => {
    this.setState({ currentMFA: mfaType });
  };

  handleNewMFA = (event) => {
    if (event.target.value === "NOMFA") this.handleSetNoMFA();
    if (event.target.value === "SMS_MFA") this.handleSetSMSMFA();
    if (event.target.value === "SOFTWARE_TOKEN_MFA") this.handleSetTOTPMFA();
  };

  handleSetNoMFA = () => {
    //Check if the user has TOTP or SMS setup we need to collect and verify their codes
    if (this.state.currentMFA === "SMS_MFA") {
      //Collect Code
    }

    if (this.state.currentMFA === "SOFTWARE_TOKEN_MFA") {
      //Collect Code
    }

    //If not that means the currentMFA method is NOMFA, so just reset the value in state in case something
    //else was selected
    this.setState({ newMFA: "NOMFA" });
  };

  handleSetSMSMFA = () => {
    this.setState({ newMFA: "SMS_MFA" });
  };

  handleSetTOTPMFA = () => {
    //First call addTOTP to populate the QR Code correctly
    //Then set state properly to render it on the screen
    this.addTOTP().then((res) => {
      this.setState({ newMFA: "SOFTWARE_TOKEN_MFA" });
    });
  };

  handleClose = (value) => {
    this.props.returnValue(value);
    this.close();
  };

  handleCancel = () => {
    this.close();
  };

  close = () => {
    this.setState({
      open: false,
      newMFA: "",
      currentMFA: "",
      userAttributes: {},
      qrCode: "",
    });
  };

  verifyTOTP = () => {
    //this function sends back a challenge code with the user object
    //to try and log them in
    return new Promise((resolve, reject) => {
      Auth.verifyTotpToken(this.state.user, this.state.totpCode)
        .then(() => {
          resolve("Verified");
        })
        .catch((err) => {
          this.props.enqueueSnackbar("Incorect Code", {
            variant: "error",
          });
        });
    });
  };

  addTOTP = () => {
    //This function gets a QR Code for the specific user to scan
    return new Promise((resolve, reject) => {
      Auth.setupTOTP(this.state.user).then((code) => {
        const authCode =
          "otpauth://totp/" +
          this.state.userAttributes.email +
          "?secret=" +
          code +
          "&issuer=Reform+Health";
        this.setState({ qrCode: authCode }, () => {
          resolve("done");
        });
      });
    });
  };

  setPreferredMFA = (type) => {
    return new Promise((resolve, reject) => {
      Auth.setPreferredMFA(this.state.user, type)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  handleSave = () => {
    const mfaChanged = this.state.newMFA !== this.state.currentMFA;
    if (!mfaChanged) return this.handleCancel();
    //first try and save TOTP settings
    if (this.state.newMFA === "SOFTWARE_TOKEN_MFA") {
      this.verifyTOTP().then(() => {
        this.setPreferredMFA("TOTP")
          .then((data) => {
            this.props.enqueueSnackbar("Account secured with Software Token", {
              variant: "success",
            });
          })
          .catch((err) => {
            //TOTP Saving error from cognito
          });
        this.handleClose("SOFTWARE_TOKEN_MFA");
      });
    }
    //now try and change to SMS and collect phone code
    //IF phone number is verified
    if ( //using props here to be triggered from verification state change in container
      this.state.newMFA === "SMS_MFA" && 
      this.props.userAttributes.phone_number_verified === true
    ) {
      //nothing yet
      this.setPreferredMFA("SMS")
        .then((data) => {
          this.props.enqueueSnackbar("Account secured with SMS", {
            variant: "success",
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar(err.message, {
            variant: "error",
          });
          console.log(err);
        });
      this.handleClose("SMS");
    }

    if (
      this.state.newMFA === "SMS_MFA" &&
      this.props.userAttributes.phone_number_verified === undefined
    ) {
      this.props.enqueueSnackbar("No Verified Phone Number on Account", {
        variant: "error",
      });
      this.handleClose();
    }

    //try setting to NOMFA, but we will need to either collect the current SMS code
    //or current TOTP code in order to do that
    if (
      this.state.currentMFA === "SOFTWARE_TOKEN_MFA" &&
      this.state.newMFA === "NOMFA"
    ) {
      this.verifyTOTP().then(() => {
        this.setPreferredMFA("NOMFA")
          .then((data) => {
            this.props.enqueueSnackbar(
              "You have removed two-factor authentication from your account",
              {
                variant: "success",
              }
            );
          })
          .catch((err) => {
            this.props.enqueueSnackbar(err, {
              variant: "error",
            });
          });
      });
      this.handleClose("NOMFA");
    }

    //And lastly as a default just set NOMFA
    if (this.state.newMFA === "NOMFA") {
      this.setPreferredMFA("NOMFA")
        .then((data) => {
          if (this.state.currentMFA === "SMS_MFA") {
            this.props.enqueueSnackbar(
              "You have removed multifactor from your account",
              {
                variant: "success",
              }
            );
          }
          this.handleClose("NOMFA");
        })
        .catch((err) => {
          this.props.enqueueSnackbar(err, {
            variant: "error",
          });
        });
    }

    /*
    this.updateUserInfo().then(userInfo => {
      this.props.enqueueSnackbar("Name Changed Successfully", {
        variant: "success"
      });
      this.setState({ open: false });
    });
    */
  };

  render() {
    const { fullScreen } = this.props;
    const mfaChanged = this.state.newMFA !== this.state.currentMFA;
    const needMFA =
      this.state.currentMFA === "SOFTWARE_TOKEN_MFA" ||
      this.state.newMFA === "SOFTWARE_TOKEN_MFA";
    const height = this.state.newMFA === "SOFTWARE_TOKEN_MFA" ? 500 : 200;
    return (
      <div style={{ float: "left" }}>
        <Button onClick={this.handleClickOpen} color="primary" variant="contained">
          Configure 2FA
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Configure Two-Factor Authentication
          </DialogTitle>
          <DialogContent style={{ height: 700, width: 500 }}>
            <FormControl fullWidth={true}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={this.state.newMFA}
                onChange={this.handleNewMFA}
              > 
                <FormControlLabel
                  id="nomfa-radio"
                  value={"NOMFA"}
                  control={<Radio />}
                  label={<Typography variant="body1">Disabled</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:175, marginTop:-30, marginBottom: 20, minHeight:35}}>Only a password will be required to log in. This is the least secure option.</Typography>
                <FormControlLabel
                  id="software-radio"
                  value={"SOFTWARE_TOKEN_MFA"}
                  control={<Radio />}
                  label={<Typography variant="body1">Software Token</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:175, marginTop:-30, marginBottom: 20, minHeight:35}}>Use an app on your phone such as Authy or Google Authenticator to provide a pin for use with your password.</Typography>
                <FormControlLabel
                  id="sms-radio"
                  value={"SMS_MFA"}
                  control={<Radio />}
                  label={<Typography variant="body1">Text Message</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:175, marginTop:-30, marginBottom: 20, minHeight:35}}>Receive a text message on your phone with a pin to use every time you log in.</Typography>                                
              </RadioGroup>
            </FormControl>

            {mfaChanged && this.state.newMFA === "SOFTWARE_TOKEN_MFA" && (
              
              //If new MFA is TOTP we neeed to show the QR Codee so they can
              //scan with their app
              <React.Fragment>
                <Divider style={{margin:20}}/>
                <Typography variant="body1">
                  Open the authentication app on your phone, scan the QR Code below, and enter the 2fA code provided by your app.
                </Typography>   
                <div style={{textAlign: "center"}}>           
                  <QRCode
                    style={{ marginTop: 20, marginBottom: 20 }}
                    size={256}
                    value={this.state.qrCode}
                  />
                </div>
              </React.Fragment>
            )}

            {mfaChanged && needMFA && this.state.newMFA !== "SMS_MFA" && (
              //if a users preferred MFA was TOTP we need to collect the code in order
              //to make a change in cognito. Likewise if someone is just setting up TOTP
              //we neeed to collect the code for confirmation
              <React.Fragment>
                <TextField
                  id="standard-dense"
                  label={"TOTP Code"}
                  margin="dense"
                  onChange={(e) => this.setState({ totpCode: e.target.value })}
                  defaultValue={""}
                  autoComplete='off'
                  fullWidth
                />
              </React.Fragment>
            )}

            {mfaChanged && this.state.newMFA === "SMS_MFA" && (
              <React.Fragment>
                <Divider style={{margin:20}}/>
                {(this.props.userAttributes.phone_number_verified && (
                  <React.Fragment>
                    Login codes will be sent to &nbsp;
                    {this.props.userAttributes.phone_number}
                  </React.Fragment>
                )) || (
                  <React.Fragment>
                    <Typography variant="body1">
                      In order to use the text message option, you must verify your cell phone number. Please close this window and verify your cell phone on the previous page.
                    </Typography>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} disabled={this.state.newMFA === "SMS_MFA" && !this.props.userAttributes.phone_number_verified } color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(MFADialog));
