import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/NavStyles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Home from "@material-ui/icons/Home";

class NavList extends React.Component {
  goHome = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    var portalID = params.get("portalID")
   
    this.props.history.push("/employer/home?portalID=" + portalID  );
  };


  render() {
    const { classes } = this.props;
    
    return (
      <div className={classes.navList}>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">MewaIO Report</ListSubheader>
          }
        >
          <ListItem button onClick={this.goHome}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </List>
      </div>
    );
  }
}


export default withStyles(styles)(NavList);
