import React from "react";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import FormViewer from "../../Components/FormViewer/FormViewer";
import Dialog from "@material-ui/core/Dialog";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { Storage } from "aws-amplify";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import api from "../../Functions/APIHelpers";
import SignatureModal from "../Signature/SignatureModal";
import Signature from "../Signature/Signature";
import DocumentDoneMark from "./DocumentDoneMark";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  signaturePaper: {
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0.2),
    marginRight: theme.spacing(0.2),
    marginLeft: theme.spacing(0.2),
    paddingBottom: theme.spacing(1),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ArcDocumentDialog extends React.Component {
  state = {
    loaded: false,
    signatureModalOpen: false,
    eeForms: [],
    selectedForm: { revisionID: "" },
    fields: [],
    formNumber: 1,
    step: "",
  };
  componentDidMount() {
    if (!this.state.loaded) {
      this.handleMapArc(this.props.arcRequest.id);
    }
  }

  handleMapArc = (arcID) => {
    var body = JSON.stringify({
      title: this.props.arcRequest.title,
      chamber_name: this.props.arcRequest.chamber_name,
      EIN: this.props.arcRequest.EIN,
    });
    api.getArcForm(arcID, body).then((res) => {
      let form = res.outputFiles[0];
      let eeForms = [];
      console.log("Call made to")
      console.log(form.fileName)
      //download file that was just mapped and place in state
      Storage.configure({ level: "public" });
      Storage.get(form.fileName).then((result) => {
        eeForms.push({
          formName: form.displayName,
          url: result,
          profileID: form.profile_id,
          revisionID: form.revision_id,
          severity: form.severity,
          viewed: false,
        });
        this.setState({ eeForms: eeForms, selectedForm: eeForms[0] }, () => {
          this.setState({ loaded: true, step: "form" });
        });
      });
    });
  };

  updateArcSignature = (id) => {
    let body = JSON.stringify({
      title: this.props.arcRequest.title,
      chamber_name: this.props.arcRequest.chamber_name,
      EIN: this.props.arcRequest.EIN,
    });

    return new Promise((resolve, reject) => {
      api
        .updateArcSignature(this.props.arcRequest.id, body)
        .then((response) => {
          //update signature date so that remaining page knows this was signed successfully
          this.props.updateRequestField(
            "signature_date",
            response.signature_date
          );
          //recalculate any auto fills and this also will check/erase errors if in error state
          this.props.calculateAutoFillFields()
          resolve(response);
        })
        .catch((err) => {
          this.setState({
            errorMessage: "Error Updating Signature",
            sigDone: true,
          });
          console.log(err);
        });
    });
  };

  handleClose = (signedDocument) => {
    this.setState({ loaded: false, step: "" }, () => {
      this.props.Close(signedDocument);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          maxWidth={"lg"}
          scroll="paper"
          fullScreen
          //onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={()=>{this.handleClose(false)}}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                ARC Document
              </Typography>

              <Button
                color="inherit"
                variant="outlined"
                onClick={() => {
                  //this.setState({ step: "sign" });
                  this.setState({ signatureModalOpen: true });
                }}
                startIcon={<BorderColorIcon />}
              >
                Sign Form
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <SignatureModal
              isOpen={this.state.signatureModalOpen}
              Close={() => {
                this.setState({ signatureModalOpen: false });
              }}
              Public={true}
              ID={this.props.arcRequest.id}
              UpdateSignature={this.updateArcSignature}
              DoneMark={<DocumentDoneMark Close={this.handleClose} />}
              CloseButton={
                <Button
                  style={{ marginLeft: -17 }}
                  onClick={()=>{this.handleClose(true)}}
                  color="primary"
                >
                  Close
                </Button>
              }
            />
            {this.state.loaded && (
              <React.Fragment>
                {this.state.step === "form" && (
                  <React.Fragment>
                    <FormViewer
                      fields={this.state.fields}
                      overrides={false}
                      Form={this.state.selectedForm}
                      FormNumber={this.state.formNumber}
                      FormTotal={this.state.eeForms.length}
                      Reload={this.reload}
                      MaxWidth={1400}
                    />
                  </React.Fragment>
                )}

                {this.state.step === "sign" && (
                  <React.Fragment>
                    <Paper
                      className={classes.signaturePaper}
                      elevation={5}
                      style={{ zIndex: 10 }}
                    >
                      <Signature
                        IsDone={() => {
                          this.setState({
                            step: "complete",
                          });
                        }}
                        Public={true}
                        ID={this.props.arcRequest.id}
                        AfterStorage={this.updateArcSignature}
                      />
                    </Paper>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(ArcDocumentDialog));
