import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import DoneIcon from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class MedicareDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sendgridEvents: [],
      activeStep: 0,
      date_added:"",
      dateSent: "",
      dateOpened: "",
      dateSigned: "",
      submittedCMS: false,
      responseCMS: false,
      loaded: false
    };
  }

  componentDidUpdate(prevProps) {
    const { Open, SelectedRecord } = this.props;
    const { loaded } = this.state;

    if (Open && !loaded) this.handleGetDetails(SelectedRecord.id);
  }

  handleGetDetails = medicareID => {
    this.getMedicareEventDetail(this.props.SelectedRecord.id).then(events => {
      this.setState({ loaded: true });
      console.log(events)
      // This will set the active step
      // to the last completed system event. Else
      // it will show incomplete steps in grey
      if (events.system_events[0].date_added) {
        this.setState({
          activeStep: 0,
          date_added: events.system_events[0].date_added
        });
      }
      if (events.system_events[1].date_sent) {
        this.setState({
          activeStep: 1,
          dateSent: events.system_events[1].date_sent
        });
      }
      if (events.system_events[2].date_opened) {
        this.setState({
          activeStep: 2,
          dateOpened: events.system_events[2].date_opened
        });
      }
      if (events.system_events[3].date_signed) {
        this.setState({
          activeStep: 3,
          dateSigned: events.system_events[3].date_signed
        });
      }
      if (events.system_events[4].submitted_cms) {
        this.setState({
          activeStep: 4,
          submittedCMS: true
        });
      }
      if (events.system_events[5].response_cms) {
        this.setState({
          activeStep: 6,
          responseCMS: true
        });
      }

      // According to backend logic, populate sendgrid events into state
      // that will be rendered according to their "bucket"
      this.setState({ sendgridEvents: events.sendgrid_events });
    }).catch(err => {
      if(err === 403){ 
        this.props.history.push("/mewa-admin/auth?error=5")
      }
      if(err === 400){
        this.props.enqueueSnackbar("Something went wrong rendering termination details", {
          variant: "error"
        });
      }
    });
  };

  getMedicareEventDetail = medicareID => {
    return new Promise((resolve, reject) => {
      resolve(api.getMedicareMEWAEvents(medicareID))
    });
  };

  resendMedicareEmail = medicareID => {
    return new Promise((resolve, reject) => {
      resolve(api.resendMedicareEmail(medicareID))
    });
  };

  getSendgridEventIcon = event => {
    switch (event.event) {
      case "open":
        return <EmailIcon />;
      case "clicked":
        return <MouseIcon />;
      default:
        return <MouseIcon />;
    }
  };

  getSystemEventIcon = systemEvent => {
    switch (systemEvent) {
      case "sent":
        return <EmailIcon />;
      case "opened":
        return <MouseIcon />;
      case "verified":
        return <DoneIcon />;
      default:
        return <MouseIcon />;
    }
  };

  handleResend = () => {
    this.resendMedicareEmail(this.props.SelectedRecord.id).then(() => {
      this.props.enqueueSnackbar("Email Resent", {
        variant: "success"
      });
      this.close();
    }).catch(err => {
      if(err === 403){ 
        this.props.history.push("/mewa-admin/auth")
      }
      if(err === 400){
        this.props.enqueueSnackbar("Something went wrong rendering medicare details", {
          variant: "error"
        });
      }
    });
  };

  close = () => {
    this.setState({ sendgridEvents: [], systemEvents: {}, loaded: false });
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { Open } = this.props;

    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Medicare Tracker
            <br/>
            <Typography variant="caption">
            Sent To: 
            </Typography>
            <Typography variant="body2">
            {this.props.SelectedRecord.employer_contact_email}
            </Typography>
          </DialogTitle>

          <DialogContent style={{ height: 550, minWidth: 325 }}>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              

              
            <Step expanded={true} key={0}>
                <StepLabel>
                  <Typography variant="subtitle1">Record Added</Typography>
                  {this.state.activeStep >= 0 && (
                    <Typography variant="caption" display="block" gutterBottom>
                      {formatDateTime(this.state.date_added)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>


              <Step expanded={true} key={1}>
                <StepLabel>
                  <Typography variant="subtitle1">Employer Notification Sent</Typography>
                  {this.state.activeStep >= 1 && (
                    <Typography variant="caption" display="block" gutterBottom>
                      {formatDateTime(this.state.dateSent)}
                    </Typography>
                  )}
                </StepLabel>
                <StepContent>
                  <List
                    dense={true}
                    disablePadding={true}
                    name="sendgridEventList"
                  >
                    {this.state.sendgridEvents
                      .filter(event => event.bucket === "date_sent")
                      .map((event, index) => {
                        return (
                          <ListItem dense={true} key={index}>
                            <ListItemIcon>
                              {this.getSendgridEventIcon(event)}
                            </ListItemIcon>
                            <ListItemText
                              primary={"Email " + event.event}
                              secondary={formatDateTime(event.timestamp)}
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </StepContent>
              </Step>


              <Step expanded={true} key={2}>
                <StepLabel>
                  <Typography variant="subtitle1">Record Opened</Typography>
                  {this.state.activeStep >= 2 && (
                    <Typography variant="caption" gutterBottom>
                      {formatDateTime(this.state.dateOpened)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>


              <Step expanded={true} key={3}>
                <StepLabel>
                  <Typography variant="subtitle1">Record Verified</Typography>
                  {this.state.activeStep >= 3 && (
                    <Typography variant="caption" display="block" gutterBottom>
                      {formatDateTime(this.state.dateSigned)}
                    </Typography>
                  )}
                </StepLabel>
              </Step>

              <Step expanded={true} key={4}>
                <StepLabel>
                  <Typography variant="subtitle1">Submitted to CMS</Typography>
                </StepLabel>
              </Step>


              <Step expanded={true} key={5}>
                <StepLabel>
                  <Typography variant="subtitle1">Response Received</Typography>
                </StepLabel>
              </Step>


            </Stepper>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Close
            </Button>            
            <Button
              name="resendEmailButton"
              onClick={this.handleResend}
              color="primary"
            >
              Resend
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(MedicareDetail));
export { MedicareDetail as PureMedicareDetail };