import React from "react";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import FormViewer from "../../Components/FormViewer/FormViewer";
import Dialog from "@material-ui/core/Dialog";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Storage } from "aws-amplify";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import api from "../../Functions/APIHelpers";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SignedArcDocumentDialog extends React.Component {
  state = {
    loaded: false,
    eeForms: [],
    selectedForm: { revisionID: "" },
    fields: [],
    formNumber: 1,
    form: ""
  };
  componentDidMount() {
    if (!this.state.loaded) {
      this.handleGetSignedForm(this.props.arcRequest.id);
    }
  }

  downloadSignedARCDocument = () => {
    Storage.get(this.state.form.fileName)
      .then((result) => {
        const link = document.createElement("a");
        link.href = result;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleGetSignedForm = (arcID) => {
    api.getArcSignedForm(arcID).then((res) => {
      let form = res.outputFiles[0];
      let eeForms = [];
      //download file that was just mapped and place in state
      Storage.configure({ level: "public" });
      Storage.get(form.fileName).then((result) => {
        eeForms.push({
          formName: form.displayName,
          url: result,
          profileID: form.profile_id,
          revisionID: form.revision_id,
          severity: form.severity,
          viewed: false,
        });
        this.setState({ eeForms: eeForms, selectedForm: eeForms[0], form: res.outputFiles[0] }, () => {
          this.setState({ loaded: true, step: "form" });
        });
      });
    });
  };

  updateArcSignature = (id) => {
    let body = JSON.stringify({
      title: this.props.arcRequest.title,
      chamber_name: this.props.arcRequest.chamber_name,
      EIN: this.props.arcRequest.EIN,
    });

    return new Promise((resolve, reject) => {
      api
        .updateArcSignature(this.props.arcRequest.id, body)
        .then((response) => {
          //update signature date so that remaining page knows this was signed successfully
          this.props.updateRequestField(
            "signature_date",
            response.signature_date
          );
          //recalculate any auto fills and this also will check/erase errors if in error state
          this.props.calculateAutoFillFields();
          resolve(response);
        })
        .catch((err) => {
          this.setState({
            errorMessage: "Error Updating Signature",
            sigDone: true,
          });
          console.log(err);
        });
    });
  };

  handleClose = (signedDocument) => {
    this.setState({ loaded: false, step: "" }, () => {
      this.props.Close(signedDocument);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          maxWidth={"lg"}
          scroll="paper"
          fullScreen
          //onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.handleClose(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Completed ARC Document
              </Typography>

              <Button
                color="inherit"
                variant="outlined"
                onClick={() => {
                  this.downloadSignedARCDocument();
                }}
                startIcon={<GetAppIcon />}
              >
                Download
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent>
            {this.state.loaded && (
              <React.Fragment>
                <FormViewer
                  fields={this.state.fields}
                  overrides={false}
                  Form={this.state.selectedForm}
                  FormNumber={this.state.formNumber}
                  FormTotal={this.state.eeForms.length}
                  Reload={this.reload}
                  MaxWidth={1400}
                />
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(SignedArcDocumentDialog));
