import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import config from "../config";
import { styles } from "./Styles/AuthStyles";
import api from "../Shared/Functions/APIHelpers";
import { Auth } from "aws-amplify";

import { InputAdornment } from "@material-ui/core";

import ShowPassword from "@material-ui/icons/RemoveRedEye";

class ForgotPassword extends React.Component {
  state = {
    open: false,
    email: "",
    showConfirmation: false,
    deliveryDetails: {},
    data: {},
    deliveryMedium: "",
    destination: "",
    newPasswordIsMasked: true,
  };

  sendCode = () => {
    const username = this.state.email.toLowerCase();
    Auth.forgotPassword(username)
      .then((data) => {
        this.setState({ showConfirmation: true });
      })
      .catch((err) => {
        if (err.code === "NotAuthorizedException") {
          api.resendSecureEmployerPassword(this.getPortalID()).then((r) => {
            window.location.href =
              config.appLocation.URL +
              "/employer/auth?portalID=" +
              this.getPortalID() +
              "&message=3";
          });
        } else {
          console.log(err);
          this.props.enqueueSnackbar(err.message, {
            variant: "error",
          });
        }
      });
  };

  toggleNewPasswordMask = () => {
    this.setState(prevState => ({
      newPasswordIsMasked: !prevState.newPasswordIsMasked
    }));
  };
  

  changePassword = () => {
    const username = this.state.email.toLowerCase();
    Auth.forgotPasswordSubmit(username, this.state.code, this.state.password)
      .then((data) => {
        this.redirectUser(1);
      })
      .catch((err) => {
        if (err.code === "InvalidParameterException" || "InvalidPasswordException") {
          this.props.enqueueSnackbar(
            "Password must be at least 8 characters, contain 1 number, and 1 special character",
            {
              variant: "error",
            }
          );
        }
        if (err.code === "CodeMismatchException") {
          this.props.enqueueSnackbar("Invalid Code", {
            variant: "error",
          });
        }
      });
  };

  Back = () => {
    this.setState({ showConfirmation: false });
  };

  Next = () => {
    this.changePassword();
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };

  redirectUser = (code) => {
    if (this.props.loginURL.indexOf("portalID") > 0) {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "&message=" + code;
    } else {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "?message=" + code;
    }
  };

  render() {
    const { showConfirmation, newPasswordIsMasked } = this.state;
    const { classes } = this.props;

    return (
      <div>
        {!showConfirmation && (
          <div>
            <div>
              <Typography variant="h4" color="inherit" noWrap>
                Reset Password
              </Typography>
            </div>
            <br />
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                Enter your email to receive a temporary code which you can use
                reset your mewa.io password.
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-email-dense"
                label="Email"
                className={classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                margin="dense"
                onChange={(e) => this.setState({ email: e.target.value })}
                defaultValue={this.state.email}
                style={{ width: 300 }}
              />
            </div>
            <br />
            <div>
              <div className={classes.leftDiv}>
                <Button
                  color="primary"
                  className={classNames(classes.button, classes.leftButton)}
                  onClick={() => this.props.action("signIn")}
                >
                  Back
                </Button>
              </div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={this.sendCode}
                  style={{ width: 100 }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
        {showConfirmation && (
          <div>
            <div>
              <Typography variant="h4" color="inherit" noWrap>
                Confirmation
              </Typography>
            </div>
            <br />
            <div className={classes.socialTextContainer}>
              <Typography variant="caption">
                Enter the verification code you received via email and select a
                new password
              </Typography>
            </div>
            <div>
              <TextField
                id="standard-code-dense"
                label="Confirmation Code"
                className={classes.textField}
                name="code"
                margin="dense"
                onChange={(e) => this.setState({ code: e.target.value })}
                style={{ width: 300 }}
              />
            </div>
            <div>
              <TextField
                id="standard-password-input"
                label="New Password"
                margin="dense"
                style={{ width: 300 }}
                onChange={(e) =>
                  this.setState({ password: e.target.value })
                }
                value={this.state.password}
                type={newPasswordIsMasked ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ShowPassword
                        onClick={this.toggleNewPasswordMask}
                        style={{ color: "grey", cursor:"pointer" }}
                      />
                    </InputAdornment>
                  )
                }}                  
              />              
            </div>
            <br />
            <div>
              <div className={classes.rightDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.rightButton)}
                  onClick={this.Next}
                  style={{ width: 220 }}
                >
                  Change my Password
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPassword);
