import React from "react";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./TableStyles";

const TableStatusIndicator = ({ initialCount, currentCount }) => {
  return (
    <React.Fragment>
      {currentCount !== initialCount && (
        <React.Fragment>
          <Typography
            variant="caption"
            id="currentRecordLabel"
            display="inline"
          >
            Filtered Records:
          </Typography>
          <Typography
            variant="caption"
            id="currentRecordCount"
            color="primary"
            display="inline"
            style={{ marginRight: 10 }}
          >
            {currentCount}
          </Typography>
          |
        </React.Fragment>
      )}

      <Typography
        variant="caption"
        id="totalRecordLabel"
        display="inline"
        style={{ marginLeft: 10 }}
      >
        Total Records: &nbsp;
      </Typography>
      <Typography
        variant="caption"
        id="totalRecordCount"
        color="primary"
        display="inline"
      >
        {initialCount}
      </Typography>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(TableStatusIndicator);
