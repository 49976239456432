import React from "react";
//mport logo from './logo.svg';
import Amplify from "aws-amplify";
import Router from "./Router";
import "./App.css";
import config from "./config";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import ErrorHOC from "./Shared/Components/Error/ErrorHOC";
import theme from "./Shared/Styles/MuiTheme";
import getBrandedThemeOrDefault from "./Auth/Styles/BrandedTheme";

Amplify.configure({
  Auth: {
    oauth: config.oauth,
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.FILE_BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
});

function App() {
  return (
    <ErrorHOC>
      <MuiThemeProvider theme={getBrandedThemeOrDefault({})}>
        <Router />
      </MuiThemeProvider>
    </ErrorHOC>
  );
}

export default App;
