import React, { useState } from "react";

import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  cardError: {
    backgroundColor: "#fd9301",
  },
  cardNoError: {
    backgroundColor: "#3b943d",
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
  },
  actions: {
    color: "white",
    display: "flex",
  },
  message: {
    flex: 2,
  },
  expand: {
    color: "white",
    padding: "8px 8px",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    padding: 16,
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  listBottom: {
    marginBottom: theme.spacing(3),
  }
}));

const UploadNotification = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [invalidRecordsExpanded, setInvalidRecordsExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenInvalidRecords = () => {
    setInvalidRecordsExpanded(!invalidRecordsExpanded);
  };

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };
  const hasError =
    props.results.duplicate_record_count > 0 ||
    props.results.invalid_mewa_record_count > 0 ||
    props.results.no_employer_email_count > 0 ||
    props.results.invalid_record_count;
  return (
    <Card
      className={classnames(classes.card, {
        [classes.cardError]: hasError,
        [classes.cardNoError]: !hasError,
      })}
      ref={ref}
    >
      <CardActions className={classes.actions}>
        <Typography variant="subtitle2" className={classes.message}>
          {props.message}
        </Typography>
        <div className={classes.icons}>
          <IconButton
            aria-label="Show info"
            className={classnames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton className={classes.expand} onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper className={classes.collapse}>
          <List>
            <ListItem className={classes.listBottom}>
              <ListItemText
                primary={"Records in File: " + props.results.csv_row_count}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Valid Records: " + props.results.successful_records}
              />
            </ListItem>

            <ListItem button onClick={handleOpenInvalidRecords}>
              <ListItemText primary={"Invalid Records: " + (props.results.invalid_mewa_record_count + props.results.invalid_record_count + props.results.no_employer_email_count)} />
              {invalidRecordsExpanded ? <CloseIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={invalidRecordsExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem className={classes.nested}>
                  <ListItemText
                    primary={"MEWA: " + props.results.invalid_mewa_record_count}
                  />
                </ListItem>
                <ListItem className={classes.nested}>
                  <ListItemText
                    primary={"No Employer Email: " + props.results.no_employer_email_count}
                  />
                </ListItem>                
                <ListItem className={classes.nested}>
                  <ListItemText
                    primary={"Other: " + props.results.invalid_record_count}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItem className={classes.listBottom}>
              <ListItemText
                primary={
                  "Already Loaded: " + props.results.duplicate_record_count
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Successfully Added: " + props.results.database_count}
              />
            </ListItem>
          </List>
        </Paper>
      </Collapse>
    </Card>
  );
});

export default UploadNotification;
