import React from "react";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Auth } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
});

class EmployerSigner extends React.Component {
  state = {
    signerFirst: "",
    signerLast: "",
  };

  handleClose = () => {
    window.location.href = "https://mewa.io/";
  };

  render() {
    return (
      <React.Fragment>
        <Card style={{ marginBottom: 50, marginTop: 25 }}>
          <CardHeader
            subheader="Signer cannot be the same as Medicare Beneficiary/Employee"
            style={{ backgroundColor: "#FFCC00" }}
          />
          <CardContent>
            <Typography variant="body2" style={{ marginTop: 10, marginBottom: 25 }}>
              CMS requires that the employer representative who signs the
              exception request cannot be the same as the Medicare
              Beneficiary/Employee. Please enter the name of another individual
              at the employer who will sign this request. This individual will
              need to sign on the next page.
            </Typography>
            <TextField
              id="employer-signer-first-text-field"
              label="Employer Signer First"
              margin="dense"
              onChange={(e) =>
                this.props.SetEmployerSignerFirst(e.target.value)
              }
              value={this.props.EmployerSignerFirst}
              fullWidth
              error={this.props.Errors.includes("employer_signer_first")}
            />
            <TextField
              id="employer-signer-last-text-field"
              label="Employer Signer Last"
              margin="dense"
              onChange={(e) => this.props.SetEmployerSignerLast(e.target.value)}
              value={this.props.EmployerSignerLast}
              error={this.props.Errors.includes("employer_signer_last")}
              fullWidth
            />
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EmployerSigner));
