import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import api from "../../Shared/Functions/APIHelpers";

import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  teamMemberBox: {
    display: "flex",
    marginTop: 25,
    "@media only screen and (max-width: 600px)": {
      display: "block",
    },
    paddingBottom: 25,
    borderBottom: "1px solid #f2f2f2",
  },
  teamMemberBoxLeft: {
    width: "70%",
  },
  teamMemberBoxRight: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

class AddTeamMemberContainer extends React.Component {
  state = {
    errors: [],

    brokerEmail: "",
    brokerFirstName: "",
    brokerLastName: "",

    employers: [],
    showPermissions: false,
    permissionsLoaded: false,

    showExistingRelationships: true,
    showNewRelationships: true,

    editBrokerFirst: "",
    editBrokerLast: "",
    editBrokerEmail: "",
    selfEdit: false,
    confirmRelationshipRemoval: false,
    showConfirmRelationshipRemovalPrompt: false,
    existingRelationshipUpdated: false,
  };

  componentDidMount() {
    if (this.props.isEdit) {
      this.handleAssignPermissions(this.getBrokerID());
    }
  }

  handleAssignPermissions = (brokerID = null) => {
    if (this.inError()) return;
    //if broker ID is supplied, include a flag in the request that we will be using ID to lookup instead of email
    let invitationNeeded = true;
    if (brokerID) invitationNeeded = false;
    //need to do a round trip to see if this broker exists already. If they do, we need to get their current permission levels for relevant employers
    this.getBrokerPermissions(invitationNeeded, brokerID).then((r) => {
      if (r.message === "employer_domain_match") {
        this.setState({
          brokerEmail: "",
          brokerFirstName: "",
          brokerLastName: "",
        });
        this.showMessage(
          "The entered email matches the domain of one of your employers",
          "warning"
        );
      } else {
        if (brokerID)
          this.setState({
            editBrokerFirst: r.broker.first_name,
            editBrokerLast: r.broker.last_name,
            editBrokerEmail: r.broker.email,
          });

        const existingRelationshipCount = r.employers.filter(
          (e) => e.has_relationship === true
        ).length;
        const newRelationshipCount = r.employers.filter(
          (e) => e.has_relationship === false
        ).length;

        if (existingRelationshipCount > 0) {
          this.setState({ showExistingRelationships: true });
        } else {
          this.setState({ showExistingRelationships: false });
        }

        if (newRelationshipCount > 0) {
          this.setState({ showNewRelationships: true });
        } else {
          this.setState({ showNewRelationships: false });
        }

        this.setState({
          employers: r.employers,
          selfEdit: r.selfEdit,
          permissionsLoaded: true,
          showPermissions: true,
        });
      }
    });
  };

  getBrokerPermissions = (invitationNeeded, brokerID) => {
    let bodyBrokerID = brokerID ? brokerID : "";
    let body = {
      new_broker_email: this.state.brokerEmail,
      new_broker_first_name: this.state.brokerFirstName,
      new_broker_last_name: this.state.brokerLastName,
      invitation_needed: invitationNeeded,
      broker_id: bodyBrokerID,
    };
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerPermissions(JSON.stringify(body)));
    });
  };

  handleSave = () => {
    if (this.props.isEdit) {
      this.handleSubmitBrokerAssignPermissions();
    } else {
      this.handleSubmitBrokerInvite();
    }
  };

  handleSubmitBrokerAssignPermissions = () => {
    //before submitting to server, check if any employers has_relationship=True
    //and permission level = -1. In this case we are REMOVING an existing relationship
    //and want to warn the user before they can submit
    if (!this.isRemovingExistingRelationship()) {
      this.handleSubmitBrokerAssignPermissionsConfirmed();
    } else {
      //Need to collect confirmation
      if (!this.state.confirmRelationshipRemoval) {
        this.setState({ showConfirmRelationshipRemovalPrompt: true });
      } else {
        this.handleSubmitBrokerAssignPermissionsConfirmed();
      }
    }
  };

  handleSubmitBrokerAssignPermissionsConfirmed = () => {
    this.assignBrokerPermissions().then((r) => {
      this.showMessage("Permissions Assigned", "success");
      this.props.history.push("/broker/view-team");
    });
  };

  isRemovingExistingRelationship = () => {
    let isRemovingExistingRelationship = false;
    this.state.employers.map((e) => {
      if (e.has_relationship && e.permission_level === -1)
        isRemovingExistingRelationship = true;
    });
    return isRemovingExistingRelationship;
  };

  handleSubmitBrokerInvite = () => {
    this.submitBrokerInvite().then((r) => {
      this.showMessage("Invitation Sent", "success");
      this.props.history.push("/broker/view-invitations");
    });
  };

  handleCancel = () => {
    this.props.history.push("/broker/view-team");
  };

  submitBrokerInvite = () => {
    let body = {
      new_broker_email: this.state.brokerEmail,
      new_broker_first_name: this.state.brokerFirstName,
      new_broker_last_name: this.state.brokerLastName,
      employers: this.state.employers.filter(
        (e) => !e.has_relationship && parseInt(e.permission_level) !== -1
      ),
    };
    return new Promise((resolve, reject) => {
      resolve(api.addBrokerBrokerInvite(JSON.stringify(body)));
    });
  };

  assignBrokerPermissions = () => {
    let body = {
      broker_id: this.getBrokerID(),
      employers: this.state.employers,
    };
    return new Promise((resolve, reject) => {
      resolve(api.assignBrokerPermissions(JSON.stringify(body)));
    });
  };

  inError = () => {
    if (this.props.isEdit) return false;
    let errors = [];
    if (!this.state.brokerEmail) errors.push("brokerEmail");
    if (!this.state.brokerFirstName) errors.push("brokerFirstName");
    if (!this.state.brokerLastName) errors.push("brokerLastName");

    this.setState({ errors: errors });
    return errors.length > 0;
  };

  getBrokerID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let brokerID = params.get("brokerID") ? params.get("brokerID") : null;
    return brokerID;
  };

  changePermissionLevel = (employer, permissionLevel) => {
    //if permissionLevel was unfedined, that means that button click was disabled. a disabled button will still call the onClick
    //event, but we will simply return and do nothing
    if (permissionLevel === undefined) return;
    let tempEmployers = this.state.employers;
    //remove current selection for employer in state
    tempEmployers = tempEmployers.filter((e) => e.id !== employer.id);

    //add with selected permission level
    employer.permission_level = parseInt(permissionLevel);
    tempEmployers.push(employer);

    //resort list so looks like nothing change
    tempEmployers.sort((a, b) => a.name.localeCompare(b.name));

    //resign employer to state
    this.setState({
      employers: tempEmployers,
      existingRelationshipUpdated: true,
    });
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };

  isSubmitDisabled = () => {
    //two scenarios could make the submit button disabled

    //First is the lenght of the employer array 0?
    if (this.state.employers.length === 0) return true;

    //second, ensure that something was changed for this broker. Either a new group assignment was made
    //or an existing relationship was edited. If something changed then subnmit should not be disabled
    if (this.relationshipSelectedOrChanged()) return false;

    //otherwise submit should remained disabled
    return true;
  };

  relationshipSelectedOrChanged = () => {
    //First check if a new relationship was added
    if (
      this.state.employers
        .filter((e) => e.has_relationship === false)
        .find((e) => e.permission_level !== -1)
    )
      return true;
    //Now check to see if any relationships have been updated
    if (this.state.existingRelationshipUpdated) return true;

    return false;
  };

  shouldRadioButtonBeDisabled = (employer, radioButtonIndex) => {
    /*
      If I'm self editing, we only care about my max permission level
      I CANT assign myself a permission ABOVE what I currently have

      If im NOT self editing, the API wont even return employers
      where my relationship level =2. SO again we only care max permission level

    */
    if (employer.max_permission < radioButtonIndex) return true;
    return false;
    //console.log(employer);
    //console.log(radioButtonIndex);
  };

  render() {
    const {
      errors,
      showPermissions,
      permissionsLoaded,
      employers,
      showExistingRelationships,
      showNewRelationships,
    } = this.state;
    const { isEdit } = this.props;
    return (
      <React.Fragment>
        {this.state.showConfirmRelationshipRemovalPrompt && (
          <ConfirmAlert
            isOpen={this.state.showConfirmRelationshipRemovalPrompt}
            isNo={() => {
              this.setState({ showConfirmRelationshipRemovalPrompt: false });
            }}
            isYes={() => {
              this.setState(
                {
                  showConfirmRelationshipRemovalPrompt: false,
                  confirmRelationshipRemoval: true,
                },
                this.handleSave
              );
            }}
            AlertTitle={"Remove Employer?"}
            AlertText={
              "By selecting “None” as a permission type, you will no longer have access to manage that employer through Mewa.io. This cannot be undone."
            }
          />
        )}
        {isEdit && (
          <React.Fragment>
            <Typography variant="h4">Manage Team Member</Typography>
            <div style={{ height: 20 }} />
            <Typography variant="h6">
              {this.state.editBrokerFirst} {this.state.editBrokerLast}
            </Typography>
            <Typography variant="body1">
              {this.state.editBrokerEmail}
            </Typography>
            <div style={{ height: 20 }} />
          </React.Fragment>
        )}
        {!isEdit && (
          <div style={{ maxWidth: 800 }}>
            <Typography variant="h4">Invite Team Member</Typography>
            <div style={{ height: 20 }} />
            <Typography variant="body2" color="inherit">
              You may invite another broker or team member from your agency to
              help manage your groups. Enter the broker's info and select the
              groups and permission levels you'd like to assign.
            </Typography>
            <div
              style={{
                marginTop: 20,
                marginBottom: 30,
                padding: 10,
                backgroundColor: "#f3f3f3",
                border: "1px solid #e3e3e3",
              }}
            >
              <Typography variant="body2">
                Please note: You will only be able to send invites for employers
                that invited you or you invited directly. If you were invited by
                a broker to manage the employer, you will not be able to invite
                a third broker to manage that group. Either the primary broker
                or the employer will have to initiate the invite. Also, you
                cannot grant a higher permission level than you've been granted
                by the employer.
              </Typography>
            </div>
            <Typography variant="h6" style={{ marginTop: 20 }}>
              Broker Info
            </Typography>
            <div>
              <TextField
                id="broker-broker-email-text-field"
                label="Broker Email"
                margin="dense"
                error={errors.includes("brokerEmail")}
                onChange={(e) => {
                  this.setState({ brokerEmail: e.target.value });
                }}
                disabled={showPermissions}
                value={this.state.brokerEmail}
                fullWidth
                autoComplete="off"
              />
              <TextField
                id="broker-broker-first-invite-text-field"
                label="Broker First Name"
                margin="dense"
                error={errors.includes("brokerFirstName")}
                onChange={(e) => {
                  this.setState({ brokerFirstName: e.target.value });
                }}
                disabled={showPermissions}
                value={this.state.brokerFirstName}
                fullWidth
                autoComplete="off"
              />
              <TextField
                id="broker-broker-last-invite-text-field"
                label="Broker Last Name"
                error={errors.includes("brokerLastName")}
                margin="dense"
                onChange={(e) => {
                  this.setState({ brokerLastName: e.target.value });
                }}
                disabled={showPermissions}
                value={this.state.brokerLastName}
                fullWidth
                autoComplete="off"
              />
            </div>
          </div>
        )}
        {!showPermissions && !isEdit && (
          <React.Fragment>
            <div style={{ marginTop: 50 }} />

            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleCancel}
              style={{ marginTop: 20, marginRight: 20 }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.handleAssignPermissions();
              }}
              id="assign-permissions-new-team-member-button"
              disabled={!this.state.brokerEmail.match(/^(\S+@\S+\.\S+)/)}
              style={{ marginTop: 20 }}
            >
              Assign Permissions
            </Button>
          </React.Fragment>
        )}
        {showPermissions && permissionsLoaded && (
          <div style={{ width: "100%" }}>
            {employers.length === 0 && (
              <div style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="body2">
                  You do not currently have a direct relationship with any
                  employers. Please ask the employer or the broker who invited
                  you to add this individual.
                </Typography>
              </div>
            )}
            {showExistingRelationships && (
              <React.Fragment>
                <div
                  style={{
                    maxWidth: 550,
                    marginTop: 10,
                    marginBottom: 30,
                    padding: 10,
                    backgroundColor: "#f3f3f3",
                    border: "1px solid #e3e3e3",
                  }}
                >
                  <Typography variant="body2">
                    Please note you cannot adjust permissions that are set by
                    the employer or set a higher permission than your own for a
                    group.
                  </Typography>
                </div>
                <Typography variant="h6" style={{ marginTop: 20 }}>
                  Existing Permissions
                </Typography>
                <Typography variant="caption" style={{ marginTop: 20 }}>
                  This individual already has access to these groups
                </Typography>
                {employers
                  .filter((e) => e.has_relationship === true)
                  .map((employer, index) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 30 }}
                        key={index}
                      >
                        <Grid container item xs={12} sm={3} alignItems="center">
                          <Typography variant="body1">
                            {employer.name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={3}
                          justifycontent="center"
                          alignItems="center"
                        >
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={employer.permission_level}
                          >
                            <React.Fragment>
                              <FormControlLabel
                                value={-1}
                                control={<Radio color="primary" />}
                                label="None"
                                disabled={this.shouldRadioButtonBeDisabled(
                                  employer,
                                  -1
                                )}
                                id={"radio-group--1" + employer.id}
                                labelPlacement="top"
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                              />
                              <FormControlLabel
                                value={0}
                                id={"radio-group-0" + employer.id}
                                control={<Radio color="primary" />}
                                label="Notify"
                                //disabled={employer.relationship_level === 1}
                                disabled={this.shouldRadioButtonBeDisabled(
                                  employer,
                                  0
                                )}
                                labelPlacement="top"
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                              />
                              <FormControlLabel
                                value={1}
                                id={"radio-group-1" + employer.id}
                                control={<Radio color="primary" />}
                                label="View"
                                disabled={this.shouldRadioButtonBeDisabled(
                                  employer,
                                  1
                                )}
                                labelPlacement="top"
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                              />
                              <FormControlLabel
                                value={2}
                                id={"radio-group-2" + employer.id}
                                control={<Radio color="primary" />}
                                label="Update"
                                disabled={this.shouldRadioButtonBeDisabled(
                                  employer,
                                  2
                                )}
                                labelPlacement="top"
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                              />
                            </React.Fragment>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    );
                  })}
              </React.Fragment>
            )}
            {showNewRelationships && (
              <React.Fragment>
                <Typography variant="h6" style={{ marginTop: 20 }}>
                  New Permissions
                </Typography>
                <Typography variant="caption" style={{ marginTop: 20 }}>
                  This individual does not yet have access to these groups
                </Typography>

                {employers
                  .filter((e) => e.has_relationship === false)
                  .map((employer, index) => {
                    console.log(employer);
                    return (
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 30 }}
                        key={index}
                      >
                        <Grid container item xs={12} sm={3} alignItems="center">
                          <Typography variant="body1">
                            {employer.name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={3}
                          justifycontent="center"
                          alignItems="center"
                        >
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={employer.permission_level}
                          >
                            <React.Fragment>
                              <FormControlLabel
                                value={-1}
                                id={"radio-group-unassigned--1" + employer.id}
                                control={<Radio color="primary" />}
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                                label="None"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                value={0}
                                id={"radio-group-unassigned-0" + employer.id}
                                control={<Radio color="primary" />}
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                                label="Notify"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                value={1}
                                id={"radio-group-unassigned-1" + employer.id}
                                control={<Radio color="primary" />}
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                                disabled={employer.max_permission < 1}
                                label="View"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                value={2}
                                id={"radio-group-unassigned-2" + employer.id}
                                control={<Radio color="primary" />}
                                onClick={(e) => {
                                  this.changePermissionLevel(
                                    employer,
                                    e.target.value
                                  );
                                }}
                                disabled={employer.max_permission < 2}
                                label="Update"
                                labelPlacement="top"
                              />
                            </React.Fragment>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    );
                  })}
              </React.Fragment>
            )}
          </div>
        )}

        {showPermissions && permissionsLoaded && (
          <React.Fragment>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleCancel}
              style={{ width: "150px", marginRight: "20px", marginTop: "50px" }}
            >
              Cancel
            </Button>

            <Button
              disabled={this.isSubmitDisabled()}
              color="primary"
              variant="contained"
              onClick={this.handleSave}
              id="submit-new-team-member-button"
              style={{ width: "150px", marginTop: "50px" }}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AddTeamMemberContainer);
