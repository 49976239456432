import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";

import config from "../../config";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import { formatDateTime } from "../../Shared/Functions/Format";

import "../../Shared/Components/Signature/Styles/checkmark.css";
/*
const styles = theme => ({
  container: {
    width: 500,
    "@media only screen and (max-width: 600px)": {
      width: '100%'
    },
    height: 400,
  },
});
*/

const initialState = {};

class OpenTerminationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  handleSave = () => {};

  verifyTermination = (term) => {
    window.location.href =
      config.appLocation.URL +
      "/employer/termination-record?id=" +
      term.id +
      "&portalID=" +
      this.props.PortalID;
  };

  handleGoDashboard = () => {
    window.location.href =
      config.appLocation.URL + "/employer/home?portalID=" + this.props.PortalID;
  };

  render() {
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          {this.props.OpenList.length !== 0 && (
            <DialogTitle id="form-dialog-title">
              Open Termination Records
            </DialogTitle>
          )}
          <DialogContent className={classes.container}>
            {this.props.OpenList.length !== 0 && (
              <Typography variant="subtitle1">
              Record saved successfully!
              <br/>
              Please verify each of the following records as well:
              </Typography>
            )}
            <Grid item xs={12} md={12}>
              <div className={classes.demo}>
                <List>
                  {this.props.OpenList.map((term, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemText
                          primary={
                            term.employee_first + " " + term.employee_last
                          }
                          secondary={
                            "Added: " + formatDateTime(term.date_added)
                          }
                        />
                        <ListItemIcon>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.verifyTermination(term);
                            }}
                            className={"verify-term"}
                          >
                            Verify
                          </Button>
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </Grid>
            {this.props.OpenList.length === 0 && (
              <React.Fragment>
                <div style={{ height: 50, width: "100%", textAlign: "center" }}>
                  <Typography variant="subtitle1">
                    All records saved successfully! <br />
                    You may simply close this browser window or go to your dashboard.
                  </Typography>
                </div>
                <div className={classes.checkDone}>
                  <div className="checkmark">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 161.2 161.2"
                      enableBackground="new 0 0 161.2 161.2"
                    >
                      <path
                        className="path"
                        fill="none"
                        stroke="#7DB0D5"
                        strokeMiterlimit="10"
                        d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                      c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                      c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                      />
                      <circle
                        className="path"
                        fill="none"
                        stroke="#7DB0D5"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        cx="80.6"
                        cy="80.6"
                        r="62.1"
                      />
                      <polyline
                        className="path"
                        fill="none"
                        stroke="#7DB0D5"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="113,52.8 
                      74.1,108.4 48.2,86.4 "
                      />
                      <circle
                        className="spin"
                        fill="none"
                        stroke="#7DB0D5"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        strokeDasharray="12.2175,12.2175"
                        cx="80.6"
                        cy="80.6"
                        r="73.9"
                      />
                    </svg>
                    <Typography
                      variant="h4"
                      style={{ textAlign: "center", marginTop: 10 }}
                    >
                      All done!
                    </Typography>
                  </div>
                </div>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleGoDashboard} color="primary">
              Go To Dashboard
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(OpenTerminationDialog));
