export default {
  s3: {
    REGION: "us-east-2",
    BUCKET: process.env.REACT_APP_BUCKET, //"app.mewa.io",
    FILE_BUCKET: process.env.REACT_APP_FILE_BUCKET, //"store.mewa.io"
  },
  apiGateway: {
    REGION: "YOUR_API_GATEWAY_REGION",
    URL: process.env.REACT_APP_GATEWAY_URL,
  },
  appLocation: {
    URL: process.env.REACT_APP_URL 
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_0U6Jk6hCg",
    APP_CLIENT_ID: "5l3v96374hgvfrpjpmeb2k8fsm",
    IDENTITY_POOL_ID: "us-east-2:0f522ead-685f-4b56-b4e1-dbc4f988201c"
  }
};