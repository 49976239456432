import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import DocumentList from "../../Shared/Components/Documents/DocumentList";
import FolderList from "../../Shared/Components/Documents/FolderList";
import Agreement from "../../Shared/Components/Agreement/Agreement";

import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  portalID: "1",
  loaded: false,
  documents: [],
  folderDocuments: [],
  folders: [],
  showFolders: true,
  folderName: 'All',
};

class DocumentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    //when back button is clicked, if we're viewing the document list,
    // allow browser to pop off history but show folders
    window.addEventListener("popstate", this.backButtonListener);

    let portalID = this.getPortalID();
    this.setState({ portalID: portalID, loaded: true }, () => {
      this.handleGetDocuments();
    });
  }

  componentWillUnmount() {
    //removes event listener when document container is unmounted
    window.removeEventListener("popstate", this.backButtonListener);
  }

  backButtonListener = () => {
    if (!this.state.showFolder) this.setState({ showFolders: true });
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    //Check to make sure that hex value is provided to DocumentList
    let portalID = params.get("portalID") ? params.get("portalID") : null;
    if (!portalID) return portalID;
    return portalID.replace(new RegExp("-", "g"), "");
  };

  handleGetDocuments = () => {
    this.getDocuments().then((r) => {
      //At first folderDocuments will be ALL documents, and then filterFolder can filter the
      this.setState({documents: r.documents, folderDocuments: r.documents, folders: r.folders })
    }).catch(err =>{
      if (err === 403) {
        this.props.history.push("/employer/auth?error=5");
      }
      if (err === 400) {
        this.props.enqueueSnackbar("There was an issue generating document list", {
          variant: "error"
        });
      }

    });
  };

  getDocuments = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getDocumentsSecured(this.state.portalID))
      else
        resolve(api.getDocuments(this.state.portalID))
    });
  };

  filterFolder = (folder) => {
    this.setState({
      folderName: folder.name,
      folderDocuments: (folder.document_type === -1) ? this.state.documents :  this.state.documents.filter(d=>d.document_type === folder.document_type),
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Agreement Open={this.props.needTOS} Close={()=>{this.props.getEmployerInfo()}} portalID={this.props.portalID} />
        <div className={classes.containerRoot} >
        <div className={classes.containerTitle}>Document Center</div>          
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper
              className={classes.containerPaper}
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              {this.state.loaded && (
                <React.Fragment>
                  <FolderList filterFolder={this.filterFolder} folderName={this.state.folderName} folders={this.state.folders} />
                  <DocumentList
                    handleGetDocuments={this.handleGetDocuments}
                    documents={this.state.folderDocuments}
                    canDelete={false}
                    employerID={null}
                    portalID={this.getPortalID()}
                    height={300}
                    width={220}
                  />
                </React.Fragment>
              )}
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(DocumentsContainer);
