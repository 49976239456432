import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import api from "../../Shared/Functions/APIHelpers";
class AcceptEmployerInvite extends React.Component {
  state = {};

  componentDidMount() {
    this.handleApproveNewBroker();
  }
  getInvitationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("invitationID");
  };
  getResult = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("result");
  };

  handleApproveNewBroker = () => {
    this.approveNewBroker().then((r) => {
      console.log(r);
    });
  };

  approveNewBroker = () => {
    let result = this.getResult() === "accept" ? "Approve" : "Deny"

    return new Promise((resolve, reject) => {
      resolve(
        api.getApproveNewBroker(this.getInvitationID(), result)
      );
    });
  };

  render() {
    const accepted = this.getResult() === "accept" ? true : false;
    return (
      <React.Fragment>
        <Container maxWidth="sm" style={{ height:"50%", display: "flex", flexDirection: "column", justifyContent:"center", textAlign:"center"}}>
          {accepted && <Typography variant="h4">Invite Accepted</Typography>}
          {!accepted && <Typography variant="h4">Invite Denied</Typography>}
          <br />
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AcceptEmployerInvite);
