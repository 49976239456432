import React from "react";

import Typography from "@material-ui/core/Typography";
import Termination from "./Termination";
import api from "../../Shared/Functions/APIHelpers";
import OpenTerminationDialog from "./OpenTerminationDialog";
import PageHeader from "../../Shared/Nav/PageHeader";
import Agreement from "../../Shared/Components/Agreement/Agreement";
import VerifiedDialog from "./VerifiedDialog";

const initialState = {
  step: "info",
  terminationID: "",
  terminationDetail: {},
  portalID: "",
  isAdd: false,
  loaded: false,
  confirmAll: false,
  showHeader: false,
  nextText: "Save",
  openListModalOpen: false,
  verifiedDialog: false,
  openList: [],
};

class TerminationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  setStep = (step) => {
    this.setState({ step: step });
  };

  componentDidMount() {
    this.setState({ portalID: this.getPortalID() });

    let confirm = this.checkConfirmAll();

    let terminationID = this.terminationID();

    if (confirm) {
      this.handleUpdateTerminationCofirmAllAsync(terminationID).then(() => {
        this.handleGetTerminationAdditionalInfo(terminationID).then(
          (needsAction) => {
            this.setState({
              showHeader: needsAction,
              nextText: "Continue",
              step: "confirmAll",
            });
          }
        );
      });
    } else if (terminationID) {
      this.handleGetTerminationAsync(terminationID).then(() => {
        this.handleGetTerminationAdditionalInfo(terminationID).then(
          (needsAction) => {
            //no matter what if we get a term and it returns all data, show Save headere
            this.setState({ showHeader: true, nextText: "Save" });
          }
        );
      });
    } else {
      this.setState({ loaded: true, isAdd: true });
    }
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID") ? params.get("portalID") : null;
  };

  checkConfirmAll = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    let confirm = params.get("confirmAll") ? params.get("confirmAll") : false;

    if (confirm === true || confirm === "True" || confirm === "true") {
      return true;
    }

    return false;

    ///return (params.get("confirmAll")) ?  params.get("confirmAll") : false;
  };

  handleUpdateTerminationCofirmAllAsync = (terminationID) => {
    return new Promise((resolve, reject) => {
      this.updateTerminationConfirmAllAsync(terminationID)
        .then((res) => {
          console.log(res);
          this.setState(
            {
              terminationDetail: res,
              terminationID: res.id,
            },
            () => {
              resolve("done");
            }
          );
        })
        .catch((err) => {
          this.setState({ step: "complete", confirmAll: true });
          console.log(err);
        });
    });
  };

  handleGetTerminationAsync = (terminationID) => {
    return new Promise((resolve, reject) => {
      this.getTerminationAsync(terminationID)
        .then((res) => {
          if (res.date_sent_cobra_admin) {
            throw new Error("SENTCOBRA");
          }
          if (res.date_verified) this.showVerifiedDialog();
          this.setState(
            {
              terminationDetail: res,
              loaded: true,
              isAdd: false,
              terminationID: terminationID,
              nextText: "Save", //When editing a record this should say save
              showHeader: true,
            },
            () => {
              resolve("done");
            }
          );
        })
        .catch((err) => {
          if (err === 403) {
            //This would be a LOGGED IN USER with a portal ID that they AREN'T authorized to use
            this.props.history.push("/employer/auth?error=2");
          }
          if (err === 400) {
            //a 400 error means its verified so set the step to complete and set next text to CONTINUE
            // but remember you wont even show the next button if get addiontal info doesn't show any more open
            this.setState(
              {
                step: "complete",
                nextText: "Continue",
                terminationID: terminationID,
                openList: [], //blank these out
              },
              () => {
                this.handleGetTerminationAdditionalInfo(terminationID).then(
                  (needsAction) => {
                    this.setState({ showHeader: needsAction });
                  }
                );
              }
            );
            this.props.enqueueSnackbar("Record Already Verified", {
              variant: "error",
            });
          }
          if (err.message === "SENTCOBRA") {
            //a SENTCOBRA error means its sent to cobra so set the step to cobrasent and set next text to CONTINUE
            // but remember you wont even show the next button if get addiontal info doesn't show any more open
            this.setState(
              {
                step: "cobrasent",
                nextText: "Continue",
                terminationID: terminationID,
                openList: [], //blank these out
              },
              () => {
                this.handleGetTerminationAdditionalInfo(terminationID).then(
                  (needsAction) => {
                    this.setState({ showHeader: needsAction });
                  }
                );
              }
            );
            this.props.enqueueSnackbar("Record Already Sent", {
              variant: "error",
            });
          }
        });
    });
  };

  handleGetTerminationAdditionalInfo = (terminationID) => {
    return new Promise((resolve, reject) => {
      this.getTerminationAdditionalInfo(terminationID)
        .then((r) => {
          //if there is an open list we need to give the option to view it then resolve
          this.setState({
            openList: r.open_list,
          });
          var needsAction = r.open_list.length !== 0;
          resolve(needsAction);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  };

  handleNext = () => {
    if (this.state.step === "info") {
      this.updateTerminationMEWA();
    }

    if (this.state.step === "complete" || this.state.step === "confirmAll") {
      this.setState({ openListModalOpen: true });
    }
  };

  showVerifiedDialog = () => {
    this.setState({ verifiedDialog: true });
  };

  updateTerminationMEWA = (body) => {
    if (!this.hasError()) {
      this.getUpdateTerminationMEWA(this.getBody())
        .then((res) => {
          console.log(res);
          this.handleGetTerminationAdditionalInfo(res.id).then((r) => {
            this.setState({ openListModalOpen: true });
          });
        })
        .catch((err) => {
          if (err === 400) {
            this.handleGetTerminationAdditionalInfo(
              this.state.terminationDetail.id
            )
              .then((needsAction) => {
                this.setState({ openListModalOpen: true });
              })
              .catch((err) => {
                console.log(err);
              });
          }
          console.log(err);
        });
    } else {
      this.props.enqueueSnackbar("Please Correct All Errors", {
        variant: "error",
      });
    }
  };

  setCheckError = (func) => {
    this.hasError = func;
  };
  setGetBody = (func) => {
    this.getBody = func;
  };

  terminationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("id") ? params.get("id") : null;
  };

  getUpdateTerminationMEWA = (body) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(
          api.updateTerminationPortalSecured(
            this.state.terminationDetail.id,
            JSON.stringify(body)
          )
        );
      } else {
        resolve(
          api.updateTerminationPortal(
            this.state.terminationDetail.id,
            JSON.stringify(body)
          )
        );
      }
    });
  };
  getTerminationAsync = (id) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getTerminationPortalSecured(id));
      else resolve(api.getTerminationPortal(id));
    });
  };

  getTerminationAdditionalInfo = (id) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getTerminationPortalAdditionalInfoSecured(id));
      else resolve(api.getTerminationPortalAdditionalInfo(id));
    });
  };

  updateTerminationConfirmAllAsync = (id) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.updateTerminationConfirmAllPortalSecured(id));
      else resolve(api.updateTerminationConfirmAllPortal(id));
    });
  };

  setTerminationInfo = (termination, terminationID) => {
    this.setState({
      terminationDetail: termination,
      terminationID: terminationID,
      isAdd: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Agreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getEmployerInfo();
          }}
          portalID={this.props.portalID}
        />
        <VerifiedDialog
          Open={this.state.verifiedDialog}
          PortalID={this.props.portalID}
          Close={() => {
            this.setState({ verifiedDialog: false });
          }}
        />
        <OpenTerminationDialog
          enqueueSnackbar={this.props.enqueueSnackbar}
          Open={this.state.openListModalOpen}
          Close={() => {
            this.setState({ openListModalOpen: false });
          }}
          OpenList={this.state.openList}
          PortalID={this.props.portalID}
        />

        {this.state.showHeader && (
          <PageHeader
            hasNext={true}
            hasBack={false}
            pageTitle="Termination Record"
            backText="Back"
            nextText={this.state.nextText}
            next={this.handleNext}
            hideArrow={true}
          />
        )}
        {this.state.step === "info" && (
          <Termination
            employerIsSecured={this.props.employerIsSecured}
            portalID={this.props.portalID}
            terminationID={this.state.terminationID}
            key={this.state.terminationID}
            terminationDetail={this.state.terminationDetail}
            setTerminationInfo={this.setTerminationInfo}
            setStep={this.setStep}
            openList={this.state.openList}
            setCheckError={this.setCheckError}
            setGetBody={this.setGetBody}
            //this prop is used to override traditional open list flow in case of confirm all
            isAdd={this.state.isAdd}
            loaded={this.state.loaded}
          />
        )}
        {this.state.step === "cobrasent" && (
          <React.Fragment>
            <div style={{ textAlign: "center", marginTop: 50 }}>
              <Typography variant="h6">
                This record has already been sent to the COBRA provider.
              </Typography>
            </div>
          </React.Fragment>
        )}
        {this.state.step === "complete" && (
          <React.Fragment>
            <div style={{ textAlign: "center", marginTop: 50 }}>
              <Typography variant="h6">
                This record has already been verified or does not exist.
              </Typography>
            </div>
          </React.Fragment>
        )}

        {this.state.step === "confirmAll" && (
          <React.Fragment>
            <div style={{ textAlign: "center", marginTop: 50 }}>
              <Typography variant="h6">
                All records confirmed. Thank you!
              </Typography>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default TerminationContainer;
