import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Agreement from "../../Shared/Components/Agreement/Agreement";
import ProgressDialog from "./ProgressDialog";
import SupportingDocumentationList from "./SupportingDocumentationList";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import { DropzoneDialog } from "material-ui-dropzone";
import { formatDateTime } from "../../Shared/Functions/Format";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  portalID: "",
  loaded: false,
  continuationAllowed: false,
  showCancelContinuation: false,
  cancellationChangeRequest: "",
  currentStatus: 0,
  errors: [],
  lastChange: "",
  lastChangeRequested: "",
  showContinuationStatusInfo: false,
  totalNumberOfEmployees: "",

  reasonForRequest: "",
  uploadedFiles: [],
  supportingDialogOpen: false,
  progressDialogOpen: false,
  saveStatus: "Saving...",
  saveAmount: 0,

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class ConinuationStatusContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    let portalID = this.getPortalID();

    this.setState({ portalID: portalID }, () => {
      this.handleGetContinuationAllowed();
    });
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    //Check to make sure that hex value is provided to DocumentList
    let portalID = params.get("portalID") ? params.get("portalID") : null;
    if (!portalID) return portalID;
    return portalID.replace(new RegExp("-", "g"), "");
  };

  getConfirmationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("confirmationID");
  };

  handleSave = () => {
    if (this.inError()) return;

    this.setState({
      saveAmount: 10,
      saveStatus: "Uploading Documentation...",
      progressDialogOpen: true,
    });
    //First we need to get presigned URLS... this will create the change request object that we link up to
    this.handleGetPresignedURLs().then((r) => {
      this.setState({ saveAmount: 20, changeRequestID: r.change_request_id });
      //now we upload each file using the presigned URL & fetch
      this.uploadFiles(r.urls).then((r) => {
        this.updateChangeRequest().then((r) => {
          this.setState({ saveAmount: 100, saveStatus: "" });
        });
        //setTimeout(() => {  ;; }, 2000);
      });
    });
  };

  handleGetPresignedURLs = () => {
    var body = JSON.stringify({
      requested_presigned_urls: this.state.uploadedFiles.map((f) => f.name),
    });

    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.getSupportingDocumentationPresignedSecured(
            this.props.portalID,
            body
          )
        );
      else
        resolve(
          api.getSupportingDocumentationPresigned(this.props.portalID, body)
        );
    });
  };

  uploadFiles = (s3Files) => {
    let progressIncrement = Math.floor(60 / s3Files.length);
    return new Promise((resolve, reject) => {
      let uploadJobs = [];
      //For each presigned url, we need to find the file in state and upload it
      s3Files.forEach((f) => {
        uploadJobs.push(
          new Promise((resolve, reject) => {
            var s3Fields = f.fields;
            const formData = new FormData();

            Object.keys(s3Fields).forEach((key) => {
              formData.append(key, s3Fields[key]);
            });
            formData.append(
              "file",
              this.state.uploadedFiles.find(
                (file) => file.name === f["frontend_name"]
              )
            );
            fetch(f.url, {
              method: "POST",
              body: formData,
            })
              .then((r) => {
                this.setState(
                  { saveAmount: this.state.saveAmount + progressIncrement },
                  () => {
                    resolve(r);
                  }
                );
              })
              .catch((e) => {
                console.error(e);
              });
          })
        );
      });

      Promise.all(uploadJobs).then((values) => {
        this.setState({
          saveAmount: 90,
          saveStatus: "Saving Data...",
        });
        resolve("done");
      });
    });
  };

  updateChangeRequest = () => {
    var body = JSON.stringify({
      full_time_employees: this.state.totalNumberOfEmployees,
      reason: this.state.reasonForRequest,
      supporting_documentation: this.state.uploadedFiles.map((f) => f.name),
      new_continuation_status: this.state.currentStatus === 0 ? 1 : 0,
    });

    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.updateChangeRequestSecured(this.state.changeRequestID, body)
        );
      else resolve(api.updateChangeRequest(this.state.changeRequestID, body));
    });
  };

  handleGetContinuationAllowed = () => {
    this.getContinuationAllowed()
      .then((r) => {
        //At first folderDocuments will be ALL documents, and then filterFolder can filter the
        this.setState({
          continuationAllowed: r.continuation_allowed,
          currentStatus: r.current_status,
          lastChange: r.last_change,
          lastChangeRequested: r.last_change_requested,
          lastChangeRequestStatus: r.last_change_request_status,
          showCancelContinuation: r.cancel_continuation_allowed,
          cancellationChangeRequest: r.cancellation_change_request,
          loaded: true,
        });
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/employer/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "There was an issue generating document list",
            {
              variant: "error",
            }
          );
        }
      });
  };

  getContinuationAllowed = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(
          api.getContinuationAllowedSecured(
            this.state.portalID,
            this.getConfirmationID()
          )
        );
      else
        resolve(
          api.getContinuationAllowed(
            this.state.portalID,
            this.getConfirmationID()
          )
        );
    });
  };

  handleCancelChangeRequest = () => {
    this.setState({
      confirmAlertOpen: true,
      confirmAlertTitle: "Cancel Change Request",
      confirmAlertText:
        "Are you sure you wish to cancel your pending continuation change request?",
      doNotFunc: () => {
        this.setState({ confirmAlertOpen: false });
      },
      doFunc: this.cancelChangeRequest,
    });
  };

  cancelChangeRequest = () => {
    if (this.props.employerIsSecured) {
      api
        .cancelChangeRequestSecured(this.state.cancellationChangeRequest)
        .then((r) => {
          window.location.reload();
        });
    } else {
      api
        .cancelChangeRequest(this.state.cancellationChangeRequest)
        .then((r) => {
          window.location.reload();
        });
    }
  };

  addUploadedFiles = (files) => {
    let currentFiles = this.state.uploadedFiles;
    files.forEach((f) => {
      currentFiles.push(f);
    });
    this.setState(
      { uploadedFiles: currentFiles, supportingDialogOpen: false },
      () => {
        setTimeout(() => {
          this.props.scrollToBottom();
        }, 350);
      }
    );
  };

  removeFile = (file) => {
    let currentFiles = this.state.uploadedFiles;
    currentFiles = currentFiles.filter((f) => f.name !== file.name);
    this.setState({ uploadedFiles: currentFiles });
  };

  inError = () => {
    let errors = this.state.errors;
    if (this.state.reasonForRequest === "") {
      errors.push("reasonForRequest");
    } else {
      errors = errors.filter((e) => e !== "reasonForRequest");
    }

    //if valued entered is not a number (blank string) return an error
    if (this.state.totalNumberOfEmployees.trim() === "") {
      errors.push("totalNumberOfEmployees");
    } else {
      errors = errors.filter((e) => e !== "totalNumberOfEmployees");
    }

    if (this.state.uploadedFiles.length === 0) {
      errors.push("uploadedFiles");
    } else {
      errors = errors.filter((e) => e !== "uploadedFiles");
    }

    this.setState({ errors: errors });
    return errors.length > 0;
  };

  checkEmployeeNumber = (e) => {
    let errors = [];
    //if state, need 20+ ee to become cobra
    if (this.state.currentStatus === 0 && e.target.value < 20)
      errors.push("totalNumberOfEmployeesInvalidCobra");

    //if  cobra, need 19 or fewer to become state
    if (this.state.currentStatus === 1 && e.target.value > 19)
      errors.push("totalNumberOfEmployeesInvalidState");

    this.setState({ errors: errors });
  };

  render() {
    const { classes } = this.props;
    console.log(this.props);
    return (
      <React.Fragment>
        <Agreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getEmployerInfo();
          }}
          portalID={this.props.portalID}
        />
        <ProgressDialog
          Open={this.state.progressDialogOpen}
          Close={() => {
            this.setState({ progressDialogOpen: false });
          }}
          progress={this.state.saveAmount}
          saveStatus={this.state.saveStatus}
          PortalID={this.props.portalID}
        />
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        <DropzoneDialog
          open={this.state.supportingDialogOpen}
          onSave={this.addUploadedFiles}
          acceptedFiles={[".pdf", ".doc", "image/bmp"]}
          maxFileSize={7000000}
          showAlerts={["error"]}
          previewText={"Selected Files: "}
          onClose={() => {
            this.setState({ supportingDialogOpen: false });
          }}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: "row" } }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          filesLimit={10}
        />
        <div
          className={classes.title}
          style={{ color: this.props.theme.header_text_color }}
        >
          <Typography variant="h4" id="continuationStatusTitle">
            Continuation Status
          </Typography>
        </div>

        {this.state.loaded && (
          <React.Fragment>
            {!this.props.navOptions.hasContinuation && (
              <React.Fragment>
                <div style={{ marginTop: 40, maxWidth: 700 }}>
                  <Typography variant="h5" id="notEnabledBody">
                    This feature is not currently enabled
                  </Typography>
                </div>
              </React.Fragment>
            )}
            {this.props.navOptions.hasContinuation && (
              <React.Fragment>
                <div style={{ marginTop: 40, maxWidth: 700 }}>
                  <Typography variant="body2" id="statusBody">
                    Continuation status determines whether continuation of
                    coverage options will be based on State Continuation or
                    COBRA rules. Employers with 20 or more employees are subject
                    to COBRA. This status may only be changed one time during
                    each calendar plan year
                  </Typography>
                </div>

                <div style={{ marginTop: 40, maxWidth: 700 }}>
                  <Typography variant="body2" id="currentStatus">
                    Current Status
                  </Typography>
                  <Typography variant="h5" id="currentStatus">
                    {this.state.currentStatus ? "COBRA" : "State Continuation"}
                  </Typography>
                </div>

                <div style={{ marginTop: 40, maxWidth: 700 }}>
                  <Typography variant="body2" id="lastChangeText">
                    Last Change:{" "}
                    {this.state.lastChange
                      ? formatDateTime(this.state.lastChange)
                      : ""}
                  </Typography>
                  <Typography variant="body2" id="lastChangeRequestedText">
                    Last Change Requested:{" "}
                    {this.state.lastChangeRequested
                      ? formatDateTime(this.state.lastChangeRequested)
                      : ""}
                  </Typography>
                  <Typography variant="body2" id="lastChangeRequestStatusText">
                    Last Request Status:{" "}
                    {this.state.lastChangeRequestStatus
                      ? this.state.lastChangeRequestStatus
                      : ""}
                  </Typography>
                </div>
                {this.state.showCancelContinuation && (
                  <div style={{ marginLeft: -7, marginTop: 40, maxWidth: 700 }}>
                    <Button
                      color="primary"
                      onClick={this.handleCancelChangeRequest}
                      variant="contained"
                    >
                      Cancel Continuation Status
                    </Button>
                  </div>
                )}

                {!this.state.showContinuationStatusInfo &&
                  !this.state.showCancelContinuation && (
                    <div
                      style={{ marginLeft: -7, marginTop: 40, maxWidth: 500 }}
                    >
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({ showContinuationStatusInfo: true });
                        }}
                        id="change-continuation-status-button"
                        variant="contained"
                        disabled={!this.state.continuationAllowed}
                      >
                        Change Continuation Status
                      </Button>
                    </div>
                  )}
                {!this.state.continuationAllowed &&
                  !this.state.showCancelContinuation && (
                    <div
                      style={{ marginLeft: -7, marginTop: 40, maxWidth: 500 }}
                    >
                      <Typography
                        variant="caption"
                        id="continuationWarning"
                        style={{ marginTop: 20 }}
                      >
                        Continuation status cannot be changed more than one time
                        per calendar plan year
                      </Typography>
                    </div>
                  )}
                {this.state.showContinuationStatusInfo &&
                  !this.state.showCancelContinuation && (
                    <div style={{ marginTop: 40, maxWidth: 700 }}>
                      <Typography
                        variant="caption"
                        id="currentStatus"
                        style={{ fontWeight: "bold" }}
                      >
                        Change Continuation Status to{" "}
                        {this.state.currentStatus ? "State" : "COBRA"}
                      </Typography>
                    </div>
                  )}

                {this.state.showContinuationStatusInfo && (
                  <React.Fragment>
                    <div
                      style={{ maxWidth: 600, marginBottom: 40, marginTop: 40 }}
                    >
                      <TextField
                        id="total-number-employees-text"
                        label="Total number of Employees"
                        margin="dense"
                        error={
                          this.state.errors.includes(
                            "totalNumberOfEmployees"
                          ) ||
                          this.state.errors.includes(
                            "totalNumberOfEmployeesInvalidState"
                          ) ||
                          this.state.errors.includes(
                            "totalNumberOfEmployeesInvalidCobra"
                          )
                        }
                        helperText={
                          (this.state.errors.includes("totalNumberOfEmployees")
                            ? "Required"
                            : "") ||
                          (this.state.errors.includes(
                            "totalNumberOfEmployeesInvalidState"
                          )
                            ? "Please enter 19 or fewer employees."
                            : "") ||
                          (this.state.errors.includes(
                            "totalNumberOfEmployeesInvalidCobra"
                          )
                            ? "Please enter 20 or more employees"
                            : "")
                        }
                        onBlur={this.checkEmployeeNumber}
                        onChange={(e) =>
                          this.setState({
                            totalNumberOfEmployees: e.target.value,
                          })
                        }
                        value={this.state.totalNumberOfEmployees}
                        fullWidth
                      />
                      <Typography
                        style={{ marginBottom: 10 }}
                        variant="caption"
                        id="totalNumberOfEmployeesDefinition"
                      >
                        Please count both full-time and part-time employees that
                        worked 50% of typical working business days from the
                        previous calendar year. Each part-time employee counts
                        as a fraction of a full-time employee, with the fraction
                        equal to the number of hours the part-time employee
                        worked divided by the hours an employee must work to be
                        considered full-time and eligible for benefits.
                      </Typography>
                      <TextField
                        id="reason-request-text"
                        label="Reason for requesting Change"
                        margin="dense"
                        error={this.state.errors.includes("reasonForRequest")}
                        helperText={
                          this.state.errors.includes("reasonForRequest")
                            ? "Required"
                            : ""
                        }
                        onChange={(e) =>
                          this.setState({ reasonForRequest: e.target.value })
                        }
                        value={this.state.reasonForRequest}
                        fullWidth
                      />
                    </div>
                    <div style={{ maxWidth: 700 }}>
                      <Typography
                        variant="h5"
                        id="supportingDocumentationHeader"
                      >
                        Supporting Documentation
                      </Typography>
                      <Typography
                        style={{ marginTop: 10 }}
                        variant="body2"
                        id="supportingDocumentationDefinition"
                      >
                        Please provide supporting documentation for this change.
                        This may include W2's, Wage & Tax Statements or other
                        tax forms
                      </Typography>
                    </div>

                    <div
                      style={{ marginLeft: -7, marginTop: 10, maxWidth: 700 }}
                    >
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({ supportingDialogOpen: true });
                        }}
                      >
                        Upload Form(s)
                      </Button>
                    </div>

                    {this.state.errors.includes("uploadedFiles") && (
                      <Typography
                        variant="caption"
                        id="uploadedFilesError"
                        style={{ color: this.props.theme.palette.error.main }}
                      >
                        Please Upload At Least 1 Supporting Document
                      </Typography>
                    )}

                    {this.state.uploadedFiles.length > 0 && (
                      <SupportingDocumentationList
                        uploadedFiles={this.state.uploadedFiles}
                        removeFile={this.removeFile}
                      />
                    )}

                    <div style={{ textAlign: "right" }}>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({ showContinuationStatusInfo: false });
                        }}
                        variant="contained"
                        style={{ marginRight: 30 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        onClick={this.handleSave}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <div
              id="bottomRef"
              ref="bottomRef"
              style={{
                height: 0,
                marginTop: 0,
                backgroundColor: "red",
                display: "block",
              }}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ConinuationStatusContainer);
