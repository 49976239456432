import React from "react";
import { withStyles } from "@material-ui/core/styles";
//import classes from "*.module.css";

const styles = (theme) => ({
  containerRoot: {
    width: '100%', 
    minHeight: '100vh',
    fontFamily: "arial, helvetica, sans-serif",
    backgroundColor: "#F7F7F7",
    color: "#505050",
  },

  container: {
    height: "70%",
    paddingTop:35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  content: {
    width: 350,
    textAlign: "center",
  },
});

const ErrorScreen = (props) => {
  const { classes } = props;

  return (
    <div className={classes.containerRoot}>
      <div className={classes.container}>
        <div className={classes.content} style={{ marginBottom: 10 }}>
          <span style={{ fontSize: "96px" }}>404</span>
        </div>
        <div className={classes.content} style={{ marginBottom:50 }}>
          <span style={{ fontSize: "24px" }}>Page not found</span>
        </div>
        <div className={classes.content} style={{ padding: 50 }}>
          <span style={{ fontSize: "14px"}}>
            The page you are looking for does not exist or another error
            occurred. Please go back and try another page.
          </span>
        </div>
        <div className={classes.content}>
          <img src="https://s3.us-east-2.amazonaws.com/static.mewa.io/logos/mewaio_logo_horiz-200x48.png" />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ErrorScreen);
