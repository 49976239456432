import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import InfoIcon from "@material-ui/icons/Info";
import NoteIcon from "@material-ui/icons/Note";
import NewTable from "../../Shared/Components/Table/NewTable";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";

import {
  dateCellDataGetterDate,
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  nestedSort,
} from "../../Shared/Components/Table/TableUtilities";

import MedicareSubmissionViewFormDialog from "./MedicareSubmissionViewFormDialog";
import MedicareSubmissionDetail from "./MedicareSubmissionDetail";
import MedicareSubmissionNotes from "./MedicareSubmissionNotes";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  detailDialogOpen: false,
  noteDialogOpen: false,
  viewFormDialogOpen: false,
  submissionURL: "",
  medicareDetail: {},

  //FOR TABLE
  tableData: [],
  dataURL: "medicare-submission-mewa",
  staticFilterURL: "medicare-submission-mewa-static-filters",
  staticFilters: ["submission_status_text"],
  staticFilterValues: [],
  sortBy: "",
  sortDirection: "ASC",
  countURL: "medicare-submission-mewa-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class MedicareSubmissionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState({ initialLoadDone: false, fetching: true }, () => {
      this.getStaticFilterValues().then((values) => {
        this.setState({ staticFilterValues: values }, () => {
          this.getTableCount().then((count) => {
            this.getTableData(0, 10)
              .then((r) => {
                this.setState({
                  tableData: r,
                  recordCount: count["record_count"],
                  fetching: false,
                  initialLoadDone: true,
                });
                if (isInitial)
                  this.setState({
                    initialCount: count["record_count"],
                    currentCount: count["record_count"],
                  });
                else this.setState({ currentCount: count["record_count"] });
              })
              .catch((err) => {
                if (err === 403) {
                  this.props.history.push("/mewa-admin/auth?error=5");
                }
                if (err === 400) {
                  this.props.enqueueSnackbar(
                    "Something went wrong rendering medicare",
                    {
                      variant: "error",
                    }
                  );
                }
              });
          });
        });
      });
    });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering medicare",
                {
                  variant: "error",
                }
              );
            }
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            ""
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    if (col.nestedCol) sortBy = col.nestedCol;
    this.setState({ sortBy: sortBy, sortDirection: sortDirection }, () => {
      this.handleInitialData();
    });
  };

  handleViewSubmission = (selectedItem, closeMenu) => {
    this.setState({
      menuOpen: false,
    });
    this.getPresignedSubmissionURL(selectedItem.id).then((r) => {
      //Open view document dialog and pass it the recieved presigned url to render
      this.setState({
        viewFormDialogOpen: true,
        submissionURL: r.download_url,
      });
    });
  };

  getPresignedSubmissionURL = (medicareSubmissionID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getMedicareSubmissionMEWAPresigned(medicareSubmissionID));
    });
  };

  handleGetSubmissionDetails = (medicareDetail, closeMenu) => {
    this.setState({ detailDialogOpen: true, medicareDetail: medicareDetail });
    closeMenu();
  };

  handleGetSubmissionNotes = (medicareDetail, closeMenu) => {
    this.setState({ noteDialogOpen: true, medicareDetail: medicareDetail });
    closeMenu();
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;

    return (
      <div>
        <MenuItem
          onClick={() => {
            this.handleGetSubmissionDetails(selectedItem, closeMenu);
          }}
        >
          <InfoIcon className={classes.leftIcon} />
          Info
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.handleGetSubmissionNotes(selectedItem, closeMenu);
          }}
        >
          <NoteIcon className={classes.leftIcon} />
          Status & Notes
        </MenuItem>
        <MenuItem
          name="downloadMedicareSubmissionMenuItem"
          onClick={() => {
            this.handleViewSubmission(selectedItem, closeMenu);
          }}
        >
          <DownloadIcon className={classes.leftIcon} />
          View Submission
        </MenuItem>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        {this.state.viewFormDialogOpen && (
          <MedicareSubmissionViewFormDialog
            Open={this.state.viewFormDialogOpen}
            Close={() => {
              this.setState({ viewFormDialogOpen: false, medicareDetail: {} });
            }}
            SubmissionURL={this.state.submissionURL}
          />
        )}
        <MedicareSubmissionDetail
          Open={this.state.detailDialogOpen}
          Close={() => {
            this.setState({ detailDialogOpen: false, medicareDetail: {} });
          }}
          RefreshTable={this.handleInitialData}
          Submission={this.state.medicareDetail}
          enqueueSnackbar={this.props.enqueueSnackbar}
          theme={this.props.theme}
        />

        <MedicareSubmissionNotes
          Open={this.state.noteDialogOpen}
          Close={() => {
            this.setState({ noteDialogOpen: false, medicareDetail: {} });
          }}
          RefreshTable={this.handleInitialData}
          Submission={this.state.medicareDetail}
          enqueueSnackbar={this.props.enqueueSnackbar}
          theme={this.props.theme}
        />

        <div className={classes.containerRoot}>
        <div className={classes.containerTitle}>Medicare Submissions</div>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper className={classes.containerPaper}>
              <NewTable
                tableName={"Medicare Submissions"}
                enqueueSnackbar={this.props.enqueueSnackbar}
                loadedData={this.state.tableData}
                loaded={this.state.initialLoadDone}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                setActiveFilters={this.setActiveFilters}
                staticFilterValues={this.state.staticFilterValues}
                activeFilters={this.state.activeFilters}
                columns={[
                  {
                    dataKey: "date_sent",
                    label: "Date Sent",
                    width: 50,
                    flexGrow: 2,
                    flexShrink: 1,
                    hasFilter: false,
                    cellRenderer: dateCellDataGetterDate,
                  },
                  {
                    dataKey: "employer_list",
                    label: "Employers",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: false,
                  },                  
                  {
                    dataKey: "form_count",
                    label: "Forms",
                    width: 100,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    dataKey: "submission_status_text",
                    label: "Status",
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                    filterType: "Static",
                  },
                  {
                    dataKey: "awaiting_response",
                    label: "Awaiting Response",
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(MedicareSubmissionContainer);
