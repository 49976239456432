import React from "react";

import { withStyles } from "@material-ui/core/styles";
//import { styles } from "../../Shared/Styles/ContainerStyles";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import { Auth } from "aws-amplify";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import Container from "@material-ui/core/Container";
import Agreement from "../../Shared/Components/Agreement/Agreement";

import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";
import { getStates } from "../../Shared/Functions/Format";
import EmployerInviterBrokerDialog from "./EmployerInviteBrokerDialog";
import ManageBrokerPermissionsDialog from "./ManageBrokerPermissionsDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  brokerBox: {
    display: "flex",
    marginTop: 25,
    "@media only screen and (max-width: 600px)": {
      display: "block",
    },
    paddingBottom: 25,
    borderBottom: "1px solid #f2f2f2",
  },
  brokerBoxLeft: {
    width: "80%",
  },
  brokerBoxRight: {
    width: "30%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
    display: "flex",
    justifyContent: "flex-end",
  },
});
class ManageBrokerContainer extends React.Component {
  state = {
    brokers: [],
    inviteBrokerDialogOpen: false,
    manageBrokerPermissionDialogOpen: false,
    selectedBroker: {},

    /*state for confirm alert */
    confirmAlertTitle: "",
    confirmAlertText: "",
    confirmAlertOpen: false,
    doFunc: null,
    doNotFunc: null,
  };

  componentDidMount() {
    this.handleGetBrokerList();
  }

  handleGetBrokerList = () => {
    this.getBrokerList().then((r) => {
      this.setState({
        brokers: r.relationships.sort((a, b) =>
          a.first_name.localeCompare(b.first_name)
        ),
      });
    });
  };

  getBrokerList = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getEmployerBrokerListSecured(this.props.portalID));
      else resolve(api.getEmployerBrokerList(this.props.portalID));
    });
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };

  getPermissionLevel = (level) => {
    switch (level) {
      case 0:
        return "Notify";
      case 1:
        return "View";
      case 2:
        return "Update";
      default:
        return "Error";
    }
  };

  handleDeleteBroker = (broker) => {
    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      selectedBroker: broker,
      confirmAlertTitle: "Delete Access",
      confirmAlertText:
        "Are you sure you would like to delete " + broker.first_name + " " + broker.last_name + "'s access to your account?",
      doNotFunc: this.doNotDeleteBroker,
      doFunc: this.doDeleteBroker,
    });
  };

  doDeleteBroker = () => {
    this.deleteBrokerRelationship(
      this.state.selectedBroker.relationship_id
    ).then((r) => {
      this.setState(
        {
          confirmAlertOpen: false,
          selectedBroker: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        },
        () => {
          this.handleGetBrokerList();
        }
      );
      this.showMessage("Broker relationship deleted sucessfully", "success");
    });
  };
  doNotDeleteBroker = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedBroker: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  deleteBrokerRelationship = (relationshipID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.deleteEmployerBrokerRelationshipSecured(relationshipID));
      else resolve(api.deleteEmployerBrokerRelationship(relationshipID));
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Agreement Open={this.props.needTOS} Close={()=>{this.props.getEmployerInfo()}} portalID={this.props.portalID} />
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        {this.state.inviteBrokerDialogOpen && (
          <EmployerInviterBrokerDialog
            Open={this.state.inviteBrokerDialogOpen}
            Close={() => {
              this.setState({ inviteBrokerDialogOpen: false });
            }}
            employerIsSecured={this.props.employerIsSecured}
            enqueueSnackbar={this.props.enqueueSnackbar}
            portalID={this.props.portalID}
          />
        )}
        {this.state.manageBrokerPermissionDialogOpen && (
          <ManageBrokerPermissionsDialog
            Open={this.state.manageBrokerPermissionDialogOpen}
            Close={() => {
              this.setState(
                { manageBrokerPermissionDialogOpen: false, selectedBroker: {} },
                () => {
                  this.handleGetBrokerList();
                }
              );
            }}
            Broker={this.state.selectedBroker}
            employerIsSecured={this.props.employerIsSecured}
            enqueueSnackbar={this.props.enqueueSnackbar}
            portalID={this.props.portalID}
          />
        )}
        <Typography variant="h4">Manage Brokers</Typography>
        <Typography
          variant="body1"
          style={{ marginTop: "15px", marginBottom: "25px" }}
        >
          The following brokers have access to your data in Mewa.io. Please note
          that some of these individuals may have been invited by another
          broker. For instance, you may have invited your broker and they gave
          permission to their team members to help manage the account.
        </Typography>



        {this.state.brokers.sort((a, b) => a.last_name > b.last_name ? 1 : -1)
          .map((broker, index) => {
            return (
              <React.Fragment>
              <Grid container spacing={2} style={{ marginTop: 30 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">
                    {broker.first_name} {broker.last_name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {broker.email}
                  </Typography>
                  <Typography variant="subtitle1">
                    Permission Level: {this.getPermissionLevel(broker.permission_level)}
                  </Typography>                      
                </Grid>
                <Grid container item xs={12} sm={6} justifyContent="flex-start" alignItems="center">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.handleDeleteBroker(broker);
                    }}
                    style={{ width: "100px", marginRight:20 }}
                  >
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.setState({
                        manageBrokerPermissionDialogOpen: true,
                        selectedBroker: broker,
                      });
                    }}
                    style={{ width: "200px" }}
                  >
                    Manage Permissions
                  </Button>
                </Grid>
              </Grid>
              </React.Fragment>
            );
          })}








          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              this.setState({ inviteBrokerDialogOpen: true });
            }}
            id='employer-invite-broker-button'
            style={{ width: "200px", marginTop: "50px" }}
          >
            Add Broker
          </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ManageBrokerContainer);
