import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ModalStyles";

const initialState = {};

class ArcExemptyEntityHelpDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  openMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Exempt Entity Help</DialogTitle>

          <DialogContent className={classes.container}>
            <div className={classes.definition}>
              <b>Sole Proprietor</b>
              <br />A sole proprietor is person who owns an unincorporated
              business by themselves and reports business activity on their Form
              1040 Schedule C. Sole proprietors are not typically subject to the
              Employee Retirement Security Act of 1974 (ERISA). Single-member
              entities can be different from sole proprietorships. If a business
              has registered with the state in which it operates and the
              business name includes an LLC or Inc, it is considered a filing
              group, even if it has no other employees.
            </div>

            <div className={classes.definition}>
              <b>Government Entity</b>
              <br />
              An employer benefit plan sponsored directly by a state or local
              government (e.g., city, county, township or school district) is
              not typically subject to the Employee Retirement Security Act of
              1974 (ERISA).
            </div>

            <div className={classes.definition}>
              <b>Church</b>
              <br />
              An employer benefit plan established or maintained by a church or
              by a convention or association of churches (includes plans
              sponsored by tax-exempt, church affiliated organizations (e.g.,
              schools and hospitals) is not typically subject to the Employee
              Retirement Security Act of 1974 (ERISA).
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(ArcExemptyEntityHelpDialog)
);
