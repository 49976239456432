import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import MenuItem from "@material-ui/core/MenuItem";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Paper from "@material-ui/core/Paper";
import CompletedForm from "./CompletedForm";
import MedicareDetail from "./MedicareDetail";
import MedicareViewFormDialog from "./MedicareViewFormDialog";
import Agreement from "../../Shared/Components/Agreement/Agreement";
import NewTable from "../../Shared/Components/Table/NewTable";
import MedicareViewAcceptanceLetterDialog from "../../Shared/Components/Medicare/MedicareViewAcceptanceLetterDialog";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import DetailsIcon from "@material-ui/icons/Info";

import CompleteIcon from "@material-ui/icons/AssignmentTurnedIn";

import {
  dateCellDataGetterDate,
  employerStatusDataGetter,
  getURLFromFilters,
} from "../../Shared/Components/Table/TableUtilities";
import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";

const initialState = {
  portalID: "",
  viewMedicareFormDialogOpen: false,
  viewMedicareAcceptanceLetterDialogOpen: false,
  medicareAcceptanceLetterPresignedURL: "",
  medicareDetail: {},
  detailDialogOpen: false,

  //FOR TABLE
  tableData: [],
  dataURL: "medicare-portal",
  sortBy: "",
  sortDirection: "ASC",
  activeFilters: [],
  loaded: false,
  closeMenu: null,
};

class MedicareContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleGetData();
  }

  handleGetData = () => {
    this.setState({ loaded: false }, () => {
      this.getTableData()
        .then((r) => {
          this.setState({ tableData: r, loaded: true });
        })
        .catch((err) => {
          if (err === 403) {
            //This would be a LOGGED IN USER with a portal ID that they AREN'T authorized to use
            this.props.history.push("/employer/auth?error=2");
          }
        });
    });
  };

  getTableData = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(
          api.getTableDataPortalSecured(
            getURLFromFilters(
              this.state.activeFilters,
              this.state.dataURL + "?portalID=" + this.props.portalID,
              this.state.sortBy,
              this.state.sortDirection
            )
          )
        );
      } else {
        resolve(
          api.getTableDataPortal(
            getURLFromFilters(
              this.state.activeFilters,
              this.state.dataURL + "?portalID=" + this.props.portalID,
              this.state.sortBy,
              this.state.sortDirection
            )
          )
        );
      }
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleGetData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    if (col.nestedCol) sortBy = col.nestedCol;
    this.setState({ sortBy: sortBy, sortDirection: sortDirection }, () => {
      this.handleGetData();
    });
  };

  handleViewCompletedForm = (selectedItem, closeMenu) => {
    //this.viewForm(selectedItem);
    this.setState({
      viewMedicareFormDialogOpen: true,
      medicareDetail: selectedItem,
    });
    closeMenu();
  };

  handleViewAcceptanceLetter = (selectedItem, closeMenu) => {
    closeMenu();
    this.getPresignedAcceptanceLetterURL(selectedItem).then((r) => {
      this.setState({
        viewMedicareAcceptanceLetterDialogOpen: true,
        medicareAcceptanceLetterPresignedURL: r.presigned_url,
        medicareDetail: selectedItem,
      });
    });
  };

  getPresignedAcceptanceLetterURL = (medicareRecord) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(
          api.getMedicareSubmissionAcceptancePresignedURLPortalSecured(
            medicareRecord.id
          )
        );
      } else {
        resolve(
          api.getMedicareSubmissionAcceptancePresignedURLPortal(
            medicareRecord.id
          )
        );
      }
    });
  };

  handleTrackMedicareForm = (selectedItem, closeMenu) => {
    this.setState({ detailDialogOpen: true, medicareDetail: selectedItem });
    closeMenu();
  };

  handleCompleteRecord = (selectedItem, closeMenu) => {
    window.location.href =
      config.appLocation.URL +
      "/employer/medicare-record?id=" +
      selectedItem.id +
      "&portalID=" +
      this.props.portalID;
    closeMenu();
  };

  handleRefreshChildTable = () => {
    this.refreshChildTable();
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;

    return (
      <div>

        <MenuItem
          onClick={() => {
            this.handleTrackMedicareForm(selectedItem, closeMenu);
          }}
        >
          <DetailsIcon className={classes.leftIcon} />
          View Details
        </MenuItem>

        <MenuItem
          disabled = {selectedItem.status === "Signed"}
          onClick={() => {
            this.handleCompleteRecord(selectedItem, closeMenu);
          }}
        >
          <CompleteIcon className={classes.leftIcon} />
          Complete Request
        </MenuItem>

        <MenuItem
          disabled = {selectedItem.status !== "Signed"}
            onClick={() => {
              this.handleViewCompletedForm(selectedItem, closeMenu);
            }}
          >
          < DownloadIcon className={classes.leftIcon} />  
            View Completed Form
          </MenuItem>

        <MenuItem
          disabled = {!selectedItem.acceptance_file }
          onClick={() => {
            this.handleViewAcceptanceLetter(selectedItem, closeMenu);
          }}
        >
          <DownloadIcon className={classes.leftIcon} />
          View CMS Acceptance Letter
        </MenuItem>



      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Agreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getEmployerInfo();
          }}
          portalID={this.props.portalID}
        />
        {this.state.viewMedicareAcceptanceLetterDialogOpen && (
          <MedicareViewAcceptanceLetterDialog
            PresignedAcceptanceURL={
              this.state.medicareAcceptanceLetterPresignedURL
            }
            Open={this.state.viewMedicareAcceptanceLetterDialogOpen}
            Close={() => {
              this.setState({
                viewMedicareAcceptanceLetterDialogOpen: false,
                medicareDetail: {},
                medicareAcceptanceLetterPresignedURL: "",
              });
            }}
          />
        )}
        <MedicareDetail
          SelectedRecord={this.state.medicareDetail}
          Open={this.state.detailDialogOpen}
          Close={() => {
            this.setState({ detailDialogOpen: false, medicareDetail: {} });
          }}
          enqueueSnackbar={this.props.enqueueSnackbar}
          history={this.props.history}
          employerIsSecured={this.props.employerIsSecured}
        />
        {this.state.viewMedicareFormDialogOpen && (
          <MedicareViewFormDialog
            Open={this.state.viewMedicareFormDialogOpen}
            Close={() => {
              this.setState({
                viewMedicareFormDialogOpen: false,
                medicareDetail: {},
              });
            }}
            SelectedRecord={this.state.medicareDetail}
          />
        )}
        <div className={classes.containerRoot}>
        <div className={classes.containerTitle}>Medicare Records</div>
          <div className={classes.containerContent}>
            <div style={{ height: 0 }} />
            <Paper
              className={classes.containerPaper}
              style={{ overflow: this.state.formViewer ? "auto" : "hidden" }}
            >
              {!this.state.formViewer && (
                <NewTable
                  tableName={"Medicare Records"}
                  enqueueSnackbar={this.props.enqueueSnackbar}
                  loadedData={this.state.tableData}
                  loaded={this.state.loaded}
                  loadMoreRows={() => {}}
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  sortTable={this.sortTable}
                  recordCount={0}
                  setActiveFilters={this.setActiveFilters}
                  activeFilters={this.state.activeFilters}
                  columns={[
                    {
                      dataKey: "employee_first",
                      label: "Employee First",
                      width: 300,
                      flexGrow: 4,
                      flexShrink: 1,
                      hasFilter: true,
                    },
                    {
                      dataKey: "employee_last",
                      label: "Employee Last",
                      hasFilter: false,
                      width: 300,
                      flexGrow: 1,
                      flexShrink: 1,
                    },
                    {
                      dataKey: "date_added",
                      label: "Date Added",
                      cellRenderer: dateCellDataGetterDate,
                      width: 125,
                      flexGrow: 0,
                      flexShrink: 0,
                      hasFilter: true,
                    },
                    {
                      dataKey: "signature_date",
                      label: "Date Verified",
                      cellRenderer: dateCellDataGetterDate,
                      width: 130,
                      flexGrow: 0,
                      flexShrink: 0,
                      hasFilter: true,
                    },
                    {
                      label: "Status",
                      dataKey: "status",
                      cellRenderer: employerStatusDataGetter,
                      width: 125,
                      flexGrow: 0,
                      flexShrink: 0,
                      hasFilter: false,
                    },
                    {
                      dataKey: "cms_status",
                      label: "CMS Status",
                      width: 125,
                      flexGrow: 0,
                      flexShrink: 0,
                      hasFilter: false,
                    },
                  ]}
                  actionMenuItems={this.getActionMenuItems}
                />
              )}
              {this.state.formViewer && (
                <CompletedForm
                  medicareID={this.state.medicareDetail.id}
                  goBack={() => {
                    this.setState({ formViewer: false, medicareDetail: {} });
                  }}
                />
              )}
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(MedicareContainer);
