import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";


import DocumentList from "../../Shared/Components/Documents/DocumentList";
import DocumentDialog from "../../Shared/Components/Documents/DocumentDialog";
import FolderList from "../../Shared/Components/Documents/FolderList";

import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  employer: {},
  addDocumentModal: false,
  hasEmployerInfo: false,
  documents: [],
  folderDocuments: [],
  folders: [],
  showFolders: true,
  folderName: 'All'
};

class DocumentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    if (!this.props.location.state.hasOwnProperty("employer")) {
      //this is if someone navigates to this page without clicking an employer
      //from the employer list
      this.props.history.push("/mewa-admin/auth?error=5");
    } else {
      //when back button is clicked, if we're viewing the document list,
      // allow browser to pop off history but show folders
      window.addEventListener("popstate", this.backButtonListener);
      this.handleGetDocuments();
      this.setState({ hasEmployerInfo: true, employer: this.props.location.state.employer});
    }
  }
  componentWillUnmount() {
    //removes event listener when document container is unmounted
    window.removeEventListener("popstate", this.backButtonListener);
  }

  backButtonListener = () => {
    if (!this.state.showFolder) this.setState({ showFolders: true });
  };

  getDocuments = () => {
    return new Promise((resolve, reject) => {
      resolve(api.getDocumentsSecured(this.props.location.state.employer.portal_id))
    });
  };

  handleOpen = () => {
    this.setState({ addDocumentModal: true });
  };

  handleGetDocuments = () => {
    this.getDocuments().then(r => {
      this.setState({documents: r.documents, folderDocuments: r.documents, folders: r.folders, loaded: true })
    }).catch(err => {
      if (err === 403) {
        this.props.history.push("/mewa-admin/auth?error=5");
      }
      if (err === 400) {
        this.props.enqueueSnackbar("There was an issue generating document list", {
          variant: "error"
        });
      }
    });
  };

  filterFolder = (folder) => {
    this.setState({
      folderName: folder.name,
      folderDocuments: (folder.document_type === -1) ? this.state.documents :  this.state.documents.filter(d=>d.document_type === folder.document_type),
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <DocumentDialog
          enqueueSnackbar={this.props.enqueueSnackbar}
          Open={this.state.addDocumentModal}
          Close={() => {
            this.setState({ addDocumentModal: false });
          }}
          states={[]}
          refreshList={this.handleGetDocuments}
          Employer={this.state.employer}
        />
        <div className={classes.containerRoot}>
          <div className={classes.containerTitle}> 
            {this.state.hasEmployerInfo &&
              this.state.employer.name}{"'s "}
            Document Center
          </div>                  
          <div className={classes.containerContent}>
          <div style={{ height: 0, textAlign: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleOpen}
                className={classes.buttonFarRight}
              >
                <AddIcon className={classes.rightIcon} />
                <div className={classes.buttonText}>Add</div>
              </Button>
            </div>            
            <div style={{ height: 0 }} />
            <Paper
              className={classes.containerPaper}
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              {this.state.loaded && (
                <React.Fragment>
                  <FolderList filterFolder={this.filterFolder} folderName={this.state.folderName} folders={this.state.folders} />
                  <DocumentList
                  handleGetDocuments={this.handleGetDocuments}
                  documents={this.state.folderDocuments}
                  canDelete={true}
                  employerID={this.state.employer.id}
                  portalID={this.state.employer.portal_id}
                  height={300}
                  width={220}
                />
                </React.Fragment>
              )}

            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(DocumentsContainer);
