import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  checkDone: {
    minWidth: 700,
    marginTop: 10,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%",
    },
  },
});

const initialState = {
  showComplete: false,
};

class SubmitArcRequestDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    const { fullScreen } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.Open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <React.Fragment><div id="updated-heaeder">ARC Form Updated</div></React.Fragment>
        </DialogTitle>
        <DialogContent style={{ marginTop: 20, marginBottom: 40 }}>
          <Typography variant="caption" color="inherit">
            Thank you, {this.props.Employer} will be notified that you have
            updated their ARC Form. You may close this window.
          </Typography>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(SubmitArcRequestDialog));
