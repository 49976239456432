import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";
import { styles } from "../../Auth/Styles/AuthStyles";
import { withSnackbar } from "notistack";
//import { styles } from "../../Shared/Styles/ContainerStyles";

import api from "../../Shared/Functions/APIHelpers";
import PasswordInput from "../../Shared/Components/Accounts/PasswordInput";
import { Auth } from "aws-amplify";

class ForgotPassword extends React.Component {
  state = {
    loaded: false,
    expired: false,
    username: "",
    user: {},
    newPassword: "",
  };

  componentDidMount() {
    //on loading this screen, the employer has accepted the password reset
    //API will now generate a temporary password that we will use to log user in behind the scenes
    //Then we will prompt them for a new password which we will login with cognito as they
    //will be in PASSWORD_RESET_REQUIRED state

    this.acceptSecurePortal(this.getSecurePortalRequestID()).then((r) => {
      if (r.message === "valid") {
        //now that we have a temporary password and email, log user in
        this.signInWithTemporaryPassword(r.username, r.temporary_password).then(
          (signInObject) => {
            this.setState({
              loaded: true,
              username: r.username,
              user: signInObject,
            });
          }
        );
      }
      if (r.message === "expired") {
        this.setState({ loaded: true, expired: true });
      }
    });
  }

  acceptSecurePortal = (requestID) => {
    return new Promise((resolve, reject) => {
      resolve(api.acceptSecurePortalEmployer(requestID));
    });
  };

  handleFinishSecurePortal = (requestID) => {
    this.finishSecurePortal(requestID).then(r => {
        window.location.href =
          config.appLocation.URL +
          "/employer/home?portalID=" +
          this.getPortalID();
    })
  };

  finishSecurePortal = (requestID) => {
    return new Promise((resolve, reject) => {
      resolve(api.finishSecurePortalEmployer(requestID));
    });
  };

  signInWithTemporaryPassword = (username, password) => {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user) => {
          resolve(user);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  signInWithNewPassword = () => {
    Auth.completeNewPassword(this.state.user, this.state.newPassword)
      .then((user) => {
      this.props.enqueueSnackbar(
                  "Password set!",
                  {
                    variant: "success",
                  }
                );         
        this.handleFinishSecurePortal(this.getSecurePortalRequestID());
      })
      .catch((e) => {
        if (e.code === "InvalidPasswordException") {
          this.props.enqueueSnackbar(
            "Password must be at least 8 characters, contain 1 number, and 1 special character",
            {
              variant: "error",
            }
          );
        } else {
          this.props.enqueueSnackbar("Something went wrong saving password", {
            variant: "error",
          });
        }
      });
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID");
  };

  getSecurePortalRequestID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("securePortalRequestID");
  };

  handleSetNewPassword = () => {
    this.signInWithNewPassword();
  };

  handleOpenPortal = () => {
      window.location.href =
          config.appLocation.URL +
          "/employer/home?portalID=" +
          this.getPortalID();
  };

  redirectUser = (code) => {
    if (this.props.loginURL.indexOf("portalID") > 0) {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "&message=" + code;
    } else {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "?message=" + code;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper className={classes.root} elevation={5} style={{ zIndex: 10 }}>
          <div className={classes.header}>
            <div className={classes.logoContainer}>
              <img
                src={require("../../Shared/Images/mewaio_vert_350.png")}
                className={classes.largeLogo}
                alt="Reform Health Logo"
              />
            </div>
            {this.state.loaded && this.state.expired && (
              <React.Fragment>
                <Typography variant="h4" color="inherit" noWrap>
                  Expired
                </Typography>
                <div style={{height:10}}></div>
                <Typography variant="caption">
                  This request has expired. You may have a new request sent by accessing Account Settings in the portal.
                </Typography>
                <div style={{height:30}}></div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleOpenPortal}
                  style={{ width: 250, marginTop: 20, marginBottom: 20 }}
                >
                  Open Portal
                </Button>                
              </React.Fragment>
            )}
            {this.state.loaded && !this.state.expired && (
              <React.Fragment>
                <Typography variant="h4" color="inherit" noWrap>
                  Set Password
                </Typography>
                <div style={{height:10}}></div>
                <Typography variant="caption">
                  Enter the password you will use to log into the portal.
                </Typography>
                <div style={{height:20}}></div>
                <PasswordInput
                  Password={this.state.newPassword}
                  SetPassword={(val) => {
                    this.setState({ newPassword: val });
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSetNewPassword}
                  style={{ width: 250, marginTop: 20, marginBottom: 20 }}
                  disabled={
                    !this.state.newPassword.match(
                      /^(?=.*[!@#$%^&*])(?=.{8,})(?=.*[0-9])/
                    )
                  }
                >
                  Set New Password
                </Button>
              </React.Fragment>
            )}
            {!this.state.loaded && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress style={{ marginTop: 50, marginBottom: 50 }} size={60} />
              </div>
            )}
          </div>
        </Paper>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(ForgotPassword));

