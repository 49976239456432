import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const ContactList = (props) => {
  const { contacts, deleteContact } = props;

  return (
    <div>
      <List dense={true}>
        {contacts.map((contact, index) => {
          return (
            <ListItem key={index}>
              <ListItemText
                primary={contact.first_name + ' ' + contact.last_name}
                secondary={contact.email}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={()=>{deleteContact(contact.id)}} edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ContactList;
