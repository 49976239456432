import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import Agreement from "../../Shared/Components/Agreement/Agreement";
import { formatDateTimeAsDate } from "../../Shared/Functions/Format";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";
const styles = (theme) => ({
  invitationBox: {
    display: "flex",
    marginTop: 25,
    "@media only screen and (max-width: 600px)": {
      display: "block",
    },
    paddingBottom: 25,
    borderBottom: "1px solid #f2f2f2",
  },
  invitationBoxLeft: {
    //width: "80%",
  },
  invitationBoxRight: {
    backgroundColor: "pink",
    display: "flex",
    //alignItems: "center",
    justifyContent: "center",
  },
});
class ViewInvitationsContainer extends React.Component {
  state = {
    loaded: false,
    invitations: [],

    /*state for confirm alert */
    confirmAlertTitle: "",
    confirmAlertText: "",
    confirmAlertOpen: false,
    doFunc: null,
    doNotFunc: null,
    selectedInvitation: {},
  };

  componentDidMount() {
    this.handleGetInvitations();
  }

  handleGetInvitations = () => {
    this.getInvitations().then((r) => {
      this.setState({ loaded: true, invitations: r });
    });
  };

  getInvitations = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(api.getInvitationsListEmployerSecured(this.props.portalID));
      } else {
        resolve(api.getInvitationsListEmployer(this.props.portalID));
      }
    });
  };

  handleResendInvitation = (invitation) => {
    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      selectedInvitation: invitation,
      confirmAlertTitle: "Resend Invitation?",
      confirmAlertText: "Are you sure you wish to resend this invitation?",
      doNotFunc: this.doNotResendInvitation,
      doFunc: this.doResendInvitation,
    });
  };

  doResendInvitation = () => {
    this.resendInvitation(this.state.selectedInvitation.id).then((r) => {
      this.setState(
        {
          confirmAlertOpen: false,
          selectedInvitation: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        },
        () => {
          this.handleGetInvitations();
        }
      );
      this.showMessage("Invitation resent sucessfully", "success");
    });
  };

  doNotResendInvitation = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedInvitation: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  resendInvitation = (invitationID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(api.resendEmployerInviteSecured(invitationID));
      } else {
        resolve(api.resendEmployerInvite(invitationID));
      }
    });
  };

  handleDeleteInvitation = (invitation) => {
    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      selectedInvitation: invitation,
      confirmAlertTitle: "Cancel Invitation?",
      confirmAlertText: "Are you sure you wish to cancel this invitation?",
      doNotFunc: this.doNotDeleteInvitation,
      doFunc: this.doDeleteInvitation,
    });
  };

  doDeleteInvitation = () => {
    this.deleteInvitation(this.state.selectedInvitation.id).then((r) => {
      this.setState(
        {
          confirmAlertOpen: false,
          selectedInvitation: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        },
        () => {
          this.handleGetInvitations();
        }
      );
      this.showMessage("Invitation deleted sucessfully", "success");
    });
  };
  doNotDeleteInvitation = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedInvitation: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  deleteInvitation = (invitationID) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(api.deleteEmployerBrokerInviteSecured(invitationID));
      } else {
        resolve(api.deleteEmployerBrokerInvite(invitationID));
      }
    });
  };

  handleAcceptInvitation = (invitation) => {
    window.location.href =
      config.appLocation.URL +
      "/employer/review-invite?portalID=" +
      this.props.portalID +
      "&response=review&invitationID=" +
      invitation.id;
  };

  handleDenyInvitation = (invitation) => {
    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      selectedInvitation: invitation,
      confirmAlertTitle: "Confirm Accept",
      confirmAlertText: "Are you sure you wish to deny this Invitation?",
      doNotFunc: this.doNotDenyInvitation,
      doFunc: this.doDenyInvitation,
    });
  };
  doDenyInvitation = () => {
    window.location.href =
      config.appLocation.URL +
      "/employer/review-invite?portalID=" +
      this.props.portalID +
      "&response=decline&invitationID=" +
      this.state.selectedInvitation.id;
  };
  doNotDenyInvitation = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedInvitation: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  updateEmployerInvite = (accept) => {
    let body = {
      result: accept ? 1 : 0,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.updateBrokerInvite(
          this.state.selectedInvitation.id,
          JSON.stringify(body)
        )
      );
    });
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };
  render() {
    const { classes } = this.props;
    const { loaded } = this.state;
    if (!loaded) return null;

    return (
      <React.Fragment>
        <Agreement Open={this.props.needTOS} Close={()=>{this.props.getEmployerInfo()}} portalID={this.props.portalID} />
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        <Typography variant="h4">Invitations</Typography>
        {this.state.invitations.length === 0 && (
          <Typography
            variant="body1"
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            You do not have any open invitations. To invite a broker to manage
            your account, click the My Acccount icon and select Manage Brokers.
          </Typography>
        )}

        {this.state.invitations.map((invite, index) => {
          return (
            <div key={index} id={'invitation'+index}>
              <Grid container spacing={2} style={{ marginTop: 30 }}>
                <Grid item xs={12} sm={9}>
                  <Typography variant="h6">{invite.employer_name}</Typography>
                  <Typography variant="subtitle1">
                    Type: {invite.to_user_type === "1" ? "Broker" : "Employer"}{" "}
                  </Typography>

                  <Typography variant="subtitle1" >
                    {invite.to_first_name} {invite.to_last_name} (
                    {invite.to_email}){" "}
                  </Typography>

                  <Typography variant="subtitle1">
                    Requested: {formatDateTimeAsDate(invite.date_added)}
                  </Typography>

                  <Typography variant="subtitle1">
                    Status:{" "}
                    {invite.result
                      ? "Accepted"
                      : invite.result === null
                      ? "Pending"
                      : "Declined"}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  justifycontent="flex-start"
                  alignItems="center"
                >
                  {!invite.to_me && (
                    <React.Fragment>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          this.handleResendInvitation(invite);
                        }}
                        style={{ width: "120px" }}
                      >
                        Resend
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          this.handleDeleteInvitation(invite);
                        }}
                        style={{ marginLeft: 20, width: "120px" }}
                      >
                        Cancel
                      </Button>
                    </React.Fragment>
                  )}
                  {invite.to_me && (
                    <React.Fragment>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          this.handleAcceptInvitation(invite);
                        }}
                        style={{ width: "120px" }}
                      >
                        Accept
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          this.handleDenyInvitation(invite);
                        }}
                        style={{ marginLeft: 20, width: "120px" }}
                      >
                        Deny
                      </Button>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 30, marginBottom: 30 }} />
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ViewInvitationsContainer);
