import React from "react";
import {
  withRouter,
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import NavList from "./Nav/NavList";
import BaseContainer from "../Shared/Layout/BaseContainer";
import MedicareContainer from "./Medicare/MedicareContainer";
import MedicareTableContainer from "./MedicareTable/MedicareTableContainer";
import ReviewBrokerPermissionsContainer from "./Broker/ReviewBrokerPermissionsContainer";
import ManageBrokerContainer from "./Broker/ManageBrokerContainer";
import ReviewContactChangeContainer from "./Broker/ReviewContactChangeContainer";
import TerminationTableContainer from "./TerminationTable/TerminationTableContainer";
import TerminationContainer from "./Termination/TerminationContainer";
import DocumentsContainer from "./Documents/DocumentsContainer";
import ContinuationStatusContainer from "./Continuation/ContinuationStatusContainer";
import ConfirmConfirmationContainer from "./Continuation/ConfirmConfirmationContainer";
import AccountContainer from "./Account/AccountContainer";
import AuthContainer from "../Auth/AuthContainer";
import ArcContainer from "./Arc/ArcContainer";
import PortalLookupRequest from "../Auth/Shared/Components/PortalLookup/PortalLookupRequest";
import ViewInvitationsContainer from "./Invite/ViewInvitationsContainer";
import SarPassThrough from "./PassThrough/SarPassThrough";
import EmployerHOC from "../Auth/HOC/EmployerHOC";
import PasswordResetRequest from "../Auth/Shared/Components/PasswordResetRequest/PasswordResetRequest";
import SecurePortalRequest from "./Account/SecurePortalRequest";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import getBrandedThemeOrDefault from "../Auth/Styles/BrandedTheme";

import Home from "./Home/Home";

class BaseContainerWrapped extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    user: {},
  };

  componentDidMount() {
    document.title = "mewa.io - Employer Portal";
  }

  getHome = () => {
    return "/employer/home?portalID=" + this.getPortalID();
  };

  getLogout = () => {
    if (this.getPortalID())
      return "/employer/auth?portalID=" + this.getPortalID();
    else return "/employer/auth";
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    return params.get("portalID");
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <BaseContainer
                {...props}
                component={Component}
                navList={NavList}
                navOptions={this.props.navOptions}
                user={this.props.user}
                home={this.getHome()}
                app={"employer"} //used for correct login app form cognito
                logoutURL={this.getLogout()}
                hasAccount={true} //This is for icon in top right
                theme={this.props.theme}
                fixed={this.props.fixed}
                fixedNarrow={this.props.fixedNarrow}
                isLoaded={true}
                portalID={this.getPortalID()}
                hideSignout={this.props.hideSignout}
                employerIsSecured={this.props.employerIsSecured}
                employerName={this.props.employerName}
                getEmployerInfo={this.props.getEmployerInfo}
                needTOS={this.props.needTOS}
                mewaName={this.props.mewaName}
                employerContact={this.props.employerContact}
                homeURLContainsParams={true}
              />
            </MuiPickersUtilsProvider>
          );
        }}
      />
    );
  }
}

BaseContainerWrapped = withRouter(EmployerHOC(BaseContainerWrapped));

const Routes = (props) => {
  const getSignInURL = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let portalID = params.get("portalID");
    let next = params.get("next");
    let nextID = params.get("id");

    if (next) {
      if (next === "term") {
        return (
          "/employer/termination-record?id=" + nextID + "&portalID=" + portalID
        );
      }
      if (next === "arc") {
        return "/employer/arc?portalID=" + portalID;
      }
      if (next === "sar") {
        return (
          "/employer/sarpassthrough?portalID=" + portalID + "&sarID=" + nextID
        );
      }
    }
    if (portalID) return "/employer/home?portalID=" + portalID;
    else return "/employer/home";
  };
  const getSignOutURL = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let portalID = params.get("portalID");
    if (portalID) return "/employer/auth?portalID=" + portalID;
    else return "/employer/auth";
  };

  return (
    <Router>
      <Switch>
        <BaseContainerWrapped
          exact
          path="/employer/home"
          fixed={true}
          component={Home}
        />
        <BaseContainerWrapped
          exact
          path="/employer/account"
          fixed={true}
          user={props.user} //explicitly giving account container access to the User object from cognito if logged in
          component={AccountContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/medicare"
          fixed={true}
          component={MedicareTableContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/medicare-record"
          fixed={true}
          component={MedicareContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/terminations"
          fixed={true}
          component={TerminationTableContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/termination-record"
          fixed={true}
          component={TerminationContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/termination-records-confirm-all"
          fixed={true}
          component={TerminationContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/documents"
          fixed={true}
          component={DocumentsContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/sarpassthrough"
          fixed={true}
          component={SarPassThrough}
        />
        <BaseContainerWrapped
          exact
          path="/employer/continuation-status"
          fixed={true}
          component={ContinuationStatusContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/confirm-confirmation"
          fixed={true}
          component={ConfirmConfirmationContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/arc"
          fixed={false}
          fixedNarrow={true}
          component={ArcContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/review-invite"
          fixed={true}
          fixedNarrow={false}
          component={ReviewBrokerPermissionsContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/review-contact-change"
          fixed={true}
          component={ReviewContactChangeContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/manage-brokers"
          fixed={true}
          component={ManageBrokerContainer}
        />
        <BaseContainerWrapped
          exact
          path="/employer/my-invitations"
          fixed={true}
          component={ViewInvitationsContainer}
        />
        <Route
          exact
          path="/employer/portal-lookup-request"
          component={() => <PortalLookupRequest />}
        />
        <Route
          exact
          path="/employer/password-reset-request"
          component={() => (
            <PasswordResetRequest
              logoutURL={getSignOutURL()}
              loginURL={getSignInURL()}
              app={"employer"}
            />
          )}
        />
        <Route
          exact
          path="/employer/secure-portal-request"
          component={() => (
            <SecurePortalRequest
              logoutURL={getSignOutURL()}
              loginURL={getSignInURL()}
            />
          )}
        />
        <Route
          exact
          path="/employer/auth"
          //user={props.user}
          component={() => (
            <AuthContainer
              logoutURL={getSignOutURL()}
              loginURL={getSignInURL()}
              theme={getBrandedThemeOrDefault({})}
              app={"employer"}
              welcomeMessage={"Welcome Employer!"}
              title={"mewa.io"}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
