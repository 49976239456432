import React from "react";
import Typography from "@material-ui/core/Typography";
import Medicare from "./Medicare";
import Form from "./Form";
import SignatureContainer from "../../Shared/Components/Signature/SignatureContainer";
import api from "../../Shared/Functions/APIHelpers";
import Agreement from "../../Shared/Components/Agreement/Agreement";

const initialState = {
  step: "info",
  medicareID: "",
  medicareDetail: {},
  isAdd: false,
  loaded: false
};

class MedicareContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  setStep = step => {
    this.setState({ step: step });
  };

  componentDidMount() {
    let medicareID = this.getMedicareID();
    if (medicareID) {
      this.getMedicareAsync(medicareID)
        .then(res => {
          this.setState({
            medicareDetail: res,
            loaded: true,
            isAdd: false,
            medicareID: medicareID
          });
        })
        .catch(err => {
          if (err === 403) {
            //This would be a LOGGED IN USER with a portal ID that they AREN'T authorized to use
            this.props.history.push("/employer/auth?error=2");
          }
          if (err === 400) {
            this.setState({step:"complete"})
            this.props.enqueueSnackbar("Record Already Verified", {
              variant: "error"
            });
          }
        });
    } else {
      this.setState({ loaded: true, isAdd: true });
    }
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID") ? params.get("portalID") : null;
  };

  getMedicareID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("id") ? params.get("id") : null;
  };

  getMedicareAsync = id => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getMedicarePortalSecured(id))
      else
        resolve(api.getMedicarePortal(id))
    });

  };

  setMedicareID = id => {
    console.log("Saved medicare ID: " + id);
    this.setState({ medicareID: id });
    //this.props.setMedicareID(id);
  };

  setMedicareInfo = (medicare, medicareID) => {
    this.setState({
      medicareDetail: medicare,
      medicareID: medicareID,
      isAdd: false
    });
  };

  updateSignature = MedicareID => {
    let updateSignatureFunction = api.updateMedicareSignature;
    if (this.props.employerIsSecured)
      updateSignatureFunction = api.updateMedicareSignatureSecured;

    return new Promise((resolve, reject) => {
      updateSignatureFunction(MedicareID)
        .then(response => {
          this.storeSignedForm().then(res => {
            console.log(res);
          });
          resolve(response);
        })
        .catch(err => {
          this.setState({
            errorMessage: "Error Updating Signature",
            sigDone: true
          });
          console.log(err);
        });
    });
  };

  storeSignedForm = () => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.addMedicareSignedFormSecured(this.state.medicareID))
      else
        resolve(api.addMedicareSignedForm(this.state.medicareID))
    });
  };
  
  render() {
    return (
      <React.Fragment>
        <Agreement Open={this.props.needTOS} Close={()=>{this.props.getEmployerInfo()}} portalID={this.props.portalID} />
        {this.state.step === "complete" && (
          <React.Fragment>
            <div
              name="verificationDiv"
              style={{ textAlign: "center", marginTop: 50 }}
            >
              <Typography variant="h6">
                This record has already be verified or does not exist.
              </Typography>
            </div>
          </React.Fragment>
        )}

        {this.state.step === "info" && (
          <Medicare
            enqueueSnackbar={this.props.enqueueSnackbar}
            employerIsSecured={this.props.employerIsSecured}
            medicareID={this.state.medicareID}
            key={this.state.medicareID}
            medicareDetail={this.state.medicareDetail}
            setMedicareInfo={this.setMedicareInfo}
            setStep={this.setStep}
            isAdd={this.state.isAdd}
            loaded={this.state.loaded}
          />
        )}

        {this.state.step === "form" && (
          <Form
            preloadedID={this.state.preloadedID}
            medicareID={this.state.medicareID}
            setStep={this.setStep}
          />
        )}

        {this.state.step === "sign" && (
          <SignatureContainer
            RequesterIsSig={this.state.medicareDetail.requester_is_sig}
            UpdateSignature={this.updateSignature}
            ID={this.state.medicareID}
            PortalID={this.props.portalID}
            Back={() => {
              this.setStep("form");
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default MedicareContainer;
export { MedicareContainer as PureMedicareContainer };
