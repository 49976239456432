import React from "react";

import MedicareForm from "../../Shared/Components/Medicare/MedicareForm";
import PageHeader from "../../Shared/Nav/PageHeader";
import api from "../../Shared/Functions/APIHelpers";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EmployerSigner from "./EmployerSigner";
import * as moment from "moment";

class Medicare extends React.Component {
  state = {
    errors: [],
    requester_is_sig: this.props.medicareDetail.requester_is_sig,
    employer_signer_first: this.props.medicareDetail.employer_signer_first,
    employer_signer_last: this.props.medicareDetail.employer_signer_last,
    employer_name: this.props.medicareDetail.employer_name || "",
    EIN: this.props.medicareDetail.EIN || "",
    number_employees: this.props.medicareDetail.number_employees || "",
    employer_street: this.props.medicareDetail.employer_street || "",
    employer_city: this.props.medicareDetail.employer_city || "",
    employer_state: this.props.medicareDetail.employer_state
      ? this.props.medicareDetail.employer_state.id
      : "",
    employer_zip: this.props.medicareDetail.employer_zip || "",
    employer_contact_first:
      this.props.medicareDetail.employer_contact_first || "",
    employer_contact_last:
      this.props.medicareDetail.employer_contact_last || "",
    employer_contact_email:
      this.props.medicareDetail.employer_contact_email || "",
    employee_first: this.props.medicareDetail.employee_first || "",
    employee_last: this.props.medicareDetail.employee_last || "",
    employee_email: this.props.medicareDetail.employee_email || "",
    beneficiary_first: this.props.medicareDetail.beneficiary_first || "",
    beneficiary_last: this.props.medicareDetail.beneficiary_last || "",
    beneficiary_ssn: "",
    beneficiary_dob: this.props.medicareDetail.beneficiary_dob
      ? moment(this.props.medicareDetail.beneficiary_dob)
      : null,
    effective_date: this.props.medicareDetail.effective_date
      ? moment(this.props.medicareDetail.effective_date)
      : null,
  };

  updateMedicarePortal = (body) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured) {
        resolve(
          api.updateMedicarePortalSecured(
            this.props.medicareDetail.id,
            JSON.stringify(body)
          )
        );
      } else {
        resolve(
          api.updateMedicarePortal(
            this.props.medicareDetail.id,
            JSON.stringify(body)
          )
        );
      }
    });
  };

  onChange = (value, field) => {
    this.setState({ [field]: value });
  };

  inError = () => {
    let errors = [];
    const body = this.getBody();
    Object.entries(body)
      .filter((f) => f[0] !== "employer_signer_first")
      .filter((f) => f[0] !== "employer_signer_last")
      .map((field) => {
        if (
          field[0] === "employer_signer_first" ||
          field[0] === "employer_signer_last"
        ) {
          if (this.state.requester_is_sig) {
          }
        }
        if (field[1] === "") errors.push(field[0]);
      });

    if (this.state.requester_is_sig) {
      console.log(body)
      //If requester is signer need to make sure first and last name were provided
      if (body.employer_signer_first === "" || body.employer_signer_first === null ) errors.push("employer_signer_first");
      if (body.employer_signer_last === "" || body.employer_signer_last === null) errors.push("employer_signer_last");
    }

    this.setState({ errors: errors });
    return errors.length > 0;
  };

  getBody = () => {
    return {
      added_by: 1,
      employer_name: this.state.employer_name,
      EIN: this.state.EIN,
      number_employees: this.state.number_employees,
      employer_street: this.state.employer_street,
      employer_city: this.state.employer_city,
      employer_state_id: this.state.employer_state,
      employer_zip: this.state.employer_zip,
      employer_contact_first: this.state.employer_contact_first,
      employer_contact_last: this.state.employer_contact_last,
      employer_contact_email: this.state.employer_contact_email,
      employee_first: this.state.employee_first,
      employee_last: this.state.employee_last,
      employee_email: this.state.employee_email,
      beneficiary_first: this.state.beneficiary_first,
      beneficiary_last: this.state.beneficiary_last,
      set_beneficiary_ssn: this.state.beneficiary_ssn.replaceAll("-", ""),
      beneficiary_dob: this.state.beneficiary_dob
        ? this.state.beneficiary_dob.format("YYYY-MM-DD")
        : "",
      effective_date: this.state.effective_date
        ? this.state.effective_date.format("YYYY-MM-DD")
        : "",
      employer_signer_first: this.state.employer_signer_first,
      employer_signer_last: this.state.employer_signer_last,
    };
  };

  handleNext = () => {
    if (!this.inError()) {
      let saveFunction = this.updateMedicarePortal;
      saveFunction(this.getBody())
        .then((r) => {
          this.props.setMedicareInfo(r, r.id);
          this.props.setStep("form");
        })
        .catch((err) => {
          console.log("Invalid data");
        });
    } else {
      this.props.enqueueSnackbar("Please Correct All Errors", {
        variant: "error",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <PageHeader
          hasBack={false}
          hasNext={true}
          pageTitle="Medicare Record"
          backText="Back"
          nextText="View Form"
          back={this.goBack}
          next={this.handleNext}
        />
        {this.state.requester_is_sig && (
          <EmployerSigner
            Errors={this.state.errors}
            SetEmployerSignerFirst={(firstName) => {
              this.setState({ employer_signer_first: firstName });
            }}
            EmployerSignerFirst={this.state.employer_signer_first}
            SetEmployerSignerLast={(lastName) => {
              this.setState({ employer_signer_last: lastName });
            }}
            EmployerSignerLast={this.state.employer_signer_last}
          />
        )}
        {this.props.loaded ? (
          <MedicareForm
            onChange={this.onChange}
            isAdd={false}
            errors={this.state.errors}
            employer_name={this.state.employer_name}
            EIN={this.state.EIN}
            number_employees={this.state.number_employees}
            employer_street={this.state.employer_street}
            employer_city={this.state.employer_city}
            employer_state={this.state.employer_state}
            employer_zip={this.state.employer_zip}
            employer_contact_first={this.state.employer_contact_first}
            employer_contact_last={this.state.employer_contact_last}
            employer_contact_email={this.state.employer_contact_email}
            employee_first={this.state.employee_first}
            employee_last={this.state.employee_last}
            employee_email={this.state.employee_email}
            beneficiary_first={this.state.beneficiary_first}
            beneficiary_last={this.state.beneficiary_last}
            beneficiary_ssn={this.state.beneficiary_ssn}
            beneficiary_dob={this.state.beneficiary_dob}
            effective_date={this.state.effective_date}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Medicare;
