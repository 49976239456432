import React, { useState } from "react";
import api from "../../Shared/Functions/APIHelpers"
import AsyncSelect from "react-select/async";

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

const BrokerInviteEmployerAsyncSelect = props => {
  const [employerSearchValue, setSearchValue] = useState();
  const [employerSelectedValue, setEmployerSelectedValue] = useState(null);
  const loadEmployerOptions = () => {
    return new Promise((resolve, reject) => {
      api
        .getBrokerInviteEmployerOptions(employerSearchValue)
        .then(response => {
          var employerList = [];
          response.forEach(r => {
            r.value = r.id;
            r.label = r.name;
            employerList.push(r);
          });
          resolve(response);
        })
        .catch(err => {
          if(err === 403){ 
            this.props.history.push("/mewa-admin/auth?error=5")
          }
          if(err === 400){
            this.props.enqueueSnackbar("Error Getting Employer Options", {
              variant: "error"
            })
          }
        });
    });
  };

  const handleEmplyerInputChange = (newValue, change) => {
    setSearchValue(newValue)
  };
  const handleEmployerSelected = (selectedValue) => {
    props.handleEmployerChange(selectedValue)
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadEmployerOptions}
      onInputChange={handleEmplyerInputChange}
      onChange={handleEmployerSelected}//handleEmployerChange
      styles={customStyles}
      isSearchable={true}
      openMenuOnClick={false}
      fullWidth={true}
      placeholder={"Type employer name..."}
      value={employerSelectedValue}
      //isClearable
    />
  );
};

export default BrokerInviteEmployerAsyncSelect;