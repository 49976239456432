import React from "react";

import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";

import { withStyles } from "@material-ui/core/styles";

const SupportingDocumentationList = ({
  uploadedFiles,
  removeFile,
  classes,
}) => {
  return (
    <React.Fragment>
      <div style={{ maxWidth: 350 }}>
        <List dense={true}>
          {uploadedFiles.map((file, index) => {
            return (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton onClick={()=>{removeFile(file)}} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    </React.Fragment>
  );
};

export default withStyles(null, { withTheme: true })(
  SupportingDocumentationList
);

