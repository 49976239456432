import React from "react";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { getStates } from "../../Shared/Functions/Format";
import { KeyboardDatePicker } from "@material-ui/pickers";
import EINTextBox, {
  mapEINToMask,
  outputPhoneFromMask,
} from "../../Shared/Components/Input/EINTextBox";

const styles = (theme) => ({
  fieldContainer: {
    width: 500,
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
});

const EmployerForm = (props) => {
  const stateAutoSelect = (event) => {
    let keyPressed = event.key.toLowerCase();
    if (keyPressed.match(/[a-z]/) && keyPressed.length === 1) {
      //this means character
      let state = getStates().find((s) =>
        s.abbreviation.charAt(0).toLowerCase().startsWith(keyPressed)
      );
      if (state) props.onChange(state.id, "state");
    }
  };

  const { errors, classes } = props;

  return (
    <React.Fragment>
      <div className={classes.fieldContainer}>
        <FormControl margin="dense" fullWidth>
          <InputLabel htmlFor="states">MEWA</InputLabel>
          <Select
            value={props.mewa || ""}
            onChange={(e) => {
              props.onChange(e.target.value, "mewa");
            }}
            inputProps={{
              name: "employerMewa",
              id: "employer-mewa-select",
            }}
            fullWidth
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {props.MEWAS.map((mewa) => {
              return (
                <MenuItem value={mewa.id} key={"mewa_" + mewa.id}>
                  {mewa.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          id="employer-name-text-field"
          label="Employer Name"
          margin="dense"
          error={errors.includes("name")}
          helperText={errors.includes("name") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "name")}
          value={props.name}
          fullWidth
        />
        <FormControl fullWidth style={{ marginTop: 10 }}>
          <InputLabel htmlFor="formatted-text-mask-input">EIN</InputLabel>
          <Input
            value={props.EIN}
            onChange={(e) => props.onChange(e.target.value, "EIN")}
            disabled={!props.isAdd || props.usingEmployerPreload}
            name="textmask"
            id="formatted-text-mask-input"
            inputComponent={EINTextBox}
          />
        </FormControl>
        <TextField
          id="employer-street-text-field"
          label="Street"
          margin="dense"
          error={errors.includes("street")}
          helperText={errors.includes("street") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "street")}
          value={props.street}
          fullWidth
        />
        <TextField
          id="employer-city-text-field"
          label="City"
          margin="dense"
          error={errors.includes("city")}
          helperText={errors.includes("city") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "city")}
          value={props.city}
          fullWidth
        />
        <TextField
          id="employer-zip-text-field"
          label="Zip"
          margin="dense"
          error={errors.includes("zip")}
          helperText={errors.includes("zip") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "zip")}
          value={props.zip}
          fullWidth
        />
        <FormControl margin="dense" onKeyUp={stateAutoSelect} fullWidth>
          <InputLabel htmlFor="states">State</InputLabel>
          <Select
            value={props.state || ""}
            onChange={(e) => props.onChange(e.target.value, "state")}
            inputProps={{
              name: "employerState",
              id: "employer-state",
            }}
            style={{ width: 70, marginRight: 10 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {getStates().map((state) => {
              return (
                <MenuItem value={state.id} key={"state_" + state.id}>
                  {state.abbreviation}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        
        <TextField
          id="employer-contact-first-text-field"
          label="Contact First"
          margin="dense"
          error={errors.includes("contact_first")}
          helperText={errors.includes("contact_first") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "contact_first")}
          value={props.contact_first}
          fullWidth
        />
        <TextField
          id="employer-contact-last-text-field"
          label="Contact Last"
          margin="dense"
          error={errors.includes("contact_last")}
          helperText={errors.includes("contact_last") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "contact_last")}
          value={props.contact_last}
          fullWidth
        />
        <TextField
          id="employer-contact-email-text-field"
          label="Contact Email"
          margin="dense"
          error={errors.includes("contact_email")}
          helperText={errors.includes("contact_email") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "contact_email")}
          value={props.contact_email}
          fullWidth
        />
        <TextField
          id="employer-contact-phone-text-field"
          label="Contact Phone"
          margin="dense"
          error={errors.includes("contact_phone")}
          helperText={errors.includes("contact_phone") ? "Required" : ""}
          onChange={(e) => props.onChange(e.target.value, "contact_phone")}
          value={props.contact_phone}
          fullWidth
        />

      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(EmployerForm);
