import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import DraftsIcon from "@material-ui/icons/Drafts";
import ErrorIcon from "@material-ui/icons/Error";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
//import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Functions/Format";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ModalStyles";

class TrackingEventListWelcomeLetter extends React.Component {
  getSendgridEventIcon = (event) => {
    switch (event.event) {
      case "open":
        return <DraftsIcon />;
      case "click":
        return <MouseIcon />;
      case "delivered":
        return <EmailIcon />;
      case "processed":
        return <EmailIcon />;
      case "bounce":
        return <ErrorIcon />;
      case "deferred":
        return <ErrorIcon />;
      case "dropped":
        return <ErrorIcon />;
      case "spamreport":
        return <ErrorIcon />;
      default:
        return <MouseIcon />;
    }
  };

  getActiveStep = () => {
    let activeStep = -1;
    if (this.props.SelectedRecord.date_sent) {
      activeStep = 0;
    }
    return activeStep;
  };
  render() {
    let activeStep = this.getActiveStep();

    return (
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step expanded={true} key={0}>
          <StepLabel>
            <Typography variant="subtitle1">Record Sent</Typography>
            {activeStep >= 0 && (
              <Typography variant="caption" display="block" gutterBottom>
                {this.props.SelectedRecord.date_sent
                  ? formatDateTime(this.props.SelectedRecord.date_sent)
                  : "-"}
              </Typography>
            )}
          </StepLabel>
          <StepContent>
            <List dense={true} disablePadding={true} name="sendgridEventList">
              {this.props.EventDetails.sendgrid_events
                .filter((event) => event.bucket === "date_sent")
                .map((event, index) => {
                  return (
                    <ListItem dense={true} key={index}>
                      <ListItemIcon>
                        {this.getSendgridEventIcon(event)}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Email " + event.event}
                        secondary={formatDateTime(event.timestamp)}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </StepContent>
        </Step>
      </Stepper>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(TrackingEventListWelcomeLetter));
