import React from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";

import { Storage } from "aws-amplify";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  reportName: "",
  links: [],
  isSecured: false,
  isExpired: false
};

class OutputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.getReportOutput(this.getOutputID())
      .then((r) => {
        if(r.is_expired){
          this.setState({
            isExpired: true
          })
        }
        else{
          this.setState({
            reportName: r.report_name,
            isSecured: r.is_secured,
            links: r.links,
          });  
        }
      })
      .catch((err) => {
        console.log(err);
        this.showUnauthorizedWarning("Must Be a Primary Contact To View");
      });
  }

  getOutputID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let outputID = params.get("id") ? params.get("id") : null;
    if (!outputID) {
      this.showUnauthorizedWarning("Must Provide a Valid Contact ID");
      this.setState({ validContactID: false });
    }
    return outputID;
  };

  getReportOutput = (outputID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getReportOutput(outputID));
    });
  };

  downloadLink = (url) => {
    if (this.state.isSecured) {
      Storage.get(url, { download: false, level: "private" }).then((result) => {
        const link = document.createElement("a");
        link.href = result;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      Storage.get(url, { download: false, level: "public" }).then((result) => {
        const link = document.createElement("a");
        link.href = result;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Container maxWidth="sm" style={{marginTop: 100, textAlign: "center"}}>
          <Typography variant="h5">{this.state.reportName}</Typography>
          {(!this.state.isExpired) && (
            <Typography variant="caption">
              <p>Please click to download the CSV Files below</p>
            </Typography>
          )}
          {(this.state.isExpired) && (
            <Typography variant="h5">
              <p>This report link has expired</p>
            </Typography>
          )}

          {this.state.links.map((link, index) => (
            <React.Fragment key={index}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                onClick={() => {
                  this.downloadLink(link.url, link.name);
                }}
                style={{ marginBottom: 20 }}
              >
                {link.name}
              </Button>
              <br />
            </React.Fragment>
          ))}
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OutputContainer);
