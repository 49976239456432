import React from "react";
import api from "../../Shared/Functions/APIHelpers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import BusinessIcon from "@material-ui/icons/Business";
import MedicareIcon from "@material-ui/icons/LocalHospital";
import TerminationIcon from "@material-ui/icons/Cancel";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import Agreement from "../../Shared/Components/Agreement/Agreement";

const initialState = {
  stats: {},
  loaded: false,
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    var portalID = this.getPortalID();
    this.setState({ portalID: portalID });

    this.getEmployerDashboard(portalID).then((r) => {
      this.setState({ loaded: true, stats: r.stats });
    });
    //if(!this.props.tosAgreed){
    //  this.getTOS();
    // }
  }

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("portalID") ? params.get("portalID") : null;
  };

  getEmployerDashboard = (id) => {
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.getEmployerDashboardSecured(id));
      else resolve(api.getEmployerDashboard(id));
    });
  };

  redirectScreen = () => {
    window.location.href = "http://foo.com/error.php";
  };

  goMedicare = () => {
    this.props.history.push(
      "/employer/medicare" + this.props.history.location.search
    );
  };

  goTerminations = () => {
    this.props.history.push(
      "/employer/terminations" + this.props.history.location.search
    );
  };

  goInvitations = () => {
    this.props.history.push(
      "/employer/my-invitations" + this.props.history.location.search
    );
  };

  goManageTeam = () => {
    this.props.history.push(
      "/employer/manage-brokers" + this.props.history.location.search
    );
  };

  getGreeting = (name) => {
    //var curHr = today.getHours()

    var curHr = new Date().getHours();

    var insert_name = "";

    if (name !== "" && name !== null) {
      insert_name = ", " + name + "!";
    } else {
      insert_name = "!";
    }

    if (curHr < 12) {
      return "Good morning" + insert_name;
    } else if (curHr < 18) {
      return "Good afternoon" + insert_name;
    } else {
      return "Good evening" + insert_name;
    }
  };

  render() {
    
    const { loaded, stats } = this.state;
    const { navOptions } = this.props;
    console.log(this.props);
    if (!loaded) return null;
    console.log(this.props)
    return (
      <React.Fragment>
        <Agreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getEmployerInfo();
          }}
          portalID={this.props.portalID}
        />
        <Typography
          variant="h4"
          id="broker-home-header"
          style={{ color: this.props.theme.header_text_color }}
        >
          {this.getGreeting(stats.first_name)}
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          Here's what's going on with {this.props.employerName} in Mewa.io
        </Typography>

        <Grid container spacing={2} style={{ marginTop: 30 }}>
          {navOptions.hasTermination && (
            <Grid item xs={12} sm={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">Open Terms</Typography>
                  <Typography
                    variant="h3"
                    align="center"
                    style={{ marginTop: 30 }}
                  >
                    <b>{stats.open_term_count}</b>
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "space-between" }}>
                  <TerminationIcon style={{ fontSize: 40 }} />
                  <Button
                    color="primary"
                    size="small"
                    onClick={this.goTerminations}
                  >
                    View
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          {navOptions.hasMedicare && (
            <Grid item xs={12} sm={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">Open Medicare</Typography>
                  <Typography
                    variant="h3"
                    align="center"
                    style={{ marginTop: 30 }}
                  >
                    <b>{stats.open_medicare_count}</b>
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "space-between" }}>
                  <MedicareIcon style={{ fontSize: 40 }} />
                  <Button
                    color="primary"
                    size="small"
                    onClick={this.goMedicare}
                  >
                    View
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} sm={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Open Invitations</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.open_invitation_count}</b>
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "space-between" }}>
                <BusinessIcon style={{ fontSize: 40 }} />
                <Button
                  color="primary"
                  size="small"
                  onClick={this.goInvitations}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" style={{ height: 350 }}>
              <CardContent style={{ height: 270 }}>
                <Typography variant="h6">My Team</Typography>
                <Typography variant="h3" align="left" style={{ marginTop: 30 }}>
                  {stats.team_members.map((row, index) => (
                    <div key={index}>
                      <Typography variant="body1">{row.name}</Typography>
                    </div>
                  ))}
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  size="small"
                  onClick={this.goManageTeam}
                >
                  Manage
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Home;
