import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArcForm from "../../Shared/Components/Arc/ArcForm";
import { outputEINFromMask } from "../../Shared/Components/Input/EINTextBox";
import { calculateAutoFillFields } from "../../Shared/Components/Arc/ArcHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";
import api from "../../Shared/Functions/APIHelpers";
import * as moment from "moment";

class MedicareSubmissionDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      requestFieldFocus: "",
      loaded: false,
      confirmSubmit: false,
      submissionNotes: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { Open, Submission } = this.props;
    const { loaded } = this.state;

    //if (Open && !prevProps.Open && !loaded) this.handleGetSubmissionNotes(Submission);
  }

  handleGetSubmissionNotes = (medicareSubmission) => {
    this.getMedicareSubmissionNotes(medicareSubmission.id).then((r) => {
      this.setState({ submissionNotes: r, loaded: true });
    });
  };

  getMedicareSubmissionNotes = (submissionID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getMedicareSubmissionNotesMEwA(submissionID));
    });
  };

  close = () => {
    this.setState({ loaded: false, submissionNotes: [] }, () => {
      this.props.Close();
    });
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Submission Details</DialogTitle>

          <DialogContent style={{ height: 400, width: 300 }}>
            <br />
            <Typography variant="caption">Status:</Typography>
            <Typography variant="body2">
              {this.props.Submission.submission_status_text}
            </Typography>
            <br />
            <Typography variant="caption">Sent:</Typography>
            <Typography variant="body2">
              {this.props.Submission.date_sent
                ? formatDateTime(this.props.Submission.date_sent)
                : ""}
            </Typography>
            <br />
            <Typography variant="caption">Delivered:</Typography>
            <Typography variant="body2">
              {this.props.Submission.resolved_date
                ? formatDateTime(this.props.Submission.resolved_date)
                : ""}
            </Typography>
            <br />
            <Typography variant="caption">Fax Number:</Typography>
            <Typography variant="body2">
              {this.props.Submission.fax_number}
            </Typography>
            <br />
            <Typography variant="caption">Pages Count:</Typography>
            <Typography variant="body2">
              {this.props.Submission.pages_count}
            </Typography>
            <br />
            <Typography variant="caption">Pages Sent:</Typography>
            <Typography variant="body2">
              {this.props.Submission.pages_sent}
            </Typography>
            <br />
            <Typography variant="caption">Result Info:</Typography>
            <Typography variant="body2">
              {this.props.Submission.result_info}
            </Typography>
          </DialogContent>
          <DialogActions>
            <React.Fragment>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </React.Fragment>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(MedicareSubmissionDetail));
