import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import api from "../../Functions/APIHelpers";


class ChangeNameDialog extends React.Component {
  state = {
    open: false,
    firstName: "",
    lastName: "" 
  };

  handleClickOpen = () => {
    this.getUserInfo().then(userInfo => {
      this.setState({firstName: userInfo.first_name, lastName: userInfo.last_name, open: true})
    })
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  getUserInfo = () => {
    return new Promise((resolve, reject) => {
      api
        .getUserInfo()
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User Info" });
          console.log(err);
        });
    });
  };

  updateUserInfo = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name : this.state.lastName,
    });

    return new Promise((resolve, reject) => {
      api
        .updateUserFullName(body)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating User Info" });
          console.log(err);
        });
    });
    
  }

  handleSave = () => {
    this.updateUserInfo().then(userInfo => {
      this.props.enqueueSnackbar("Name Changed Successfully", {
          variant: "success"
        });
      this.setState({open: false})
    })
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div style={{ float: "left" }}>
        <Button onClick={this.handleClickOpen} color="primary" size="small">
          Change Name
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
          >
            <Typography
              variant="body2"
              color="inherit"
            >
              Change Your Name
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
            </Typography>
             <TextField
                id="standard-dense-first-name"
                label="Fist Name"
                margin="dense"
                onChange={e =>this.setState({firstName: e.target.value})}
                value={this.state.firstName}
                fullWidth
              />
              <TextField
                id="standard-dense-last-name"
                label="Last Name"
                margin="dense"
                onChange={e =>this.setState({lastName: e.target.value})}
                value={this.state.lastName}
                fullWidth
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


export default withMobileDialog()(ChangeNameDialog);