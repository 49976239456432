export function getStates() {
  return [
    { abbreviation: "AK", id: "2ea938d4d58849cfb08dc123a94918c1" },
    { abbreviation: "AL", id: "13726889bf804a0ca63ea91611f07173" },
    { abbreviation: "AR", id: "b2aa6bf3449a4f63bfff12bae6921751" },
    { abbreviation: "AZ", id: "0c324192210b499386eb415b26fdee55" },
    { abbreviation: "CA", id: "a4d4504bc5394ed588b9a1a739c8afda" },
    { abbreviation: "CO", id: "238858e803554ef08f8ed08604b626c8" },
    { abbreviation: "CT", id: "0000e5a04d8041739a1f575535c4b12a" },
    { abbreviation: "DC", id: "0f039300d61249268217051324d4cb21" },
    { abbreviation: "DE", id: "4048f17397dc4bc6b9ec52c9c734afa3" },
    { abbreviation: "FL", id: "59376da4327d4ab495940176bbe826d9" },
    { abbreviation: "GA", id: "ec812ec10c1f495bb0889154c72f1e15" },
    { abbreviation: "HI", id: "09434cab86874d8199a52cfb6850cb2e" },
    { abbreviation: "IA", id: "5a3a84d50788438bb7ecfa140c4af440" },
    { abbreviation: "ID", id: "03782130eac74424986085c28a60f3d8" },
    { abbreviation: "IL", id: "edd95ee9dce344c69fd730e7dbc154e7" },
    { abbreviation: "IN", id: "a4a990b1e11347daa8d01dd1862475e2" },
    { abbreviation: "KS", id: "6927a18104704050a02f0bd472ed5db7" },
    { abbreviation: "KY", id: "2616447d755743a28a4b015f4f57c001" },
    { abbreviation: "LA", id: "32915495484e4087ae97cb2c2bcb93e8" },
    { abbreviation: "MA", id: "7fbd9d4433254232859c98ea71520ad2" },
    { abbreviation: "MD", id: "c9bd529307bd4f79aff920a9211e2e97" },
    { abbreviation: "ME", id: "9239d270120444008ccc051456d26074" },
    { abbreviation: "MI", id: "02da6b626dec432684313f44134878ae" },
    { abbreviation: "MN", id: "2ea47091859f443b8fe6ffea784c548b" },
    { abbreviation: "MO", id: "1baa0702493549f9bd32bfc0e2e935a9" },
    { abbreviation: "MS", id: "133fd10cde6b411a85f06bf08f333eda" },
    { abbreviation: "MT", id: "492b03eb627a45d69069f47b2506ad72" },
    { abbreviation: "NC", id: "536a04c310f14f7cb223220a2d30f43b" },
    { abbreviation: "ND", id: "5837d9a88bbe4d00a0e220217d75f52a" },
    { abbreviation: "NE", id: "b6f605c3e0154391b3b81b582d579fcb" },
    { abbreviation: "NH", id: "cea226e78df140bdab9fc56188133e64" },
    { abbreviation: "NJ", id: "4f85a09b8a354f95b86fcebf5a9a6760" },
    { abbreviation: "NM", id: "b5b2c2a097404b4a80a2174f35324117" },
    { abbreviation: "NV", id: "3e0cbaf587534f94960a4a24f6ec4c36" },
    { abbreviation: "NY", id: "d51d1b74e0e14c9aa6b2596b735b26ce" },
    { abbreviation: "OH", id: "3c194574f02d4badbe4f4541af8e0b5f" },
    { abbreviation: "OK", id: "7f5bf95862de46369168f44086254972" },
    { abbreviation: "OR", id: "136bb7540f6a44a4ae9411cdf46f0fc4" },
    { abbreviation: "PA", id: "6fa45e8947ad475c8f1f2ae783bea54d" },
    { abbreviation: "RI", id: "98b93b6263404f008c692898ab204686" },
    { abbreviation: "SC", id: "4f9b9d8d45864c229d3d1fd2fdcce241" },
    { abbreviation: "SD", id: "ce083fc70dc64e2284569c4f9204f4ca" },
    { abbreviation: "TN", id: "1513c55a6fbf4c1881ff8d76ebe83cb7" },
    { abbreviation: "TX", id: "e65e4175cb2e469fb0cd270c38d44537" },
    { abbreviation: "UT", id: "337ccd622b18464d9d2484931d774153" },
    { abbreviation: "VA", id: "c05e2ab4099a463abc3f04fc438f5d16" },
    { abbreviation: "VT", id: "0bdd88280d07484ea409d51ae4db3a61" },
    { abbreviation: "WA", id: "b11744335b5a4c6cb41a4699c444e391" },
    { abbreviation: "WI", id: "1e76a57145a34b3a9fd08a94a00cdc24" },
    { abbreviation: "WV", id: "5b29b923705247e8ae94042642283e07" },
    { abbreviation: "WY", id: "6a4de9d7a57a4653877b538883af708a" },
  ];
}

export function mapStateFromID(stateID) {
  console.log(stateID);
  switch (stateID) {
    case "2ea938d4d58849cfb08dc123a94918c1":
      return "Alaska";
    case "13726889bf804a0ca63ea91611f07173":
      return "Alabama";
    case "b2aa6bf3449a4f63bfff12bae6921751":
      return "Arkansas";
    case "0c324192210b499386eb415b26fdee55":
      return "Arizona";
    case "a4d4504bc5394ed588b9a1a739c8afda":
      return "California";
    case "238858e803554ef08f8ed08604b626c8":
      return "Colorado";
    case "0000e5a04d8041739a1f575535c4b12a":
      return "Connecticut";
    case "0f039300d61249268217051324d4cb21":
      return "District of Columbia";
    case "4048f17397dc4bc6b9ec52c9c734afa3":
      return "Delaware";
    case "59376da4327d4ab495940176bbe826d9":
      return "Florida";
    case "ec812ec10c1f495bb0889154c72f1e15":
      return "Georgia";
    case "09434cab86874d8199a52cfb6850cb2e":
      return "Hawaii";
    case "5a3a84d50788438bb7ecfa140c4af440":
      return "Iowa";
    case "03782130eac74424986085c28a60f3d8":
      return "Idaho";
    case "edd95ee9dce344c69fd730e7dbc154e7":
      return "Illinois";
    case "a4a990b1e11347daa8d01dd1862475e2":
      return "Indiana";
    case "6927a18104704050a02f0bd472ed5db7":
      return "Kansas";
    case "2616447d755743a28a4b015f4f57c001":
      return "Kentucky";
    case "32915495484e4087ae97cb2c2bcb93e8":
      return "Louisiana";
    case "7fbd9d4433254232859c98ea71520ad2":
      return "Massachusetts";
    case "c9bd529307bd4f79aff920a9211e2e97":
      return "Maryland";
    case "9239d270120444008ccc051456d26074":
      return "Maine";
    case "02da6b626dec432684313f44134878ae":
      return "Michigan";
    case "2ea47091859f443b8fe6ffea784c548b":
      return "Minnesota";
    case "1baa0702493549f9bd32bfc0e2e935a9":
      return "Missouri";
    case "133fd10cde6b411a85f06bf08f333eda":
      return "Mississippi";
    case "492b03eb627a45d69069f47b2506ad72":
      return "Montana";
    case "536a04c310f14f7cb223220a2d30f43b":
      return "North Carolina";
    case "5837d9a88bbe4d00a0e220217d75f52a":
      return "North Dakota";
    case "b6f605c3e0154391b3b81b582d579fcb":
      return "Nebraska";
    case "cea226e78df140bdab9fc56188133e64":
      return "New Hampshire";
    case "4f85a09b8a354f95b86fcebf5a9a6760":
      return "New Jersey";
    case "b5b2c2a097404b4a80a2174f35324117":
      return "New Mexico";
    case "3e0cbaf587534f94960a4a24f6ec4c36":
      return "Nevada";
    case "d51d1b74e0e14c9aa6b2596b735b26ce":
      return "New York";
    case "3c194574f02d4badbe4f4541af8e0b5f":
      return "Ohio";
    case "7f5bf95862de46369168f44086254972":
      return "Oklahoma";
    case "136bb7540f6a44a4ae9411cdf46f0fc4":
      return "Oregon";
    case "6fa45e8947ad475c8f1f2ae783bea54d":
      return "Pennsylvania";
    case "98b93b6263404f008c692898ab204686":
      return "Rhode Island";
    case "4f9b9d8d45864c229d3d1fd2fdcce241":
      return "South Carolina";
    case "ce083fc70dc64e2284569c4f9204f4ca":
      return "South Dakota";
    case "1513c55a6fbf4c1881ff8d76ebe83cb7":
      return "Tennessee";
    case "e65e4175cb2e469fb0cd270c38d44537":
      return "Texas";
    case "337ccd622b18464d9d2484931d774153":
      return "Utah";
    case "c05e2ab4099a463abc3f04fc438f5d16":
      return "Virginia";
    case "0bdd88280d07484ea409d51ae4db3a61":
      return "Vermont";
    case "b11744335b5a4c6cb41a4699c444e391":
      return "Washington";
    case "1e76a57145a34b3a9fd08a94a00cdc24":
      return "Wisconsin";
    case "5b29b923705247e8ae94042642283e07":
      return "West Virginia";
    case "6a4de9d7a57a4653877b538883af708a":
      return "Wyoming";
    default:
      return null;
  }
}

export function mapStateAbbrFromID(stateID) {
  switch (stateID) {
    case "2ea938d4d58849cfb08dc123a94918c1":
      return "AK";
    case "13726889bf804a0ca63ea91611f07173":
      return "AL";
    case "b2aa6bf3449a4f63bfff12bae6921751":
      return "AR";
    case "0c324192210b499386eb415b26fdee55":
      return "AZ";
    case "a4d4504bc5394ed588b9a1a739c8afda":
      return "CA";
    case "238858e803554ef08f8ed08604b626c8":
      return "CO";
    case "0000e5a04d8041739a1f575535c4b12a":
      return "CT";
    case "0f039300d61249268217051324d4cb21":
      return "DC";
    case "4048f17397dc4bc6b9ec52c9c734afa3":
      return "DE";
    case "59376da4327d4ab495940176bbe826d9":
      return "FL";
    case "ec812ec10c1f495bb0889154c72f1e15":
      return "GA";
    case "09434cab86874d8199a52cfb6850cb2e":
      return "HI";
    case "5a3a84d50788438bb7ecfa140c4af440":
      return "IA";
    case "03782130eac74424986085c28a60f3d8":
      return "ID";
    case "edd95ee9dce344c69fd730e7dbc154e7":
      return "IL";
    case "a4a990b1e11347daa8d01dd1862475e2":
      return "IN";
    case "6927a18104704050a02f0bd472ed5db7":
      return "KS";
    case "2616447d755743a28a4b015f4f57c001":
      return "KY";
    case "32915495484e4087ae97cb2c2bcb93e8":
      return "LA";
    case "7fbd9d4433254232859c98ea71520ad2":
      return "MA";
    case "c9bd529307bd4f79aff920a9211e2e97":
      return "MD";
    case "9239d270120444008ccc051456d26074":
      return "ME";
    case "02da6b626dec432684313f44134878ae":
      return "MI";
    case "2ea47091859f443b8fe6ffea784c548b":
      return "MN";
    case "1baa0702493549f9bd32bfc0e2e935a9":
      return "MO";
    case "133fd10cde6b411a85f06bf08f333eda":
      return "MS";
    case "492b03eb627a45d69069f47b2506ad72":
      return "MT";
    case "536a04c310f14f7cb223220a2d30f43b":
      return "NC";
    case "5837d9a88bbe4d00a0e220217d75f52a":
      return "ND";
    case "b6f605c3e0154391b3b81b582d579fcb":
      return "NE";
    case "cea226e78df140bdab9fc56188133e64":
      return "NH";
    case "4f85a09b8a354f95b86fcebf5a9a6760":
      return "NJ";
    case "b5b2c2a097404b4a80a2174f35324117":
      return "NM";
    case "3e0cbaf587534f94960a4a24f6ec4c36":
      return "NV";
    case "d51d1b74e0e14c9aa6b2596b735b26ce":
      return "NY";
    case "3c194574f02d4badbe4f4541af8e0b5f":
      return "OH";
    case "7f5bf95862de46369168f44086254972":
      return "OK";
    case "136bb7540f6a44a4ae9411cdf46f0fc4":
      return "OR";
    case "6fa45e8947ad475c8f1f2ae783bea54d":
      return "PA";
    case "98b93b6263404f008c692898ab204686":
      return "RI";
    case "4f9b9d8d45864c229d3d1fd2fdcce241":
      return "SC";
    case "ce083fc70dc64e2284569c4f9204f4ca":
      return "SD";
    case "1513c55a6fbf4c1881ff8d76ebe83cb7":
      return "TN";
    case "e65e4175cb2e469fb0cd270c38d44537":
      return "TX";
    case "337ccd622b18464d9d2484931d774153":
      return "UT";
    case "c05e2ab4099a463abc3f04fc438f5d16":
      return "VA";
    case "0bdd88280d07484ea409d51ae4db3a61":
      return "VT";
    case "b11744335b5a4c6cb41a4699c444e391":
      return "WA";
    case "1e76a57145a34b3a9fd08a94a00cdc24":
      return "WI";
    case "5b29b923705247e8ae94042642283e07":
      return "WV";
    case "6a4de9d7a57a4653877b538883af708a":
      return "WY";
    default:
      return null;
  }
}

export function mapStateIDFromAbbr(stateAbbr) {
  switch (stateAbbr) {
    case "AK":
      return "2ea938d4d58849cfb08dc123a94918c1";
    case "AL":
      return "13726889bf804a0ca63ea91611f07173";
    case "AR":
      return "b2aa6bf3449a4f63bfff12bae6921751";
    case "AZ":
      return "0c324192210b499386eb415b26fdee55";
    case "CA":
      return "a4d4504bc5394ed588b9a1a739c8afda";
    case "CO":
      return "238858e803554ef08f8ed08604b626c8";
    case "CT":
      return "0000e5a04d8041739a1f575535c4b12a";
    case "DC":
      return "0f039300d61249268217051324d4cb21";
    case "DE":
      return "4048f17397dc4bc6b9ec52c9c734afa3";
    case "FL":
      return "59376da4327d4ab495940176bbe826d9";
    case "GA":
      return "ec812ec10c1f495bb0889154c72f1e15";
    case "HI":
      return "09434cab86874d8199a52cfb6850cb2e";
    case "IA":
      return "5a3a84d50788438bb7ecfa140c4af440";
    case "ID":
      return "03782130eac74424986085c28a60f3d8";
    case "IL":
      return "edd95ee9dce344c69fd730e7dbc154e7";
    case "IN":
      return "a4a990b1e11347daa8d01dd1862475e2";
    case "KS":
      return "6927a18104704050a02f0bd472ed5db7";
    case "KY":
      return "2616447d755743a28a4b015f4f57c001";
    case "LA":
      return "32915495484e4087ae97cb2c2bcb93e8";
    case "MA":
      return "7fbd9d4433254232859c98ea71520ad2";
    case "MD":
      return "c9bd529307bd4f79aff920a9211e2e97";
    case "ME":
      return "9239d270120444008ccc051456d26074";
    case "MI":
      return "02da6b626dec432684313f44134878ae";
    case "MN":
      return "2ea47091859f443b8fe6ffea784c548b";
    case "MO":
      return "1baa0702493549f9bd32bfc0e2e935a9";
    case "MS":
      return "133fd10cde6b411a85f06bf08f333eda";
    case "MT":
      return "492b03eb627a45d69069f47b2506ad72";
    case "NC":
      return "536a04c310f14f7cb223220a2d30f43b";
    case "ND":
      return "5837d9a88bbe4d00a0e220217d75f52a";
    case "NE":
      return "b6f605c3e0154391b3b81b582d579fcb";
    case "NH":
      return "cea226e78df140bdab9fc56188133e64";
    case "NJ":
      return "4f85a09b8a354f95b86fcebf5a9a6760";
    case "NM":
      return "b5b2c2a097404b4a80a2174f35324117";
    case "NV":
      return "3e0cbaf587534f94960a4a24f6ec4c36";
    case "NY":
      return "d51d1b74e0e14c9aa6b2596b735b26ce";
    case "OH":
      return "3c194574f02d4badbe4f4541af8e0b5f";
    case "OK":
      return "7f5bf95862de46369168f44086254972";
    case "OR":
      return "136bb7540f6a44a4ae9411cdf46f0fc4";
    case "PA":
      return "6fa45e8947ad475c8f1f2ae783bea54d";
    case "RI":
      return "98b93b6263404f008c692898ab204686";
    case "SC":
      return "4f9b9d8d45864c229d3d1fd2fdcce241";
    case "SD":
      return "ce083fc70dc64e2284569c4f9204f4ca";
    case "TN":
      return "1513c55a6fbf4c1881ff8d76ebe83cb7";
    case "TX":
      return "e65e4175cb2e469fb0cd270c38d44537";
    case "UT":
      return "337ccd622b18464d9d2484931d774153";
    case "VA":
      return "c05e2ab4099a463abc3f04fc438f5d16";
    case "VT":
      return "0bdd88280d07484ea409d51ae4db3a61";
    case "WA":
      return "b11744335b5a4c6cb41a4699c444e391";
    case "WI":
      return "1e76a57145a34b3a9fd08a94a00cdc24";
    case "WV":
      return "5b29b923705247e8ae94042642283e07";
    case "WY":
      return "6a4de9d7a57a4653877b538883af708a";
    default:
      return null;
  }
}

export function formatDateTime(time) {
  var date = new Date(time);
  return date.toLocaleString();
}

export function formatDateTimeAsDate(time) {
  if (!time) return "";
  else {
    var date = new Date(time);
    return date.toLocaleDateString();
  }
}

export function formatTerminationDate(time) {
  var date = new Date(time);
  return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
}

export function formatTerminationDateDropdown(time) {
  var options = { month: "long" };
  var date = new Date(time);
  return (
    new Intl.DateTimeFormat("en-US", options).format(date.getMonth()) +
    " " +
    date.getDate() +
    "st, " +
    date.getFullYear()
  );
}

export function formatMedicareSubmissionStatus(status) {
  if (status === 0) return "Awaiting Response";
  if (status === 1) return "Awaiting Response";
  if (status === 2) return "Approved";
  if (status === 3) return "Denied";

  return "";
}
