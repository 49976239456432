import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import TrackingEventList from "../../Shared/Components/Tracking/TrackingEventList";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";
import Divider from "@material-ui/core/Divider";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class TerminationDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventDetails: [],
      loaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { Open, SelectedRecord } = this.props;
    const { loaded } = this.state;
    if (Open && !prevProps.Open && !loaded)
      this.handleGetDetails(SelectedRecord.id);
  }

  handleGetDetails = (terminationID) => {
    this.getTerminationEventDetail(this.props.SelectedRecord.id)
      .then((eventDetails) => {
        this.setState({
          eventDetails: eventDetails,
          loaded: true,
        });
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/mewa-admin/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "Something went wrong rendering termination details",
            {
              variant: "error",
            }
          );
        }
      });
  };

  getTerminationEventDetail = (terminationID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getTerminationMEWAEvents(terminationID));
    });
  };

  resendTerminationEmail = (terminationID) => {
    return new Promise((resolve, reject) => {
      resolve(api.resendTerminationEmail(terminationID));
    });
  };

  handleResend = () => {
    this.resendTerminationEmail(this.props.SelectedRecord.id)
      .then(() => {
        this.props.enqueueSnackbar("Email Resent", {
          variant: "success",
        });
        this.close();
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/mewa-admin/auth");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "Something went wrong rendering termination details",
            {
              variant: "error",
            }
          );
        }
      });
  };

  close = () => {
    this.setState(
      {
        eventDetails: [],
        loaded: false,
      },
      () => {
        this.props.Close();
      }
    );
  };

  handleClose = () => {
    this.close();
  };

  formatBoolean = (val) => {
    if (val) return "Yes";
    return "No";
  };

  render() {
    const { Open } = this.props;
    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Termination Details</DialogTitle>

          <DialogContent style={{ height: 550, minWidth: 325, marginTop: 10 }}>
            {this.state.loaded && (
              <React.Fragment>
                <Typography variant="body1">Date Added:</Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.date_added
                    ? formatDateTime(this.props.SelectedRecord.date_added)
                    : ""}
                </Typography>

                <Divider style={{ margin: 20 }} />

                <Typography variant="body1">Employer Contact</Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.employer_contact_first}{" "}
                  {this.props.SelectedRecord.employer_contact_last} (
                  {this.props.SelectedRecord.employer_contact_email})
                </Typography>
                <div style={{ height: 10 }}></div>
                <Typography variant="body1">
                  Carrier Termination Reason
                </Typography>
                <Typography variant="caption">
                  -{this.props.SelectedRecord.original_termination_reason_text}
                </Typography>
                <div style={{ height: 10 }}></div>
                <Typography variant="body1">Verify With Employer</Typography>
                <Typography variant="caption">
                  {Object.keys(this.props.SelectedRecord).length !== 0
                    ? this.formatBoolean(this.props.SelectedRecord.verify)
                    : "-"}
                </Typography>

                <Divider style={{ margin: 20 }} />

                <Typography variant="body1">Brokers</Typography>
                {this.props.SelectedRecord.brokers.map((broker, index) => {
                  return (
                    <Typography key={index} variant="caption">
                      {broker.name} ({broker.email})<br />
                    </Typography>
                  );
                })}

                <div style={{ height: 10 }}></div>
                <Typography variant="body1">Date Broker Notified</Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.date_broker_notified
                    ? formatDateTime(
                        this.props.SelectedRecord.date_broker_notified
                      )
                    : ""}
                </Typography>

                <Divider style={{ margin: 20 }} />

                <Typography variant="body1">Date Sent</Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.date_sent
                    ? formatDateTime(this.props.SelectedRecord.date_sent)
                    : "-"}
                </Typography>
                <div style={{ height: 10 }}></div>
                <Typography variant="body1">Date Opened</Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.date_opened
                    ? formatDateTime(this.props.SelectedRecord.date_opened)
                    : "-"}
                </Typography>
                <div style={{ height: 10 }}></div>
                <Typography variant="body1">Date Verified</Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.date_verified
                    ? formatDateTime(this.props.SelectedRecord.date_verified)
                    : "-"}
                </Typography>
                <div style={{ height: 10 }}></div>
                <Typography variant="body1">Date Closed</Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.date_closed
                    ? formatDateTime(this.props.SelectedRecord.date_closed)
                    : "-"}
                </Typography>

                <Divider style={{ margin: 20 }} />

                <Typography variant="body1">
                  Final Termination Reason
                </Typography>
                <Typography variant="caption">
                  {this.props.SelectedRecord.termination_reason_text}
                </Typography>
                <div style={{ height: 10 }}></div>
                <Typography variant="body1">Eligible Event</Typography>
                <Typography variant="caption">
                  {Object.keys(this.props.SelectedRecord).length !== 0
                    ? this.formatBoolean(this.props.SelectedRecord.include_mcp)
                    : ""}
                </Typography>
                <div style={{ height: 10 }}></div>
                <Typography variant="body1">
                  Date Sent to COBRA Admin
                </Typography>
                {this.props.SelectedRecord.include_mcp && (
                  <Typography variant="caption">
                    {this.props.SelectedRecord.date_sent_cobra_admin
                      ? formatDateTime(
                          this.props.SelectedRecord.date_sent_cobra_admin
                        )
                      : "-"}
                  </Typography>
                )}
                {!this.props.SelectedRecord.include_mcp && (
                  <Typography variant="caption">-</Typography>
                )}

                <Divider style={{ marginTop: 20, marginBottom: 30 }} />

                <Typography variant="h6">Detailed Event History</Typography>
                <TrackingEventList
                  EventDetails={this.state.eventDetails}
                  SelectedRecord={this.props.SelectedRecord}
                />
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            <Button
              name="resendEmailButton"
              onClick={this.handleResend}
              color="primary"
            >
              Resend
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(TerminationDetail));
export { TerminationDetail as PureTerminationDetail };
