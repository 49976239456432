import { createTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";

export default createTheme({
  palette: {
    primary: {
      light: blue[600],
      //main: blue[800],
      main: "#2996cc",
      dark: blue[1000],
      contrastText: "#fff",
    },
    //text: {
    //  primary: "#ffffff",
    //////None},
    disabled: grey,
    secondary: {
      light: '#FF9C29',
      main: '#f57921',
      dark: '#FF9C29',
    },
    error: red
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: "4px 24px 4px 16px"
      }
    }
  },
  typography: {
    useNextVariants: true,
  },
});