import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import PublicIcon from "@material-ui/icons/Public";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import classnames from "classnames";
import { formatDateTime } from "../../Functions/Format";
import { withStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  private: {
    backgroundColor: "#fff8ed",
    border: "#fcdcac 1px solid",
    borderRadius: '4px'
  },
}));

const RecordNoteList = ({ RecordNotes }) => {
  const classes = useStyles();

  return (
    <List>
      {RecordNotes.map((note, index) => {
        return (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <React.Fragment>
                  {note.is_public && (
                    <Avatar>
                      <PublicIcon />
                    </Avatar>
                  )}
                  {!note.is_public && (
                    <Avatar>
                      <LockIcon />
                    </Avatar>
                  )}
                </React.Fragment>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{note.author_name}</span>
                      <span>
                        <Typography variant="caption">
                          {formatDateTime(note.date_added)}
                        </Typography>
                      </span>
                    </div>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ padding: 15, display: 'block' }}
                      className={classnames(
                        { [classes.private]: !note.is_public },
                      )}
                      color="textPrimary"
                    >
                      {note.message}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default withStyles(null, { withTheme: true })(RecordNoteList);
