import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import api from "../../Functions/APIHelpers";
import { formatDateTime } from "../../Functions/Format";
import TextField from "@material-ui/core/TextField";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { RemoveRedEye } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";
//import { withStyles } from "@material-ui/core/styles";
//import { styles } from "../../Shared/Styles/ModalStyles";


class PasswordInput extends React.Component {
    constructor(props) {
      super(props);
  
      this.state ={
        loaded: false,
        characterLength: true,
        specialChar: false,
        number: false,
        passwordIsMasked: true
      };
    }

    togglePasswordMask = () => {
      this.setState(prevState => ({
        passwordIsMasked: !prevState.passwordIsMasked
      }));
    };
  
    render() {
      const { passwordIsMasked } = this.state;

      return (
          <React.Fragment>
          <Typography variant="caption">
              Password must contain at least 8 characters, including at least 1 number, and 1 special character.
              </Typography>
              <div style={{height:20}}></div>
              <TextField
                  id="password-input-box"
                  label={"Password"}
                  margin="dense"
                  onChange={(e) =>
                    this.props.SetPassword(e.target.value)
                  }
                  defaultValue={this.props.Password}
                  style={{ width: 300 }}
                  autoComplete="off"
                  type={passwordIsMasked ? "password" : "text"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <RemoveRedEye
                          onClick={this.togglePasswordMask}
                          style={{ color: "grey", cursor:"pointer" }}
                        />
                      </InputAdornment>
                    )
                  }}                
                />
              <div style={{marginLeft:30}}>
              <List>
                <ListItem dense={true}>
                  <ListItemIcon>
                    { (this.props.Password.match(/^(?=.{8,})/) )  ? <CheckCircleIcon color="primary" /> : <NotInterestedIcon /> }
                  </ListItemIcon>
                  <ListItemText
                    style={{marginLeft:-20}}
                    primary="At Least 8 Characters"
                  />
                </ListItem>
                <ListItem dense={true}>
                  <ListItemIcon>
                  { (this.props.Password.match(/(?=.*[0-9])/) ) ? <CheckCircleIcon color="primary" /> : <NotInterestedIcon /> }
                  </ListItemIcon>
                  <ListItemText
                  style={{marginLeft:-20}}
                    primary="At Least 1 Number"
                  />
                </ListItem>
                <ListItem dense={true}>
                  <ListItemIcon>
                  { (this.props.Password.match(/^(?=.*[!@#$%^&*])/) ) ? <CheckCircleIcon color="primary" /> : <NotInterestedIcon /> }
                  </ListItemIcon>
                  <ListItemText
                    style={{marginLeft:-20}}
                    primary="At Least 1 Special Character"
                  />
                </ListItem>
            </List>
            </div>

              </React.Fragment>
      )
      }    
}

export default (PasswordInput);
