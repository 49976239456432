import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../../../config";
import { styles } from "../../../Styles/AuthStyles";
import { withSnackbar } from "notistack";
//import { styles } from "../../Shared/Styles/ContainerStyles";
import BrokerRequestAllDone from "./BrokerRequestAllDone";
import api from "../../../../Shared/Functions/APIHelpers";
import { Auth } from "aws-amplify";

class BrokerRequestAccount extends React.Component {
  state = {
    lookupEmail: "",
    finished: false
  };

  componentDidMount() {
      let brokerEmail = this.getBrokerEmail()
      if(brokerEmail){
        this.handleSendBrokerCreateAccountLink(brokerEmail)
      }
  }

  handleSendBrokerCreateAccountLink = (brokerEmail) => {
    let body = {
        broker_email: brokerEmail
      };
    api.requestAccountLink(JSON.stringify(body)).then(r=>{
        this.setState({finished: true})
    })
  };

  getBrokerEmail = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return decodeURIComponent(params.get("email"));
  };

  redirectUser = (code) => {
    if (this.props.loginURL.indexOf("portalID") > 0) {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "&message=" + code;
    } else {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "?message=" + code;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper className={classes.root} elevation={5} style={{ zIndex: 10 }}>
          <div className={classes.header}>
            <div className={classes.logoContainer}>
              <img
                src={require("../../../../Shared/Images/mewaio_vert_350.png")}
                className={classes.largeLogo}
                alt="mewa.io Logo"
              />
            </div>

            
            {(this.state.finished) && (
                <BrokerRequestAllDone/>
            )}
            
          </div>
        </Paper>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(BrokerRequestAccount));
