import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../Functions/APIHelpers";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Storage } from "aws-amplify";
import DownloadIcon from "@material-ui/icons/CloudDownload";
let DocumentComponent = null;
let PageComponent = null;
import("react-pdf")
  .then(({ Document, Page }) => {
    DocumentComponent = Document;
    PageComponent = Page;
  })
  .catch((err) => console.log("Unsupported"));

const styles = (theme) => ({
  overrideDiv: {
    float: "right",
    "@media only screen and (max-width: 400px)": {
      display: "none",
    },
  },
});

class FormViewer extends React.Component {
  state = {
    numPages: null,
    showOverrides: false,
    canvasWidth: 0,
    canvasHeight: 0,
    pageHeight: 0,
    pageWidth: 0,
    pageNumber: 1,
    formNumber: 1,
    editModalOpen: false,
    isLoaded: false,
    override: {},
    field: "",
    fieldName: "",
    fieldType: "",
    overrides: [],
    fit: "width",
  };

  componentDidMount() {
    this.updateDimensions();
    if (this.props.overrides) this.getOverrides();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages, isLoaded: true }, () => {
      this.updateDimensions();
    });
  };

  scrollToMyRef1 = () => {
    var element = this.refs.myRef1;

    element.scrollIntoView({ behavior: "auto", block: "end" });
  };

  getOverrides = () => {
    return new Promise((resolve, reject) => {
      api
        .getMappingRuleOverrides(this.props.User)
        .then((response) => {
          this.setState({ overrides: response });
          resolve(response);
        })
        .catch((err) => {
          this.setState({
            errorMessage: "Error Getting Mapping Rule Overrides",
          });
          console.log(err);
        });
    });
  };

  maxWidth(width) {
    var maxWidth = 0;
    var margin = 0;

    if (this.props.MaxWidth === 1400) {
      margin = 100;
    } else {
      margin = 300;
    }

    if (width > 1600) {
      maxWidth = this.props.MaxWidth;
    } else if (width > 850) {
      maxWidth = (width - margin) * 0.9;
    } else if (width > 400) {
      maxWidth = width * 0.9;
    } else {
      maxWidth = 335;
    }

    if (maxWidth > this.props.MaxWidth) {
      return this.props.MaxWidth;
    } else {
      return maxWidth;
    }
  }

  maxHeight(height) {
    if (height > 1800) {
      return 1350;
    } else if (height > 800) {
      return height - 450;
    } else {
      return 900;
    }
  }

  updateDimensions = () => {
    var w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName("body")[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height =
        w.innerHeight || documentElement.clientHeight || body.clientHeight,
      pageWidth = 0,
      pageHeight = 0;

    this.setState({ width, height });

    var maxWidth = this.maxWidth(width);
    var maxHeight = this.maxHeight(height);

    if (this.state.fit === "width") {
      pageWidth = maxWidth;
      pageHeight = maxWidth * 1.2928;
    } else {
      if (maxHeight * 0.773 < maxWidth && maxWidth > 330) {
        pageHeight = maxHeight;
        pageWidth = maxHeight * 0.773;
      } else {
        pageWidth = maxWidth;
        pageHeight = maxWidth * 1.2928;
      }
    }

    var canvasHeight = (pageHeight + 12) * this.state.numPages;
    var canvasWidth = pageWidth + 2;

    this.setState(
      {
        pageHeight: pageHeight,
        pageWidth: pageWidth,
        canvasHeight: canvasHeight,
        canvasWidth: canvasWidth,
      },
      () => {
        this.highlightOverrides();
      }
    );
  };

  renderHighlights = (fields, action, e) => {
    if (e) {
      var xCoor = e.nativeEvent.offsetX;
      var yCoor = e.nativeEvent.offsetY;
    }

    var scale = 1.465 * (this.state.canvasWidth / 900);
    var pageSize = this.state.pageHeight;

    var highlight_layer = this.refs.highlight_layer;
    const ctx = this.refs.highlight_layer.getContext("2d");

    var overField = false;

    for (var i = 0; i < fields.length; i++) {
      var left = fields[i].left;
      var top = fields[i].top;

      var height = fields[i].height;
      var width = fields[i].width;

      var preceding_page_height = (pageSize + 14) * (fields[i].page_number - 1);
      var page_location = pageSize - top * scale;
      var top_resized = preceding_page_height + page_location;

      var left_resized = left * scale + 2;

      var height_resized = height * scale;
      var width_resized = width * scale;

      var right_resized = left_resized + width_resized;
      var bottom_resized = top_resized + height_resized;

      if (
        e &&
        xCoor > left_resized &&
        xCoor < right_resized &&
        yCoor > top_resized &&
        yCoor < bottom_resized
      ) {
        overField = true;

        if (action === "HighlightSingle") {
          ctx.clearRect(0, 0, highlight_layer.width, highlight_layer.height);
          ctx.fillStyle = "rgba(41, 150, 204, 0.33)";
          ctx.fillRect(
            left_resized,
            top_resized,
            width_resized,
            height_resized
          );
        } else if (action === "Click") {
          var override = this.state.overrides.find(
            (override, i) => override.field.id === fields[i].id
          );

          if (override) {
            this.setState({
              isEdit: true,
              override: override,
            });
          } else {
            this.setState({
              isEdit: false,
              override: {},
            });
          }

          this.setState({
            fieldID: fields[i].id,
            fieldName: fields[i].name,
            fieldType: fields[i].field_type,
            editModalOpen: true,
          });
        }
      } else if (action === "HighlightAll") {
        ctx.fillStyle = "rgba(41, 150, 204, 0.33)";
        ctx.fillRect(left_resized, top_resized, width_resized, height_resized);
      }
    }

    if (!overField) {
      if (action === "HighlightSingle") {
        ctx.clearRect(0, 0, highlight_layer.width, highlight_layer.height);
      }
    }
  };

  onMouseMove = (e) => {
    if (!this.props.overrides || this.props.fields.length === 0) return;
    var fields = this.props.fields;
    this.renderHighlights(fields, "HighlightSingle", e);

    this.highlightOverrides();
  };

  onClick = (e) => {
    if (!this.props.overrides || this.props.fields.length === 0) return;
    var fields = this.props.fields;
    this.renderHighlights(fields, "Click", e);
  };

  clearHighlights() {
    var highlight_layer = this.refs.highlight_layer;
    const ctx = this.refs.highlight_layer.getContext("2d");

    ctx.clearRect(0, 0, highlight_layer.width, highlight_layer.height);
  }

  highlightOverrides() {
    if (!this.state.showOverrides) {
      return;
    }

    var fields = this.props.fields;
    var overrides = this.state.overrides;

    var filtered_fields = fields.filter(function (item) {
      return overrides.map((f) => f.field.id).includes(item.id);
    });

    this.renderHighlights(filtered_fields, "HighlightAll", null);
  }

  reload = () => {
    this.getOverrides();
    this.props.Reload();
  };

  handleClose = () => {
    this.setState({ editModalOpen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });

    if (name !== "showOverrides") {
      if (this.state.fit === "width") {
        this.setState(
          {
            fit: "height",
          },
          () => {
            this.updateDimensions();
          }
        );
      } else {
        this.setState(
          {
            fit: "width",
          },
          () => {
            this.updateDimensions();
          }
        );
      }
      this.updateDimensions();
    } else {
      if (this.state.showOverrides) {
        this.setState(
          {
            showOverrides: false,
          },
          () => {
            this.clearHighlights();
          }
        );
      } else {
        this.setState(
          {
            showOverrides: true,
          },
          () => {
            this.highlightOverrides();
          }
        );
      }
    }
  };

  createPages = (numPages, width) => {
    var pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <div
          key={"page_" + i}
          style={{ border: "1px solid #f2f2f2", marginBottom: 10 }}
        >
          <PageComponent
            pageNumber={i}
            width={width}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            loading={<CircularProgress />}
          />
        </div>
      );
    }

    return pages;
  };

  handleDownload = () => {
    console.log(this.props.theme);
    console.log(styles);
    //theme.palette.secondary.main,
    const link = document.createElement("a");
    link.href = this.props.Form.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { classes } = this.props;

    if (!DocumentComponent && !PageComponent) {
      return (
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{ marginTop: 50, textAlign: "center", fontSize: 20 }}
          >
            Unable to Preview Form
          </Typography>
          <Button
            edge="start"
            style={{
              backgroundColor: "#f57921",
              width: "150px",
              marginTop: 10,
            }}
            onClick={() => {
              this.handleDownload();
            }}
            variant="contained"
            startIcon={<DownloadIcon style={{ color: "#fff" }} />}
          >
            <Typography style={{ color: "#fff" }}>Download</Typography>
          </Button>
        </Box>
      );
    }
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <div
            id="myRef1"
            ref="myRef1"
            style={{ height: 0, marginTop: 0, display: "block" }}
          />

          <div
            style={{
              marginBottom: 20,
              display: this.state.isLoaded ? "none" : "block",
            }}
          >
            <CircularProgress style={{ marginTop: 20 }} />
            <Typography variant="body1" style={{ marginTop: 30 }}>
              Loading forms...
            </Typography>
          </div>

          {/*Use inline-block to keep aligned with form */}
          <div
            style={{
              border: "0px solid #f2f2f2",
              width: "100%",
              verticalAlign: "middle",
              marginBottom: 0,
              position: "relative",
              textAlign: "center",
              display: this.state.isLoaded ? "block" : "none",
            }}
          >
            <div style={{ display: "inline-block", width: "100%" }}>
              <div style={{ float: "left" }}>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginTop: 12, marginRight: 15 }}
                  >
                    Reviewing form {this.props.FormNumber} of{" "}
                    {this.props.FormTotal}
                  </Typography>
                  <div className={classes.mobile}>
                    <Typography variant="caption">
                      Fit Height
                      <Switch
                        defaultChecked
                        value="fit"
                        color="default"
                        onChange={this.handleChange("fit")}
                      />
                      Fit Width
                    </Typography>
                  </div>
                </div>
              </div>
              {this.props.overrides && (
                <div className={classes.overrideDiv}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.showOverrides}
                        onChange={this.handleChange("showOverrides")}
                        value="showOverrides"
                        color="primary"
                      />
                    }
                    label="Highlight Overrides"
                  />
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              border: "0px solid #f2f2f2",
              width: this.state.canvasWidth,
              height: this.state.canvasHeight,
              marginBottom: 10,
              position: "relative",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            <div style={{ display: this.state.isLoaded ? "block" : "none" }}>
              <canvas
                ref="highlight_layer"
                width={this.state.canvasWidth}
                height={this.state.canvasHeight}
                style={{ position: "absolute", left: 0, top: 0, zIndex: 500 }}
                onMouseMove={this.onMouseMove}
                onClick={this.onClick}
              />

              <DocumentComponent
                id="doc_id"
                style={{ position: "absolute", left: 0, top: 0 }}
                file={this.props.Form.url}
                loading={<CircularProgress />}
                onLoadSuccess={this.onDocumentLoad}
              >
                {this.createPages(this.state.numPages, this.state.pageWidth)}
              </DocumentComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FormViewer);
