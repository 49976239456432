import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { DropzoneDialog } from "material-ui-dropzone";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classnames from "classnames";

import RecordNotes from "../../Shared/Components/Notes/RecordNotes";
import { withStyles } from "@material-ui/core/styles";
import { formatMedicareSubmissionStatus } from "../../Shared/Functions/Format";
import { styles } from "../../Shared/Styles/ModalStyles";
import api from "../../Shared/Functions/APIHelpers";
import * as moment from "moment";

class MedicareSubmissionNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      requestFieldFocus: "",
      loaded: false,
      recordDetailLoaded: false,
      expandedRecord: {},
      expandedRecordStatus: 0,
      recordNotes: [],
      recordNoteText: "",
      isPublic: false,
      confirmSubmit: false,
      uploadAcceptanceDialogOpen: false,
      uploadedAcceptanceFile: {},
      recordCurrentAcceptance: "",
      recordNewAcceptance: "",
      submissionRecords: [],
      submissionNotes: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { Open, Submission } = this.props;
    const { loaded } = this.state;

    if (Open && !prevProps.Open && !loaded)
      this.handleGetSubmissionRecords(Submission);
  }

  handleGetSubmissionRecords = (medicareSubmission, keepExpand) => {
    this.getMedicareSubmissionRecords(medicareSubmission.id).then((r) => {
      this.setState({
        submissionRecords: r,
        loaded: true,
        expandedRecord: {},
        expandedRecordStatus: 0,
        recordDetailLoaded: false,
        recordNotes: [],
        recordNoteText: "",
        isPublic: false,
      });
    });
  };

  getMedicareSubmissionRecords = (submissionID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getMedicareSubmissionRecordsMEwA(submissionID));
    });
  };

  handleExpandRecord = (record, expanded) => {
    if (!expanded) {
      //When closing we need to get info for *ALL* records again. This is becaus the "Status" line in the header
      //will be reflected by the expandedRecordStatus state element as a user is editing notes, but when closed, the status
      //in the accordion header will rely on whatever status is in submission records state array which is populated by handleGetSubmissionRecords
      this.handleGetSubmissionRecords(this.props.Submission);
      return;
    } else {
      this.setState({
        expandedRecord: record,
        expandedRecordStatus: record.status,
      });
      this.getMedicareRecordNotes(record.id, this.props.Submission.id).then(
        (r) => {
          this.setState({
            recordNotes: r.notes,
            recordCurrentAcceptance: r.acceptance_file,
            recordNewAcceptance: r.acceptance_file, //seed new with old value
            recordDetailLoaded: true,
          });
        }
      );
    }
  };

  getMedicareRecordNotes = (recordID, submissionID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getMedicareSubmissionNotesMEwA(recordID, submissionID));
    });
  };

  handleUpdateMedicareRecord = (medicareRecord) => {
    if (this.state.recordCurrentAcceptance !== this.state.recordNewAcceptance) {
      //get presigned URL
      //upload file
      //update medicare record with fileName
      this.getAcceptanceFilePresignedURL(medicareRecord.id).then((r) => {
        this.uploadAcceptanceFile(r).then((r) => {
          this.updateMedicareRecord(medicareRecord.id, true).then((r) => {
            this.setState({
              recordNotes: r,
              recordNoteText: "",
              isPublic: false,
              expandedRecord: medicareRecord,
              recordDetailLoaded: true,
            });
          });
        });
      });
    } else {
      this.updateMedicareRecord(medicareRecord.id, false).then((r) => {
        this.setState({
          recordNotes: r,
          recordNoteText: "",
          isPublic: false,
          expandedRecord: medicareRecord,
          recordDetailLoaded: true,
        });
      });
    }
  };

  getAcceptanceFilePresignedURL = (medicareID) => {
    let body = {
      acceptance_file: this.state.uploadedAcceptanceFile[0].name,
      submission_id: this.props.Submission.id,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.getMedicareSubmissionNotesMEWAPresignedURL(
          medicareID,
          JSON.stringify(body)
        )
      );
    });
  };

  uploadAcceptanceFile = (s3File) => {
    return new Promise((resolve, reject) => {
      //create a request Body that consists of the necessary presigned URL
      //information from s3 AND the actual file object we are trying to upload
      //as a key called FILE, then post it
      var s3Fields = s3File.fields;
      const formData = new FormData();
      Object.keys(s3Fields).forEach((key) => {
        formData.append(key, s3Fields[key]);
      });
      formData.append("file", this.state.uploadedAcceptanceFile[0]);
      //post the acceptance file to S3
      fetch(s3File.url, {
        method: "POST",
        body: formData,
      })
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  updateMedicareRecord = (medicareRecordID, acceptanceLetter) => {
    let body = {
      message: this.state.recordNoteText,
      is_public: this.state.isPublic,
      status: this.state.expandedRecordStatus,
      submission_id: this.props.Submission.id,
    };

    if (acceptanceLetter)
      body.acceptance_file = this.state.uploadedAcceptanceFile[0].name;
    else body.acceptance_file = this.state.recordCurrentAcceptance;

    return new Promise((resolve, reject) => {
      resolve(
        api.addMedicareSubmissionNoteMEWA(
          medicareRecordID,
          JSON.stringify(body)
        )
      );
    });
  };

  close = () => {
    this.setState({ loaded: false, submissionNotes: [] }, () => {
      this.props.RefreshTable();
      this.props.Close();
    });
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <DropzoneDialog
          open={this.state.uploadAcceptanceDialogOpen}
          onSave={(f) => {
            console.log(f);
            this.setState({
              uploadedAcceptanceFile: f,
              recordNewAcceptance: f[0].name,
              uploadAcceptanceDialogOpen: false,
            });
            //addUploadedFiles(f);
          }}
          acceptedFiles={[".pdf", ".doc", ".docx", "image/*", ".HEIC"]}
          maxFileSize={7000000}
          showAlerts={["error"]}
          previewText={"Selected Files: "}
          onClose={() => {
            this.setState({ uploadAcceptanceDialogOpen: false });
          }}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{
            container: { spacing: 1, direction: "row" },
          }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          filesLimit={11}
        />
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          scroll="paper"
          maxWidth={false}
        >
          <DialogTitle id="form-dialog-title">
            Medicare Submission Notes
          </DialogTitle>

          <DialogContent style={{ height: 600, width: 600 }}>
            {this.state.loaded && (
              <React.Fragment>
                <div>
                  {this.state.submissionRecords.map((record, index) => {
                    return (
                      <Accordion
                        key={index}
                        onChange={(e, expanded) => {
                          this.handleExpandRecord(record, expanded);
                        }}
                        expanded={this.state.expandedRecord === record}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={classnames({
                            [classes.expandedAccordionHeader]:
                              this.state.expandedRecord === record,
                          })}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography>
                              {record.name + " (" + record.employer_name + ")"}
                            </Typography>
                            <Typography variant="caption">
                              {this.state.expandedRecord === record &&
                                formatMedicareSubmissionStatus(
                                  this.state.expandedRecordStatus
                                )}
                              {this.state.expandedRecord !== record &&
                                formatMedicareSubmissionStatus(record.status)}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexWrap: "wrap" }}>
                          {this.state.recordDetailLoaded && (
                            <React.Fragment>
                              <FormControl
                                fullWidth={true}
                                style={{ marginBottom: 20 }}
                              >
                                <InputLabel
                                  id="medicare_status-dropdown"
                                  htmlFor="medicare-status_select"
                                >
                                  Status
                                </InputLabel>
                                <Select
                                  id="medicare-status_select"
                                  value={this.state.expandedRecordStatus}
                                  onChange={(e) => {
                                    this.setState({
                                      expandedRecordStatus: e.target.value,
                                    });
                                  }}
                                >
                                  <MenuItem
                                    id="medicare-status-awaiting-li"
                                    value={1}
                                  >
                                    Awaiting Response
                                  </MenuItem>
                                  <MenuItem
                                    id="medicare-status-approved-li"
                                    value={2}
                                  >
                                    Approved
                                  </MenuItem>
                                  <MenuItem
                                    id="medicare-status-denied-li"
                                    value={3}
                                  >
                                    Denied
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <TextField
                                id="outlined-multiline-flexible"
                                label={"Note Text " + "(" + this.state.recordNoteText.length.toString() + " / 1000) " }
                                multiline
                                fullWidth
                                rows={4}
                                value={this.state.recordNoteText}
                                onChange={(e) => {
                                  if(this.state.recordNoteText.length < 1000)
                                    this.setState({
                                      recordNoteText: e.target.value,
                                    });
                                }}
                                variant="outlined"
                                style={{ marginBottom: 20 }}
                              />
                              <div style={{ marginBottom: 20 }}>
                                <Button
                                  color="primary"
                                  startIcon={<CloudDownloadIcon />}
                                  onClick={() => {
                                    this.setState({
                                      uploadAcceptanceDialogOpen: true,
                                    });
                                  }}
                                  style={{ marginLeft: -3 }}
                                >
                                  Upload Acceptance Letter
                                </Button>

                                {this.state.recordNewAcceptance && (
                                  <React.Fragment>
                                    <br />
                                    <span>
                                      {this.state.recordNewAcceptance}
                                    </span>
                                  </React.Fragment>
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.isPublic}
                                      onChange={(e) => {
                                        this.setState({
                                          isPublic: e.target.checked,
                                        });
                                      }}
                                      value="Public Note"
                                      color="primary"
                                    />
                                  }
                                  //style={{ marginTop: 40 }}
                                  label="Public (Share status and note with employer)"
                                />
                                <Button
                                  onClick={() => {
                                    this.handleUpdateMedicareRecord(record);
                                  }}
                                  size="small"
                                  color="primary"
                                  autoFocus
                                  variant="contained"
                                >
                                  Update
                                </Button>
                              </div>
                              <RecordNotes
                                Refresh={() => {
                                  this.getMedicareRecordNotes(record.id);
                                }}
                                Record={record}
                                RecordNotes={this.state.recordNotes}
                                CanAdd={false}
                                style={{ marginTop: 30 }}
                              />
                            </React.Fragment>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <React.Fragment>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </React.Fragment>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(MedicareSubmissionNotes));
