import React from "react";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from '@material-ui/core/Input';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { getStates } from "../../Functions/Format";
import { KeyboardDatePicker } from '@material-ui/pickers'
import EmployerAsyncSelect from "../Employer/EmployerAsyncSelect";
import EINTextBox, {
  mapEINToMask,
  outputPhoneFromMask,
} from "../Input/EINTextBox";

const styles = theme => ({
  fieldContainer: {
    width: 500,
    "@media only screen and (max-width: 600px)": {
      width: "100%"
    }
  }
});

const MedicareControl = props => {

  const stateAutoSelect = (event) => {
    let keyPressed = event.key.toLowerCase()
    if(keyPressed.match(/[a-z]/) && (keyPressed.length === 1)){ //this means character
      let state = getStates().find(s=>s.abbreviation.charAt(0).toLowerCase().startsWith(keyPressed))
      if(state)
        props.onChange(state.id,"employer_state")
    }
  };

  const { errors, classes  } = props;
  return (
    <React.Fragment>
      <div className={classes.fieldContainer}>
        {(props.isAdd && (
          <EmployerAsyncSelect handleEmployerChange={props.employerChange} />
        )) || (
          <TextField
            id="employer-name-text-field"
            label="Employer Name"
            disabled={!props.isAdd}
            error={errors.includes("employer_name")}
            helperText={errors.includes("employer_name") ? "Required" : ""}
            margin="dense"
            onChange={e => props.onChange(e.target.value, "employer_name")}
            value={props.employer_name}
            fullWidth
          />
        )}

        <FormControl fullWidth style={{marginTop:10}}>
          <InputLabel htmlFor="formatted-text-mask-input">
            EIN
          </InputLabel>
          <Input
            value={props.EIN}
            onChange={(e) => props.onChange(e.target.value, "EIN")}
            disabled={!props.isAdd || props.usingEmployerPreload}
            name="textmask"
            id="formatted-text-mask-input"
            inputComponent={EINTextBox}
            
          />
        </FormControl>

        <TextField
          id="numberEmployeesTextField"
          label="Number of Employees"
          margin="dense"
          error={errors.includes("number_employees")}
          helperText={errors.includes("number_employees") ? "Required" : ""}
          onChange={e => props.onChange(e.target.value,"number_employees")}
          value={props.number_employees}
          fullWidth
        />
        <Typography variant="caption" color="inherit">
        Total number of full and part-time employees, including parent companies, subsidiaries, and sibling companies.
        </Typography>
        

        <FormLabel component="legend" style={{ marginTop: 70 }}>
          Address
        </FormLabel>
        <TextField
          id="employerStreetTextField"
          label="Employer Street"
          margin="dense"
          error={errors.includes("employer_street")}
          helperText={errors.includes("employer_street") ? "Required" : ""}
          onChange={e => props.onChange(e.target.value,"employer_street")}
          value={props.employer_street}
          fullWidth
        />

        <TextField
          id="employerCityTextField"
          label="Employer City"
          margin="dense"
          error={errors.includes("employer_city")}
          helperText={errors.includes("employer_city") ? "Required" : ""}
          onChange={e => props.onChange(e.target.value,"employer_city")}
          value={props.employer_city}
          fullWidth
        />

        <FormControl margin="dense" onKeyUp={stateAutoSelect} fullWidth>
          <InputLabel htmlFor="states">State</InputLabel>
          <Select
            value={props.employer_state || ""}
            onChange={e => props.onChange(e.target.value,"employer_state")}
            inputProps={{
              name: "employerState",
              id: "employer-state"
            }}
            style={{ width: 70, marginRight: 10 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {getStates().map(state => {
              return (
                <MenuItem value={state.id} key={"state_" + state.id}>
                  {state.abbreviation}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          id="employerZipTextField"
          label="Employer Zip"
          margin="dense"
          error={errors.includes("employer_zip")}
          helperText={errors.includes("employer_zip") ? "Required" : ""} 
          value={props.employer_zip}
          onChange={e => props.onChange(e.target.value,"employer_zip")}
          fullWidth
        />

        <FormLabel component="legend" style={{ marginTop: 70 }}>
          Employer Contact
        </FormLabel>

        <TextField
          id="employerContactFirstTextField"
          label="First"
          //disabled={!props.isAdd || props.usingEmployerPreload}
          margin="dense"
          error={errors.includes("employer_contact_first")}
          helperText={errors.includes("employer_contact_first") ? "Required" : ""} 
          value={props.employer_contact_first}
          onChange={e => props.onChange(e.target.value,"employer_contact_first")}
          fullWidth
        />

        <TextField
          id="employerContactLastTextField"
          label="Last"
          //disabled={!props.isAdd || props.usingEmployerPreload}
          margin="dense"
          error={errors.includes("employer_contact_last")}
          helperText={errors.includes("employer_contact_last") ? "Required" : ""} 
          value={props.employer_contact_last}
          onChange={e => props.onChange(e.target.value,"employer_contact_last")}
          fullWidth
        />

        <TextField
          id="employerEmailTextField"
          label="Email"
          disabled={!props.isAdd || props.usingEmployerPreload}
          margin="dense"
          error={errors.includes("employer_contact_email")}
          helperText={errors.includes("employer_contact_email") ? "Required" : ""} 
          value={props.employer_contact_email}
          onChange={e => props.onChange(e.target.value,"employer_contact_email")}
          fullWidth
        />
        <FormLabel component="legend" style={{ marginTop: 70 }}>
          Employee
        </FormLabel>

        <TextField
          id="employeeFirstTextField"
          label="First"
          margin="dense"
          error={errors.includes("employee_first")}
          helperText={errors.includes("employee_first") ? "Required" : ""} 
          value={props.employee_first}
          onChange={e => props.onChange(e.target.value,"employee_first")}
          fullWidth
        />

        <TextField
          id="employeeLastTextField"
          label="Last"
          margin="dense"
          error={errors.includes("employee_last")}
          helperText={errors.includes("employee_last") ? "Required" : ""} 
          value={props.employee_last}
          onChange={e => props.onChange(e.target.value,"employee_last")}
          fullWidth
        />

        <TextField
          id="employeeEmailTextField"
          label="Email"
          margin="dense"
          error={errors.includes("employee_email")}
          helperText={errors.includes("employee_email") ? "Required" : ""} 
          value={props.employee_email}
          onChange={e => props.onChange(e.target.value,"employee_email")}
          fullWidth
        />

        <FormLabel component="legend" style={{ marginTop: 70 }}>
          Beneficiary
        </FormLabel>
        <TextField
          id="beneficiaryFirstTextField"
          label="First"
          margin="dense"
          error={errors.includes("beneficiary_first")}
          helperText={errors.includes("beneficiary_first") ? "Required" : ""} 
          value={props.beneficiary_first}
          onChange={e => props.onChange(e.target.value,"beneficiary_first")}
          fullWidth
        />

        <TextField
          id="beneficiaryLastTextField"
          label="Last"
          margin="dense"
          error={errors.includes("beneficiary_last")}
          helperText={errors.includes("beneficiary_last") ? "Required" : ""} 
          value={props.beneficiary_last}
          onChange={e => props.onChange(e.target.value,"beneficiary_last")}
          fullWidth
        />

        <TextField
          id="beneficiarySSNTextField"
          label="SSN"
          margin="dense"
          error={errors.includes("set_beneficiary_ssn")}
          helperText={errors.includes("set_beneficiary_ssn") ? "Required" : ""} 
          value={props.beneficiary_ssn}
          onChange={e => props.onChange(e.target.value,"beneficiary_ssn")}
          fullWidth
        />

        <KeyboardDatePicker
          id="DateOfBirth"
          autoOk={true}
          error={errors.includes("beneficiary_dob")}
          value={props.beneficiary_dob}
          onChange={date => props.onChange(date, "beneficiary_dob")}
          openTo="date"
          format="MM/DD/YYYY"
          label="Date Of Birth"
          views={["year", "month", "date"]}   
        />

        <div style={{ height: 70 }} />
        <KeyboardDatePicker
          id="effectiveDateTextBox"
          value={props.effective_date}
          autoOk={true}
          error={errors.includes("effective_date")}
          onChange={date => props.onChange(date, "effective_date")}
          openTo="date"
          format="MM/DD/YYYY"
          label="Effective Date"
          views={["year", "month", "date"]}
        />
        <br />

        <div style={{ height: 30 }} />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(MedicareControl);
