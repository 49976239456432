import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";
import Switch from "@material-ui/core/Switch";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import PasswordChangeDialog from "../../Shared/Components/Accounts/PasswordChangeDialog";
import ChangeNameDialog from "../../Shared/Components/Accounts/ChangeNameDialog";
import MFADialog from "../../Shared/Components/Accounts/MFADialog";
import SingleFieldDialog from "../../Shared/Components/Accounts/SingleFieldDialog";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import Agreement from "../../Shared/Components/Agreement/Agreement";

import Grid from "@material-ui/core/Grid";

import PhoneNumberTextBox, {
  mapPhoneToMask,
  outputPhoneFromMask,
} from "../../Shared/Components/Input/PhoneNumberTextBox";
import { InputAdornment } from "@material-ui/core";
import ShowPassword from "@material-ui/icons/RemoveRedEye";

import api from "../../Shared/Functions/APIHelpers";
import config from "../../config";


class AccountContainer extends React.Component {
  state = {
    employerHasAuth: true,
    confirmSecureEmployer: false,

    user: {},
    userAttributes: {},
    hasIdentityID: false,
    firstName: "",
    lastName: "",
    email: "",
    emailHasChanged: false,
    oldPassword: "",
    newPassword: "",
    phoneMask: "(   )    -    ",
    verifyEmailEnabled: true,
    verifyPhoneEnabled: true,
    showPhoneVerificationDialogue: false,
    showEmailVerificationDialogue: false,
    phoneCode: "",
    emailCode: "",
    oldPasswordIsMasked: true,
    newPasswordIsMasked: true,
    preferredMFA: "",
  };

  componentDidMount() {
    if (this.props.employerIsSecured) {
      this.getUserInfo().then((userInfo) => {
        this.setState({
          firstName: userInfo.first_name,
          lastName: userInfo.last_name,
          email: userInfo.email,
        });
      });
      this.getAttributes();
    }
  }

  confirmSecureEmployer = () => {
    this.setState({ confirmSecureEmployer: true });
  };
  cancelSecureEmployer = () => {
    this.setState({ confirmSecureEmployer: false });
  };

  handleSecureEmployer = () => {
    this.secureEmployer(true)
      .then((r) => {
        this.props.enqueueSnackbar(
          "Secure Request Sent. Check Your Email to Finish Securing Portal",
          {
            variant: "success",
          }
        );
        this.setState({ confirmSecureEmployer: false });
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push(
            "/employer/auth?error=5?portalID=" + this.props.portalID
          );
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "Something went wrong rendering securing employer portal",
            {
              variant: "error",
            }
          );
        }
      });
  };

  handleRemoveSecure = () => {
    this.secureEmployer(false).then((r) => {
      window.location.href =
        config.appLocation.URL +
        "/employer/home?portalID=" +
        this.props.portalID;
    });
  };

  secureEmployer = (is_secure) => {
    let body = JSON.stringify({
      secure_employer: is_secure,
    });
    return new Promise((resolve, reject) => {
      if (is_secure)
        resolve(api.enableEmployerIsProtected(this.props.portalID, body));
      else
        resolve(
          api.enableEmployerIsProtectedSecured(this.props.portalID, body)
        );
    });
  };

  getConfirmTextSecure = () => {
    return (
      "Are you sure you wish to require a password to use this portal? An email will be sent to " +
      this.props.employerContact +
      " with a link to set your password and enable this setting. Until this process is completed, you will be able to access the portal without a password."
    );
  };

  getConfirmTextRemoveSecure = () => {
    return "Are you sure you wish to remove security on this portal?";
  };


  getPreferredMFA = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          preferredMFA: user.preferredMFA,

        });

      })
      .catch((err) => console.log(err));
  };

  getAttributes = () => {
    //Auth.currentAuthenticatedUser()
    Auth.currentUserInfo()
      .then((user) => {
        this.setState({
          user: this.props.user,
          userAttributes: user.attributes,
          verifyEmailEnabled: !user.attributes.email_verified,
          verifyPhoneEnabled: !user.attributes.phone_number_verified,
        });
        //console.log(user)
        //console.log(user.attributes)
        //console.log(user.preferredMFA)
        //console.log(user.attributes.phone_number_verified)
        if (user.attributes.phone_number !== undefined){
          var defaultPhone = user.attributes.phone_number.substring(2)
          this.setState({ open: true, phoneMask: (mapPhoneToMask(defaultPhone).conformedValue) });
        }
      })
      .catch((err) => console.log(err));
  };

  sendPhoneCode = () => {
    Auth.verifyCurrentUserAttribute("phone_number")
      .then(() => {
        this.props.enqueueSnackbar("Verification Code Sent", {
          variant: "success",
        });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  };

  sendEmailCode = () => {
    Auth.verifyCurrentUserAttribute("email")
      .then(() => console.log("a verification code is sent"))
      .catch((err) => console.log("failed with error", err));
  };

  updateEmail = (email) => {
    Auth.updateUserAttributes(this.state.user, { email: email }).then(() => {
      this.getAttributes();
    });
  };

  updatePhone = (phone_number) => {
    Auth.updateUserAttributes(this.state.user, {
      phone_number: phone_number,
    }).then(() => {
      this.getAttributes();
      this.sendPhoneCode();
      this.setState({showPhoneVerificationDialogue: true})
    });
  };

  verifyEmail = (code) => {
    Auth.verifyCurrentUserAttributeSubmit("email", code)
      .then(() => {
        this.props.enqueueSnackbar("Email Confirmed", {
          variant: "success",
        });
        this.getAttributes();
        this.setState({ showEmailVerificationDialogue: false})
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  };

  verifyPhone = (code) => {
    console.log(code)
    Auth.verifyCurrentUserAttributeSubmit("phone_number", code)
      .then(() => {
        this.props.enqueueSnackbar("Phone Number Confirmed", {
          variant: "success",
        });
        this.getAttributes();
        this.setState({ showPhoneVerificationDialogue: false})
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.message, {
          variant: "error",
        });        
        
      });
  };

  getUserInfo = () => {
    return new Promise((resolve, reject) => {
      api
        .getUserInfo()
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting User Info" });
          console.log(err);
        });
    });
  };

  handleUpdatePhone = () => {
    var phone_number = outputPhoneFromMask(this.state.phoneMask).conformedValue
    
    this.updatePhone(phone_number)
  }

  handleClosePhoneVerificationDialogue = () => {
    this.setState({ showPhoneVerificationDialogue: false})
  }

  handleVerifyPhone = () => {
    this.verifyPhone(this.state.phoneCode)
  }

  handleUpdatePassword = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(
          user,
          this.state.oldPassword,
          this.state.newPassword
        );
      })
      .then(data => {
        this.props.enqueueSnackbar("Password Changed Successfully", {
          variant: "success"
        });
        this.setState({ oldPassword: "", newPassword: "" });
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.message, {
          variant: "error"
        });
      });
  };

  toggleOldPasswordMask = () => {
    this.setState(prevState => ({
      oldPasswordIsMasked: !prevState.oldPasswordIsMasked
    }));
  };

  toggleNewPasswordMask = () => {
    this.setState(prevState => ({
      newPasswordIsMasked: !prevState.newPasswordIsMasked
    }));
  };
  
  updateMFA = (value) => {
    this.setState({preferredMFA: value})

  }


  handleCloseEmailVerificationDialogue = () => {
    this.setState({ showEmailVerificationDialogue: false})
  }

  handleVerifyEmail = () => {
    this.verifyEmail(this.state.emailCode)
  }

  updateUserInfo = () => {
    var body = JSON.stringify({
      first_name: this.state.firstName,
      last_name : this.state.lastName,
    });

    return new Promise((resolve, reject) => {
      api
        .updateUserFullName(body)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating User Info" });
          console.log(err);
        });
    });
    
  }


  updateUserEmail = () => {
    var body = JSON.stringify({
      email: this.state.email,
    });
  
    return new Promise((resolve, reject) => {
      api
        .updateUserEmail(body)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Email Address" });
          console.log(err);
        });
    });
    
  }


  handleUpdateProfile = () => {
    this.updateUserInfo().then(userInfo => {
      this.props.enqueueSnackbar("Profile Updated Successfully", {
          variant: "success"
        });
    })
  }


  handleUpdateEmail = () => {

    this.updateUserEmail().then(userInfo => {
      this.props.enqueueSnackbar("Email Updated Successfully", {
          variant: "success"
        });
    }) 
     
    this.setState({showEmailVerificationDialogue: true})
    this.updateEmail(this.state.email)
  }


  render() {
    const { fullScreen } = this.props;
    const { oldPasswordIsMasked, newPasswordIsMasked } = this.state;

    return (
      <React.Fragment>
        <Agreement Open={this.props.needTOS} Close={()=>{this.props.getEmployerInfo()}} portalID={this.props.portalID} />
        {!this.props.employerIsSecured && (
          <ConfirmAlert
            isOpen={this.state.confirmSecureEmployer}
            isNo={this.cancelSecureEmployer}
            isYes={this.handleSecureEmployer}
            AlertTitle={"Confirm Setting"}
            AlertText={this.getConfirmTextSecure()}
          />
        )}
        {this.props.employerIsSecured && (
          <ConfirmAlert
            isOpen={this.state.confirmSecureEmployer}
            isNo={this.cancelSecureEmployer}
            isYes={this.handleRemoveSecure}
            AlertTitle={"Confirm Remove Security"}
            AlertText={this.getConfirmTextRemoveSecure()}
          />
        )}
        <Typography variant="h4">Account Settings</Typography>

        <React.Fragment>
            
            <Grid container spacing={2} style={{ marginTop: 30 }}>
            <Grid item xs={12} sm={3}>
            <Typography variant="h5" style={{marginBottom: 30}}>Secure Portal</Typography>
              <Typography variant="caption">
                <p>
                    By default, your employer portal can only be accessed with
                    your unique portal ID and does not require a password. While
                    no private information is displayed through the portal -
                    only collected - you may wish to require a password to
                    access the portal or update records nonetheless.
                  </p>
                  <p>
                    By enabling this setting, you will be creating a password
                    protected account that will need to be used whenever you log
                    into the portal or update a record through an email link.
                    Because each portal can have only one password protected
                    account, only you will be able to access the portal or
                    update requests from Mewa.io once this feature is activated.
                    This account will be set up with the email{" "}
                    <b>{this.props.employerContact}</b>
                    </p>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
            {(this.props.employerIsSecured && (
                  <Typography variant="body1">Status: Secured</Typography>
                )) || (
                  <Typography variant="body1">Status: Not Secured</Typography>
                )}

                <div style={{ marginTop: 20, textAlign: "left" }} />
                  <Button
                    variant="contained"
                    onClick={this.confirmSecureEmployer}
                    color="primary"
                    
                  >
                    {(this.props.employerIsSecured) && (
                      <React.Fragment>Disable Secure Employer Portal</React.Fragment>
                    )}
                    {(!this.props.employerIsSecured) && (
                      <React.Fragment>Enable Secure Employer Portal</React.Fragment>
                    )}
                    
                  </Button>
            </Grid>          
          </Grid>

          <Divider style={{marginTop:30, marginBottom:30}}/>

        </React.Fragment>

        {this.props.employerIsSecured && (
          <React.Fragment>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.showEmailVerificationDialogue}
              aria-labelledby="responsive-dialog-title"
              width="400"
            >
              <DialogTitle id="responsive-dialog-title">
                Enter Verification Code
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1">
                  Please enter the verification code that was sent to{" "}
                  {this.state.email}.
                </Typography>
                <TextField
                  id="standard-dense"
                  label="Verification Code"
                  margin="dense"
                  onChange={(e) =>
                    this.setState({
                      emailCode: e.target.value,
                      hasChanged: true,
                    })
                  }
                  defaultValue={this.props.defaultValue}
                  fullWidth
                  autoComplete="off"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseEmailVerificationDialogue}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleVerifyEmail}
                  color="primary"
                  autoFocus
                >
                  Verify
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullScreen={fullScreen}
              open={this.state.showPhoneVerificationDialogue}
              aria-labelledby="responsive-dialog-title"
              width="400"
            >
              <DialogTitle id="responsive-dialog-title">
                Enter Verification Code
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1">
                  Please enter the verification code that was sent to your cell
                  phone.
                </Typography>
                <TextField
                  id="standard-dense"
                  label="Verification Code"
                  margin="dense"
                  onChange={(e) =>
                    this.setState({
                      phoneCode: e.target.value,
                      hasChanged: true,
                    })
                  }
                  defaultValue={this.props.defaultValue}
                  fullWidth
                  autoComplete="off"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClosePhoneVerificationDialogue}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleVerifyPhone}
                  color="primary"
                  autoFocus
                >
                  Verify
                </Button>
              </DialogActions>
            </Dialog>

            <Typography variant="h4">Account Settings</Typography>


            <Grid container spacing={2} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={3}>
              <Typography variant="h5" style={{marginBottom: 30}}>Profile</Typography>
                <Typography variant="caption">
                  This information will be used in communications.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="broker-first"
                  label="First Name"
                  margin="normal"
                  fullWidth
                  onChange={(e) =>
                    this.setState({ firstName: e.target.value })
                  }
                  value={this.state.firstName}
                />
                <TextField
                  id="broker-last"
                  label="Last Name"
                  margin="normal"
                  fullWidth
                  onChange={(e) =>
                    this.setState({ lastName: e.target.value })
                  }
                  value={this.state.lastName}
                />

                <div style={{ marginTop: 20, textAlign: "left" }} />
                  <Button
                    variant="contained"
                    onClick={this.handleUpdateProfile}
                    color="primary"
                  >
                    Update Profile
                  </Button>
              </Grid>          
            </Grid>

            <Divider style={{marginTop:30, marginBottom:30}}/>



            <Grid container spacing={2} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={3}>
              <Typography variant="h5" style={{marginBottom: 30}}>Email</Typography>
                <Typography variant="caption">
                  Your email is your login username and also where all
                    notifications are sent.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {(this.state.userAttributes.email_verified && (
                    <Typography variant="body1">Status: Verified</Typography>
                  )) || (
                    <Typography variant="body1">
                      Status: Not Verified
                    </Typography>
                  )}
                  <TextField
                    id="broker-last"
                    label="Email"
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                        verifyEmailEnabled: true,
                      })
                    }
                    value={this.state.email}
                  />
                  <div style={{ marginTop: 20, textAlign: "left" }}/ >
                    <Button variant="contained" onClick={this.handleUpdateEmail} disabled={!this.state.email.match(/^(\S+@\S+\.\S+)/)} color="primary">
                      Update Email
                    </Button>
              </Grid>          
            </Grid>

            <Divider style={{marginTop:30, marginBottom:30}}/>



            <Grid container spacing={2} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={3}>
              <Typography variant="h5" style={{marginBottom: 30}}>Password</Typography>
                <Typography variant="caption">
                Please use a strong password to secure your account and
                    considering enabling two-factor authentication.
                    <br />
                    <br />
                    Passwords must:
                    <ul>
                      <li>Be at least 8 characters</li>
                      <li>Contain at least 1 number</li>
                      <li>Contain at least 1 special character</li>
                    </ul>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                    id="password-current"
                    label="Current Password"
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      this.setState({ oldPassword: e.target.value })
                    }
                    value={this.state.oldPassword}
                    type={oldPasswordIsMasked ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ShowPassword
                            onClick={this.toggleOldPasswordMask}
                            style={{ color: "grey", cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="password-new"
                    label="New Password"
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      this.setState({ newPassword: e.target.value })
                    }
                    value={this.state.newPassword}
                    type={newPasswordIsMasked ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ShowPassword
                            onClick={this.toggleNewPasswordMask}
                            style={{ color: "grey", cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ marginTop: 20, textAlign: "left" }} />
                    <Button
                      variant="contained"
                      onClick={this.handleUpdatePassword}
                      color="primary"
                    >
                      Update Password
                    </Button>
              </Grid>          
            </Grid>

            <Divider style={{marginTop:30, marginBottom:30}}/>



            <Grid container spacing={2} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={3}>
              <Typography variant="h5" style={{marginBottom: 30}}>Cell Phone</Typography>
                <Typography variant="caption">
                In order to receive one-time pins as a text message for use
                    with two-factor authentication, you must enter and verify
                    your cell phone number.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {(this.state.userAttributes.phone_number_verified && (
                    <Typography variant="body1">Status: Verified</Typography>
                  )) || (
                    <Typography variant="body1">
                      Status: Not Verified
                    </Typography>
                  )}
                  <div style={{ height: 20 }}></div>
                  <InputLabel htmlFor="formatted-text-mask-input">
                    Cell Phone Number
                  </InputLabel>
                  <Input
                    value={this.state.phoneMask}
                    //defaultValue={defaultPhone}
                    onChange={(e) =>
                      this.setState({
                        phoneMask: e.target.value,
                        verifyPhoneEnabled: true,
                      })
                    }
                    name="phonemask"
                    id="formatted-text-mask-input"
                    inputComponent={PhoneNumberTextBox}
                    //style={{width:300}}
                    fullWidth
                  />
                  <div style={{ marginTop: 20, textAlign: "left" }} />
                    <Button
                      variant="contained"
                      onClick={this.handleUpdatePhone}
                      color="primary"
                    >
                      Update Cell Phone
                    </Button>
              </Grid>          
            </Grid>

            <Divider style={{marginTop:30, marginBottom:30}}/>



            <Grid container spacing={2} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={3}>
              <Typography variant="h5" style={{marginBottom: 30}}>Two-Factor Authentication</Typography>
                <Typography variant="caption">
                    Two-Factor Authentication means that in addition to your
                    password, a one-time pin is required to log into your
                    account. This pin can either be sent to you via text or
                    using an app on your phone.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
              {(this.state.preferredMFA !== "NOMFA" && (
                    <Typography variant="body1">Status: Enabled</Typography>
                  )) || (
                    <Typography variant="body1">Status: Not Enabled</Typography>
                  )}
                  <div style={{ marginTop: 20, textAlign: "left" }} />
                    <MFADialog
                      enqueueSnackbar={this.props.enqueueSnackbar}
                      user={this.props.user}
                      userAttributes={this.state.userAttributes}
                      returnValue={this.updateMFA}
                    />
              </Grid>          
            </Grid>

            <Divider style={{marginTop:30, marginBottom:30}}/>


          </React.Fragment>
        )}
        <div style={{ marginBottom: 15, marginTop: 10 }}>
              <Typography variant="caption">
                Mewa.io Version Number
              </Typography>
            </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AccountContainer);
