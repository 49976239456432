import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";
import TextField from "@material-ui/core/TextField";
import api from "../../Shared/Functions/APIHelpers";
import { getStates } from "../../Shared/Functions/Format";

class ManageBrokerPermissionDialog extends React.Component {
  state = {
    permissionLevel: null
  };
  componentDidMount() {}

  close = () => {
      this.props.Close()
  };

  handleSave = () => {
    this.updateEmployerBrokerPermission().then((r) => {
      this.props.enqueueSnackbar("Broker Permission Updated!", {
        variant: 'success',
      });
      this.close()
    });
  };

  updateEmployerBrokerPermission = () => {
    let body = JSON.stringify({
      permission_level: this.state.permissionLevel
    })
    return new Promise((resolve, reject) => {
      if (this.props.employerIsSecured)
        resolve(api.updateEmployerBrokerRelationshipSecured(this.props.Broker.relationship_id, body));
      else resolve(api.updateEmployerBrokerRelationship(this.props.Broker.relationship_id, body));
    });
  };

  handleClose = () => {
    this.close();
  };

  
  render() {
    
    const controlledPermissionLevel = (this.state.permissionLevel === null) ? this.props.Broker.permission_level : this.state.permissionLevel
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Manage Broker Permissions</DialogTitle>

          <DialogContent >
            <div>
              <Typography variant="body2" color="inherit">
                Manage the brokers permission level here. 
              </Typography>

              <br />
            </div>

            <FormControl
              component="fieldset"
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              <RadioGroup
                aria-label="permission-level-radio"
                name="permission-level-radio"
                value={controlledPermissionLevel}
                onChange={(e) => {
                  this.setState({ permissionLevel: parseInt(e.target.value) });
                }}
              >              
                <FormControlLabel
                  id="notify-radio"
                  value={0}
                  control={<Radio />}
                  label={<Typography variant="body1">Notify</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will receive notifications when we need to contact you regarding things like termination verifications, employees who are eligible for Medicare and completing ARC Forms, however they will not be able to see any information about the requests.</Typography>
                <FormControlLabel
                  id="view-radio"
                  value={1}
                  control={<Radio />}
                  label={<Typography variant="body1">View</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will be able to view the status of any records in your Mewa.io account, but will not be able to edit them.</Typography>
                <FormControlLabel
                  id="update-radio"
                  value={2}
                  control={<Radio />}
                  label={<Typography variant="body1">Update</Typography>}
                />
                <Typography variant="caption" style={{marginLeft:120, marginTop:-30, marginBottom: 20, minHeight:35}}>The broker will be able to view the status of any records in your Mewa.io account and update certain records on your behalf, such as a termination verification. They will not be able to sign any documents on your behalf.</Typography>
              </RadioGroup>


            </FormControl>
            
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Close
            </Button>            
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(ManageBrokerPermissionDialog)
);
