import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import DoneMark from "./DoneMark";
import config from "../../config";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
//import { styles } from "../../Shared/Styles/ModalStyles";

const initialState = {};
const styles = (theme) => ({
  checkDone: {
    height: 300,
    minWidth: 700,
    marginTop: 10,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%",
    },
    "@media only screen and (max-height: 400px)": {
      marginTop: 0,
      height: 280,
    },
  },
});
class SupportingDocumentationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  handleChange = (files) => {
    console.log(files);
  };

  handleGoDashboard = () => {
    this.props.Close();
    window.location.reload();
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={false}
          scroll="paper"
          minWidth={400}
        >
          <DialogTitle id="form-dialog-title">
            {this.props.saveStatus}
          </DialogTitle>

          <DialogContent style={{ minHeight: 50, width: 400, textAlign: 'center' }}>
            {this.props.progress !== 100 && (
              <LinearProgress
                variant="determinate"
                value={this.props.progress}
              />
            )}
            {this.props.progress === 100 && (
              <div style={{ textAlign: 'center'}}>
                <DoneMark />
                
                <Typography style={{marginTop: 15}} variant="caption" id="changeRequestCompleteText">
                  Your request will be reviewed by the MEWA’s Plan
                  Administrator. You will be notified of the response via email.
                </Typography>
                
                </div>
            )}
          </DialogContent>
          <DialogActions>
            {this.props.progress === 100 && (
              <Button
                style={{ marginLeft: -17 }}
                onClick={this.handleGoDashboard}
                color="primary"
              >
                Close
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(SupportingDocumentationDialog)
);
