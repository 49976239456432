import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/NavStyles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MedicareIcon from "@material-ui/icons/LocalHospital";
import TerminationIcon from "@material-ui/icons/Cancel";

import EmployerIcon from "@material-ui/icons/LocationCity";
import Home from "@material-ui/icons/Home";
import AssignmentIcon from "@material-ui/icons/Assignment";

class NavList extends React.Component {
  getMEWAID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let mewaID = params.get("mewaID") ? params.get("mewaID") : null;
    return mewaID;
  };

  goHome = () => {
    let url = "/broker/home";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();

    this.props.history.push(url);
  };

  goEmployers = () => {
    let url = "/broker/employers";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    this.props.history.push(url);
  };

  goMedicare = () => {
    let url = "/broker/medicare";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    this.props.history.push(url);
  };

  goTerminations = () => {
    let url = "/broker/termination";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    this.props.history.push(url);
  };

  goArcForm = () => {
    let url = "/broker/arc-records";
    if (this.getMEWAID()) url += "?mewaID=" + this.getMEWAID();
    this.props.history.push(url);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.navList}>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              <img
                src={this.props.theme.navlist_logo}
                className={classes.headerlogo}
                style={{ width: 130, marginTop: 10 }}
                alt="Reform Health"
              />
            </ListSubheader>
          }
        >
          <ListItem button onClick={this.goHome}>
            <ListItemIcon className={classes.listItem}>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          <ListItem
            button
            onClick={this.goEmployers}
            id="go-employers-navlist-broker"
          >
            <ListItemIcon className={classes.listItem}>
              <EmployerIcon />
            </ListItemIcon>
            <ListItemText primary="Employers" />
          </ListItem>

          <ListItem button onClick={this.goTerminations}>
            <ListItemIcon className={classes.listItem}>
              <TerminationIcon />
            </ListItemIcon>
            <ListItemText primary="Termination Records" />
          </ListItem>

          <ListItem button onClick={this.goMedicare}>
            <ListItemIcon className={classes.listItem}>
              <MedicareIcon />
            </ListItemIcon>
            <ListItemText primary="Medicare Records" />
          </ListItem>

          <ListItem button onClick={this.goArcForm}>
            <ListItemIcon className={classes.listItem}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="ARC Forms" />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(NavList);
