import React from "react";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import FormViewer from "../../Shared/Components/FormViewer/FormViewer";
//import FormViewer from "../../Sh/Components/FormViewer/FormViewer";
import Dialog from "@material-ui/core/Dialog";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DialogContent from "@material-ui/core/DialogContent";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Storage } from "aws-amplify";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import api from "../../Shared/Functions/APIHelpers"

import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  largeLogo: {
    height: 700,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MedicareViewFormDialog extends React.Component {
  state = {
    loaded: false,
    signatureModalOpen: false,
    eeForms: [],
    selectedForm: { revisionID: "" },
    fields: [],
    formNumber: 1,
    step: "",
    fileURL: ""
  };
  componentDidMount() {
    if (!this.state.loaded) {
      this.handleGetMedicareForm();
    }
  }

  handleGetMedicareForm = () => {
        api
          .getMedicareForm(this.props.SelectedRecord.id)
          .then(response => {
            //this.setState({revisionID : response
            this.setState({fileURL: response.outputFiles[0].fileName}, ()=>{this.handleLoadMedicareForm()})
            //this.load(response.outputFiles);
          })
          .catch(err => {
            console.log('got an error')
            this.setState({ errorMessage: "Error Getting Forms" });
            console.log(err);
          });
  };
  handleLoadMedicareForm = () => {
    //download file that was just mapped and place in state
    Storage.configure({ level: "public" });
    Storage.get(this.state.fileURL).then((result) => {
      let eeForms = [];
      eeForms.push({
        formName: "",
        url: result,
        profileID: "",
        revisionID: "",
        severity: '',
        viewed: false,
      });
      this.setState({ eeForms: eeForms, selectedForm: eeForms[0] }, () => {
        this.setState({ loaded: true, step: "form" });
      });
    });
  };

  handleDownload = () => {
    Storage.get(this.state.fileURL)
      .then((result) => {
        const link = document.createElement("a");
        link.href = result;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({ loaded: false }, () => {
      this.props.Close();
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          maxWidth={"lg"}
          scroll="paper"
          fullScreen
          //onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.handleClose();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                View Docoument
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.handleDownload();
                }}
                aria-label="close"
              >
                <DownloadIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            {this.state.loaded && (
              <React.Fragment>
                <React.Fragment>
                  <FormViewer
                    fields={this.state.fields}
                    overrides={false}
                    Form={this.state.selectedForm}
                    FormNumber={this.state.formNumber}
                    FormTotal={this.state.eeForms.length}
                    Reload={this.reload}
                    MaxWidth={1400}
                  />
                </React.Fragment>
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(MedicareViewFormDialog)
);
