import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import NewTable from "../../Shared/Components/Table/NewTable";
import SelectableTable from "../../Shared/Components/Table/SelectableTable";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import MedicareDialog from "./MedicareDialog";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import UploadDialog from "../../Shared/Components/Upload/UploadDialog";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import DownloadDialog from "../../Shared/Components/Download/DownloadDialog";
import MedicareViewAcceptanceLetterDialog from "../../Shared/Components/Medicare/MedicareViewAcceptanceLetterDialog";
import MedicareDetail from "./MedicareDetail";
import MedicareSubmissionDialog from "./MedicareSubmissionDialog";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DetailsIcon from "@material-ui/icons/Info";

import {
  dateCellDataGetter,
  dateCellDataGetterDate,
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  nestedSort,
} from "../../Shared/Components/Table/TableUtilities";
import api from "../../Shared/Functions/APIHelpers";

import Typography from "@material-ui/core/Typography";

const initialState = {
  medicareModal: false,
  downloadRecordDialog: false,
  submitCMSDialog: false,
  detailDialogOpen: false,
  viewMedicareAcceptanceLetterDialogOpen: false,
  medicareAcceptanceLetterPresignedURL: "",
  isAdd: true,
  medicareDetail: {},

  //FOR TABLE
  tableData: [],
  dataURL: "medicare-mewa",
  staticFilterURL: "medicare-mewa-static-filters",
  staticFilters: ["employer__mewa__name", "status", "cms_status"],
  staticFilterValues: [],
  sortBy: "",
  sortDirection: "ASC",
  countURL: "medicare-mewa-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  selectedRows: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class MedicareContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState(
      { initialLoadDone: false, selectedRows: [], fetching: true },
      () => {
        this.getStaticFilterValues().then((values) => {
          this.setState({ staticFilterValues: values }, () => {
            this.getTableCount().then((count) => {
              this.getTableData(0, 10)
                .then((r) => {
                  this.setState({
                    tableData: r,
                    recordCount: count["record_count"],
                    fetching: false,
                    initialLoadDone: true,
                  });
                  if (isInitial)
                    this.setState({
                      initialCount: count["record_count"],
                      currentCount: count["record_count"],
                    });
                  else this.setState({ currentCount: count["record_count"] });
                })
                .catch((err) => {
                  if (err === 403) {
                    this.props.history.push("/mewa-admin/auth?error=5");
                  }
                  if (err === 400) {
                    this.props.enqueueSnackbar(
                      "Something went wrong rendering medicare",
                      {
                        variant: "error",
                      }
                    );
                  }
                });
            });
          });
        });
      }
    );
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering medicare",
                {
                  variant: "error",
                }
              );
            }
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            ""
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  setSelectedRows = (rows) => {
    this.setState({ selectedRows: rows });
  };

  sortTable = (sortBy, sortDirection, col) => {
    if (col.nestedCol) sortBy = col.nestedCol;
    this.setState({ sortBy: sortBy, sortDirection: sortDirection }, () => {
      this.handleInitialData();
    });
  };

  editMedicare = (medicareDetail) => {
    this.setState({
      medicareDetail: medicareDetail,
      isAdd: false,
      medicareModal: true,
    });
  };

  /* These functions both need the confirm dialogs*/
  /************************************************/
  handleDeleteMedicare = (selectedItem, closeMenu) => {
    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      closeMenu: closeMenu,
      medicareDetail: selectedItem,
      confirmAlertTitle: "Delete Medicare Record",
      confirmAlertText:
        "Are you sure you wish to delete this medicare record for " +
        selectedItem.employer_name +
        "?",
      doNotFunc: this.doNotDeleteMedicare,
      doFunc: this.doDeleteMedicare,
    });
  };

  doNotDeleteMedicare = () => {
    this.setState({
      confirmAlertOpen: false,
      medicareDetail: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  doDeleteMedicare = () => {
    this.setState({ confirmAlertOpen: false });
    this.deleteMedicare(this.state.medicareDetail.id).then((r) =>
      this.handleInitialData()
    );
    this.state.closeMenu();
  };

  deleteMedicare = (medicareID) => {
    return new Promise((resolve, reject) => {
      resolve(api.deleteMedicareMEWA(medicareID));
    });
  };

  handleResendMedicare = (selectedItem, closeMenu) => {
    let confirmText = (
      <React.Fragment>
        Are you sure you wish to resend this Medicare request to{" "}
        {selectedItem.employer_name}? This email will be sent to{" "}
        {selectedItem.employer_contact_first}{" "}
        {selectedItem.employer_contact_last}(
        {selectedItem.employer_contact_email}). This will clear the signature if
        the form has already been signed.
        <br />
        <br />
        Employers receive reminders automatically. Please only resend this
        notification if the employer has requested it.
      </React.Fragment>
    );


    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      medicareDetail: selectedItem,
      closeMenu: closeMenu,
      confirmAlertTitle: "Resend Request?",
      confirmAlertText: confirmText,
      doNotFunc: this.doNotResendMedicare,
      doFunc: this.doResendMedicare,
    });
  };

  doNotResendMedicare = () => {
    this.setState({
      confirmAlertOpen: false,
      medicareDetail: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  doResendMedicare = () => {
    this.setState({ confirmAlertOpen: false });
    this.resendMedicareEmail(this.state.medicareDetail.id).then(() => {
      this.props.enqueueSnackbar("Email Resent", {
        variant: "success",
      });
      this.state.closeMenu();
    });
  };

  resendMedicareEmail = (medicareID) => {
    return new Promise((resolve, reject) => {
      resolve(api.resendMedicareEmail(medicareID));
    });
  };
  /************************************************/
  /************************************************/

  handleTrackMedicare = (medicareDetail, closeMenu) => {
    this.setState({ detailDialogOpen: true, medicareDetail: medicareDetail });
    closeMenu();
  };

  handleEditMedicare = (medicareDetail, closeMenu) => {
    this.setState({
      medicareDetail: medicareDetail,
      medicareModal: true,
      isAdd: false,
    });
    closeMenu();
  };

  handleViewAcceptanceLetter = (medicareDetail, closeMenu) => {
    this.getPresignedAcceptanceLetterURL(medicareDetail).then((r) => {
      this.setState({
        viewMedicareAcceptanceLetterDialogOpen: true,
        medicareAcceptanceLetterPresignedURL: r.presigned_url,
        medicareDetail: medicareDetail,
      });
      closeMenu();
    });
  };

  getPresignedAcceptanceLetterURL = (medicareRecord) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getMedicareSubmissionAcceptanceMEWAPresignedURL(medicareRecord.id)
      );
    });
  };

  handleOpenBulkLoad = () => {
    this.setState({ uploadRecordDialog: true });
  };

  handleOpenMedicareModal = () => {
    this.setState({ medicareModal: true, isAdd: true });
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;

    return (
      <div>
        <MenuItem
          onClick={() => {
            this.handleTrackMedicare(selectedItem, closeMenu);
          }}
        >
          <DetailsIcon className={classes.leftIcon} />
          Record Details
        </MenuItem>

        <MenuItem
          name="resendMedicareEmailMenuItem"
          onClick={() => {
            this.handleResendMedicare(selectedItem, closeMenu);
          }}
        >
          <SendIcon className={classes.leftIcon} />
          Resend Notification
        </MenuItem>

        <MenuItem
          disabled={selectedItem.acceptance_file === false}
          name="resendMedicareEmailMenuItem"
          onClick={() => {
            this.handleViewAcceptanceLetter(selectedItem, closeMenu);
          }}
        >
          <DownloadIcon className={classes.leftIcon} />
          View CMS Acceptance Letter
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleEditMedicare(selectedItem, closeMenu);
          }}
        >
          <EditIcon className={classes.leftIcon} />
          Edit Record
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.handleDeleteMedicare(selectedItem, closeMenu);
          }}
        >
          <DeleteIcon className={classes.leftIcon} />
          Delete Record
        </MenuItem>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        {this.state.viewMedicareAcceptanceLetterDialogOpen && (
          <MedicareViewAcceptanceLetterDialog
            PresignedAcceptanceURL={
              this.state.medicareAcceptanceLetterPresignedURL
            }
            Open={this.state.viewMedicareAcceptanceLetterDialogOpen}
            Close={() => {
              this.setState({
                viewMedicareAcceptanceLetterDialogOpen: false,
                medicareDetail: {},
                medicareAcceptanceLetterPresignedURL: "",
              });
            }}
          />
        )}
        {this.state.submitCMSDialog && (
          <MedicareSubmissionDialog
            Open={this.state.submitCMSDialog}
            IncludedForms={this.state.selectedRows
              .filter((r) => {
                let record = this.state.tableData[parseInt(r)];
                if (record.signature_date) return true;
              })
              .map((r) => {
                return this.state.tableData[parseInt(r)];
              })}
            Close={() => {
              this.setState({
                submitCMSDialog: false,
              });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
          />
        )}

        <UploadDialog
          Open={this.state.uploadRecordDialog}
          Close={() => {
            this.setState({ uploadRecordDialog: false });
          }}
          RefreshTable={this.handleInitialData}
          enqueueSnackbar={this.props.enqueueSnackbar}
          type={"medicare"}
        />

        <MedicareDetail
          SelectedRecord={this.state.medicareDetail}
          Open={this.state.detailDialogOpen}
          Close={() => {
            this.setState({ detailDialogOpen: false, medicareDetail: {} });
          }}
          enqueueSnackbar={this.props.enqueueSnackbar}
          history={this.props.history}
        />
        <MedicareDialog
          enqueueSnackbar={this.props.enqueueSnackbar}
          isAdd={this.state.isAdd}
          Open={this.state.medicareModal}
          Close={() => {
            this.setState({
              medicareModal: false,
              medicareDetail: {},
              isAdd: true,
            });
          }}
          key={this.state.medicareDetail.id + Math.random().toString()}
          RefreshTable={this.handleInitialData}
          medicareDetail={this.state.medicareDetail}
        />
        <div className={classes.containerRoot}>
          {/*<div style={{ height: 0}}>
            <Fab
              color="primary"
              size="small"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpenMedicareModal}
            >
              <Add />
            </Fab>
        </div>*/}
          <div className={classes.containerTitle}>Medicare Records</div>
          <div className={classes.containerContent}>
            <div style={{ height: 0, textAlign: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleOpenMedicareModal}
                className={classes.button}
              >
                <AddIcon className={classes.rightIcon} />
                <div className={classes.buttonText}>Add</div>
              </Button>
            </div>
            <Paper className={classes.containerPaper}>
              <SelectableTable
                tableName={"Medicare Records"}
                enqueueSnackbar={this.props.enqueueSnackbar}
                loadedData={this.state.tableData}
                loaded={this.state.initialLoadDone}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                selectedRows={this.state.selectedRows}
                setSelectedRows={this.setSelectedRows}
                canSelectRow={(rowIndex) => {
                  let row = this.state.tableData[rowIndex];
                  if (
                    row.status === "Signed" &&
                    row.cms_status === "Not Sent"
                  ) {
                    return true;
                  } else if (row.status !== "Signed") {
                    this.props.enqueueSnackbar(
                      "Record not completed by Employer",
                      {
                        variant: "warning",
                      }
                    );
                    return false;
                  } else {
                    this.props.enqueueSnackbar("Record already sent", {
                      variant: "warning",
                    });
                    return true;
                  }
                }}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                setActiveFilters={this.setActiveFilters}
                staticFilterValues={this.state.staticFilterValues}
                activeFilters={this.state.activeFilters}
                columns={[
                  {
                    dataKey: "employer_name",
                    label: "Employer Name",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "mewa",
                    nestedCol: "employer__mewa__name",
                    label: "MEWA",
                    customSort: nestedSort,
                    hasFilter: true,
                    hide: true, //dont neeed tis vis
                    filterType: "Static",
                  },
                  {
                    dataKey: "employer_contact_email",
                    label: "Employer Contact Email",
                    hasFilter: true,
                    hide: true, //dont neeed tis vis
                    filterType: "Search",
                  },
                  {
                    label: "Carrier Group ID",
                    dataKey: "employer__carrier_case_id",
                    hide: true,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    label: "Employee First",
                    dataKey: "employee_first",
                    hasFilter: false,
                    width: 250,
                    flexGrow: 4,
                    flexShrink: 1,
                  },
                  {
                    label: "Employee Last",
                    dataKey: "employee_last",
                    hasFilter: false,
                    width: 250,
                    flexGrow: 4,
                    flexShrink: 1,
                  },
                  {
                    dataKey: "beneficiary_last",
                    label: "Beneficiary Last",
                    hasFilter: true,
                    hide: true, //dont neeed tis vis
                    filterType: "Search",
                  },

                  {
                    dataKey: "date_added",
                    label: "Date Added",
                    cellRenderer: dateCellDataGetterDate,
                    width: 125,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    dataKey: "status",
                    label: "ER Status",
                    width: 125,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                    filterType: "Static",
                  },
                  {
                    dataKey: "cms_status",
                    label: "CMS Status",
                    width: 125,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                    filterType: "Static",
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div id="tableStatusDownloadButtons">
                <Button
                  onClick={() => {
                    this.setState({ submitCMSDialog: true });
                  }}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 0, marginTop: 15 }}
                  startIcon={<SendIcon />}
                  disabled={this.state.selectedRows.length <= 0}
                >
                  Submit Forms to CMS
                </Button>
              </div>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(MedicareContainer);
