import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

class DenyRequestReasonDialog extends React.Component {
  SetRequestReason;

  handleCancel = () => {
    this.props.Close();
  };

  handleSave = () => {
      this.props.Save();
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.Open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="body2" color="inherit">
            Enter a reason for Denial
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="standard-dense"
            label={"Reason"}
            margin="dense"
            multiline
            rows={4}
            onChange={(e) =>
              this.props.SetRequestReason(e.target.value)
            }
            value={this.props.DenyRequestReason}
            style={{ width: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(DenyRequestReasonDialog);
