import React from "react";
import api from "../../Shared/Functions/APIHelpers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import Grid from "@material-ui/core/Grid";
import BrokerAgreement from "../../Shared/Components/Agreement/BrokerAgreement";
import TerminationIcon from "@material-ui/icons/Cancel";
import MedicareIcon from "@material-ui/icons/LocalHospital";
import ARCIcon from "@material-ui/icons/Assignment";

import { Auth } from "aws-amplify";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./HomeStyles";

const initialState = {
  stats: {},
  loaded: false,
  preferredMFA: "",
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.getAttributes()
    this.handleGetBrokerDashboard().then((r) => {
      this.setState({ loaded: true, stats: r.stats });
    });
    
  }


  getAttributes = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user)
        this.setState({
          preferredMFA: user.preferredMFA,

        });

      })
      .catch((err) => console.log(err));
  };

  handleGetBrokerDashboard = () => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerDashboard());
    });
  };

  getPortalID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("brokerID") ? params.get("brokerID") : null;
  };

  redirectScreen = () => {
    window.location.href = "http://foo.com/error.php";
  };

  goMedicare = () => {
    this.props.history.push("/broker/medicare");
  };

  goEmployers = () => {
    this.props.history.push("/broker/employers");
  };

  goTerminations = () => {
    this.props.history.push("/broker/termination");
  };

  goArc = () => {
    this.props.history.push("/broker/arc-records");
  };

  goTeam = () => {
    this.props.history.push("/broker/view-team");
  };

  goInvitations = () => {
    this.props.history.push("/broker/view-invitations");
  };
  goAccountSettings = () => {
    this.props.history.push("/broker/account")
  };

  getGreeting = (name ) => {
    //var curHr = today.getHours()

    var curHr = new Date().getHours();

    var insert_name = ""

    if (name !== '' && name !== null) {
      insert_name = ", " + name + "!"
    } else {
      insert_name = "!"
    }

    if (curHr < 12) {
      return "Good morning" + insert_name
    } else if (curHr < 18) {
      return "Good afternoon" + insert_name
    } else {
      return "Good evening" + insert_name
    }
  }

  render() {
    const { loaded, stats } = this.state;
    const { classes } = this.props;

    if (!loaded) return null;
    return (
      <React.Fragment>
      <BrokerAgreement Open={this.props.needTOS} Close={()=>{this.props.getBrokerTOS()}} />
        <Typography
          variant="h4"
          id="broker-home-header"
          style={{ color: this.props.theme.header_text_color }}
        >
          {this.getGreeting(stats.first_name)}
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          Here's what's going on with your employers in Mewa.io
        </Typography>
        {this.state.preferredMFA === "NOMFA" && (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={9}>
              <Card style={{ backgroundColor: "#fafafa"}}>
                <CardContent>
                  <Typography variant="h6">
                    Enable Multi-Factor Authentication
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    Multi-Factor Authentication helps keep your account secure
                    by requiring a separate code along with your password -
                    either using an app on your phone or by entering a pin we'll
                    text you every time you log in
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    It's simple to use and we highly recommend enabling this
                    important security feature!
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "flex-end" }}>
                  <Button onClick={this.goAccountSettings} color="primary" size="small">
                    YES, LET'S DO IT!
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Employers in Mewa.io</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.employers_count}</b>
                </Typography>
              </CardContent>
              <CardActions>
                <BusinessIcon style={{ fontSize: 40 }} />
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Subscribers</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.subscriber_count}</b>
                </Typography>
              </CardContent>
              <CardActions>
                <PersonIcon style={{ fontSize: 40 }} />
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card variant="outlined" style={{display:"none"}}>
              <CardContent>
                <Typography variant="h6">Members</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.member_count}</b>
                </Typography>
              </CardContent>
              <CardActions>
                <PeopleIcon style={{ fontSize: 40 }} />
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Open Terms</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.open_term_count}</b>
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "space-between" }}>
                <TerminationIcon style={{ fontSize: 40 }} />
                <Button
                  color="primary"
                  size="small"
                  onClick={this.goTerminations}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Open Medicare</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.open_medicare_count}</b>
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "space-between" }}>
                <MedicareIcon style={{ fontSize: 40 }} />
                <Button color="primary" size="small" onClick={this.goMedicare}>
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Open ARC Forms</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.open_arc_count}</b>
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "space-between" }}>
                <ARCIcon style={{ fontSize: 40 }} />
                <Button color="primary" size="small" onClick={this.goArc}>
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" style={{ height: 350}}>
              <CardContent style={{ height: 270 }}>
                <Typography variant="h6">My Team</Typography>
                <Typography variant="h3" align="left" style={{ marginTop: 30 }}>
                  {stats.team_members.map((row, index) => (
                    <div key={index}>
                      <Typography variant="body1">{row.name}</Typography>
                    </div>
                  ))}
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <Button color="primary" size="small" onClick={this.goTeam}>
                  Manage
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Open Invitations</Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ marginTop: 30 }}
                >
                  <b>{stats.open_invitations}</b>
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  size="small"
                  onClick={this.goInvitations}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Home);