import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/NavStyles";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Home from "@material-ui/icons/Home";
import FolderIcon from "@material-ui/icons/Folder";

import AssignmentIcon from "@material-ui/icons/Assignment";
import MedicareIcon from "@material-ui/icons/LocalHospital";
import TerminationIcon from "@material-ui/icons/Cancel";

import PeopleIcon from "@material-ui/icons/People";

class NavList extends React.Component {
  goHome = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    var portalID = params.get("portalID");

    this.props.history.push("/employer/home?portalID=" + portalID);
  };

  goMedicare = () => {
    this.props.history.push(
      "/employer/medicare" + this.props.history.location.search
    );
  };

  goTerminations = () => {
    this.props.history.push(
      "/employer/terminations" + this.props.history.location.search
    );
  };

  goDocuments = () => {
    this.props.history.push(
      "/employer/documents" + this.props.history.location.search
    );
  };

  goArcForm = () => {
    this.props.history.push(
      "/employer/arc" + this.props.history.location.search
    );
  };

  goContinuationStatus = () => {
    this.props.history.push(
      "/employer/continuation-status" + this.props.history.location.search
    );
  };

  render() {
    const { classes, navOptions } = this.props;
    
    return (
      <div className={classes.navList}>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              <img
                src={this.props.theme.navlist_logo}
                className={classes.headerlogo}
                style={{ width: 130, marginTop: 10 }}
                alt="Reform Health"
              />
            </ListSubheader>
          }
        >
          <ListItem button onClick={this.goHome}>
            <ListItemIcon className={classes.listItem}>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          {navOptions.hasTermination && (
          <ListItem button onClick={this.goTerminations}>
            <ListItemIcon className={classes.listItem}>
              <TerminationIcon />
            </ListItemIcon>
            <ListItemText primary="Termination Records" />
          </ListItem>
          )}
          {navOptions.hasMedicare && (
          <ListItem button onClick={this.goMedicare}>
            <ListItemIcon className={classes.listItem}>
              <MedicareIcon />
            </ListItemIcon>
            <ListItemText primary="Medicare Records" />
          </ListItem>
          )}
          {navOptions.hasARC && (
          <ListItem button onClick={this.goArcForm}>
            <ListItemIcon className={classes.listItem}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="ARC Form" />
          </ListItem>
          )}
          <ListItem button onClick={this.goDocuments} id="go-document-center-navlist-employer">
            <ListItemIcon className={classes.listItem}>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary="Document Center" />
          </ListItem>
          
          {navOptions.hasContinuation && (
            <ListItem button onClick={this.goContinuationStatus}>
              <ListItemIcon className={classes.listItem}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Continuation Status" />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(NavList);
