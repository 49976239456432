import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DoneMark from "./DoneMark";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";

const styles = (theme) => ({
  checkDone: {
    marginTop: 10,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%",
    },
  },
});

const initialState = {
  arcRequestSubmitting: false,
  showComplete: false,
};

class SubmitArcRequestDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleCancel = () => {
    this.setState({ arcRequestSubmitting: false });
    this.props.Close();
  };

  handleSubmit = () => {
    this.setState({ arcRequestSubmitting: true });
    this.props
      .Submit()
      .then((r) => {
        this.setState({ arcRequestSubmitting: false, showComplete: true });
      })
      .catch((errr) => {
        console.log(this.props.errors);
      });
  };

  handleDashboard = () => {
    window.location.href =
      config.appLocation.URL + "/employer/home?portalID=" + this.props.PortalID;
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.Open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{ minWidth: 500 }}>
          {!this.state.showComplete && this.props.errors.length === 0 && (
            <React.Fragment>Submit ARC Form?</React.Fragment>
          )}

          {this.props.errors.length > 0 && (
            <React.Fragment>
              <div id="unable-to-submit">Unable to Submit!</div>
            </React.Fragment>
          )}

          {this.state.showComplete && (
            <React.Fragment>
              <div id="all-finished">All Finished!</div>
            </React.Fragment>
          )}
        </DialogTitle>
        <DialogContent>
          {!this.state.showComplete && this.props.errors.length === 0 && (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="caption" color="inherit">
                Your responses have been saved. To submit your responses and
                close this form, please click the submit button. Once submitted,
                you will no longer be able to make changes.
              </Typography>
            </div>
          )}

          {this.props.errors.length > 0 && (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="caption" color="inherit">
                The form cannot be submitted until all fields are completed and
                contain no negative values. Please close this window and correct
                the errors on the form before continuing. If you require
                assistance, you may close this window and click the Request Help
                button to request help from your broker.
              </Typography>
            </div>
          )}
          {this.props.errors.length == 0 && this.state.arcRequestSubmitting && (
            <CircularProgress
              size={75}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -37,
                marginLeft: -37,
                color: "primary",
              }}
            />
          )}
          {this.state.showComplete && <DoneMark />}
        </DialogContent>

        <DialogActions>
          {!this.state.showComplete && (
            <React.Fragment>
              <Button
                id="close-submit-arc-button"
                onClick={this.handleCancel}
                color="primary"
              >
                Close
              </Button>
              <Button
                id="submit-arc-button"
                onClick={this.handleSubmit}
                color="primary"
                disabled={
                  this.props.errors.length > 0 ||
                  this.state.arcRequestSubmitting
                }
                autoFocus
              >
                Submit
              </Button>
            </React.Fragment>
          )}
          {this.state.showComplete && (
            <Button
              id="go-to-dashboard-arc-button"
              onClick={this.handleDashboard}
              color="primary"
              autoFocus
            >
              Go To Dashboard
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(SubmitArcRequestDialog));
