import React from "react";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./TableStyles";

const TableFilters = ({
  filter,
  hasActiveFilter,
  addFilter,
  removeFilter,
  textFilters,
  setTextFilters,
}) => {
  const onChange = (val) => {
    setTextFilters((prev) => ({
      ...prev,
      [filter.dataKey]: val,
    }));
  };

  const handleTextFilterClick = () => {
    if (!hasActiveFilter) addFilter(textFilters[filter.dataKey], filter);
    else {
      removeFilter({
        [filter.dataKey]: textFilters[filter.dataKey],
        label: filter.label,
        dataKey: filter.dataKey,
      });
      setTextFilters((prev) => ({
        ...prev,
        [filter.dataKey]: "",
      }));
    }
  };

  const value = textFilters[filter.dataKey];

  return (
    <FormControl  fullWidth={true}>
      <InputLabel htmlFor="text-input-filter">{filter.label}</InputLabel>
      <Input
        id="text-input-filter"
        //minWidth={400}
        defaultValue={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        disabled={hasActiveFilter}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleTextFilterClick}
            >
              {hasActiveFilter ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default withStyles(styles)(TableFilters);
