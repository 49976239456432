import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import classnames from "classnames";
import { formatDateTime } from "../../Functions/Format";
import { withStyles } from "@material-ui/core/styles";
import RecordNoteList from "./RecordNoteList";
import RecordNoteForm from "./RecordNoteForm";

const RecordNotes = ({ Record, RecordNotes, CanAdd, Refresh }) => {
  const [isAdding, setIsAdding] = useState(false);
  return (
    <React.Fragment>
      <div style={{ overflow: "auto", maxHeight: "70%", width:"100%" }}>
        <RecordNoteList RecordNotes={RecordNotes} />
      </div>
    </React.Fragment>
  );
};

export default withStyles(null, { withTheme: true })(RecordNotes);
