import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import TrackingEventList from "../../Shared/Components/Tracking/TrackingEventList";

import Divider from "@material-ui/core/Divider";

import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class EmployerTeamDialog extends React.Component {
  state = {
    loaded: false,
    teamMembers: [],
  };

  close = () => {
    this.setState({ loaded: false }, () => {
      this.props.Close();
    });
  };

  handleClose = () => {
    this.close();
  };
  componentDidMount() {
    const { Employer } = this.props;
    this.handleGetEmployerTeam(Employer.portal_id);
  }

  handleGetEmployerTeam = (employerPortalID) => {
    this.getEmployerTeam(employerPortalID).then((r) => {
      this.setState({ loaded: true, teamMembers: r });
    });
  };

  getEmployerTeam = (employerPortalID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerTeamEmployerMEWA(employerPortalID));
    });
  };

  getPermissionLevel = (employers) => {

    var employerID = this.props.Employer.id

    for (var i = 0; i < employers.length; i++) {
      if (employers[i].id === employerID) {
        var permissionLevel = employers[i].permission_level
        if (permissionLevel === 0) return "Notify";
        if (permissionLevel === 1) return "View";
        if (permissionLevel === 2) return "Update";
      }
    } 

    return "";
  };

  isAddedBy = (employers) => {
    //When checking if a relationship is added by an employer we first
    //check if it is an Auto Added relationship. These will default to a relationship level of 1
    //but that doesn't mean theyre added by employer (in this case)
    var employerID = this.props.Employer.id

    for (var i = 0; i < employers.length; i++) {
      if (employers[i].id === employerID) {
        if(employers[i].auto_add)
          return "System"
        else if (employers[i].relationship_level === 1)
          return "Employer"
        else
          return "Team Member"
      }
    } 
    return "";
  };

  render() {
    const { Open } = this.props;
    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            {this.props.Employer.name} Team
          </DialogTitle>

          <DialogContent style={{ minHeight: 400, minWidth: 325, maxWidth: 450 }}>
            <Typography variant="body1">
              The following individuals have access to this employer's Mewa.io data.
            </Typography> 
            <div style={{height:30}}></div>           
            {this.state.teamMembers.map((teamMember, index) => {
              return (
                <div key={index} style={{ display: "block" }}>
                  <Typography variant="h6">
                  {teamMember.first_name} {teamMember.last_name} 
                  </Typography>
                  <Typography variant="body1">
                    {teamMember.email}
                  </Typography>
                  <Typography variant="body2">
                    Permission Level: {this.getPermissionLevel(teamMember.employers)}
                  </Typography>
                  <Typography variant="body2">
                    Added By: {this.isAddedBy(teamMember.employers)} {" "}
                  </Typography>                  
                  <Divider style={{marginTop:20, marginBottom:20}}/>
                </div>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EmployerTeamDialog));
