import React from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Storage } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ModalStyles";
import api from "../../Functions/APIHelpers";
let DocumentComponent = null;
let PageComponent = null;
import("react-pdf")
  .then(({ Document, Page }) => {
    DocumentComponent = Document;
    PageComponent = Page;
  })
  .catch((err) => console.log("Unsupported"));

const initialState = {
  uploadedFile: {
    name: "",
  },
  documentType: "",
  documentName: "",
  uploading: false,
};

class DocumentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  uploadFileS3 = () => {
    return new Promise((resolve, reject) => {
      var fileName =
        this.props.Employer.portal_id +
        "/document_center/" +
        this.state.uploadedFile.name;
      Storage.put(fileName, this.state.uploadedFile, {
        contentType: "pdf",
        contentDisposition: "attachment",
      })
        .then((result) => {
          console.log("finished uploading to s3: ");
          console.log(
            this.props.Employer.portal_id +
              "/document_center/" +
              this.state.uploadedFile.name
          );
          resolve(result);
        })
        .catch((err) => {
          console.log("error from storage put: ");
          console.log(err);
        });
    });
  };

  saveFile = () => {
    var body = JSON.stringify({
      employer: this.props.Employer.id,
      name:
        this.state.documentType === "Other"
          ? this.state.documentName
          : this.state.documentType,
      file_name: this.state.uploadedFile.name,
      document_type: 1,
      image_url: this.state.uploadedFile.name.replace("pdf", "png"),
      s3_bucket: process.env.REACT_APP_FILE_BUCKET,
      s3_document:
        "public/" +
        this.props.Employer.portal_id +
        "/document_center/" +
        this.state.uploadedFile.name,
    });

    return new Promise((resolve, reject) => {
      api
        .addDocument(this.props.Employer.portal_id, body)
        .then((r) => {
          console.log(
            "finished uploading to backend which calls thumbnail lambda: "
          );
          console.log(this.state.uploadedFile.name);
          console.log(this.props.Employer.portal_id);
          console.log(this.props.Employer.id);

          resolve(r);
        })
        .catch((err) => {
          this.setState({ errorMessage: "Error Adding Document" });
          console.log(err);
        });
    });
  };

  handleSave = () => {
    this.setState({ uploading: true }, () => {
      this.uploadFileS3().then((res) => {
        this.saveFile().then((res) => {
          this.props.refreshList();
          this.handleClose();
        });
      });
    });
  };

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { fullScreen, classes } = this.props;
    const { uploading } = this.state;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Add Document</DialogTitle>

          <DialogContent style={{ height: 600, width: 400 }}>
            <FormControl margin="dense" name="relationshipTypeSelect">
              <InputLabel htmlFor="document-type-simple">
                Document Type
              </InputLabel>
              <Select
                value={this.state.documentType}
                onChange={(e) => {
                  this.setState({ documentType: e.target.value });
                }}
                inputProps={{
                  name: "documentType",
                  id: "document-type-simple",
                }}
                style={{ width: 170, marginRight: 10 }}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"SPD"}>SPD</MenuItem>
                <MenuItem value={"MEWA Agreement"}>MEWA Agreement</MenuItem>
                <MenuItem value={"MEWA Info"}>Mewa Info</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
            <br />

            {this.state.documentType === "Other" && (
              <TextField
                id="standard-dense"
                label="Document Name"
                name="documentNameTextField"
                margin="dense"
                onChange={(e) =>
                  this.setState({ documentName: e.target.value })
                }
                value={this.state.documentName}
                fullWidth
              />
            )}
            <input
              accept="pdf/*"
              className={classes.input}
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={(e) => {
                this.setState({ uploadedFile: e.target.files[0] });
              }}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="outlined"
                component="span"
                className={classes.button}
                style={{ marginLeft: 0, marginTop: 20, marginBottom: 10 }}
              >
                Select File
              </Button>
            </label>

            {this.state.uploadedFile.name !== "" && (
              <React.Fragment>
                <div>File uploaded: {String(this.state.uploadedFile.name)}</div>
              </React.Fragment>
            )}

            {this.state.uploadedFile.name !== "" && !uploading && (
              <Paper
                style={{ marginTop: 10, overflow: "hidden" }}
                height={400}
                width={200}
              >
                {!DocumentComponent && !PageComponent && (
                  <div>Unable to load preview</div>
                )}
                {DocumentComponent && PageComponent && (
                  <DocumentComponent
                    file={this.state.uploadedFile}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    noData={""}
                    id={"documentCanvas"}
                  >
                    <PageComponent pageNumber={1} scale={0.5} height={200} />
                  </DocumentComponent>
                )}
              </Paper>
            )}

            {this.state.uploadedFile.name !== "" && uploading && (
              <div
                style={{
                  marginTop: 40,
                  textAlign: "center",
                }}
              >
                <CircularProgress size={60} />
                <br />
                Uploading
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={this.handleSave}
              disabled={uploading}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(DocumentDialog));
