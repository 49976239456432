import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import MessagesIcon from "@material-ui/icons/QuestionAnswer";
import DoneIcon from "@material-ui/icons/Done";
import Level1Icon from "@material-ui/icons/FavoriteBorder";
import Level2Icon from "@material-ui/icons/Favorite";
import FormViewer from "./FormViewer";

import api from "../../Functions/APIHelpers";
import { Storage } from "aws-amplify";
import config from "../../../config";

const styles = theme => ({
  paper: {
    "@media only screen and (max-width: 400px)": {
      boxShadow: "none"
    }
  },
  formDiv: {
    margin: 20,
    "@media only screen and (max-width: 400px)": {
      margin: 0
    }
  }
});

class ReviewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    numPages: null,
    pageNumber: 1,
    formNumber: 1,
    ee_forms: [],
    selected_form: { revisionID: "" },
    form: {},
    revisionID: "",
    fields: [],
    messageModalOpen: false,
    messageSenderID: '',
    messageSenderName: ''
  };

  componentDidMount() {
    this.props.setGoNext(this.nextForm);
    this.props.setGoBack(this.prevForm);
    if(this.props.medicareID)
      this.getMedicareFormByID(this.props.medicareID)
    if(this.props.terminationID)
      this.getTerminationFormByID(this.props.terminationID)
  }

  scrollToMyRef1 = () => {
    var element = this.refs.myRef1;

    element.scrollIntoView({ behavior: "auto", block: "end" });
  };

  getFields = revisionID => {
    return new Promise((resolve, reject) => {
      api
        .getFields(revisionID)
        .then(r => {
          this.setState({ fields: r });
          resolve(r);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Fields" });
          console.log(err);
        });
    });
  };

  getTerminationFormByID = id => {
    return new Promise((resolve, reject) => {
      api
        .getTerminationForm(id)
        .then(response => {
          //this.setState({revisionID : response
          this.load(response.outputFiles);
          resolve(response);
        })
        .catch(err => {
          console.log('got an error')
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  getMedicareFormByID = id => {
    return new Promise((resolve, reject) => {
      api
        .getMedicareForm(id)
        .then(response => {
          //this.setState({revisionID : response
          this.load(response.outputFiles);
          resolve(response);
        })
        .catch(err => {
          console.log('got an error')
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  getSingleFormByID = (userID, formID) => {
    return new Promise((resolve, reject) => {
      api
        .getSingleFormByID(userID, formID)
        .then(response => {
          //this.setState({revisionID : response
          this.load(response.outputFiles);
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  getSingleFormByVersion = userID => {
    return new Promise((resolve, reject) => {
      api
        .getSingleFormByRevision(userID, this.state.selected_form.revisionID)
        .then(response => {
          //this.setState({revisionID : response
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  getCurrentForms = userID => {
    return new Promise((resolve, reject) => {
      api
        .getCurrentForms(userID, false)
        .then(response => {
          this.load(response.outputFiles);
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Forms" });
          console.log(err);
        });
    });
  };

  getCurrentEmployeeForms = () => {
    return new Promise((resolve, reject) => {
      api
        .getCurrentEmployeeForms(this.props.employer, this.props.form)
        .then(response => {
          this.load(response.outputFiles);
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Employee Forms" });
          console.log(err);
        });
    });
  };

  load = filesToLoad => {
    Storage.configure({ level: "public" });

    //Array of promises to store while being resolved
    var promises = [];
    console.log(filesToLoad)
    filesToLoad.forEach(form => {
      promises.push(
        Storage.get(form.fileName).then(result => {
          return {
            formName: form.displayName,
            url: result,
            profileID: form.profile_id,
            revisionID: form.revision_id,
            severity: form.severity,
            viewed: false
          };
        })
      );
    });
    //once all promises are resolved, add objects to local state
    Promise.all(promises).then(forms => {
      console.log(forms)
      console.log(forms[0])
      this.setState({
        ee_forms: forms,
        selected_form: forms[0]
      });
      this.props.isDoneLoading();
      this.selectForm(0);
    });
  };

  reload = () => {
    this.getSingleFormByVersion(this.props.auth.user_id).then(r => {
      this.setState({
        selected_form: { ...this.state.selected_form, url: "" }
      });
      this.selectForm(this.state.formNumber - 1);
    });
  };

  selectForm = index => {
    var form = this.state.ee_forms[index];
    this.setState({ selected_form: form });
    //this.getFields(form.revisionID);

    let formsCopy = JSON.parse(JSON.stringify(this.state.ee_forms));

    formsCopy[index].viewed = true;
    this.setState({
      ee_forms: formsCopy,
      formNumber: index + 1
    });

    if (index === 0) this.props.setFirstForm(true);
    else this.props.setFirstForm(false);
    if (index === this.state.ee_forms.length - 1) this.props.setLastForm(true);
    else this.props.setLastForm(false);
  };

  prevForm = () => {
    var currentForm = this.state.formNumber;

    if (currentForm > 1) {
      this.selectForm(currentForm - 2);
      this.scrollToMyRef1();
    }
  };

  nextForm = () => {
    var currentForm = this.state.formNumber;

    if (currentForm < this.state.ee_forms.length) {
      this.selectForm(currentForm);
      this.scrollToMyRef1();
    }
  };

   openMessageModal = form => {
    this.setState({messageModalOpen: true, messageSenderID : form.profileID, messageSenderName: form.formName})
  };

  closeMessageModal = event => {
    this.setState({messageModalOpen: false})
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div
          id="myRef1"
          ref="myRef1"
          style={{ height: 0, marginTop: 0, display: "block" }}
        />
        <Paper className={classes.paper}>
          {(this.props.displaySidebar && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: 20,
                backgroundColor: "white"
              }}
            >
              <div
                style={{
                  justifyContent: "flex-end",
                  paddingTop: 50,
                  backgroundColor: "white",
                  width: 275
                }}
              >
                {this.state.ee_forms.map((form, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Button
                        variant="outlined"
                        color={
                          (this.state.selected_form.url === form.url &&
                            "primary") ||
                          "default"
                        }
                        className={classes.button}
                        onClick={() => this.selectForm(index)}
                        style={{
                          marginBottom: 10,
                          marginRight: 10,
                          width: 165
                        }}
                      >
                        {form.formName}
                      </Button>
                      {(form.viewed && (
                        <DoneIcon
                          color={"primary"}
                          style={{ marginRight: 10 }}
                        />
                      )) || (
                        <DoneIcon
                          style={{ marginRight: 10, color: "#ffffff" }}
                        />
                      )}
                      {form.severity === 2 && (
                        <Level2Icon color={"secondary"} />
                      )}
                      {form.severity === 1 && (
                        <Level1Icon color={"secondary"} />
                      )}
                      <IconButton
                        onClick={ () => {this.openMessageModal(form)}}
                      >
                          <MessagesIcon color={"primary"} />
                      </IconButton>
                    </React.Fragment>
                  );
                })}
              </div>

              <div style={{ justifyContent: "flex-end" }}>
                <FormViewer
                  fields={this.state.fields}
                  User={this.props.auth.user_id}
                  overrides={
                    this.props.forms === "employee" ||
                    this.props.forms === "brokerEmployee"
                  }
                  Form={this.state.selected_form}
                  FormNumber={this.state.formNumber}
                  FormTotal={this.state.ee_forms.length}
                  Reload={this.reload}
                  MaxWidth={1150}
                />

                <div
                  style={{
                    display: this.state.ee_forms.length > 1 ? "block" : "none"
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex" }}>
                      <React.Fragment>
                        <IconButton
                          disabled={this.state.formNumber > 1 ? false : true}
                          onClick={this.prevForm}
                          aria-label="Back"
                        >
                          <BackIcon
                            color={
                              this.state.formNumber > 1 ? "primary" : "disabled"
                            }
                          />
                        </IconButton>
                      </React.Fragment>

                      <Typography style={{ margin: 14 }}>
                        Form {this.state.formNumber} of{" "}
                        {this.state.ee_forms.length}
                      </Typography>

                      <React.Fragment>
                        <IconButton
                          disabled={
                            this.state.formNumber < this.state.ee_forms.length
                              ? false
                              : true
                          }
                          onClick={this.nextForm}
                          aria-label="Next"
                        >
                          <NextIcon
                            color={
                              this.state.formNumber < this.state.ee_forms.length
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </IconButton>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) || (
            <div className={classes.formDiv}>
              {/*<div style={{ margin: 20 }}>*/}
              <FormViewer
                fields={this.state.fields}
                //User={this.props.auth.user_id}
                overrides={
                  this.props.forms === "employee" ||
                  this.props.forms === "brokerEmployee"
                }
                Form={this.state.selected_form}
                FormNumber={this.state.formNumber}
                FormTotal={this.state.ee_forms.length}
                Reload={this.reload}
                MaxWidth={1400}
              />

              <div
                style={{
                  display: this.state.ee_forms.length > 1 ? "block" : "none"
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ display: "flex" }}>
                    <React.Fragment>
                      <IconButton
                        disabled={this.state.formNumber > 1 ? false : true}
                        onClick={this.prevForm}
                        aria-label="Back"
                      >
                        <BackIcon
                          color={
                            this.state.formNumber > 1 ? "primary" : "disabled"
                          }
                        />
                      </IconButton>
                    </React.Fragment>

                    <Typography style={{ margin: 14 }}>
                      Form {this.state.formNumber} of{" "}
                      {this.state.ee_forms.length}
                    </Typography>

                    <React.Fragment>
                      <IconButton
                        disabled={
                          this.state.formNumber < this.state.ee_forms.length
                            ? false
                            : true
                        }
                        onClick={this.nextForm}
                        aria-label="Next"
                      >
                        <NextIcon
                          color={
                            this.state.formNumber < this.state.ee_forms.length
                              ? "primary"
                              : "disabled"
                          }
                        />
                      </IconButton>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

ReviewContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReviewContainer);
