import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import api from "../../Shared/Functions/APIHelpers";

import Button from "@material-ui/core/Button";

class DenyEmployerInvitePublic extends React.Component {
  state = {
    invitationResult: "",
  };

  componentDidMount() {
    this.handleUpdateEmployerInvite();
  }
  getInvitationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("invitationID");
  };


  handleUpdateEmployerInvite = () => {
    this.updateEmployerInvite()
      .then((r) => {
        console.log(r)
      })
      .catch((err) => {
        this.setState({
          invitationResult: "This Invitation Has Been Closed or Cancelled",
        });
      });
  };

  updateEmployerInvite = () => {
    let body = {
      result: 0,
      permission_level: 0,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.updateBrokerInviteNoAccount(this.getInvitationID(), JSON.stringify(body))
      );
    });
  };
  render() {
    return (
      <React.Fragment>
        <Container
          maxWidth="sm"
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4">This Invitation Has Been Declined</Typography>
        
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DenyEmployerInvitePublic);
