import React from "react";

import PageHeader from "../../Shared/Nav/PageHeader";
import FormViewerContainer from "../../Shared/Components/FormViewer/FormViewerContainer";

const initialState = {};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {}

  goBack = () => {
    console.log("clicking back!")
    this.props.history.push("/employer/medicare?id=" + this.props.medicareID)
  };

  goNext = () => {
    if (this.state.isLastForm) {
      this.props.action("signature");
    } else {
      this.childGoNext();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ padding: 10 }}>
        <PageHeader
          hasNext={false}
          hasBack={true}
          pageTitle="View Form"
          backText="Back"
          nextText="Sign"
          back={this.props.goBack}
          //next={() => {this.props.setStep("sign")}}
        />
        <FormViewerContainer
          displaySidebar={false}
          setGoNext={func => (this.childGoNext = func)}
          setGoBack={func => (this.childGoBack = func)}
          isDoneLoading={()=>{console.log('done')}}
          setFirstForm={()=>{console.log('first form')}}
          setLastForm={()=>{console.log('last form')}}
          medicareID={this.props.medicareID}
        />
        </div>
      </React.Fragment>
    );
  }
}

export default Form;