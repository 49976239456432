import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Storage } from "aws-amplify";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import CloudDownload from "@material-ui/icons/CloudDownload";
import LaunchIcon from "@material-ui/icons/Launch";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewDocumentDialog from "./ViewDocumentDialog";
import { formatDateTime } from "../../Functions/Format";
import ConfirmAlert from "../Confirm/ConfirmAlert";
import image from "../../Images/doc.png";
import api from "../../../Shared/Functions/APIHelpers";
import Tooltip from "@material-ui/core/Tooltip";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    //height: '50vh',
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    //alignItems:"flex-start",
    paddingLeft: 25,
    paddingRight: 25,
    "@media only screen and (max-width: 600px)": {
      justifyContent: "center",
    },
  },
  icon: {
    color: "white",
  },
});

class DocumentList extends React.Component {
  state = {
    documents: [],
    confirmAlertOpen: false,
    viewDocumentDialogOpen: false,
    selectedFile: {},
    documentToDelete: {},
  };

  deleteDocument = (id) => {
    return new Promise((resolve, reject) => {
      api
        .deleteDocument(id)
        .then((r) => {
          resolve("done");
        })
        .catch((err) => {
          this.props.enqueueSnackbar("Can't Delete Document", {
            variant: "error",
          });
        });
    });
  };

  downloadFromS3 = (file) => {
    //var f = this.props.portalID + '/document_center/' + file.file_name

    Storage.get(file.document_url)
      .then((result) => {
        const link = document.createElement("a");
        link.href = result;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteFromS3 = (file) => {
    return new Promise((resolve, reject) => {
      Storage.remove(file.document_url)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  handleDownloadFile = (file) => {
    this.downloadFromS3(file);
  };

  handleViewDocument = (file) => {
    this.setState({ selectedFile: file, viewDocumentDialogOpen: true });
    //this.setState({viewDocumentDialogOpen: true})
  };

  handleDeleteFile = () => {
    var file = this.state.documentToDelete;
    this.deleteFromS3(file).then(() => {
      this.deleteDocument(file.id).then(() => {
        this.props.handleGetDocuments();
      });
    });
  };

  handleConfirmDelete = (file) => {
    this.setState({ confirmAlertOpen: true, documentToDelete: file });
  };
  refreshList = () => {
    this.getDocuments(this.props.portalID);
  };

  DoNotDelete = () => {
    this.setState({ confirmAlertOpen: false, documentToDelete: {} });
  };

  DoDelete = () => {
    this.setState({ confirmAlertOpen: false });
    this.handleDeleteFile();
  };

  render() {
    const { classes, height, width } = this.props;
    return (
      <React.Fragment>
        {this.state.viewDocumentDialogOpen && (
          <ViewDocumentDialog
            Open={this.state.viewDocumentDialogOpen}
            Close={() => {
              this.setState({
                viewDocumentDialogOpen: false,
                selectedFile: {},
              });
            }}
            SelectedFile={this.state.selectedFile}
          />
        )}
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.DoNotDelete}
          isYes={this.DoDelete}
          AlertTitle={"Confirm Delete"}
          AlertText={"Are you sure you wish to delete this document"}
        />

        <GridList cellHeight={150} className={classes.gridList}>
          {this.props.documents.map((file, index) => (
            <Tooltip title={file.name} sx={{ fontSize: 40 }} arrow={true}>
              <GridListTile
                key={index}
                style={{
                  height: height,
                  width: width,
                  border: "1px solid #f2f2f2",
                  margin: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.handleViewDocument(file);
                }}
              >
                <img height={400} src={file.image_url} alt={file.file_name} />
                <GridListTileBar
                  title={file.name}
                  subtitle={<span>{formatDateTime(file.date_added)}</span>}
                  actionIcon={
                    <React.Fragment>
                      <div style={{ display: "flex", flexWrap: "nowrap" }}>
                        <IconButton className={classes.icon}>
                          <LaunchIcon style={{ fontSize: 20 }} />
                        </IconButton>
                        {this.props.canDelete && !file.static && (
                          <IconButton
                            className={classes.icon}
                            onClick={() => {
                              this.handleConfirmDelete(file);
                            }}
                          >
                            <DeleteIcon style={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                      </div>
                    </React.Fragment>
                  }
                />
              </GridListTile>
            </Tooltip>
          ))}
        </GridList>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DocumentList);
