import React from "react";
import DisabledIcon from "@material-ui/icons/Block";
import LockIcon from "@material-ui/icons/Lock";
import HasAccountIcon from "@material-ui/icons/Check";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
export function dateCellDataGetter(val, col) {
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (val) {
    var time = new Date(val);
    return time.toLocaleString("en-US", { timeZone: timeZone });
  } else {
    return val;
  }
}

export function dateCellDataGetterDate(val, col) {
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (val) {
    var time = new Date(val);
    return time.toLocaleDateString("en-US", { timeZone: timeZone });
  } else {
    return val;
  }
}
export function employerTerminatedIconDataGetter(val, col) {
  //if val is false, employer is not ignored so do nothing
  if (val) return <RemoveCircleOutlineIcon />;
  else return null;
}

export function employerIgnoreIconDataGetter(val, col) {
  //if val is false, employer is not ignored so do nothing
  if (val) return <DisabledIcon />;
  else return null;
}

export function employerSecureIconDataGetter(val, col) {
  if (val) return <LockIcon />;
  else return null;
}
export function brokerLockedIconDataGetter(val, col) {
  if (val) return <LockIcon />;
  else return null;
}

export function brokerHasAccountIconDataGetter(val, col) {
  if (val) return <HasAccountIcon />;
  else return null;
}

export function employerStatusDataGetter(val, col) {
  if (val === "Sent") return "Not Opened";
  else return val;
}

export function booleanCellDataGetter(val, col) {
  if (val) return "Yes";
  else return "No";
}
export function contactTypeDataGetter(val, col) {
  if (val) return "Primary";
  else return "Secondary";
}

export function nestedCellDataGetter(val, col) {
  return val[col.nestedCol];
}

export function newContinuationStatusDataGetter(val, col) {
  if (val === 0) {
    return "State Continuation";
  }
  if (val === 1) {
    return "COBRA";
  }
}

export function changeRequestStatusDataGetter(val, col) {
  if (val === 0) {
    return "Pending";
  }
  if (val === 1) {
    return "Approved";
  }
  if (val === 2) {
    return "Denied";
  }
  if (val === 3) {
    return "Cancelled";
  }
}

export function employerMEWACellDataGetter(val, col) {
  return val["name"];
}

export function booleanSort(direction, sortBy, col, data) {
  if (direction === "ASC")
    return data.sort((a, b) => (a === b ? 0 : a ? 1 : -1));
  else return data.sort((a, b) => (a === b ? 0 : a ? 1 : -1));
}

export function nestedSort(direction, sortBy, col, data) {
  if (direction === "ASC")
    return data.sort((a, b) =>
      a[sortBy][col.nestedCol].localeCompare(b[sortBy][col.nestedCol])
    );
  else
    return data.sort((a, b) =>
      b[sortBy][col.nestedCol].localeCompare(a[sortBy][col.nestedCol])
    );
}

export function employerIgnoreSort(direction, sortBy, col, data) {
  if (direction === "ASC")
    return data.sort((a, b) =>
      a["ignore"] === b["ignore"] ? 0 : a["ignore"] ? 1 : -1
    );
  else
    return data.sort((a, b) =>
      a["ignore"] === b["ignore"] ? 0 : b["ignore"] ? 1 : -1
    );
}

export function getCountURLFromFilters(activeFilters, url) {
  //loop through filters and append to get url as query args
  if (!activeFilters.length) return url;
  url = url + "?";
  activeFilters.forEach((f, i) => {
    url += i ? "&" : "";
    if (f.nestedCol)
      url += f.nestedCol + "=" + encodeURIComponent(f[f.dataKey]);
    else url += f.dataKey + "=" + encodeURIComponent(f[f.dataKey]);
  });

  return url;
}

export function getURLFromFilters(activeFilters, url, sortBy, sortDirection) {
  //loop through filters and append to get url as query args
  activeFilters.forEach((f, i) => {
    url += i ? "&" : "";
    url += f.dataKey + "=" + encodeURIComponent(f[f.dataKey]);
  });

  //Check for sort parameters
  if (sortBy !== "") {
    url +=
      "&sortBy=" +
      encodeURIComponent(sortBy) +
      "&sortDirection=" +
      encodeURIComponent(sortDirection);
  }

  return url;
}

export function getInfiniteURLFromFilters(
  activeFilters,
  url,
  startIndex,
  stopIndex,
  sortBy,
  sortDirection,
  nestedSortBy
) {
  //loop through filters and append to get url as query args
  url = url + "?startIndex=" + startIndex + "&stopIndex=" + stopIndex;

  //if (!activeFilters.length) return url;
  activeFilters.forEach((f, i) => {
    url += "&";
    if (f.nestedCol) {
      url += f.nestedCol + "=" + encodeURIComponent(f[f.dataKey]);
    } else {
      url += f.dataKey + "=" + encodeURIComponent(f[f.dataKey]);
    }
  });

  //now add sort if not empty
  if (sortBy !== "")
    url +=
      "&sortBy=" +
      encodeURIComponent(sortBy) +
      "&sortDirection=" +
      encodeURIComponent(sortDirection);
  if (nestedSortBy !== "")
    url +=
      "&sortBy=" +
      encodeURIComponent(nestedSortBy) +
      "&sortDirection=" +
      encodeURIComponent(sortDirection);
  return url;
}
