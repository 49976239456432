import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SignedDocumentDialog from "./SignedArcDocumentDialog";
import classnames from "classnames";
import { formatDateTime } from "../../Functions/Format";
import { withStyles } from "@material-ui/core/styles";
import "./SignedDocumentationStyles.css";

const SignedDocumentationList = ({
  arcRequest,
  calloutSignatureDate,
  classes,
}) => {
  const [signedDocumentDialogOpen, setSignedDocumentDialogOpen] = useState(false);
  const getListItemText = () => {
    return arcRequest.sig_form_name;
  };
  const getListItemStatus = () => {
    if (arcRequest.signature_date === null) {
      return "Not Signed";
    } else {
      return "Signed " + formatDateTime(arcRequest.signature_date);
    }
  };

  const downloadDocument = () => {
    setSignedDocumentDialogOpen(true)
  };

  return (
    <React.Fragment>
    {signedDocumentDialogOpen && (
              <SignedDocumentDialog
                Open={signedDocumentDialogOpen}
                Close={(signedDocument) => {
                  setSignedDocumentDialogOpen(false)
                }}
                arcRequest={arcRequest}
              />
            )}
      <div style={{ maxWidth: 350 }}>
        <List dense={true}>
          <ListItem>
            <ListItemText
              primary={getListItemText()}
              secondary={
                <span
                  className={classnames({
                    blinkSignatureDate: calloutSignatureDate,
                  })}
                >
                  {getListItemStatus()}
                </span>
              }
            />
          </ListItem>
          <ListItemSecondaryAction>
            {arcRequest.signature_date && (
              <IconButton
                onClick={() => {
                  downloadDocument();
                }}
                edge="end"
                aria-label="open"
              >
                <OpenInNewIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </List>
      </div>
    </React.Fragment>
  );
};

export default withStyles(null, { withTheme: true })(SignedDocumentationList);
