import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import FolderIcon from "@material-ui/icons/Folder";
import CancelIcon from "@material-ui/icons/Cancel";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import DeleteIcon from "@material-ui/icons/Delete";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import BusinessIcon from "@material-ui/icons/BusinessCenter";
import LockIcon from "@material-ui/icons/Lock";

import NewTable from "../../Shared/Components/Table/NewTable";
import BrokerPasswordDialog from "./BrokerPasswordDialog";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import Add from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import BrokerContactDialog from "./BrokerContactDialog";
import ViewEmployersDialog from "./ViewEmployersDialog";


import config from "../../config";
import {
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  brokerLockedIconDataGetter,
  brokerHasAccountIconDataGetter,
} from "../../Shared/Components/Table/TableUtilities";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  selectedBroker: {},
  brokerPasswordDialogOpen: false,
  brokerPassword: "",

  //FOR TABLE
  tableData: [],
  recordCount: 0,
  dataURL: "broker-portal-mewa",
  staticFilterURL: "broker-portal-mewa-static-filter",
  staticFilters: [],
  staticFilterValues: [],
  sortBy: "",
  nestedSortBy: "",
  sortDirection: "ASC",
  countURL: "broker-portal-mewa-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,

  brokerContactDialogOpen: false,
  viewEmployersDialogOpen: false
};

class BrokerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState({ initialLoadDone: false, fetching: true }, () => {
      this.getStaticFilterValues().then((values) => {
        this.setState({ staticFilterValues: values }, () => {
          this.getTableCount().then((count) => {
            this.getTableData(0, 10)
              .then((r) => {
                console.log(r)
                this.setState({
                  tableData: r,
                  recordCount: count["record_count"],
                  fetching: false,
                  initialLoadDone: true,
                });
                if (isInitial)
                  this.setState({
                    initialCount: count["record_count"],
                    currentCount: count["record_count"],
                  });
                else this.setState({ currentCount: count["record_count"] });
              })
              .catch((err) => {
                if (err === 403) {
                  this.props.history.push("/mewa-admin/auth?error=5");
                }
                if (err === 400) {
                  this.props.enqueueSnackbar(
                    "Something went wrong rendering brokers",
                    {
                      variant: "error",
                    }
                  );
                }
              });
          });
        });
      });
    });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering brokers",
                {
                  variant: "error",
                }
              );
            }
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            this.state.nestedSortBy
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    let nestedColSortKey = "";
    if (col.nestCol) {
      nestedColSortKey = col.nestedColSortKey;
    }

    this.setState(
      {
        sortBy: sortBy,
        sortDirection: sortDirection,
        nestedSortBy: nestedColSortKey,
      },
      () => {
        this.handleInitialData();
      }
    );
  };

  handleResendBrokerInvite = (selectedItem, closeMenu) => {
    this.setState({
      selectedBroker: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Resend Broker Invite",
      confirmAlertText: "Are you sure you wish to resend an invite to " + selectedItem.first_name + " " + selectedItem.last_name + " (" + selectedItem.email + ")?",
      doNotFunc: this.doNotResnedBrokerInvite,
      doFunc: this.doResendBrokerInvite,
    });
    closeMenu();
  };

  doNotResnedBrokerInvite = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedBroker: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  doResendBrokerInvite = () =>{
    this.resendBrokerInvite(this.state.selectedBroker.id)
      .then((r) => {
        this.handleInitialData();
        this.props.enqueueSnackbar("Broker invite resent", {
          variant: "success",
        });
        this.setState({
          confirmAlertOpen: false,
          selectedBroker: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          "Something went wrong resending broker invite",
          {
            variant: "error",
          }
        );
        this.setState({
          confirmAlertOpen: false,
          selectedBroker: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      });
  }

  

  resendBrokerInvite = (brokerID) => {
    return new Promise((resolve, reject) => {
      api
        .resendBrokerInviteMEWA(brokerID)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };


  handleBrokerUnlockAccount = (selectedItem, closeMenu) => {
    this.setState({
      selectedBroker: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Unlock Account",
      confirmAlertText: "Are you sure you wish to unlock " + selectedItem.first_name + " " + selectedItem.last_name + "'s account?",
      doNotFunc: this.doNotUnlockBroker,
      doFunc: this.doUnlockBroker,
    });
    closeMenu();
  };

  doNotUnlockBroker = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedBroker: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };
  doUnlockBroker = () => {
    this.lockBroker(this.state.selectedBroker.id)
      .then((r) => {
        this.handleInitialData();
        this.props.enqueueSnackbar("Broker account unlocked", {
          variant: "success",
        });
        this.setState({
          confirmAlertOpen: false,
          selectedBroker: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          "Something went wrong unlocking broker account",
          {
            variant: "error",
          }
        );
        this.setState({
          confirmAlertOpen: false,
          selectedBroker: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      });
  };

  handleLockBrokerAccount = (selectedItem, closeMenu) => {
    this.setState({
      selectedBroker: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Lock Account",
      confirmAlertText: "Are you sure you wish to lock " + selectedItem.first_name + " " + selectedItem.last_name + "'s account?",
      doNotFunc: this.doNotLockBroker,
      doFunc: this.doLockBroker,
    });
    closeMenu();
  };

  doNotLockBroker = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedBroker: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  doLockBroker = () => {
    this.lockBroker(this.state.selectedBroker.id)
      .then((r) => {
        this.handleInitialData();
        this.props.enqueueSnackbar("Broker account locked", {
          variant: "success",
        });
        this.setState({
          confirmAlertOpen: false,
          selectedBroker: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          "Something went wrong locking broker account",
          {
            variant: "error",
          }
        );
        this.setState({
          confirmAlertOpen: false,
          selectedBroker: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      });
  };

  lockBroker = (brokerID) => {
    return new Promise((resolve, reject) => {
      api
        .lockBrokerAccountMEWA(brokerID)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  finalResetPassword = () => {
    this.resetPassword(this.state.selectedBroker.id).then((r) => {
      this.handleInitialData();
      this.props.enqueueSnackbar("Broker Password Has Been Reset", {
        variant: "success",
      });
    });
    this.setState({
      brokerPasswordDialogOpen: false,
      brokerPassword: "",
      selectedBroker: {},
    });
  };

  resetPassword = (brokerID) => {
    var body = JSON.stringify({
      password: this.state.brokerPassword,
    });
    return new Promise((resolve, reject) => {
      api
        .resetBrokerPasswordMEWA(brokerID, body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleResetBrokerPassword = (selectedItem, closeMenu) => {
    this.setState({
      brokerPasswordDialogOpen: true,
      selectedBroker: selectedItem,
    });
    closeMenu();
  };

  resetBrokerPasswordMEWA = (brokerID) => {};

  handleViewEmployers = (selectedItem, closeMenu) => {
    this.setState({ selectedBroker:selectedItem, viewEmployersDialogOpen: true });
    closeMenu();
  };

  handleEditBrokerContact = (selectedItem, closeMenu) => {
    this.setState({ selectedBroker:selectedItem, brokerContactDialogOpen: true });
    closeMenu();
  };

  handleOpen = () => {
    this.setState({ employerModalOpen: true, isAdd: true });
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;

    return (
      <div>
       
        <MenuItem
          onClick={() => {
            this.handleResetBrokerPassword(selectedItem, closeMenu);
          }}
        >
          <LockOpenIcon className={classes.leftIcon} />
          Reset Password
        </MenuItem>
        {selectedItem.locked && (
          <MenuItem
            onClick={() => {
              this.handleBrokerUnlockAccount(selectedItem, closeMenu);
            }}
          >
            <LockIcon className={classes.leftIcon} />
            Unlock Account
          </MenuItem>
        )}
        {!selectedItem.locked && (
          <MenuItem
            onClick={() => {
              this.handleLockBrokerAccount(selectedItem, closeMenu);
            }}
          >
            <LockIcon className={classes.leftIcon} />
            Lock Account
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            this.handleViewEmployers(selectedItem, closeMenu);
          }}
        >
          <GroupIcon className={classes.leftIcon} />
          View Employers
        </MenuItem>
        <MenuItem
          disabled = {selectedItem.has_account}
          onClick={()=>{
            this.handleEditBrokerContact(selectedItem, closeMenu)
          }}
        >
        <EditIcon className={classes.leftIcon} />
          Edit Contact
        </MenuItem>
        <MenuItem
          disabled = {selectedItem.has_account}
          onClick={() => {
            this.handleResendBrokerInvite(selectedItem, closeMenu);
          }}
        >
          <SendIcon className={classes.leftIcon} />
          Resend Account Invite
        </MenuItem> 
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        {(this.state.brokerContactDialogOpen) && (
          <BrokerContactDialog
            Open={this.state.brokerContactDialogOpen}
            Close={() => {
              this.setState({ brokerContactDialogOpen: false, selectedBroker: {} });
            }}
            RefreshTable={this.handleInitialData}
            enqueueSnackbar={this.props.enqueueSnackbar}
            history={this.props.history}
            Broker={this.state.selectedBroker}
          />
        )}
        {this.state.brokerPasswordDialogOpen && (
          <BrokerPasswordDialog
            Open={this.state.brokerPasswordDialogOpen}
            Close={() => {
              this.setState({
                brokerPasswordDialogOpen: false,
                brokerPassword: "",
                selectedBroker: {},
              });
            }}
            Password={this.state.brokerPassword}
            SetPassword={(password) => {
              this.setState({ brokerPassword: password });
            }}
            Save={this.finalResetPassword}
          />
        )}
        {this.state.viewEmployersDialogOpen && (
          <ViewEmployersDialog
            Open={this.state.viewEmployersDialogOpen}
            Close={() => {
              this.setState({ viewEmployersDialogOpen: false, selectedBroker: {} });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            history={this.props.history}
            Broker={this.state.selectedBroker}
          />
        )}    
        <div className={classes.containerRoot}>
          {/*
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <Add />
            </Fab>
          </div>
          */}
          <div className={classes.containerTitle}>Brokers</div>
          <div className={classes.containerContent}>
            <div style={{ height: 0, textAlign: "right" }}>
            </div>
            <Paper className={classes.containerPaper}>
              <NewTable
                tableName={"Brokers"}
                enqueueSnackbar={this.props.enqueueSnackbar}
                loadedData={this.state.tableData}
                loaded={this.state.initialLoadDone}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                staticFilterValues={this.state.staticFilterValues}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                setActiveFilters={this.setActiveFilters}
                activeFilters={this.state.activeFilters}
                columns={[
                  {
                    dataKey: "first_name",
                    label: "First Name",
                    width: 150,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "last_name",
                    label: "Last Name",
                    width: 150,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "email",
                    label: "Email",
                    width: 150,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "agency_name",
                    label: "Agency",
                    width: 200,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },                  
                  {
                    label: "Has Account",
                    dataKey: "has_account",
                    cellRenderer: brokerHasAccountIconDataGetter,
                    width: 100,
                    flexGrow: 1,
                    flexShrink: 0,
                    hasFilter: false,
                    disableSort: true
                  }, 
                  {
                    label: "Locked",
                    dataKey: "locked",
                    cellRenderer: brokerLockedIconDataGetter,
                    width: 85,
                    flexGrow: 1,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(BrokerContainer);
