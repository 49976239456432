import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import * as moment from "moment";
import {
  formatTerminationDate,
  formatDateTime,
  formatTerminationDateDropdown,
} from "../../Functions/Format";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../../Shared/Styles/ModalStyles";

const initialState = {
  newTerminationDate: null, //2020-10-31
  allowedDates: [],
  errors: []
};

class EditTerminationDateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  handleClose = () => {
    this.reset()
    this.props.Close();
  };


  handleSave = () => {
    if (!this.inError()) {
    this.props.setTerminationDate(moment(this.state.newTerminationDate).format("YYYY-MM-DD"))
    this.reset()
    this.handleClose()
    }
  };

  inError = () => {
    let errors = [];
    
    if(!this.state.newTerminationDate)
    {
      errors.push("newTerminationDateError")
    }
    this.setState({ errors: errors });
    return errors.length > 0;
  };

  getAllowedDates = () => {
    let allowedDates = [];

    //first add 6 moonths back from todays date, depending on preMidYear
    for (let i = 0; i < 6; ++i) {
      let today = new Date()
      let currentDate = moment(new Date(today.getFullYear(), today.getMonth(), 1));
      let todayLessMonths = currentDate.subtract(i, "months")
      if(!allowedDates.find(d => d.format("L") === todayLessMonths.format("L") ))
        allowedDates.push(todayLessMonths);
    }

    //then add 6 months after the term date
    for (let i = 1; i < 7; ++i) {
      let today = new Date()
      let currentDate = moment(new Date(today.getFullYear(), today.getMonth(), 1));
      let todayPlusMonths = currentDate.add(i, "months")
      if(!allowedDates.find(d => d.format("L") === todayPlusMonths.format("L") ))
        allowedDates.push(todayPlusMonths);
    }
    return allowedDates.sort((a, b) => a - b)
  };


  render() {
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Edit Coverage Termination Date
          </DialogTitle>

          <DialogContent
            style={{ maxHeight: 250, maxWidth: 400 }}
            className={classes.container}
          >
            <Typography
              style={{ display: "inline" }}
              variant="subtitle1"
              color="inherit"
            >
              Current Date:{" "}
              <b>{moment(this.props.currentTerminationDate).format("L")}</b>
            </Typography>
            <br />
            <br />
            <FormControl name="terminationDateSelect" error={this.state.errors.includes("newTerminationDateError")}>
              <InputLabel htmlFor="termination-date-simple">New Date</InputLabel>
              <Select
                value={this.state.newTerminationDate || moment(this.props.currentTerminationDate).format("L")}
                onChange={(e) =>{
                  this.setState({ newTerminationDate: e.target.value })
                }}
                inputProps={{
                  name: "terminationDateSelect",
                  id: "termination-date--simple",
                }}
                style={{ width: 300, marginRight: 10 }}
              >
                {this.getAllowedDates().map((date) => {
                  return (
                    <MenuItem value={date.format("L")} key={date}>
                      {date.format("LL")}
                    </MenuItem>
                  );
                })}
              </Select>
              {(this.state.errors.includes("newTerminationDateError")) && (
                <FormHelperText>Please Select a New Date</FormHelperText>
              )}
              
            </FormControl>

            <br />
            <br />
            <div style={{ marginTop: 20 }}>
              <Typography variant="caption" color="inherit">
                Please enter the date on which coverage should terminate. In the
                case of an event, like a termination or resignation, typically
                this will be the first day of the month following the event.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleSave} color="primary">
              Save
            </Button>            
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(EditTerminationDateDialog)
);
