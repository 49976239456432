import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import NewTable from "../../Shared/Components/Table/NewTable";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import UploadDialog from "../../Shared/Components/Upload/UploadDialog";
import UploadIcon from "@material-ui/icons/CloudUpload";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DownloadDialog from "../../Shared/Components/Download/DownloadDialog";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TerminationDetail from "./TerminationDetail";
import DetailsIcon from "@material-ui/icons/Info";
import TerminationDialog from "./TerminationDialog";
import BrokerAgreement from "../../Shared/Components/Agreement/BrokerAgreement";
import {
  dateCellDataGetter,
  dateCellDataGetterDate,
  booleanCellDataGetter,
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  employerIgnoreIconDataGetter,
  nestedSort,
} from "../../Shared/Components/Table/TableUtilities";

import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  terminationModal: false,
  uploadRecordDialog: false,
  downloadRecordDialog: false,
  detailDialogOpen: false,
  downloadType: "",
  terminationDetail: {},

  //FOR TABLE
  tableData: [],
  recordCount: 0,
  dataURL: "broker-portal-termination",
  staticFilterURL: "broker-portal-termination-static-filters",
  staticFilters: ["employer__mewa__name", "status"],
  staticFilterValues: [],
  sortBy: "",
  sortDirection: "ASC",
  countURL: "broker-portal-termination-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class TerminationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState({ initialLoadDone: false, fetching: true }, () => {
      this.getStaticFilterValues().then((values) => {
        this.setState({ staticFilterValues: values }, () => {
          this.getTableCount().then((count) => {
            this.getTableData(0, 10)
              .then((r) => {
                this.setState({
                  tableData: r,
                  recordCount: count["record_count"],
                  fetching: false,
                  initialLoadDone: true,
                });
                //if this is the first time the page is mounted, set record count as initial table amount
                //else set the current filtered count
                if (isInitial)
                  this.setState({
                    initialCount: count["record_count"],
                    currentCount: count["record_count"],
                  });
                else this.setState({ currentCount: count["record_count"] });
              })
              .catch((err) => {
                if (err === 403) {
                  this.props.history.push("/mewa-admin/auth?error=5");
                }
                if (err === 400) {
                  this.props.enqueueSnackbar(
                    "Something went wrong rendering medicare",
                    {
                      variant: "error",
                    }
                  );
                }
              });
          });
        });
      });
    });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering medicare",
                {
                  variant: "error",
                }
              );
            }
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            ""
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    this.setState({ sortBy: sortBy, sortDirection: sortDirection }, () => {
      this.handleInitialData();
    });
  };

  editTermination = (terminationDetail, closeMenu) => {
    this.setState({
      terminationDetail: terminationDetail,
      isAdd: false,
      terminationModal: true,
    });
    closeMenu();
  };

  handleResendTermination = (selectedItem, closeMenu) => {
    let confirmText = (
      <React.Fragment>
        Are you sure you wish to resend this termination record to {selectedItem.employer_name}? This email will
        be sent to {selectedItem.employer_contact_first}{" "}
        {selectedItem.employer_contact_last}(
        {selectedItem.employer_contact_email}).
        <br />
        <br />
        Employers receive reminders automatically. Please only resend this
        notification if the employer has requested it.
      </React.Fragment>
    );

    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      closeMenu: closeMenu,
      terminationDetail: selectedItem,
      confirmAlertTitle: "Confirm Resend",
      confirmAlertText: confirmText,
      doNotFunc: this.doNotResendTermination,
      doFunc: this.doResendTermination,
    });
  };

  doResendTermination = () => {
    this.setState({ confirmAlertOpen: false });
    this.resendTerminationEmail(this.state.terminationDetail.id).then(() => {
      this.props.enqueueSnackbar("Email Resent", {
        variant: "success",
      });
      this.state.closeMenu();
    });
  };

  doNotResendTermination = () => {
    this.setState({
      confirmAlertOpen: false,
      teriminationDetail: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  resendTerminationEmail = (terminationID) => {
    return new Promise((resolve, reject) => {
      resolve(api.resendBrokerTerminationEmail(terminationID));
    });
  };

  /************************************************/

  handleCompleteTermination = (selectedItem, closeMenu) => {
    this.setState({
      terminationModal: true,
      terminationDetail: selectedItem,
    });
    closeMenu();
  };

  handleTrackTermination = (selectedItem, closeMenu) => {
    this.setState({ detailDialogOpen: true, terminationDetail: selectedItem });
    closeMenu();
  };

  handleOpenBulkLoad = () => {
    this.setState({ uploadRecordDialog: true });
  };

  handleDownloadAuthForms = () => {
    this.setState({ downloadRecordDialog: true, downloadType: "authForm" });
  };

  handleOpenDownload = () => {
    this.setState({ downloadRecordDialog: true, downloadType: "termination" });
  };

  handleOpenDownloadAuth = () => {
    this.setState({ downloadRecordDialog: true });
  };

  handleOpenTerminationModal = () => {
    this.setState({ terminationModal: true, isAdd: true });
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;
    return (
      <div>
        <MenuItem
          disabled={selectedItem.permission_level < 1}
          name="trackRequestMenuItem"
          onClick={() => {
            this.handleTrackTermination(selectedItem, closeMenu);
          }}
        >
          <DetailsIcon className={classes.leftIcon} />
          Termination Details
        </MenuItem>

        <MenuItem
          disabled={selectedItem.permission_level < 2}
          name="completeTerminationMenuItem"
          onClick={() => {
            this.handleCompleteTermination(selectedItem, closeMenu);
          }}
          id={'complete-termination-' + selectedItem.id}
        >
          <AssignmentTurnedInIcon className={classes.leftIcon} />
          Complete Termination
        </MenuItem>

        <MenuItem
          disabled={!selectedItem.date_closed}
          name="resendTerminationEmailMenuItem"
          onClick={() => {
            this.handleResendTermination(selectedItem, closeMenu);
          }}
          disabled={selectedItem.status === "Complete"}
        >
          <SendIcon className={classes.leftIcon} />
          Resend
        </MenuItem>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <BrokerAgreement
          Open={this.props.needTOS}
          Close={() => {
            this.props.getBrokerTOS();
          }}
        />
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        {this.state.terminationModal && (
          <TerminationDialog
            terminationDetail={this.state.terminationDetail}
            Open={this.state.terminationModal}
            Close={() => {
              this.setState({ terminationModal: false, terminationDetail: {} });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            history={this.props.history}
            RefreshTable={this.handleInitialData}
          />
        )}
        {this.state.detailDialogOpen && (
          <TerminationDetail
            SelectedRecord={this.state.terminationDetail}
            Open={this.state.detailDialogOpen}
            Close={() => {
              this.setState({ detailDialogOpen: false, terminationDetail: {} });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            history={this.props.history}
          />
        )}
        <div className={classes.containerRoot}>
          {/*
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpenTerminationModal}
            >
              <Add />
            </Fab>
          </div>
          */}
          <div className={classes.containerTitle}>Termination Records</div>
          <div className={classes.containerContent}>
            <Paper className={classes.containerPaper}>
              <NewTable
                enqueueSnackbar={this.props.enqueueSnackbar}
                loaded={this.state.initialLoadDone}
                loadedData={this.state.tableData}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                staticFilterValues={this.state.staticFilterValues}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                setActiveFilters={this.setActiveFilters}
                activeFilters={this.state.activeFilters}
                tableName={"Termination Records"}
                columns={[
                  {
                    label: "Employer Name",
                    dataKey: "employer_name",
                    width: 300,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "mewa",
                    nestedCol: "employer__mewa__name",
                    label: "MEWA",
                    customSort: nestedSort,
                    hasFilter: true,
                    hide: true, //dont neeed tis vis
                    filterType: "Static",
                  },
                  {
                    dataKey: "employer_contact_email",
                    label: "Employer Contact",
                    hasFilter: true,
                    hide: true, //dont neeed tis vis
                    filterType: "Search",
                  },
                  {
                    label: "Carrier Group ID",
                    dataKey: "employer__carrier_case_id",
                    width: 0,
                    flexGrow: 1,
                    hide: true,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    label: "Employee First",
                    dataKey: "employee_first",
                    hasFilter: false,
                    width: 250,
                    flexGrow: 4,
                    flexShrink: 1,
                  },
                  {
                    label: "Employee Last",
                    dataKey: "employee_last",
                    hasFilter: false,
                    width: 250,
                    flexGrow: 4,
                    flexShrink: 1,
                  },
                  {
                    label: "Date Added",
                    dataKey: "date_added",
                    cellRenderer: dateCellDataGetterDate,
                    width: 124,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Date Closed",
                    dataKey: "date_closed",
                    cellRenderer: dateCellDataGetterDate,
                    width: 150,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Verify",
                    dataKey: "verify",
                    cellRenderer: booleanCellDataGetter,
                    width: 74,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Status",
                    dataKey: "status",
                    width: 125,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: true,
                    filterType: "Static",
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div id="tableStatusDownloadButtons"></div>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(TerminationContainer);
