import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getStates } from "../../Functions/Format";
import { KeyboardDatePicker } from "@material-ui/pickers";
import * as moment from "moment";
import EmployerAsyncSelect from "../Employer/EmployerAsyncSelect";
import { formatTerminationDate } from "../../Functions/Format";
import EditTerminationDateDialog from "./EditTerminationDateDialog";

import EINTextBox, {
  mapEINToMask,
  outputPhoneFromMask,
} from "../Input/EINTextBox";

const styles = (theme) => ({
  fieldContainer: {
    width: 500,
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
});

const TerminationForm = (props) => {
  const [editTerminationDateDialogOpen, setEditTerminationDateDialogOpen] =
    useState(false);

  const handleHelp = () => {
    props.OpenHelpModal();
  };

  const stateAutoSelect = (event) => {
    let keyPressed = event.key.toLowerCase();
    if (keyPressed.match(/[a-z]/) && keyPressed.length === 1) {
      //this means character
      let state = getStates().find((s) =>
        s.abbreviation.charAt(0).toLowerCase().startsWith(keyPressed)
      );
      if (state) props.onChange(state.id, "employee_state");
    }
  };

  const mapRelationshipType = (type) => {
    switch (type) {
      case 0:
        return "Employee";
      case 1:
        return "Spouse";
      case 2:
        return "Child";
      default:
        return "Other";
    }
  };
  const { classes, errors } = props;

  return (
    <React.Fragment>
      <EditTerminationDateDialog
        Close={() => {
          setEditTerminationDateDialogOpen(false);
        }}
        Open={editTerminationDateDialogOpen}
        currentTerminationDate={props.termination_date}
        setTerminationDate={(newDate) => {
          props.onChange(newDate, "termination_date");
        }}
      />
      <div className={classes.fieldContainer} id={'term-form'}>
        {(props.isAdd && (
          <EmployerAsyncSelect handleEmployerChange={props.employerChange} />
        )) || (
          <TextField
            id="employer-name-text-field"
            label="Employer Name"
            disabled={!props.isAdd}
            error={errors.includes("employer_name")}
            helperText={errors.includes("employer_name") ? "Required" : ""}
            margin="dense"
            onChange={(e) => props.onChange(e.target.value, "employer_name")}
            value={props.employer_name}
            fullWidth
          />
        )}

        <FormControl fullWidth style={{ marginTop: 10 }}>
          <InputLabel htmlFor="formatted-text-mask-input">EIN</InputLabel>
          <Input
            value={props.EIN}
            onChange={(e) => props.onChange(e.target.value, "EIN")}
            disabled={!props.isAdd || props.usingEmployerPreload}
            name="textmask"
            id="formatted-text-mask-input"
            inputComponent={EINTextBox}
          />
        </FormControl>

        <TextField
          id="email-text-field"
          label="Email"
          disabled={!props.isAdd || props.usingEmployerPreload}
          error={errors.includes("employer_contact_email")}
          helperText={
            errors.includes("employer_contact_email") ? "Required" : ""
          }
          margin="dense"
          onChange={(e) =>
            props.onChange(e.target.value, "employer_contact_email")
          }
          value={props.employer_contact_email}
          fullWidth
        />

        <FormLabel
          component="legend"
          style={{ marginTop: 70, marginBottom: 5 }}
        >
          Employee
        </FormLabel>
        <Typography variant="subtitle1" color="inherit">
          {props.employee_first + " " + props.employee_last}
        </Typography>

        <FormLabel
          component="legend"
          style={{ marginTop: 20, marginBottom: 5 }}
        >
          Terminating Coverage
        </FormLabel>

        <ul style={{ marginLeft: -17 }}>
          {props.members
            .sort((a, b) => a.relationship_type - b.relationship_type)
            .map((member, index) => {
              return (
                <li key={index}>
                  <Typography key={index} variant="subtitle1" color="inherit">
                    {member.first_name +
                      " " +
                      member.last_name +
                      " (" +
                      mapRelationshipType(member.relationship_type) +
                      ")"}
                  </Typography>
                </li>
              );
            })}
        </ul>

        <FormLabel component="legend" style={{ marginTop: 70 }}>
          Mailing Address for Continuation of Coverage Forms
        </FormLabel>
        <TextField
          id="employee-street-text-field"
          label="Street"
          error={errors.includes("employee_street")}
          helperText={errors.includes("employee_street") ? "Required" : ""}
          margin="dense"
          onChange={(e) => props.onChange(e.target.value, "employee_street")}
          value={props.employee_street}
          fullWidth
        />

        <TextField
          id="employee-city-text-field"
          label="City"
          error={errors.includes("employee_city")}
          helperText={errors.includes("employee_city") ? "Required" : ""}
          margin="dense"
          onChange={(e) => props.onChange(e.target.value, "employee_city")}
          value={props.employee_city}
          fullWidth
        />

        <FormControl margin="dense" onKeyUp={stateAutoSelect} fullWidth>
          <InputLabel htmlFor="states">State</InputLabel>
          <Select
            value={props.employee_state || ""}
            onChange={(e) => props.onChange(e.target.value, "employee_state")}
            inputProps={{
              name: "employeeState",
              id: "employee-state",
            }}
            style={{ width: 70, marginRight: 10 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {getStates().map((state) => {
              return (
                <MenuItem value={state.id} key={"state_" + state.id}>
                  {state.abbreviation}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          id="employee-zip-text-field"
          label="Zip"
          error={errors.includes("employee_zip")}
          helperText={errors.includes("employee_zip") ? "Required" : ""}
          margin="dense"
          value={props.employee_zip}
          onChange={(e) => props.onChange(e.target.value, "employee_zip")}
          fullWidth
        />

        <div style={{ height: 70 }} />

        <FormLabel component="legend" style={{ marginBottom: 5 }}>
          Date Coverage Terminates
        </FormLabel>
        <Typography
          style={{ display: "inline" }}
          variant="subtitle1"
          color="inherit"
        >
          {moment(props.termination_date).format("L")}
        </Typography>
        <Button
          onClick={() => {
            setEditTerminationDateDialogOpen(true);
          }}
          color="primary"
        >
          Edit
        </Button>

        <br />
        <br />
        <FormControl margin="dense" name="terminationReasonSelect">
          <InputLabel
            htmlFor="termination-reason-simple"
            error={errors.includes("termination_reason")}
          >
            Termination Reason
          </InputLabel>
          <Select
            value={props.termination_reason}
            onChange={(e) =>
              props.onChange(e.target.value, "termination_reason")
            }
            inputProps={{
              name: "terminationReason",
              id: "termination-reason-simple",
            }}
            style={{ width: 250, marginRight: 10 }}
          >
            <MenuItem value={0}>
              <em>Select</em>
            </MenuItem>
            <MenuItem id="employee-fired-or-laid-off-list-item" value={2}>
              Employee Fired or Laid Off
            </MenuItem>
            <MenuItem id="employee-resigned-list-item" value={19}>
              Employee Resigned
            </MenuItem>
            <MenuItem id="employee-requested-change-list-item" value={1}>
              Employee Requested Change
            </MenuItem>
            <MenuItem id="ineligible-dependent-list-item" value={3}>
              Ineligible Depdendent
            </MenuItem>
            <MenuItem id="divorce-list-item" value={4}>
              Divorce/Legal Separation
            </MenuItem>
            <MenuItem id="retired-list-item" value={6}>
              Retired
            </MenuItem>
            <MenuItem id="medicare-list-item" value={7}>
              Medicare
            </MenuItem>
            <MenuItem id="death-list-item" value={8}>
              Death
            </MenuItem>
            <MenuItem id="status-change-list-item" value={9}>
              Reduction in Hours (Status Change)
            </MenuItem>
            <MenuItem id="reduction-eol-list-item" value={11}>
              Reduction (End of Leave)
            </MenuItem>
            <MenuItem id="bankruptcy-list-item" value={12}>
              Bankruptcy
            </MenuItem>
            <MenuItem id="work-stoppage-list-item" value={13}>
              Work Stoppage
            </MenuItem>
            <MenuItem id="userra-reduction-list-item" value={15}>
              USERRA (Reduction in Hours)
            </MenuItem>
            <MenuItem id="contract-never-effective-list-item" value={17}>
              Contract Never Effective
            </MenuItem>
            <MenuItem id="mistake-not-list-item" value={20}>
              COBRA / Continuation of Coverage Ended
            </MenuItem>
            <MenuItem id="mistake-not-list-item" value={18}>
              Mistake / Not Terminated
            </MenuItem>
            <MenuItem id="mistake-not-list-item" value={21}>
              Renewal
            </MenuItem>
          </Select>
        </FormControl>
        <Button style={{ marginTop: 20 }} onClick={handleHelp} color="primary">
          Help
        </Button>
        <div style={{ height: 200 }} />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(TerminationForm);
