import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import TrackingEventList from "../Tracking/TrackingEventList";
import api from "../../Functions/APIHelpers";
import { formatDateTime } from "../../Functions/Format";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Styles/ModalStyles";

class ArcRequestDetail extends React.Component {
  state = {
    loaded: false,
    eventDetails: [],
  };

  close = () => {
    this.setState({ loaded: false }, () => {
      this.props.Close();
    });
  };

  handleClose = () => {
    this.close();
  };

  componentDidUpdate(prevProps) {
    const { Open, ArcRequest } = this.props;
    const { loaded } = this.state;
    if (Open && !prevProps.Open && !loaded)
      this.handleGetDetails(ArcRequest.id);
  }

  handleGetDetails = (arcID) => {
    this.getArcEventDetail(arcID).then((eventDetails) => {
      this.setState({ loaded: true, eventDetails:eventDetails });
    });
  };

  getArcEventDetail = (arcID) => {
    return new Promise((resolve, reject) => {
      resolve(this.props.GetDetailFunction(arcID));
    });
  };

  render() {
    const { Open } = this.props;

    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            ARC Request Tracker
            <br />
            <Typography variant="caption">
              Sent To: {this.props.ArcRequest.employer_contact_email}
            </Typography>
          </DialogTitle>

          <DialogContent style={{ height: 550, width: 400 }}>
            {this.state.loaded && (
              <TrackingEventList
                EventDetails={this.state.eventDetails}
                SelectedRecord={this.props.ArcRequest}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(ArcRequestDetail));
