import React from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import PasswordInput from "./Shared/Components/PasswordInput";

import { styles } from "./Styles/AuthStyles";

import { Auth } from "aws-amplify";

class SignIn extends React.Component {
  state = {
    open: false,
    user: {},
    email: "",
    emailError: false,
    password: "",
    passwordError: false,
    collectCode: false,
    code: "",
  };

  handleKey = (event) => {
    if (event.key === "Enter" && !this.state.collectCode) this.SignIn();
    if (event.key === "Enter" && this.state.collectCode) this.SubmitCode();
    if (event.key === "Enter") event.preventDefault();
  };

  SignIn = () => {
    if (this.isValidForm()) {
      Auth.signIn(this.state.email.toLowerCase(), this.state.password)
        .then((user) => {
          this.setState({ user: user });
          if (
            user.challengeName !== undefined &&
            (user.challengeName === "SMS_MFA" ||
              user.challengeName === "SOFTWARE_TOKEN_MFA")
          ) {
            this.collectCode();
          } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.props.changePassword(user);
          } else {
            this.redirectLoggedInUser(user.attributes["custom:app"]);
          }
        })
        .catch((err) => {
          this.props.enqueueSnackbar("Incorrect Username or Password", {
            variant: "error",
          });
          this.setState({ emailError: false, passwordError: false });
        });
    }
  };

  SubmitCode = () => {
    Auth.confirmSignIn(
      this.state.user,
      this.state.code,
      this.state.user.challengeName
    )
      .then((user) => {
        this.redirectLoggedInUser(
          user["signInUserSession"]["idToken"]["payload"]["custom:app"]
        );
      })
      .catch((err) => {
        this.props.enqueueSnackbar("Incorrect Challenge Code", {
          variant: "error",
        });
        console.log(err);
      });
  };

  collectCode = () => {
    this.setState({ collectCode: true });
  };

  isValidForm = () => {
    if (this.state.email && this.state.password) {
      return true;
    } else {
      this.setState({
        emailError: this.state.email === "",
        passwordError: this.state.password === "",
      });
    }
  };

  redirectLoggedInUser = (userApp) => {
    if (this.props.app === userApp)
      this.props.history.push(this.props.loginURL);
    else if(userApp === "admin") //admins can access all portals
      this.props.history.push(this.props.loginURL);
    else {
      this.props.enqueueSnackbar("Not Authorized To View This Application", {
        variant: "error",
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div onKeyDown={this.handleKey}>
        <div>
          <div>
            <Typography variant="h4" id="welcome-message-header-auth"color="inherit" noWrap>
              {this.props.welcomeMessage}
            </Typography>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ display: "none" }}
              id="please-sign-in-subtitle"
            >
              Please Sign In
            </Typography>
          </div>
          <br />

          <form>
            {!this.state.collectCode && (
              <React.Fragment>
                <div>
                  <TextField
                    id="standard-email-dense"
                    label={"Email"}
                    error={this.state.emailError}
                    helperText={this.state.emailError ? "Required" : ""}
                    className={classes.textField}
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="dense"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    defaultValue={this.state.email}
                    fullWidth
                  />
                </div>
                <div>
                  <PasswordInput
                    id="standard-password-input"
                    label="Password"
                    error={this.state.passwordError}
                    helperText={this.state.passwordError ? "Required" : ""}
                    //name="password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    fullWidth
                  />
                </div>
              </React.Fragment>
            )}
            {this.state.collectCode && (
              <div>
                <Typography variant="caption">
                  Please enter your multi-factor verification code which was
                  either sent via text message or from your software token
                  application.
                </Typography>

                <TextField
                  id="standard-email-dense"
                  label={"Code"}
                  className={classes.textField}
                  margin="dense"
                  onChange={(e) => this.setState({ code: e.target.value })}
                  defaultValue={this.state.code}
                  fullWidth
                  autoFocus
                />
              </div>
            )}
          </form>

          <div>
            <div>
              {this.state.collectCode && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ marginLeft: 0, marginTop: 10 }}
                  onClick={this.SubmitCode}
                  fullWidth
                  id="submitCodeButton"
                  name="submitCodeButton"
                >
                  Submit Code
                </Button>
              )}
              {!this.state.collectCode && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ marginLeft: 0, marginTop: 10 }}
                  onClick={this.SignIn}
                  fullWidth
                  id="signInButton"
                  name="signInButton"
                >
                  Sign In
                </Button>
              )}
              <Button
                color="primary"
                className={classNames(classes.button, classes.leftButton)}
                style={{ marginLeft: 0, marginBottom: 5 }}
                onClick={() => this.props.action("forgotPassword")}
              >
                Forgot Password?
              </Button>
            </div>

            <div
              className={classes.needAccountContainer}
              style={{ display: "none" }}
            >
              <Button
                color="primary"
                className={classes.button}
                onClick={() => this.props.action("signUp")}
              >
                Need Account?
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
