import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import TableCell from "@material-ui/core/TableCell";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";
import Checkbox from "@material-ui/core/Checkbox";
import { useMediaQuery } from "react-responsive";
import { styles } from "./TableStyles";
import Box from "@material-ui/core/Box";

import { AutoSizer, Column, Table, InfiniteLoader } from "react-virtualized";

import TableFilters from "./TableFilters";

const SelectableTable = (props) => {
  /* 
    ***NOTES***
    (1) When using an override method like getCellData
    or headerRenderer, any classes applied in the returned
    content will override css rules applied directly
    to the component.

    IE: if headerRenderer applies class=classes.test
    it will override
    <Column
      headerClassName={classes.header}
    />

    (2) Also be careful when mixing the className prop built-in
    to material ui components, and the classNames() function that
    we imported from a 3rd part. 

    IE: we can't pass a classNames calculate list of classes to a 
    className prop:

    let computedClasses = {
      classes.classOne,
      classes.classTwo
    }
    <TableCell
      classNames={computedClasses}
    />
    ^^This won't work it needs to be 
    <TableCell
      className={computerClasses}
    />
    ***NOTE***
    */
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  const cellRenderer = ({ cellData, columnIndex }) => {
    //Adding 1 to the column index to account for the first column selectable
    columnIndex = columnIndex - 1;
    //find column to sort by from props
    let columns = props.columns.filter((c) => !c.hide);
    let col = columns.find((c, index) => index === columnIndex);

    //each prop column can define its own renderer method if
    //it needs to format data differently then what's returned
    //from the server
    if (col.cellRenderer) cellData = col.cellRenderer(cellData, col);

    //define classes for cells
    let cellClasses = {};

    return (
      <TableCell
        component="div"
        variant="body"
        className={cellClasses}
        style={{ border: 0 }}
      >
        {cellData}
      </TableCell>
    );
  };

  const actionCellRenderer = ({ rowIndex }) => {
    return (
      <IconButton
        aria-label="Filter list"
        color="primary"
        onClick={(e) => {
          openMenu(e, rowIndex);
        }}
      >
        <MoreVert />
      </IconButton>
    );
  };

  const selectCellRenderer = ({ rowIndex }) => {
    const { selectedRows } = props;
    let cellClasses = classNames(classes.flexContainerFirst);
    let isHighlightedRow = selectedRows.includes(rowIndex);
    if (isHighlightedRow) isHighlightedRow = true;
    else isHighlightedRow = false;

    return (
      <TableCell
        component="div"
        variant="body"
        className={cellClasses}
        style={{ border: 0 }}
      >
        <Checkbox
          checked={isHighlightedRow}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </TableCell>
    );
  };

  const handleHighlightRow = (event, rowIndex, rowData) => {
    const { selectedRows, setSelectedRows, canSelectRow } = props;
    //this represents the action icon
    if(event.target.tagName === "svg" || event.target.tagName === "BUTTON" || event.target.tagName === "path") 
      return;
    
    if (canSelectRow(rowIndex)) {
      if (selectedRows.includes(rowIndex)) {
        //remove row from highilighted items
        let currentSelectedRows = selectedRows.filter(
          (i) => i.toString() !== rowIndex.toString()
        );
        setSelectedRows(currentSelectedRows);
      } else {
        //add row to highlighted items
        let currentSelectedRows = selectedRows;
        currentSelectedRows.push(rowIndex);
        setSelectedRows(currentSelectedRows);
      }
    }
  };

  const headerRenderer = ({ dataKey, label }) => {
    const { classes, columns, sortBy, sortDirection } = props;
    let content = null;
    let headerClasses = {};
    //need to find columns from included props
    //because method signature doesn't include index
    //to know if we are on the first column or not
    let isFirstColumn = false; //= columns[0].dataKey === dataKey;
    /*
    headerClasses = classNames(
      {
        [classes.tableCell]: !isFirstColumn,
        [classes.tableCellFirst]: isFirstColumn,
      },
      {
        [classes.flexContainer]: !isFirstColumn,
        [classes.flexContainerFirst]: isFirstColumn,
      }
    );*/

    //seperating out content variable to include
    //sort direction buttons based on nested col or datakey
    content = (
      <React.Fragment>
        <Typography variant="caption" style={{color: "#2996cc"}}><b>{label}</b></Typography>
        {sortBy === dataKey && sortDirection === "ASC" && (
          <UpIcon style={{ fontSize: 14 }} />
        )}
        {sortBy === dataKey && sortDirection === "DESC" && (
          <DownIcon style={{ fontSize: 14 }} />
        )}
      </React.Fragment>
    );

    //render with content and calculated classes
    return (
      <TableCell
        className={headerClasses}
        style={{ border: 0 }}
        component="div"
        variant="head"
      >
        {content && content}
      </TableCell>
    );
  };

  const getRowClassName = ({ index }) => {
    const { classes, selectedRows } = props;

    let isHighlightedRow = selectedRows.find((i) => i == index);
    if (isHighlightedRow) isHighlightedRow = true;
    else isHighlightedRow = false;

    return classNames(
      classes.tableRow,
      {
        [classes.tableRowHover]: index >= 0,
      },
      {
        [classes.highlightedRows]: isHighlightedRow,
      },
      classes.hiddenRows
    );
  };

  const sortTable = ({ sortBy, sortDirection }) => {
    //find column to sort by from props
    var col = props.columns.find((c) => c.dataKey === sortBy);
    props.sortTable(sortBy, sortDirection, col);
  };

  const openMenu = (e, selectedIndex) => {
    setAnchorEl(e.currentTarget);
    setSelectedItem(loadedData[selectedIndex]);
    setMenuOpen(true);
  };

  const closeMenu = (selectedItem) => {
    setAnchorEl(null);
    setSelectedItem({});
    setMenuOpen(false);
  };

  const isRowLoaded = ({ index }) => {
    return !!loadedData[index];
  };

  const getFilterHeightPercentage = () => {
    if (isBigScreen) return "5%";
    else return "10%";
  };

  const getTableHeightPercantage = () => {
    if (isBigScreen) return "88%";
    else return "65%";
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 800px)",
  });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 800px)" });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });

  const {
    classes,
    columns,
    actionMenuItems,
    loadedData,
    setActiveFilters,
    activeFilters,
    enqueueSnackbar,
    tableName,
  } = props;

  return (
    <React.Fragment>
      <div
        style={{
          height: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box height={"32px"} style={{marginTop:10}} component="div" display="block">
          <TableFilters
            enqueueSnackbar={enqueueSnackbar}
            loadedData={loadedData}
            columns={columns}
            setActiveFilters={setActiveFilters}
            staticFilterValues={props.staticFilterValues}
            activeFilters={activeFilters}
            tableName={tableName}
          />
        </Box>
        <Box flex="1" height={"80px"} component="div" display="block">
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={props.loadMoreRows}
            rowCount={props.recordCount}
            threshold={15}
            minimumBatchSize={10}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    ref={registerChild}
                    width={width}
                    height={height}
                    headerHeight={36}
                    rowHeight={48}
                    className={classes.table}
                    //need to target headerClassName seperately, because
                    //classes applied to each header via the headerRenderer
                    //function apply to an element nested ONE LEVEL to deep
                    headerClassName={classes.header}
                    gridClassName={classes.gridOverride}
                    rowCount={loadedData.length}
                    rowClassName={getRowClassName}
                    onRowClick={({ event,  index, rowData }) => handleHighlightRow(event, index, rowData)}
                    onRowsRendered={onRowsRendered}
                    rowGetter={({ index }) => loadedData[index]}
                    sort={sortTable}
                    sortBy={props.sortBy}
                    sortDirection={props.sortDirection}
                  >
                    <Column
                      label={"select"}
                      dataKey={"select"}
                      cellRenderer={selectCellRenderer}
                      headerRenderer={() => {
                        return null;
                      }}
                      width={50}
                      minWidth={50}
                    />
                    {columns.map((col, index) => {
                      console.log(col.label + ' ' + index)
                      
                      if (index > 0 && (isTabletOrMobileDevice || isSmallScreen))
                        return null;
                      if (col.hide) return null;
                      //for columns that we want to filter on but not show
                      else
                        return (
                          <Column
                            key={index}
                            label={col.label}
                            dataKey={col.dataKey}
                            cellRenderer={cellRenderer}
                            headerStyle={{overflow: "hidden"}}
                            headerRenderer={headerRenderer}
                            width={col.width}
                            flexGrow={col.flexGrow}
                            flexShrink={col.flexShrink}
                          />
                        );
                    })}
                    <Column
                      label={"actions"}
                      dataKey={"action"}
                      cellRenderer={actionCellRenderer}
                      headerRenderer={() => {
                        return null;
                      }}
                      style={{ marginLeft: "auto" }}
                      width={50}
                      minWidth={50}
                    />
                  </Table>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
          {!props.loaded && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress style={{ marginTop: "30vh" }} size={60} />
            </div>
          )}
        </Box>
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={closeMenu}
      >
        {actionMenuItems(selectedItem, closeMenu)}
      </Menu>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(SelectableTable);
