import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";
import { styles } from "../../Auth/Styles/AuthStyles";
import { withSnackbar } from "notistack";
//import { styles } from "../../Shared/Styles/ContainerStyles";

import api from "../../Shared/Functions/APIHelpers";
import PasswordInput from "../../Shared/Components/Accounts/PasswordInput";
import { Auth } from "aws-amplify";

class CreateAccount extends React.Component {
  state = {
    loaded: false,
    expired: false,
    inviteClosed: false,
    username: "",
    user: {},
    newPassword: "",
  };

  componentDidMount() {
    let brokerID = "";
    //FIRST we will check for an invitation ID
    //if there is one present, first accept (or decline) the invite before allowing account creation
    if (this.getInvitationID()) {
      this.updateEmployerInvite()
        .then((r) => {
          this.handleCreateBrokerAccount(r.to_broker);
        })
        .catch((err) => {
          this.setState({ inviteClosed: true, loaded: true });
        });
    } else if (this.getBrokerInvitationID()) {
      this.updateBrokerToBrokerInvite().then((r) => {
        if (r.message === "invite_closed") {
          window.location.href =
            config.appLocation.URL + this.props.logoutURL + "?error=8";
        }
        this.handleCreateBrokerAccount(r.broker_id);
      });
    } else {
      brokerID = this.getBrokerID();
      //First make sure that a valid broker ID was provided, if not redirect to login screen
      if (!brokerID) {
        window.location.href =
          config.appLocation.URL + this.props.logoutURL + "?error=8";
      }
      this.handleCreateBrokerAccount(brokerID);
    }
  }

  handleCreateBrokerAccount = (brokerID) => {
    //on loading this screen, the broker has already been in our system or was JUST created, and we have
    //established relationships using their email.
    //If they make it here, we will hit the ACCEPT endpoint to create the cognito user,
    //and that will return us a temporary password that we will login with

    //Then we will prompt them for a new password which we will login with cognito as they
    //will be in PASSWORD_RESET_REQUIRED state

    this.createBrokerAccount(brokerID).then((r) => {
      if (r.message === "valid") {
        //now that we have a temporary password and email, log user in
        this.signInWithTemporaryPassword(r.username, r.temporary_password).then(
          (signInObject) => {
            this.setState({
              loaded: true,
              username: r.username,
              user: signInObject,
            });
          }
        );
      }
      if (r.message === "account_exists") {
        window.location.href =
          config.appLocation.URL + this.props.logoutURL + "?error=9";
      }
      if (r.message === "invalid_broker_id") {
        window.location.href =
          config.appLocation.URL + this.props.logoutURL + "?error=8";
      }
    });
  };

  createBrokerAccount = (brokerID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getCreateBrokerAccount(brokerID));
    });
  };

  signInWithTemporaryPassword = (username, password) => {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user) => {
          resolve(user);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  signInWithNewPassword = () => {
    Auth.completeNewPassword(this.state.user, this.state.newPassword)
      .then((user) => {
        this.props.enqueueSnackbar("Password set!", {
          variant: "success",
        });
        if (this.getBrokerInvitationID() && this.getMEWAID()) {
          window.location.href =
            config.appLocation.URL +
            "/broker/review-broker-invite?invitationID=" +
            this.getBrokerInvitationID() +
            "&mewaID=" +
            this.getMEWAID();
        } else if (this.getBrokerInvitationID() && !this.getMEWAID()) {
          window.location.href =
            config.appLocation.URL +
            "/broker/review-broker-invite?invitationID=" +
            this.getBrokerInvitationID();
        } else if (this.getMEWAID() && !this.getBrokerInvitationID()) {
          window.location.href =
            config.appLocation.URL + "/broker/home?mewaID=" + this.getMEWAID();
        } else {
          window.location.href = config.appLocation.URL + "/broker/home";
        }
      })
      .catch((e) => {
        if (e.code === "InvalidPasswordException") {
          this.props.enqueueSnackbar(
            "Password must be at least 8 characters, contain 1 number, and 1 special character",
            {
              variant: "error",
            }
          );
        } else {
          this.props.enqueueSnackbar("Something went wrong saving password", {
            variant: "error",
          });
        }
      });
  };

  getBrokerID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("brokerID");
  };

  getMEWAID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("mewaID") ? params.get("mewaID") : null;
  };

  getInvitationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("invitationID");
  };

  getBrokerInvitationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("brokerInvitationID");
  };

  getResult = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("result");
  };

  updateEmployerInvite = () => {
    let body = {
      result: this.getResult() === "accept" ? 1 : 0,
      permission_level: 0,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.updateBrokerInviteNoAccount(
          this.getInvitationID(),
          JSON.stringify(body)
        )
      );
    });
  };

  updateBrokerToBrokerInvite = () => {
    //becuase this is a broker to broker invite, this endpoint doesn't establish any relationships,
    //it only creates the broker object and returns it. Therefore no request body is needed

    return new Promise((resolve, reject) => {
      resolve(api.acceptBrokerInvite(this.getBrokerInvitationID()));
    });
  };

  handleSetNewPassword = () => {
    this.signInWithNewPassword();
  };

  render() {
    const { classes } = this.props;
    console.log(this.props);
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} sm={6}>
          <div className={classes.root_text_container}>
            <div className={classes.root_text}>
              <Typography variant="h4">Welcome Broker!</Typography>
              <div style={{ height: 30 }} />
              <Typography variant="body2" color="inherit">
                We’re glad you’re here! In order to manage your groups, please
                start by selecting a password.
              </Typography>
              <div style={{ height: 25 }} />
              <Typography variant="body2" color="inherit">
                Once you’ve created your account, you can log in at www.mewa.io.
                Your username is your email.
              </Typography>
              <div style={{ height: 25 }} />
              <Typography variant="body2" color="inherit">
                Remember, we’ll always send relevant notifications to your
                email, so you won’t need to login to the portal unless you’d
                like to check the status of a request.
              </Typography>
              <div style={{ height: 25 }} />
              <Typography variant="body2" color="inherit">
                For questions and support, please visit{" "}
                <a
                  href="https://support.mewa.io"
                  style={{ textDecoration: "none" }}
                >
                  support.mewa.io
                </a>
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <Paper
              className={classes.root}
              elevation={5}
              style={{ zIndex: 10 }}
            >
              <div className={classes.header}>
                <div className={classes.logoContainer}>
                  <img
                    src={require("../../Shared/Images/mewaio_vert_350.png")}
                    className={classes.largeLogo}
                    alt="Reform Health Logo"
                  />
                </div>
                {this.state.loaded && !this.state.inviteClosed && (
                  <React.Fragment>
                    <Typography variant="h4" color="inherit" noWrap>
                      Set Password
                    </Typography>
                    <div style={{ height: 10 }}></div>
                    <Typography variant="caption">
                      Enter the password you will use to log into the portal.
                    </Typography>
                    <div style={{ height: 20 }}></div>
                    <PasswordInput
                      Password={this.state.newPassword}
                      SetPassword={(val) => {
                        this.setState({ newPassword: val });
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSetNewPassword}
                      id="set-new-password-button"
                      style={{ width: 250, marginTop: 20, marginBottom: 20 }}
                      disabled={
                        !this.state.newPassword.match(
                          /^(?=.*[!@#$%^&*])(?=.{8,})(?=.*[0-9])/
                        )
                      }
                    >
                      Set New Password
                    </Button>
                  </React.Fragment>
                )}
                {this.state.loaded && this.state.inviteClosed && (
                  <React.Fragment>
                    <Typography variant="body1" color="inherit" noWrap>
                      Invite Closed or No Longer Exists
                    </Typography>
                  </React.Fragment>
                )}
                {!this.state.loaded && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress
                      style={{ marginTop: 50, marginBottom: 50 }}
                      size={60}
                    />
                  </div>
                )}
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(CreateAccount));
