import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import api from "../../Shared/Functions/APIHelpers";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class ContactDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      team: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { Open, contactDetail } = this.props;
    const { loaded } = this.state;

    if (Open && !loaded) this.handleGetContactTeam(contactDetail.id);
  }

  handleGetContactTeam = (contactID) => {
    api
      .getBrokerContactTeam(contactID)
      .then((team) => {
        this.setState({ loaded: true, team: team });
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/mewa-admin/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "Something went wrong rendering contact team details",
            {
              variant: "error",
            }
          );
        }
      });
  };

  close = () => {
    this.setState({ sendgridEvents: [], systemEvents: {}, loaded: false });
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  render() {
    const { Open } = this.props;

    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Broker Contact Team</DialogTitle>

          <DialogContent style={{ height: 350, width: 400 }}>

            <List>
              <Typography variant="subtitle2" color="inherit">
                Primary Contacts
              </Typography>
              {this.state.team
                .filter((t) => t.is_primary)
                .map((t, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={t.first_name + " " + t.last_name}
                        secondary={"Email: " + t.email}
                      />
                    </ListItem>
                  );
                })}
              <Typography variant="subtitle2" color="inherit" style={{marginTop: 20}}>
                Secondary Contacts
              </Typography>
              {this.state.team
                .filter((t) => !t.is_primary)
                .map((t, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={t.first_name + " " + t.last_name}
                        secondary={"Email: " + t.email}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(ContactDetail));
