import React from "react";

import Button from "@material-ui/core/Button";
import FolderIcon from "@material-ui/icons/Folder";

import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
  verticalButtons: {
    flexDirection:'column'
  },
  flexContainer: {
    display:'flex', 
    justifyContent:'center',  
    flexWrap: 'wrap',
    padding: 25
  }
});


const FolderList = props => {
  
  const getFolderButtons = () => {
    return props.folders.map( (f, index) => {
      return (
        <Button
          variant="contained"
          color={(props.folderName === f.name) ? "primary" : "default"}
          startIcon={<FolderIcon />}
          style={{flexBasis: 180, margin: 5, fontSize:10}}
          key={index}
          onClick={() => {props.filterFolder(f)}}
        >
          {f.name}
        </Button>
      );
    });
  };

  const {classes} = props;
  
  
  return (
    <React.Fragment>
      
      <div className={classes.flexContainer}>
      {getFolderButtons()}
      </div>
      
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(FolderList);