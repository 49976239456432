import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../../../config";
import { styles } from "../../../Styles/AuthStyles";
import { withSnackbar } from "notistack";
//import { styles } from "../../Shared/Styles/ContainerStyles";
import PortalLookupAllDone from "./PortalLookupAllDone";
import api from "../../../../Shared/Functions/APIHelpers";
import { Auth } from "aws-amplify";

class PortalLookupRequest extends React.Component {
  state = {
    lookupEmail: "",
    finished: false
  };

  componentDidMount() {}

  handleSendPortalLink = () => {
    let body = {
        employer_email: this.state.lookupEmail,
      };
    api.sendEmployerPortalLookup(JSON.stringify(body)).then(r=>{
        this.setState({finished: true})
    })
  };

  redirectUser = (code) => {
    if (this.props.loginURL.indexOf("portalID") > 0) {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "&message=" + code;
    } else {
      window.location.href =
        config.appLocation.URL + this.props.logoutURL + "?message=" + code;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper className={classes.root} elevation={5} style={{ zIndex: 10 }}>
          <div className={classes.header}>
            <div className={classes.logoContainer}>
              <img
                src={require("../../../../Shared/Images/mewaio_vert_350.png")}
                className={classes.largeLogo}
                alt="mewa.io Logo"
              />
            </div>

            
            {(!this.state.finished) && (
                <React.Fragment>
              <Typography variant="h4" color="inherit" noWrap>
                Employer Portal Lookup
              </Typography>
              <div style={{ height: 10 }}></div>
              <Typography variant="caption">
                To log into your employer portal, you will need to use your
                customized portal link. If you don't have that handy, please
                enter your email to have a link sent to you.
              </Typography>
              <div style={{ height: 20 }}></div>
              <TextField
                id="standard-dense"
                label="Email"
                margin="dense"
                multiline
                onChange={(e) => this.setState({ lookupEmail: e.target.value })}
                defaultValue={this.state.lookupEmail}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSendPortalLink}
                style={{ width: 250, marginTop: 20, marginBottom: 20 }}
              >
                Send Portal Link
              </Button>
            </React.Fragment>
            )}
            {this.state.finished&& (
            <React.Fragment><PortalLookupAllDone /></React.Fragment>
          )}
          </div>
        </Paper>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(PortalLookupRequest));
