import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MouseIcon from "@material-ui/icons/Mouse";
import EmailIcon from "@material-ui/icons/Email";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import TrackingEventListWelcomeLetter from "../../Shared/Components/Tracking/TrackingEventListWeclomeLetter";
import api from "../../Shared/Functions/APIHelpers";
import { formatDateTime } from "../../Shared/Functions/Format";
import Divider from "@material-ui/core/Divider";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

class EmployerDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventDetails: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const { Employer } = this.props;
    this.handleGetDetails(Employer.portal_id);
  }


  handleGetDetails = (portalID) => {
    this.getEmployerEventDetail(portalID)
      .then((eventDetails) => {
        this.setState({
          eventDetails: eventDetails,
          loaded: true,
        });
      })
      .catch((err) => {
        if (err === 403) {
          this.props.history.push("/mewa-admin/auth?error=5");
        }
        if (err === 400) {
          this.props.enqueueSnackbar(
            "Something went wrong rendering termination details",
            {
              variant: "error",
            }
          );
        }
      });
  };

  getEmployerEventDetail = (portalID) => {
    return new Promise((resolve, reject) => {
      resolve(api.getEmployerMEWAEvents(portalID));
    });
  };

  close = () => {
    this.setState(
      {
        eventDetails: [],
        loaded: false,
      },
      () => {
        this.props.Close();
      }
    );
  };

  handleClose = () => {
    this.close();
  };

  formatBoolean = (val) => {
    if (val) return "Yes";
    return "No";
  };

  render() {
    const { Open } = this.props;
    return (
      <div>
        <Dialog
          open={!!Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={this.props.fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Employer Details</DialogTitle>

          <DialogContent style={{ height: 350, minWidth: 325, marginTop: 10 }}>
            {this.state.loaded && (
              <React.Fragment>
                <Typography variant="h6">Welcome Letter History</Typography>
                <TrackingEventListWelcomeLetter
                  EventDetails={this.state.eventDetails}
                  SelectedRecord={this.props.Employer}
                />
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EmployerDetail));
export { EmployerDetail as PureEmployerDetail };
