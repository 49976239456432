import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../../Shared/Styles/ModalStyles";

const initialState = {};

class TerminationHelpDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  close = () => {
    this.reset();
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  openMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            Termination Reason Help
          </DialogTitle>

          <DialogContent className={classes.container}>
            <Typography variant="body1">
              <div className={classes.definition}>
                <b>Employee Fired or Laid Off</b>
                <br />
                Termination of employee by the employer at will.
              </div>

              <div className={classes.definition}>
                <b>Employee Resigned</b>
                <br />
                Employee voluntary left the employer.
              </div>
              <div className={classes.definition}>
                <b>Employee Requested Change</b>
                <br />
                Employee terminated all or part of their coverage with the
                employer, for instance in order to enroll in a spouse's plan
              </div>
              <div className={classes.definition}>
                <b>Ineligible Dependent</b>
                <br />
                Dependent turns age 26 and is no longer eligible for coverage as
                a child. Also applies to children losing coverage through a
                change in qualified medical support order or disability status.
              </div>
              <div className={classes.definition}>
                <b>Divorce/Legal Separation</b>
                <br />
                Spouse and children losing coverage due to a divorce.
              </div>
              <div className={classes.definition}>
                <b>Retirement</b>
                <br />
                Retirement from an employer.
              </div>
              <div className={classes.definition}>
                <b>Medicare</b>
                <br />
                Employee electing to join Medicare coverage immediately
                following termination of the group health benefit.
              </div>
              <div className={classes.definition}>
                <b>Death</b>
                <br />
                Loss of coverage for the spouse and children of a deceased
                employee.
              </div>
              <div className={classes.definition}>
                <b>Reduction - Status Change</b>
                <br />
                Loss of eligibility due to an employer decision to broadly cut
                employee hours.
              </div>
              <div className={classes.definition}>
                <b>Reduction - End of Leave</b>
                <br />
                Loss of eligibility due to the conclusion of a period of leave
                with no continued employment.
              </div>
              <div className={classes.definition}>
                <b>Bankruptcy</b>
                <br />
                Bankruptcy of the employer, both those with continuing
                operations and those resulting in closure of the business.
              </div>
              <div className={classes.definition}>
                <b>Work Stoppage</b>
                <br />
                Labor Strikes, workers actions, and other cases of employee
                initiated stops in work.
              </div>
              <div className={classes.definition}>
                <b>USERRA-Reduction in hours</b>
                <br />
                Uniformed Service Employment and Redeployment Rights Act.
                Reduction to below 30 hours per week due to a deployment with a
                branch of the armed forces, reserve component, and other
                elements of civic defense infrastructure.
              </div>
              <div className={classes.definition}>
                <b>COBRA / Continuation of Coverage Ended</b>
                <br />
                COBRA/Continuation of Coverage has ended. This could be because
                the employee has cancelled coverage or because the continuation
                period has expired.
              </div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(TerminationHelpDialog));
