import { green } from "@material-ui/core/colors";

export const styles = (theme) => ({
  container: {
    width: 500,
    "@media only screen and (max-width: 700px)": {
      width: "85%",
    },
    height: 400,
  },
  title: {
    //backgroundColor: blue[800],
    color: "white",
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(2),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  primaryIcon: {
    color: theme.palette.primary.main,
  },
  divider: {
    height: 40,
  },
  definition: {
    marginBottom: 20,
  },
  buttonStyle: {
    "@media print": {
      display: "none",
    },
  },
  checkDone: {
    height: 300,
    //minWidth: 700,
    marginTop: 10,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%",
    },
    "@media only screen and (max-height: 400px)": {
      marginTop: 0,
      height: 280,
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  expandedAccordionHeader: {
    backgroundColor: "#bdbdbd",
  },
});
