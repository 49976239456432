import React from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router
} from "react-router-dom";

import HomeContainer from "./MEWA/HomeContainer"
import AuthContainer from "./Auth/AuthContainer";
import EmployerContainer from "./Employer/EmployerContainer";
import BrokerContainer from "./Broker/BrokerContainer";
import ReportingContainer from "./Reporting/ReportingContainer";
import RedirectUtility from "./Shared/Functions/RedirectUtility";
import ErrorScreen from "./Shared/Components/Error/ErrorScreen";

const Routes = () => (
  <Router>
    <Switch>
      {/*<Route path ="/employer" component={} />*/} 

      {/*<Route path="/auth" component={AuthContainer} /> */}
      <Route exact path="/error" component={ErrorScreen} />
      <Route exact path="/employer" component={() => ( <RedirectUtility redirectURL={"/employer/auth"} /> )}/>
      <Route path="/employer" component={EmployerContainer} />
      <Route exact path="/home" component={() => ( <RedirectUtility redirectURL={"/mewa-admin/auth"} /> )}/>
      <Route exact path="/mewa-admin" component={() => ( <RedirectUtility redirectURL={"/mewa-admin/auth"} /> )}/>
      <Route path="/mewa-admin" component={HomeContainer} />
      {/*<MEWAHOC path="/medicare" component={HomeContainer}  />*/}
      <Route path="/broker" component={BrokerContainer} /> 
      <Route path="/report" component={ReportingContainer} /> 
      
      <Route path="/" component={ErrorScreen} />

    </Switch>
  </Router>
);

export default Routes;