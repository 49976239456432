import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import EmployerForm from "./EmployerForm";
import { outputEINFromMask } from "../../Shared/Components/Input/EINTextBox";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ModalStyles";

import api from "../../Shared/Functions/APIHelpers";

class EmployerDialog extends React.Component {
  
  state = {
    errors: [],
    MEWAS:[],
    mewa: this.props.employer.mewa ? this.props.employer.mewa.id :  "",
    name: this.props.employer.name || "",
    EIN: this.props.employer.EIN || "",
    street: this.props.employer.street || "",
    city: this.props.employer.city || "",
    state: this.props.employer.state ? this.props.employer.state.id :  "",
    zip: this.props.employer.zip || "",
    contact_first: this.props.employer.contact_first || "",
    contact_last: this.props.employer.contact_last || "",
    contact_email: this.props.employer.contact_email || "",
    contact_phone: this.props.employer.contact_phone || "",
  }
  componentDidMount() { 
    this.getMewas();
  };
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}

  getMewas() {
    return new Promise((resolve, reject) => {
      api
        .getMewas()
        .then(r => {
          resolve(r);
          this.setState({MEWAS:r})
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Getting Mewas" });
          console.log(err);
        });
    });
  };  

  addEmployer = (body) => {
    return new Promise((resolve, reject) => {
      api
        .addEmployer(JSON.stringify(body))
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          if(err === 403){ 
            this.props.history.push("/mewa-admin/auth?error=5")
          }
          if(err === 400){
            this.props.enqueueSnackbar("Something went wrong adding employer", {
              variant: "error"
            })
          }
        });
    });
  };

  updateEmployer = (body) => {
    return new Promise((resolve, reject) => {
      api
        .updateEmployerMEWA(this.props.employer.id, JSON.stringify(body))
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          if(err === 403){ 
            this.props.history.push("/mewa-admin/auth?error=5")
          }
          if(err === 400){
            this.props.enqueueSnackbar("Something went wrong updating employer", {
              variant: "error"
            })
          }
        });
    });
  };

  inError = () => {
    let errors = [];
    const body = this.getBody();
    Object.entries(body).map((field) => {
      if (field[1] === "") errors.push(field[0]);
    });
    this.setState({ errors: errors });
    return errors.length > 0;
  };

  getBody = () => {
    return {
    mewa_id: this.state.mewa,
    name: this.state.name,
    EIN: outputEINFromMask(this.state.EIN).conformedValue,
    street: this.state.street,
    city: this.state.city,
    state_id: this.state.state,
    zip: this.state.zip,
    contact_first: this.state.contact_first,
    contact_last: this.state.contact_last,
    contact_email: this.state.contact_email,
    contact_phone: this.state.contact_phone
    };
  };

  close = () => {
    this.props.Close();
  };

  handleClose = () => {
    this.close();
  };

  onChange = (value, field) => {
    this.setState({ [field]: value })
  }

  handleSave = () => {
    if(this.inError()) return;
    let saveFunction = this.props.isAdd ? this.addEmployer : this.updateEmployer;
    saveFunction(this.getBody())
    .then( (r) => {
      this.handleClose()
      this.props.RefreshTable()
    })
    .catch(err => { 
      if (err === 403) {
        this.props.history.push("/mewa-admin/auth?error=5");
      }
      if (err === 400) {
        this.props.enqueueSnackbar("Something went wrong saving medicare", {
          variant: "error",
        });
      }
    })
  };

  render() {
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={!!this.props.Open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullScreen={fullScreen}
          scroll="paper"
        >
          {this.props.isAdd && (
            <DialogTitle id="form-dialog-title">Add Employer</DialogTitle>
          )}
          {!this.props.isAdd && (
            <DialogTitle id="form-dialog-title">Edit Employer</DialogTitle>
          )}
          <DialogContent style={{ height: 600, width: 550 }}>
            <EmployerForm 
              errors={this.state.errors}
              isAdd={this.props.isAdd}
              onChange={this.onChange}
              MEWAS={this.state.MEWAS}
              mewa={this.state.mewa}
              name={this.state.name}
              EIN={this.state.EIN}
              street={this.state.street}
              state={this.state.state}
              city={this.state.city}
              zip={this.state.zip}
              contact_first={this.state.contact_first}
              contact_last={this.state.contact_last}
              contact_email={this.state.contact_email}
              contact_phone={this.state.contact_phone}
            />
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleClose} color="primary">
              Close
            </Button>            
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EmployerDialog));
