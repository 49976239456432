import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import FolderIcon from "@material-ui/icons/Folder";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import DeleteIcon from "@material-ui/icons/Delete";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import BusinessIcon from "@material-ui/icons/BusinessCenter";
import NewTable from "../../Shared/Components/Table/NewTable";
import TableStatusIndicator from "../../Shared/Components/Table/TableStatusIndicator";
import EmployerDialog from "./EmployerDialog";
import EmployerContinuationStatusDialog from "./EmployerContinuationStatusDialog";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import EmployerPasswordDialog from "./EmployerPasswordDialog";
import EmployerTeamDialog from "./EmployerTeamDialog";
import EmployerDetail from "./EmployerDetail";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EnableIcon from "@material-ui/icons/CheckCircle";

import config from "../../config";
import {
  getCountURLFromFilters,
  getInfiniteURLFromFilters,
  nestedCellDataGetter,
  nestedSort,
  employerSecureIconDataGetter,
  employerIgnoreIconDataGetter,
  employerTerminatedIconDataGetter,
  employerIgnoreSort,
  employerMEWACellDataGetter,
} from "../../Shared/Components/Table/TableUtilities";
import api from "../../Shared/Functions/APIHelpers";

const initialState = {
  employerModalOpen: false,
  employerTeamModalOpen: false,
  employerContinuationDialogOpen: false,
  detailDialogOpen: false,
  isAdd: true,
  employer: {},

  //FOR TABLE
  tableData: [],
  recordCount: 0,
  dataURL: "employers",
  staticFilterURL: "employers-mewa-static-filters",
  staticFilters: ["mewa__name", "continuation_status"],
  staticFilterValues: [],
  sortBy: "",
  nestedSortBy: "",
  sortDirection: "ASC",
  countURL: "employers-mewa-count",
  initialCount: "--",
  currentCount: "--",
  activeFilters: [],
  fetching: false,
  initialLoadDone: true,
  closeMenu: null,

  /* state for password reset dialog */
  employerPasswordDialogOpen: false,
  employerPassword: "",

  /*state for confirm alert */
  confirmAlertTitle: "",
  confirmAlertText: "",
  confirmAlertOpen: false,
  doFunc: null,
  doNotFunc: null,
};

class EmployerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset() {
    this.setState(initialState);
  }

  componentDidMount() {
    this.handleInitialData(true);
  }

  handleInitialData = (isInitial) => {
    this.setState({ initialLoadDone: false, fetching: true }, () => {
      this.getStaticFilterValues().then((values) => {
        this.setState({ staticFilterValues: values }, () => {
          this.getTableCount().then((count) => {
            this.getTableData(0, 10)
              .then((r) => {
                this.setState({
                  tableData: r,
                  recordCount: count["record_count"],
                  fetching: false,
                  initialLoadDone: true,
                });
                if (isInitial)
                  this.setState({
                    initialCount: count["record_count"],
                    currentCount: count["record_count"],
                  });
                else this.setState({ currentCount: count["record_count"] });
              })
              .catch((err) => {
                if (err === 403) {
                  this.props.history.push("/mewa-admin/auth?error=5");
                }
                if (err === 400) {
                  this.props.enqueueSnackbar(
                    "Something went wrong rendering employers",
                    {
                      variant: "error",
                    }
                  );
                }
              });
          });
        });
      });
    });
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state.fetching) return;
    this.setState({ fetching: true }, () => {
      return new Promise((resolve, reject) => {
        this.getTableData(startIndex, stopIndex)
          .then((r) => {
            var currentData = this.state.tableData;
            var newData = [...currentData, ...r];
            this.setState({ tableData: newData, fetching: false }, () => {
              resolve(newData);
            });
          })
          .catch((err) => {
            if (err === 403) {
              this.props.history.push("/mewa-admin/auth?error=5");
            }
            if (err === 400) {
              this.props.enqueueSnackbar(
                "Something went wrong rendering emplopyers",
                {
                  variant: "error",
                }
              );
            }
          });
      });
    });
  };

  getTableCount = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableCount(
          getCountURLFromFilters(this.state.activeFilters, this.state.countURL)
        )
      );
    });
  };

  getTableData = (startIndex, stopIndex) => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getTableData(
          getInfiniteURLFromFilters(
            this.state.activeFilters,
            this.state.dataURL,
            startIndex,
            stopIndex,
            this.state.sortBy,
            this.state.sortDirection,
            this.state.nestedSortBy
          )
        )
      );
    });
  };

  getStaticFilterValues = () => {
    return new Promise((resolve, reject) => {
      resolve(
        api.getStaticFilterValues(
          this.state.staticFilterURL,
          JSON.stringify({ filters: this.state.staticFilters })
        )
      );
    });
  };

  setActiveFilters = (filters) => {
    this.setState({ activeFilters: filters }, () => {
      this.handleInitialData();
    });
  };

  sortTable = (sortBy, sortDirection, col) => {
    let nestedColSortKey = "";
    if (col.nestCol) {
      nestedColSortKey = col.nestedColSortKey;
    }

    this.setState(
      {
        sortBy: sortBy,
        sortDirection: sortDirection,
        nestedSortBy: nestedColSortKey,
      },
      () => {
        this.handleInitialData();
      }
    );
  };

  handleDocumentCenter = (selectedItem, closeMenu) => {
    this.props.history.push({
      pathname: "/mewa-admin/documents",
      state: { employer: selectedItem },
    });
    closeMenu();
  };

  handleOpenPortal = (selectedItem, closeMenu) => {
    var url =
      config.appLocation.URL +
      "/employer/home?portalID=" +
      selectedItem.portal_id;
    var win = window.open(url, "_blank");
    win.focus();
    closeMenu();
  };

  handleChangeContinuationStatus = (employer, closeMenu) => {
    this.setState({
      employer: employer,
      employerContinuationDialogOpen: true,
    });
    closeMenu();
  };

  handleEditEmployer = (employer, closeMenu) => {
    this.setState({
      employer: employer,
      isAdd: false,
      employerModalOpen: true,
    });
    closeMenu();
  };

  handleDisableSecure = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Disable Password Requirement",
      confirmAlertText:
        "Are you sure you wish to disable the password requirement for this employer portal?",
      doNotFunc: this.doNotDisableSecureEmployer,
      doFunc: this.doDisableSecureEmployer,
    });
    closeMenu();
  };

  doDisableSecureEmployer = () => {
    this.disableSecureEmployer(this.state.employer.portal_id).then((r) => {
      this.setState({
        confirmAlertOpen: false,
        employer: {},
        doFunc: null,
        doNotFunc: null,
        confirmAlertTitle: "",
        confirmAlertText: "",
      });
      this.props.enqueueSnackbar("Employer Security Removed", {
        variant: "success",
      });
    });
  };
  doNotDisableSecureEmployer = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  disableSecureEmployer = (portalID) => {
    return new Promise((resolve, reject) => {
      api
        .disableSecureEmployerMEWA(portalID)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          if (err === 403) {
            this.props.history.push("/mewa-admin/auth?error=5");
          }
          if (err === 400) {
            this.props.enqueueSnackbar(
              "Something went wrong disable employer security",
              {
                variant: "error",
              }
            );
          }
        });
    });
  };

  handleResendWelcomeEmail = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Resend Employer Welcome Email",
      confirmAlertText:
        "Are you sure you wish to resend the welcome email to " +
        selectedItem.name +
        "? The email will be sent to " +
        selectedItem.contact_first +
        " " +
        selectedItem.contact_last +
        " (" +
        selectedItem.contact_email +
        ").",
      doNotFunc: this.doNotResendEmployer,
      doFunc: this.doResendEmployer,
    });
    closeMenu();
  };
  doResendEmployer = () => {
    this.resendWelcomeEmail(this.state.employer.portal_id).then((r) => {
      this.setState({
        confirmAlertOpen: false,
        employer: {},
        doFunc: null,
        doNotFunc: null,
        confirmAlertTitle: "",
        confirmAlertText: "",
      });
      this.handleInitialData();
      this.props.enqueueSnackbar("Welcome Email Resent", {
        variant: "success",
      });
    });
  };
  doNotResendEmployer = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  resendWelcomeEmail = (portalID) => {
    return new Promise((resolve, reject) => {
      api
        .resendEmployerWelcomeEmailMEWA(portalID)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          if (err === 403) {
            this.props.history.push("/mewa-admin/auth?error=5");
          }
          if (err === 400) {
            this.props.enqueueSnackbar(
              "Something went wrong resending welcome email",
              {
                variant: "error",
              }
            );
          }
        });
    });
  };

  handleResetPassword = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Reset Password",
      confirmAlertText:
        "Are you sure you wish to reset " +
        selectedItem.contact_first +
        " " +
        selectedItem.contact_last +
        "'s password at " +
        selectedItem.name +
        "?",
      doNotFunc: this.doNotResetPassword,
      doFunc: this.doResetPassword,
    });
  };

  doNotResetPassword = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  doResetPassword = () => {
    this.setState({
      confirmAlertOpen: false,
      employerPasswordDialogOpen: true,
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  finalResetPassword = () => {
    this.resetPassword(this.state.employer.portal_id).then((r) => {
      this.handleInitialData();
      this.props.enqueueSnackbar("Contact Password Has Been Reset", {
        variant: "success",
      });
    });
    this.setState({ employerPasswordDialogOpen: false, employerPassword: "" });
    this.state.closeMenu();
  };

  resetPassword = (portalID) => {
    var body = JSON.stringify({
      password: this.state.employerPassword,
    });
    return new Promise((resolve, reject) => {
      api
        .resetEmployerPassword(portalID, body)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleTerminateEmployer = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Disable " + selectedItem.name,
      confirmAlertText:
        "Are you sure you wish to terminate " +
        selectedItem.name +
        "? They will no longer receive most notifications aside from an ARC form, if necessary.",
      doNotFunc: this.doNotTerminateEmployer,
      doFunc: this.doTerminateEmployer,
    });
  };

  doNotTerminateEmployer = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  doTerminateEmployer = () => {
    this.setState({ confirmAlertOpen: false });
    this.terminateEmployer(this.state.employer.portal_id).then((r) => {
      this.handleInitialData();
      this.props.enqueueSnackbar("Employer is Terminated", {
        variant: "success",
      });
    });
    this.state.closeMenu();
  };

  terminateEmployer = (portalID) => {
    return new Promise((resolve, reject) => {
      api
        .terminateEmployer(portalID, null)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleDeleteEmployer = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Delete " + selectedItem.name + "?",
      confirmAlertText:
        "Deleting this employer will delete all associated records, including Medicare and Termination Records, and all files in the Document Center. Are you sure you wish to continue?",
      doNotFunc: this.doNotDeleteEmployer,
      doFunc: this.doDeleteEmployer,
    });
  };

  doNotDeleteEmployer = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
    this.state.closeMenu();
  };

  doDeleteEmployer = () => {
    this.setState({ confirmAlertOpen: false });
    this.deleteEmployer(this.state.employer.portal_id).then((r) => {
      this.handleInitialData();
      this.props.enqueueSnackbar("Employer is Deleted", {
        variant: "success",
      });
    });
    this.state.closeMenu();
  };

  deleteEmployer = (portalID) => {
    return new Promise((resolve, reject) => {
      api
        .deleteEmployer(portalID, null)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleSendPortalLink = (selectedItem, closeMenu) => {
    this.setState({
      employer: selectedItem,
      confirmAlertOpen: true,
      closeMenu: closeMenu,
      confirmAlertTitle: "Send Portal Link",
      confirmAlertText:
        "Are you sure you wish to send " +
        selectedItem.name +
        ", a link to their portal? The link will be sent to " +
        selectedItem.contact_first +
        " " +
        selectedItem.contact_last +
        " (" +
        selectedItem.contact_email +
        ").",
      doNotFunc: this.doNotSendPortalLink,
      doFunc: this.doSendPortalLink,
    });
    closeMenu();
  };

  doNotSendPortalLink = () => {
    this.setState({
      confirmAlertOpen: false,
      employer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  doSendPortalLink = () => {
    this.sendPortalLink(this.state.employer.portal_id)
      .then((r) => {
        this.props.enqueueSnackbar("Portal Link Resent", {
          variant: "success",
        });
        this.setState({
          confirmAlertOpen: false,
          employer: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      })
      .catch((err) => {
        this.props.enqueueSnackbar("Something went wrong sending portal link", {
          variant: "error",
        });
        this.setState({
          confirmAlertOpen: false,
          employer: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        });
      });
  };

  sendPortalLink = (portalID) => {
    return new Promise((resolve, reject) => {
      api
        .sendPortalLinkMEWA(portalID)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  handleViewEmployerTeam = (selectedItem, closeMenu) => {
    this.setState({ employer: selectedItem, employerTeamModalOpen: true });
    closeMenu();
  };

  handleViewEmployerDetail = (selectedItem, closeMenu) => {
    this.setState({ employer: selectedItem, detailDialogOpen: true });
    closeMenu();
  };

  handleOpen = () => {
    this.setState({ employerModalOpen: true, isAdd: true });
  };

  getActionMenuItems = (selectedItem, closeMenu) => {
    const { classes } = this.props;
    console.log(selectedItem);
    return (
      <div>
        <MenuItem
          onClick={() => {
            this.handleSendPortalLink(selectedItem, closeMenu);
          }}
        >
          <HomeIcon className={classes.leftIcon} />
          Send Employer Portal Link
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleResendWelcomeEmail(selectedItem, closeMenu);
          }}
        >
          <SendIcon className={classes.leftIcon} />
          Resend Employer Welcome
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.handleViewEmployerDetail(selectedItem, closeMenu);
          }}
        >
          <InfoIcon className={classes.leftIcon} />
          Employer Detail
        </MenuItem>

        <MenuItem
          disabled={!selectedItem.is_secured}
          onClick={() => {
            this.handleResetPassword(selectedItem, closeMenu);
          }}
        >
          <LockOpenIcon className={classes.leftIcon} />
          Reset Password
        </MenuItem>

        <MenuItem
          disabled={!selectedItem.is_secured}
          onClick={() => {
            this.handleDisableSecure(selectedItem, closeMenu);
          }}
        >
          <LockOpenIcon className={classes.leftIcon} />
          Disable Secure Portal
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleOpenPortal(selectedItem, closeMenu);
          }}
        >
          <BusinessIcon className={classes.leftIcon} />
          Open Employer Portal
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleDocumentCenter(selectedItem, closeMenu);
          }}
        >
          <FolderIcon className={classes.leftIcon} />
          Open Document Center
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleViewEmployerTeam(selectedItem, closeMenu);
          }}
        >
          <GroupIcon className={classes.leftIcon} />
          View Team
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleEditEmployer(selectedItem, closeMenu);
          }}
        >
          <EditIcon className={classes.leftIcon} />
          Edit Employer
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleChangeContinuationStatus(selectedItem, closeMenu);
          }}
        >
          <BusinessIcon className={classes.leftIcon} />
          Change Continuation Status
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleTerminateEmployer(selectedItem, closeMenu);
          }}
        >
          <CancelIcon className={classes.leftIcon} />
          Terminate Employer
        </MenuItem>

        <MenuItem
          onClick={() => {
            this.handleDeleteEmployer(selectedItem, closeMenu);
          }}
        >
          <DeleteIcon className={classes.leftIcon} />
          Delete Employer
        </MenuItem>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />

        {this.state.employerContinuationDialogOpen && (
          <EmployerContinuationStatusDialog
            Open={this.state.employerContinuationDialogOpen}
            Close={() => {
              this.setState({ employerContinuationDialogOpen: false });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            Employer={this.state.employer}
            RefreshTable={this.handleInitialData}
          />
        )}
        {this.state.detailDialogOpen && (
          <EmployerDetail
            Open={this.state.detailDialogOpen}
            Close={() => {
              this.setState({ detailDialogOpen: false });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            Employer={this.state.employer}
            RefreshTable={this.handleInitialData}
          />
        )}
        {this.state.employerTeamModalOpen && (
          <EmployerTeamDialog
            Open={this.state.employerTeamModalOpen}
            Close={() => {
              this.setState({ employerTeamModalOpen: false });
            }}
            enqueueSnackbar={this.props.enqueueSnackbar}
            Employer={this.state.employer}
          />
        )}
        <EmployerPasswordDialog
          Open={this.state.employerPasswordDialogOpen}
          Close={() => {
            this.setState({
              employerPasswordDialogOpen: false,
              employerPassword: "",
            });
          }}
          Password={this.state.employerPassword}
          SetPassword={(password) => {
            this.setState({ employerPassword: password });
          }}
          Save={this.finalResetPassword}
        />
        <EmployerDialog
          tableName={"Employers"}
          enqueueSnackbar={this.props.enqueueSnackbar}
          isAdd={this.state.isAdd}
          Open={this.state.employerModalOpen}
          Close={() => {
            this.setState({
              employerModalOpen: false,
              employer: {},
              isAdd: true,
            });
          }}
          key={this.state.employer.id + Math.random().toString()}
          RefreshTable={this.handleInitialData}
          employer={this.state.employer}
        />

        <div className={classes.containerRoot}>
          {/*
          <div style={{ height: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={this.handleOpen}
            >
              <Add />
            </Fab>
          </div>
          */}
          <div className={classes.containerTitle}>Employers</div>
          <div className={classes.containerContent}>
            <div style={{ height: 0, textAlign: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleOpen}
                className={classes.button}
              >
                <AddIcon className={classes.rightIcon} />
                <div className={classes.buttonText}>Add</div>
              </Button>
            </div>
            <Paper className={classes.containerPaper}>
              <NewTable
                tableName={"Employers"}
                enqueueSnackbar={this.props.enqueueSnackbar}
                loadedData={this.state.tableData}
                loaded={this.state.initialLoadDone}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                sortTable={this.sortTable}
                staticFilterValues={this.state.staticFilterValues}
                recordCount={this.state.recordCount}
                loadMoreRows={this.loadMoreRows}
                setActiveFilters={this.setActiveFilters}
                activeFilters={this.state.activeFilters}
                columns={[
                  {
                    label: "Carrier ID",
                    dataKey: "carrier_case_id",
                    width: 100,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "name",
                    label: "Employer Name",
                    width: 250,
                    flexGrow: 4,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "mewa",
                    nestedCol: "mewa__name",
                    label: "MEWA",
                    cellRenderer: employerMEWACellDataGetter,
                    customSort: nestedSort,
                    hasFilter: true,
                    filterType: "Static",
                    width: 250,
                    flexGrow: 3,
                    flexShrink: 1,
                  },
                  {
                    dataKey: "contact_email",
                    label: "Email",
                    width: 200,
                    minWidth: 200,
                    flexGrow: 3,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Search",
                  },
                  {
                    dataKey: "continuation_status",
                    label: "Continuation Status",
                    width: 170,
                    flexGrow: 3,
                    flexShrink: 1,
                    hasFilter: true,
                    filterType: "Static",
                  },
                  {
                    label: "Terminated",
                    dataKey: "termination_date",
                    cellRenderer: employerTerminatedIconDataGetter,
                    width: 100,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                  {
                    label: "Secure",
                    dataKey: "is_secured",
                    cellRenderer: employerSecureIconDataGetter,
                    width: 75,
                    flexGrow: 0,
                    flexShrink: 0,
                    hasFilter: false,
                  },
                ]}
                actionMenuItems={this.getActionMenuItems}
              />
            </Paper>
            <div id="tableStatus" style={{ display: "flex" }}>
              <div
                id="tableStatusIndicator"
                style={{
                  flexGrow: "1",
                  textAlign: "right",
                  marginRight: 0,
                  marginTop: 20,
                }}
                color="primary"
              >
                <TableStatusIndicator
                  initialCount={this.state.initialCount}
                  currentCount={this.state.currentCount}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(EmployerContainer);
