import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";

import api from "../../Shared/Functions/APIHelpers";

class ManageEmployersContainer extends React.Component {
  state = {
    errors: [],
    employers: [],
    loaded: true,

    /*State for confirm alert*/
    confirmAlertTitle: "",
    confirmAlertText: "",
    confirmAlertOpen: false,
    doFunc: null,
    doNotFunc: null,
    selectedEmployer: {},
  };

  componentDidMount() {
    this.handleGetBrokerEmployers();
  }

  handleGetBrokerEmployers = () => {
    this.getBrokerEmployers().then((r) => {
      this.setState({ loaded: true, employers: r.employers });
    });
  };

  getBrokerEmployers = () => {
    return new Promise((resolve, reject) => {
      resolve(api.getBrokerEmployersManage());
    });
  };
  removeRelationship = (employerID) => {
    let body = {
      employer_id: employerID,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.brokerEmployersManageRemoveRelationship(JSON.stringify(body))
      );
    });
  };
  handleDeleteRelationship = (employer) => {
    this.setState({
      confirmAlertOpen: true,
      menuOpen: false,
      selectedEmployer: employer,
      confirmAlertTitle: "Remove Employer?",
      confirmAlertText:
        "Are you sure you wish to remove this employer? This cannot be undone.",
      doNotFunc: this.doNotRemoveRelationship,
      doFunc: this.doRemoveRelationshipInvitation,
    });
  };

  doRemoveRelationshipInvitation = () => {
    this.removeRelationship(this.state.selectedEmployer.id).then((r) => {
      this.setState(
        {
          confirmAlertOpen: false,
          selectedInvitation: {},
          doFunc: null,
          doNotFunc: null,
          confirmAlertTitle: "",
          confirmAlertText: "",
        },
        () => {
          this.handleGetBrokerEmployers();
        }
      );
      this.showMessage("Relationship removed sucessfully", "success");
    });
  };
  doNotRemoveRelationship = () => {
    this.setState({
      confirmAlertOpen: false,
      selectedEmployer: {},
      doFunc: null,
      doNotFunc: null,
      confirmAlertTitle: "",
      confirmAlertText: "",
    });
  };

  showMessage = (message, level) => {
    this.props.enqueueSnackbar(message, {
      variant: level,
    });
  };

  render() {
    const { employers, loaded } = this.state;
    return (
      <React.Fragment>
        <ConfirmAlert
          isOpen={this.state.confirmAlertOpen}
          isNo={this.state.doNotFunc}
          isYes={this.state.doFunc}
          AlertTitle={this.state.confirmAlertTitle}
          AlertText={this.state.confirmAlertText}
        />
        <Typography variant="h4">My Employers</Typography>
        <div style={{ height: 20 }} />
        <Typography variant="body2" color="inherit">
          You currently have access to the following employers. If you no longer
          work with one of these employers, you may remove them below. Please
          note, you will no longer have access to manage the employer through
          Mewa.io. <br />
          <br />
          If you simply would like to receive fewer notifications, you can
          adjust notification preferences in Account Settings.
        </Typography>
        <div style={{ height: 20 }} />
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Employers
        </Typography>

        {loaded && (
          <Box
            style={{
              width: 400,
            }}
          >
            <List>
              {employers.map((employer, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText primary={employer.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          this.handleDeleteRelationship(employer);
                        }}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </React.Fragment>
    );
  }
}

export default ManageEmployersContainer;
