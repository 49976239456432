import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/ContainerStyles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import api from "../../Shared/Functions/APIHelpers";

import Button from "@material-ui/core/Button";

class AcceptEmployerInvite extends React.Component {
  state = {
    invitationResult: "",
  };

  componentDidMount() {
    this.handleUpdateEmployerInvite();
  }
  getInvitationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("invitationID");
  };
  getResult = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("result");
  };

  handleUpdateEmployerInvite = () => {
    this.updateEmployerInvite()
      .then((r) => {
        //ensure correct messaging is displayed once the invite is accepted or denied properly
        if (this.getResult() === "accept") {
          this.setState({ invitationResult: "Invitation Accepted" });
        } else {
          this.setState({ invitationResult: "Invitation Denied" });
        }
      })
      .catch((err) => {
        this.setState({
          invitationResult: "This Invitation Has Been Closed or Cancelled",
        });
      });
  };

  updateEmployerInvite = () => {
    let body = {
      result: this.getResult() === "accept" ? 1 : 0,
      permission_level: 0,
    };
    return new Promise((resolve, reject) => {
      resolve(
        api.updateBrokerInvite(this.getInvitationID(), JSON.stringify(body))
      );
    });
  };
  render() {
    return (
      <React.Fragment>
        <Container
          maxWidth="sm"
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4">{this.state.invitationResult}</Typography>

          <div style={{ marginTop: 40 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.props.history.push("/broker/employers");
              }}
              style={{ width: "200px" }}
            >
              View Employers
            </Button>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AcceptEmployerInvite);
