export const styles = theme => ({
    renewalTable: {
        marginTop: 100,
        "@media only screen and (max-width: 650px)": {
          display: "none !important",
        },
      },
      tableCell: {
        "@media only screen and (max-width: 650px)": {
          display: "none !important",
      },
    },
});